<dx-load-panel
  *ngIf="isDataLoading$ | async"
  shadingColor="rgba(0,0,0,0.4)"
  [visible]="true"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
<ng-container
  *var="Entity.conferenceAttendeeDetails | hasPermission: EntityPermissionActivityKeys.write | async as canWrite"
>
  <ag-shr-modal-window
    #attendeeFormModalRef
    [title]="eventName$ | async | eventNameTitle: eventNameTitleMask : caption"
    [width]="'100%'"
    [height]="'100%'"
    customClass="attendee-details-modal__container"
    [isFullScreen]="true"
    [useScrollView]="false"
    [inProgress]="inProgress$ | async"
    [showSaveButton]="canWrite"
    (onSaveClick)="handleSaveAttendee($event)"
    [onCloseModal]="handleClosePopup"
    (onShown)="validateGroupRules()"
    (onHidden)="handelModalHidden()"
    [extraToolbarItems]="[
      {
        widget: 'dxDropDownButton',
        location: 'before',
        toolbar: 'bottom',
        visible: canWrite,
        options: {
          icon: 'email',
          items: conferenceEmailTemplates$ | async,
          displayExpr: emailTemplateDisplayExpr,
          onItemClick: sendToEmail,
          text: 'SEND TO EMAIL',
          type: 'normal',
          showArrowIcon: false,
          disabled: (hasFormChanges$ | async),
          dropDownOptions: {
            width: 230,
          },
        },
      },
    ]"
    *var="formData$ | async as formData"
  >
    <div class="attendee-details-modal__modal-wrapper" *ngIf="attendeeFormModalRef?.popupComponent?.visible ?? false">
      <div class="attendee-details-header-container" [ngClass]="{ 'header-collapsed': !isHeaderPinned }">
        <ag-crm-attendee-details-header *ngIf="isHeaderPinned" [formData]="formData"></ag-crm-attendee-details-header>
        <dx-button
          class="pin-button"
          stylingMode="text"
          [icon]="isHeaderPinned ? 'pin' : 'unpin'"
          (onClick)="onHeaderPinClick()"
        ></dx-button>
      </div>

      <div class="attendee-details-modal__validation-summary" *ngIf="canWrite">
        <dx-button
          *ngFor="let item of errors$ | async"
          class="attendee-details-modal__validation-summary-item"
          type="danger"
          stylingMode="outlined"
          [text]="item?.label | empty: 'Invalid field'"
          (onClick)="onValidationSummaryItemClick(item)"
        ></dx-button>
      </div>

      <div class="attendee-details-modal__validation-summary" *ngIf="canWrite">
        <dx-button
          *ngFor="let item of warnings$ | async"
          class="attendee-details-modal__validation-summary-item attendee-details-modal__validation-summary-item--warning"
          type="normal"
          stylingMode="outlined"
          [text]="item?.label | empty: 'Invalid field'"
          (onClick)="onValidationSummaryItemClick(item)"
        ></dx-button>
      </div>

      <div class="attendee-details-modal__tabs-container">
        <dx-tab-panel
          #tabsRef
          width="100%"
          height="100%"
          keyExpr="id"
          [animationEnabled]="false"
          [swipeEnabled]="false"
          [dataSource]="attendeeSections$ | async"
          [selectedIndex]="selectedTabIndex"
          [deferRendering]="false"
          [showNavButtons]="true"
          (onSelectionChanged)="onTabPanelSelectionChanged($event)"
        >
          <div *dxTemplate="let section of 'title'">
            <div class="attendee-details-modal__accordion-title-container">
              <span class="attendee-details-modal__accordion-title">{{ section.title }}</span>
              <i
                [id]="section?.id + '_icon'"
                *ngIf="section?.id | attendeeDetailsTitleIcon | async as iconClass"
                class="dx-icon attendee-details-modal__title-icon"
                [ngClass]="iconClass"
              >
                <dx-tooltip
                  *ngIf="section?.id | attendeeDetailsTitleIconHint | async as iconHint"
                  [target]="'#' + section?.id + '_icon'"
                  showEvent="mouseenter"
                  hideEvent="mouseleave"
                  position="top"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data = data of 'content'">{{ iconHint }}</div>
                </dx-tooltip>
              </i>
            </div>
          </div>

          <div *dxTemplate="let section of 'item'" class="attendee-details-modal__accordion-item-container">
            <ng-container [ngSwitch]="section.id">
              <ag-crm-attendee-general-info-form
                *ngSwitchCase="AttendeeDetailsModalSection.generalInfo"
                [registrantData]="formData"
                [validationGroup]="generalInfoValidationGroup"
                [primaryEmailValidationGroup]="primaryEmailValidationGroup"
                [tShirtSizesValidationGroup]="tShirtSizesValidationGroup"
                [dietaryConsiderationValidationGroup]="dietaryConsiderationValidationGroup"
                [emergencyContactValidationGroup]="emergencyContactValidationGroup"
                [emergencyContactRelationshipTypeValidationGroup]="emergencyContactRelationshipTypeValidationGroup"
                [mobilePhoneValidationGroup]="mobilePhoneValidationGroup"
                [billingAddressValidationGroup]="billingAddressValidationGroup"
                [shippingAddressValidationGroup]="shippingAddressValidationGroup"
              ></ag-crm-attendee-general-info-form>

              <ag-crm-attendee-hotel-reservation
                *ngSwitchCase="AttendeeDetailsModalSection.hotelReservation"
                [hotelReservation]="formData?.[RegistrantKeys.hotelReservation]"
                [validationGroup]="hotelReservationValidationGroup"
              ></ag-crm-attendee-hotel-reservation>

              <ag-crm-attendee-guests *ngSwitchCase="AttendeeDetailsModalSection.guests"></ag-crm-attendee-guests>

              <ag-crm-attendee-excursions
                *ngSwitchCase="AttendeeDetailsModalSection.excursions"
                [selectedExcursions]="formData?.[RegistrantKeys.selectedExcursions]"
              ></ag-crm-attendee-excursions>

              <ag-crm-attendee-notes *ngSwitchCase="AttendeeDetailsModalSection.notes"></ag-crm-attendee-notes>

              <ag-crm-attendee-transactions
                *ngSwitchCase="AttendeeDetailsModalSection.transactions"
              ></ag-crm-attendee-transactions>

              <ag-crm-attendee-flight
                *ngSwitchCase="AttendeeDetailsModalSection.flyInformation"
              ></ag-crm-attendee-flight>

              <ag-shr-changes-history-grid
                #attendeeDetailsChangeHistoryRef
                *ngSwitchCase="AttendeeDetailsModalSection.changeLog"
                [changeLogType]="ChangeLogsHistoryType.registrant"
                [attendee]="attendee$ | async"
              ></ag-shr-changes-history-grid>
            </ng-container>
          </div>
        </dx-tab-panel>
      </div>
    </div>
  </ag-shr-modal-window>
</ng-container>

<dx-popup
  *var="formData$ | async as formData"
  class="header-details-popup"
  [visible]="!isHeaderPinned"
  [hideOnOutsideClick]="false"
  [showTitle]="true"
  [shading]="false"
  [width]="isPopupMinimized ? 230 : 320"
  [height]="isPopupMinimized ? 100 : 300"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  (onHiding)="isHeaderPinned = true"
  (onContentReady)="onContentReady($event)"
>
  <!-- Custom Title Template -->
  <div *dxTemplate="let data of 'title'">
    <div class="custom-popup-title">
      <!-- Popup title (full name) -->
      <span class="popup-title-text">
        {{
          [
            formData?.[RegistrantKeys.prefix] | prefix | async,
            formData?.[RegistrantKeys.firstName],
            formData?.[RegistrantKeys.middleName],
            formData?.[RegistrantKeys.lastName],
            formData?.[RegistrantKeys.suffix] | suffix | async,
          ] | fullName
        }}
      </span>

      <!-- Minimize button -->
      <dx-button
        class="minimize-button"
        [icon]="isPopupMinimized ? 'fullscreen' : 'minus'"
        stylingMode="text"
        (onClick)="minimizePopup()"
      ></dx-button>

      <!-- Close button -->
      <dx-button icon="close" stylingMode="text" (onClick)="closePopup()"></dx-button>
    </div>
  </div>

  <dxo-position my="right top" at="right top" of="#attendeeFormModalRef"> </dxo-position>
  <div class="header-popup-content">
    <ag-crm-attendee-details-header
      [formData]="formData$ | async"
      [showPicture]="false"
    ></ag-crm-attendee-details-header>
  </div>
</dx-popup>
