import { Injectable } from '@angular/core';
import { ConferenceGuestsService } from 'ag-common-svc/lib/services/conference-guests.service';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';
import { combineLatest, firstValueFrom, map, mergeMap, Observable, shareReplay } from 'rxjs';
import {
  AssociationKeys,
  AttendeeType,
  Conference,
  ConferenceKeys,
  ConferenceRegistrationCategoryName,
  ConferenceRegistrationCommonTask,
  ExcursionStatistic,
  GuestData,
  Registrant,
  RegistrantKeys,
  RegistrantModelKeys,
} from 'ag-common-lib/public-api';
import {
  ExcursionInfoTableData,
  ExcursionInfoTableDataKeys,
} from 'ag-common-svc/lib/components/excursions-info-table/excursions-info-table.models';

import { convertSelectedExcursionsToList, getExcursionsMap } from 'ag-common-svc/lib/utils/excursions-data.utils';
import { ConferenceRegistrantsService, ConferenceService } from 'ag-common-svc/public-api';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Injectable()
export class AttendeeExcursionsService {
  selectedExcursionsDataSource$: Observable<ExcursionInfoTableData[]>;
  registrantGuests$: Observable<GuestData[]>;
  conference$: Observable<Conference> = this.attendeeDetailsModalService.conference$;
  excursionStatisticsMap$: Observable<Map<string, ExcursionStatistic>> =
    this.attendeeDetailsModalService.conferenceDbId$.pipe(
      mergeMap(conferenceDbId => this.conferenceService.getExcursionStatistics(conferenceDbId)),
    );
  excursionsTaskStatus$: Observable<ConferenceRegistrationCommonTask>;
  assignOwnerList$ = this.attendeeDetailsModalService.assignOwnerList$;

  constructor(
    private conferenceService: ConferenceService,
    private conferenceGuestsService: ConferenceGuestsService,
    private attendeeDetailsModalService: AttendeeDetailsModalService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
  ) {
    this.registrantGuests$ = this.attendeeDetailsModalService.registrant$.pipe(
      mergeMap((conferenceRegistration: Registrant) => {
        return this.conferenceGuestsService.getList(
          conferenceRegistration?.[RegistrantModelKeys.conferenceDbId],
          conferenceRegistration?.[BaseModelKeys.dbId],
        );
      }),
      shareReplay(1),
    );

    const excursions$ = this.conference$.pipe(map(conference => conference?.[ConferenceKeys.excursions] ?? []));

    this.selectedExcursionsDataSource$ = combineLatest({
      registrant: this.attendeeDetailsModalService.registrant$,
      registrantGuests: this.registrantGuests$,
      excursions: excursions$,
    }).pipe(
      map(({ registrant, registrantGuests, excursions }) => {
        const conferenceRegistrationData = registrant?.[RegistrantModelKeys.data];
        const { excursionsDates, excursionsMap } = getExcursionsMap(excursions);
        const list: ExcursionInfoTableData[] = convertSelectedExcursionsToList(
          excursionsMap,
          conferenceRegistrationData[RegistrantKeys.selectedExcursions],

          {
            [ExcursionInfoTableDataKeys.conferenceDbId]: registrant?.[RegistrantModelKeys.conferenceDbId],
            [ExcursionInfoTableDataKeys.registrantDbId]: registrant?.[BaseModelKeys.dbId],
            [ExcursionInfoTableDataKeys.attendeeId]: registrant?.[BaseModelKeys.dbId],
            [ExcursionInfoTableDataKeys.fullName]: [
              conferenceRegistrationData[RegistrantKeys.firstName],
              conferenceRegistrationData[RegistrantKeys.lastName],
            ]
              .filter(Boolean)
              .join(' '),
            [ExcursionInfoTableDataKeys.attendeeType]: AttendeeType.Invitee,
            [ExcursionInfoTableDataKeys.attendeeDob]: conferenceRegistrationData[RegistrantKeys.dob],
          },
          excursionsDates,
        );

        registrantGuests.forEach(guest => {
          const guestExcursionsList = convertSelectedExcursionsToList(
            excursionsMap,
            guest[RegistrantKeys.selectedExcursions],
            {
              [ExcursionInfoTableDataKeys.conferenceDbId]: registrant?.[RegistrantModelKeys.conferenceDbId],
              [ExcursionInfoTableDataKeys.registrantDbId]: registrant?.[BaseModelKeys.dbId],
              [ExcursionInfoTableDataKeys.attendeeId]: guest?.[BaseModelKeys.dbId],
              [ExcursionInfoTableDataKeys.fullName]: [guest[AssociationKeys.firstName], guest[AssociationKeys.lastName]]
                .filter(Boolean)
                .join(' '),
              [ExcursionInfoTableDataKeys.attendeeType]: AttendeeType.Guest,
              [ExcursionInfoTableDataKeys.attendeeDob]: guest[AssociationKeys.dob],
            },
            excursionsDates,
          );

          list.push(...guestExcursionsList);
        });

        return list;
      }),
    );

    this.excursionsTaskStatus$ = this.attendeeDetailsModalService.registrant$.pipe(
      map(registrant => {
        return registrant?.[RegistrantModelKeys.task]?.[ConferenceRegistrationCategoryName.registrantExcursionCategory];
      }),
      shareReplay(1),
    );
  }

  updateExcursionsTask = async (task: Partial<ConferenceRegistrationCommonTask>) => {
    const registrant = await firstValueFrom(this.attendeeDetailsModalService.registrant$);
    const registrantId = registrant[BaseModelKeys.dbId];
    const conferenceDbId = registrant[RegistrantModelKeys.conferenceDbId];

    const update = {
      [RegistrantModelKeys.task]: {
        [ConferenceRegistrationCategoryName.registrantExcursionCategory]: task,
      },
    };

    await this.conferenceRegistrantsService.update(conferenceDbId, registrantId, update);
  };
}
