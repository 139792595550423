import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import {
  ActiveLookup,
  Address,
  AddressModelKeys,
  BUSINESS_PERSONAL_TYPE_LOOKUP,
  COUNTRIES,
} from '@ag-common-lib/public-api';
import { Observable } from 'rxjs';
import { ColumnButtonClickEvent, RowRemovingEvent } from 'devextreme/ui/data_grid';
import { LookupsService } from 'ag-common-svc/public-api';
import { FullAddressPipe } from 'ag-common-svc/shared/pipes/full-address.pipe';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-addresses-grid',
  templateUrl: './addresses-grid.component.html',
  styleUrls: ['./addresses-grid.component.scss'],
  providers: [FullAddressPipe],
})
export class AddressesGridComponent {
  @HostBinding('class') className = 'addresses-grid';

  @Input() addresses: Address[] = [];
  @Input() isEditable: boolean = true;
  @Input() emptyMessage: string = 'No Addresses Currently Exist';
  @Input() hasPermissionsToAdd: boolean = true;
  @Input() hasPermissionsToEdit: boolean = true;
  @Input() hasPermissionsToDelete: boolean = true;
  @Input() showPhysicalLocation = true;
  @Input() showBillingAddress = true;
  @Input() showShippingAddress = true;
  @Input() typesLookup: Partial<ActiveLookup>[] = BUSINESS_PERSONAL_TYPE_LOOKUP;
  @Output() addAddress = new EventEmitter<void>();
  @Output() editAddress = new EventEmitter<Address>();
  @Output() removeAddress = new EventEmitter<RowRemovingEvent>();

  protected readonly countries = COUNTRIES;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly AddressModelKeys = AddressModelKeys;
  protected readonly statesLookup$: Observable<ActiveLookup[]> = this.lookupsService.statesLookup$;

  constructor(
    private readonly lookupsService: LookupsService,
    protected fullAddressPipe: FullAddressPipe,
  ) {}

  protected handleAddAddress = () => {
    this.addAddress.emit();
  };

  protected handleEditAddress = (e: ColumnButtonClickEvent) => {
    this.editAddress.emit(e?.row?.data);
  };

  protected canDeleteRow = e => {
    return !e.row.data.is_primary_billing && !e.row.data.is_primary_shipping;
  };
}
