import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import {
  Conference,
  CONFERENCE_STEPS_TEMPLATE_NAME_LOOKUP,
  ConferenceKeys,
  ConferenceRegistrationStepName,
  ConferenceStepsConfiguration,
  ConferenceStepsConfigurationKeys,
  Entity,
  EntityPermissionActivityKeys,
  INITIAL_CONFERENCE_STEPS_CONFIGURATION,
  LookupKeys,
} from 'ag-common-lib/public-api';
import { CONFERENCE_STEPS_SECTIONS } from './conference-steps-info-configuration.models';
import { ConferenceStepInfoModalComponent } from './conference-step-info-modal/conference-step-info-modal.component';
import { ConferenceService } from 'ag-common-svc/lib/services/conference.service';
import { ClickEvent } from 'devextreme/ui/button';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-crm-conference-steps-info-config-form',
  templateUrl: './conference-steps-info-configuration-form.component.html',
  styleUrls: ['./conference-steps-info-configuration-form.component.scss'],
})
export class ConferenceStepsInfoConfigurationFormComponent implements OnInit {
  @HostBinding('class') className = 'conference-steps-info-form';
  @ViewChild('conferenceStepModalRef') conferenceStepModal: ConferenceStepInfoModalComponent;
  @Input() conference: Conference;
  @Input() validationGroup: string;
  @Input() inProgress: boolean = false;
  @Input() isReadonlyMode: boolean = false;

  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly stepNameLookup = CONFERENCE_STEPS_SECTIONS;
  protected readonly stepNameTemplateLookup = CONFERENCE_STEPS_TEMPLATE_NAME_LOOKUP;
  protected readonly ConferenceStepsConfigurationKeys = ConferenceStepsConfigurationKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly ConferenceRegistrationStepName = ConferenceRegistrationStepName;

  constructor(private conferenceService: ConferenceService) {}

  ngOnInit(): void {
    const configurations = this.conference?.[ConferenceKeys.stepsConfiguration];
    const stepsConfigurations = configurations?.length ? configurations : INITIAL_CONFERENCE_STEPS_CONFIGURATION;
    Object.assign(this.conference, { [ConferenceKeys.stepsConfiguration]: stepsConfigurations });
  }

  protected showEditPopup = ({ row: { data } }): void => {
    this.conferenceStepModal.showModal(data, this.conference?.[BaseModelKeys.dbId], this.onRowUpdate);
  };

  private onRowUpdate = async (data: ConferenceStepsConfiguration) => {
    const configurations: ConferenceStepsConfiguration[] = [];

    this.conference?.[ConferenceKeys.stepsConfiguration]?.forEach(stepsConfiguration => {
      const configuration = Object.assign({}, stepsConfiguration);
      if (
        data?.[ConferenceStepsConfigurationKeys.stepName] ===
        stepsConfiguration?.[ConferenceStepsConfigurationKeys.stepName]
      ) {
        Object.assign(configuration, data);
      }
      configurations.push(configuration);
    });
    return this.saveChanges(configurations);
  };

  private saveChanges = async (configurations: ConferenceStepsConfiguration[]) => {
    Object.assign(this.conference, { [ConferenceKeys.stepsConfiguration]: configurations });
    return false;
  };

  protected toggleSectionForWizard = (e: ClickEvent, activeControlKey, isActive) => {
    e?.event?.stopPropagation();

    this.conference[activeControlKey] = !isActive;
  };

  protected toggleSection = (e: ClickEvent, enableControlKey, isEnabled) => {
    e?.event?.stopPropagation();

    this.conference[enableControlKey] = !isEnabled;
  };
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly Entity = Entity;
}
