import { Injectable } from '@angular/core';
import {
  Agent,
  AgentKeys,
} from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/domain/agent.model';
import { FlightPreference } from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/flight-preference.model';
import { BaseFormService } from '../../../../utils/base-form-service';
import { AgentService } from '../../../../services/agent.service';

@Injectable({
  providedIn: 'root',
})
export class FlightPreferencesService extends BaseFormService<FlightPreference> {
  constructor(private readonly agentService: AgentService) {
    super();
  }

  public getFormData = (agent?: Agent) => {
    const initialFlightPreferenceInitialData = Object.assign({}, agent?.[AgentKeys.flightPreference]);

    this.formData = new Proxy(initialFlightPreferenceInitialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });

    return this.formData;
  };

  public handleSave = agentId => {
    const updates = {};

    Object.assign(updates, { [AgentKeys.flightPreference]: Object.assign({}, this.formData) });

    this.startProgress();
    return this.agentService
      .updateAgentFields(agentId, updates)
      .then(() => {
        this.formChangesDetector.clear();

        return updates;
      })

      .finally(() => {
        this.stopProgress();
      });
  };
}
