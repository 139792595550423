import { Component, Input, HostBinding, ViewChild } from '@angular/core';
import {
  AgentKeys,
  Conference,
  ConferenceKeys,
  ElasticSearchAgent,
  EMAIL_ACTION_TRIGGERS_LOOKUP,
  EmailAddressKeys,
  EmailTemplateConfiguration,
  EmailTemplateConfigurationKeys,
  EmailTemplateModel,
  EmailTemplatesDetailsMap,
  EmailTemplatesModels,
  EVENTS_EMAIL_TEMPLATES,
  LookupKeys,
  Registrant,
  RegistrantKeys,
  RegistrantModelKeys,
} from 'ag-common-lib/public-api';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import DataSource from 'devextreme/data/data_source';
import { getAgentFullName } from 'ag-common-svc/lib/services/agent.service/agent-service-utils';
import CustomStore from 'devextreme/data/custom_store';
import { DxFilterOperators } from 'ag-common-svc/lib/services/elastic-search.services/base-elastic-search-service';
import {
  AgentEmailAddressesService,
  AuthService,
  ConferenceRegistrantsService,
  ConferenceService,
} from 'ag-common-svc/public-api';
import { RowInsertingEvent, RowUpdatingEvent, RowRemovingEvent, ColumnButtonClickEvent } from 'devextreme/ui/data_grid';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import {
  EventInquiryRequest,
  EventInquiryRequestKeys,
} from 'ag-common-lib/lib/models/registration/event-inquiry-request.model';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { filter, firstValueFrom, mergeMap, Observable, shareReplay } from 'rxjs';
import { ConferenceModalService } from '../conference-form.service';
import { EventInquiryRequestsService } from 'ag-common-svc/lib/services/event-inquire-requests.service';
import { RegistrationParticipationEmailService } from 'ag-common-svc/lib/services/registration-participation-email.service';
import { RegistrationSummaryEmailService } from 'ag-common-svc/lib/services/registration-summary-email.service';
import { AttendeesEmailsService } from 'src/app/ag-crm/registrations/attendees-emails.service';
import { ConferenceRegistrationEmailCampaignService } from 'ag-common-svc/lib/services/email-campaigns/conference-registration-email-campaign.service';

@Component({
  selector: 'ag-crm-conference-email-sender-form',
  templateUrl: './conference-email-sender-form.component.html',
  styleUrls: ['./conference-email-sender-form.component.scss'],
  providers: [RegistrationSummaryEmailService, ConferenceRegistrationEmailCampaignService, AttendeesEmailsService],
})
export class ConferenceEmailSenderFormComponent {
  @HostBinding('class') className = 'conference-email-sender-form';
  @ViewChild('sendTestEmailModalRef', { static: true }) sendTestEmailModalComponent: ModalWindowComponent;
  @Input() conference: Partial<Conference>;
  @Input() validationGroup: string;
  @Input() isReadonlyMode: boolean = false;

  protected readonly LookupKeys = LookupKeys;
  protected readonly AgentKeys = AgentKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly EmailTemplatesModels = EmailTemplatesModels;
  protected readonly RegistrantModelKeys = RegistrantModelKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly EventInquiryRequestKeys = EventInquiryRequestKeys;
  protected readonly EmailTemplateConfigurationKeys = EmailTemplateConfigurationKeys;
  protected readonly emailActionTriggersLookup = EMAIL_ACTION_TRIGGERS_LOOKUP;
  protected readonly emailTemplatesLookup = EVENTS_EMAIL_TEMPLATES;
  protected readonly agentsEmailAddressesDataSource = this.agentEmailAddressesService.agentsEmailAddressesDataSource;
  protected dataSource = new DataSource({
    paginate: true,
    pageSize: 250,
    store: new CustomStore({
      key: 'dbId',
      loadMode: 'processed',
      load: async loadOptions => {
        const filter: any[] = [];

        if (loadOptions.filter) {
          this.conference?.[ConferenceKeys.bccEmail]?.forEach((item: any) => {
            if (filter?.length) {
              filter.push('or');
            }
            filter.push([AgentKeys.email_addresses, DxFilterOperators.contains, item?.address]);
          });
        }

        if (loadOptions.searchValue) {
          [AgentKeys.email_addresses, AgentKeys.p_agent_first_name, AgentKeys.p_agent_last_name].forEach(field => {
            if (filter?.length) {
              filter.push('or');
            }

            filter.push([field, DxFilterOperators.contains, loadOptions.searchValue]);
          });
        }

        loadOptions.filter = filter;
        const data = [];
        const response = await this.agentElasticSearchService.getFromElastic(loadOptions);
        const aggregations = response?.aggregations;
        const emailsAggregations = aggregations?.[AgentKeys.email_addresses];

        response?.data?.forEach((agent: ElasticSearchAgent) => {
          const fullName = getAgentFullName(agent);
          agent?.[AgentKeys.email_addresses]?.forEach(emailAddress => {
            const address = emailAddress?.[EmailAddressKeys.address];
            data.push({
              name: fullName,
              address: address,
            });
          });
        });

        return {
          data,
          totalCount: emailsAggregations?.doc_count,
        };
      },
    }),
  });

  protected conferenceRegistrants$: Observable<Registrant[]> = this.conferenceModalService.conferenceDbId$.pipe(
    filter(Boolean),
    mergeMap(conferenceDbId => {
      return this.conferenceRegistrantsService.getRegistrantsByConferenceId(conferenceDbId);
    }),
    shareReplay(1),
  );
  protected eventInquiryRequests$: Observable<EventInquiryRequest[]> = this.conferenceModalService.conferenceDbId$.pipe(
    mergeMap(conferenceDbId => {
      return this.eventInquiryRequestsService.getList(conferenceDbId);
    }),
    shareReplay(1),
  );

  protected testEmailFormData: {
    to: string[];
    emailTemplate: EmailTemplateModel;
    emailTemplateConfiguration: EmailTemplateConfiguration;
    payload?: Registrant | EventInquiryRequest;
  };

  constructor(
    private authService: AuthService,
    private attendeesEmailsService: AttendeesEmailsService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
    private eventInquiryRequestsService: EventInquiryRequestsService,
    private conferenceService: ConferenceService,
    private conferenceModalService: ConferenceModalService,
    private agentElasticSearchService: AgentElasticSearchService,
    private agentEmailAddressesService: AgentEmailAddressesService,
    private registrationParticipationEmailService: RegistrationParticipationEmailService,
  ) {}

  protected handlerSendTestEmail = async (e: ColumnButtonClickEvent<EmailTemplateConfiguration>) => {
    const emailTemplateConfiguration: EmailTemplateConfiguration = e?.row?.data;
    const agent = await firstValueFrom(this.authService.loggedInAgent$);
    const primaryEmail = agent?.[AgentKeys.p_email];
    const templateName = emailTemplateConfiguration?.[EmailTemplateConfigurationKeys.templateName];
    const emailTemplate = EmailTemplatesDetailsMap.get(templateName);

    this.testEmailFormData = {
      to: [primaryEmail].filter(Boolean),
      emailTemplate,
      emailTemplateConfiguration,
      payload: null,
    };

    this.sendTestEmailModalComponent.showModal();
  };

  protected sendToEmail = async () => {
    try {
      const emailRecipientTestMode = this.testEmailFormData.to;
      const emailTemplate = this.testEmailFormData.emailTemplate;
      const emailTemplateConfiguration = this.testEmailFormData.emailTemplateConfiguration;

      switch (emailTemplate.model) {
        case EmailTemplatesModels.registrationInquiry:
          const eventInquiryRequest = this.testEmailFormData.payload;
          const emailActionTrigger = emailTemplateConfiguration?.[EmailTemplateConfigurationKeys.trigger];
          const eventInquiryRequestDbId = eventInquiryRequest?.[BaseModelKeys.dbId];
          const eventInquiryDbId = eventInquiryRequest?.[EventInquiryRequestKeys.eventInquiryDbId];

          this.registrationParticipationEmailService.sendRegistrationParticipationEmail({
            isTestEmail: true,
            eventInquiryDbId,
            emailRecipientTestMode,
            emailActionTrigger,
            eventInquiryRequestDbId,
          });
          break;

        case EmailTemplatesModels.registrant:
          const registrant: Registrant = this.testEmailFormData.payload;

          this.attendeesEmailsService.sendEmails(emailTemplateConfiguration, {
            registrants: [registrant],
            isTestEmail: true,
            emailRecipientTestMode,
          });
          break;
      }

      this.sendTestEmailModalComponent.forceCloseModal();
    } catch (error) {
      debugger;
    }
  };

  protected emailTemplateDisplayExpr = item => {
    const template = item?.templateName;
    const templateConfiguration = EmailTemplatesDetailsMap.get(template);
    const title = templateConfiguration?.title;

    return title;
  };

  protected setTemplateCellValue = (updatedData, value) => {
    const eventName = this.conference?.[ConferenceKeys.eventName];
    const templateData = EmailTemplatesDetailsMap.get(value);

    Object.assign(updatedData, {
      [EmailTemplateConfigurationKeys.templateName]: value,
      [EmailTemplateConfigurationKeys.subject]: [eventName, templateData?.title].filter(Boolean).join(' - '),
    });
  };

  protected calculateTemplateNameDisplayValue = (templateConfiguration: EmailTemplateConfiguration) => {
    const template = templateConfiguration?.[EmailTemplateConfigurationKeys.templateName];
    const templateData = EmailTemplatesDetailsMap.get(template);

    if (!templateData) {
      return template;
    }

    return [templateData?.forEvent, templateData?.title].filter(Boolean).join(' - ');
  };

  protected onRowInserting = (e: RowInsertingEvent) => {
    delete e.data.__KEY__;

    const templateConfiguration: EmailTemplateConfiguration = Object.assign({}, e.data);
    const trigger = templateConfiguration?.trigger;

    const templateConfigurations = [];

    this.conference[ConferenceKeys.emailTemplates]?.forEach(item => {
      const itemTrigger = item?.trigger;
      const template = Object.assign({}, item);

      if (itemTrigger === trigger) {
        template.trigger = null;
      }
      templateConfigurations.push(template);
    });
    templateConfigurations.push(Object.assign({}, e.data));

    e.cancel = this.saveChanges(templateConfigurations);
  };

  protected onRowUpdating = (e: RowUpdatingEvent) => {
    const templateConfigurations = [];
    const trigger = Object.assign({}, e.oldData, e.newData)?.trigger;
    debugger;

    this.conference[ConferenceKeys.emailTemplates]?.forEach(range => {
      const template = Object.assign({}, range);
      templateConfigurations.push(template);

      if (e.key === range) {
        Object.assign(template, e.newData);
        return;
      }

      const itemTrigger = template?.trigger;
      if (itemTrigger === trigger) {
        template.trigger = null;
      }
    });

    e.cancel = this.saveChanges(templateConfigurations);
  };

  protected onRowRemoving = (e: RowRemovingEvent) => {
    const templateConfigurations = [];

    this.conference[ConferenceKeys.emailTemplates]?.forEach(range => {
      if (e.key !== range) {
        const template = Object.assign({}, range);
        templateConfigurations.push(template);
      }
    });

    e.cancel = this.saveChanges(templateConfigurations);
  };

  protected canSendTestEmail = (e: ColumnButtonClickEvent<EmailTemplateConfiguration>) => {
    return !e?.row?.isEditing;
  };

  private saveChanges = async (templateConfigurations: EmailTemplateConfiguration[]) => {
    const conferenceDbId = this.conference?.[BaseModelKeys.dbId];

    if (!conferenceDbId) {
      Object.assign(this.conference, { [ConferenceKeys.emailTemplates]: templateConfigurations });
      return false;
    }

    return this.conferenceService
      .updateFields(conferenceDbId, { [ConferenceKeys.emailTemplates]: templateConfigurations })
      .then(() => {
        Object.assign(this.conference, { [ConferenceKeys.emailTemplates]: templateConfigurations });
        return false;
      })
      .catch(() => {
        return true;
      });
  };
}
