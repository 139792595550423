import { Inject, Injectable } from '@angular/core';

import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP } from '../injections/firebase-app';
import { DataService } from './data.service';
import { BaseModel } from '@ag-common-lib/lib/models/base.model';

const AUTH_LOG_COLLECTION_NAME = 'auth-log';

@Injectable({
  providedIn: 'root',
})
export class AuthLogService extends DataService<BaseModel> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp);
    this.collection = AUTH_LOG_COLLECTION_NAME;
  }

  create(): Promise<BaseModel> {
    return super.create({});
  }
}
