import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogPipesModule } from '../../../pipes/change-log-pipes.module';
import { ChangeLogAgentCampaignsComponent } from './change-log-agent-campaigns.component';

@NgModule({
  declarations: [ChangeLogAgentCampaignsComponent],
  imports: [CommonModule, SharedModule, ChangeLogPipesModule],
  exports: [ChangeLogAgentCampaignsComponent],
})
export class ChangeLogAgentCampaignsModule {}
