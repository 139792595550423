import { EmailLog } from '@ag-common-lib/public-api';
import { Inject, Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import { EmailLogKeys } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/email-log.model';
import { dateFromTimestamp } from '../utils/date-from-timestamp';
import { SHOULD_FETCH_TARGET_ACTIVITIES_SET } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/activity-info.model';
import {
  ActivityBase,
  ActivityBaseKeys,
} from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/activity-base.model';
import { QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';
import { combineLatest, defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangeSourceType } from '@ag-common-lib/public-api';
import { EmailAddress } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/email-address.model';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable({
  providedIn: 'root',
})
export class EmailLogsService extends DataService<EmailLog> {
  private readonly emailLogsCollectionPath = 'crm-record-email-logsClone';

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, EmailLogsService.fromFirestore);
    super.setCollection(this.emailLogsCollectionPath);
  }

  static readonly fromFirestore = (data): EmailLog => {
    return Object.assign({}, data, {
      [EmailLogKeys.date]: dateFromTimestamp(data[EmailLogKeys.date]),
    }) as EmailLog;
  };

  updateEmailLogsFields(
    documentId: string,
    data: Partial<EmailLog>,
    action: ChangeSourceType | null = null,
  ): Promise<EmailLog> {
    Object.assign(data, {
      [BaseModelKeys.actionName]: action,
    });
    return super.updateFields(documentId, data, true);
  }

  getEmailLogsList = (activityConfig: ActivityBase, targetContactEmails: EmailAddress[]) =>
    defer(() => {
      const targetType = activityConfig[ActivityBaseKeys.targetType];
      const targetId = activityConfig[ActivityBaseKeys.targetId];
      const shouldFetchTargetActivities = SHOULD_FETCH_TARGET_ACTIVITIES_SET.has(targetType);
      const targetQp: QueryParam[] = [];
      const senderQp: QueryParam[] = [];
      const toQp: QueryParam[] = [];
      const ccQp: QueryParam[] = [];
      const bccQp: QueryParam[] = [];

      targetQp.push(new QueryParam(ActivityBaseKeys.targetId, WhereFilterOperandKeys.equal, targetId));

      senderQp.push(new QueryParam(EmailLogKeys.sender, WhereFilterOperandKeys.in, targetContactEmails));
      toQp.push(new QueryParam(EmailLogKeys.to, WhereFilterOperandKeys.arrayContainsAny, targetContactEmails));
      ccQp.push(new QueryParam(EmailLogKeys.cc, WhereFilterOperandKeys.arrayContainsAny, targetContactEmails));
      bccQp.push(new QueryParam(EmailLogKeys.bcc, WhereFilterOperandKeys.arrayContainsAny, targetContactEmails));

      const queries: Observable<EmailLog[]>[] = [];

      if (shouldFetchTargetActivities) {
        queries.push(this.getList(targetQp));
      }
      if (targetContactEmails?.length) {
        queries.push(this.getList(senderQp));
        queries.push(this.getList(toQp));
        queries.push(this.getList(ccQp));
        queries.push(this.getList(bccQp));
      }

      return combineLatest(queries);
    }).pipe(
      map(items => {
        const itemsMap = new Map();

        items?.forEach(emailLogs =>
          emailLogs?.forEach(emailLog => {
            const dbId = emailLog[BaseModelKeys.dbId];
            itemsMap.set(dbId, emailLog);
          }),
        );

        return Array.from(itemsMap.values());
      }),
    );
}
