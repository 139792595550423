import { Injectable } from '@angular/core';
import { Agent, ChangeSourceType, LookupKeys } from '@ag-common-lib/public-api';
import { BehaviorSubject } from 'rxjs';
import { FormChangesDetector } from '../../../../../shared/utils';
import { confirm } from 'devextreme/ui/dialog';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { AgentService } from '../../../../services/agent.service/agent.service';
import { updateDoc } from 'firebase/firestore';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable()
export class PersonalInformationService {
  formData: Partial<Agent>;

  private readonly _inProgress$ = new BehaviorSubject<boolean>(false);
  inProgress$ = this._inProgress$.asObservable();

  selectedGender$ = new BehaviorSubject(null);

  readonly formChangesDetector = new FormChangesDetector();

  private _agentId: string;
  constructor(private agentService: AgentService) {}

  cancelPersonalInfo = ({ event, component }): void => {
    if (!this.formChangesDetector?.hasChanges) {
      return;
    }

    event.cancel = true;

    const result = confirm('<i>Are you sure you want to Cancel without Saving?</i>', 'Confirm');
    result.then(dialogResult => {
      if (dialogResult) {
        const changes = this.formChangesDetector.getAllChanges();

        changes.forEach(([key, value]) => {
          Object.assign(this.formData, { [key]: value });
        });

        this.formChangesDetector?.clear();
        component.instance.hide();
      }
    });
  };

  savePersonalInfo = (modalWindowComponent: ModalWindowComponent, action: ChangeSourceType | null = null) => {
    const hasChanges = this.formChangesDetector.hasChanges;

    if (hasChanges) {
      const agentId = this._agentId;
      const updates = {};
      const changes = this.formChangesDetector.getAllChanges();

      changes.forEach(([key]) => {
        const update = this.formData[key] ?? null;
        Object.assign(updates, { [key]: update });
      });
      this._inProgress$.next(true);
      return this.agentService
        .updateAgentFields(agentId, updates, action)
        .then(() => {
          const selectedGender = this.selectedGender$.value;
          if (selectedGender && !selectedGender.isAssigned) {
            updateDoc(selectedGender.reference, { [LookupKeys.isAssigned]: true }).then();
          }
          this.formChangesDetector.clear();
          modalWindowComponent?.hideModal();
          return { agentId, updates };
        })
        .finally(() => {
          this._inProgress$.next(false);
        });
    }

    return null;
  };

  getFormData = (agent?: Agent) => {
    this._agentId = agent[BaseModelKeys.dbId];
    const initialData = Object.assign({}, agent);
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);
        return true;
      },
    });

    return this.formData;
  };
}
