import { Injectable } from '@angular/core';
import { PolicyTransaction, PolicyTransactionKeys } from '@ag-common-lib/lib/models/domain/policy-transaction.model';
import { Agency } from '@ag-common-lib/public-api';
import { AgencyService } from './agency.service';
import * as papaparse from 'papaparse';
import { isMatch, parse } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class ImportFileValidatorService {
  agencyToAgencyIdMappingTable: Map<string, Agency> = new Map<string, Agency>();
  private _transactionsMap = new Map<string, PolicyTransaction[]>();

  constructor(public agencyService: AgencyService) {}

  importPaidFiles = async (files: File[], messagesMonitor: string[], selectedYear: number): Promise<PolicyTransaction[]> => {
    const promises: Promise<PolicyTransaction[]>[] = files.map(this.parseFile);

    const transactionsCollections = await Promise.all(promises);
    const transactions = transactionsCollections.flat(1);

    try {
      await this.evaluateImport(messagesMonitor, selectedYear);
    } catch (error) {
      messagesMonitor.unshift('Stopping import until all records are corrected!');
      throw new Error(error);
    }

    return transactions;
  };

  private parseFile = (file: File): Promise<PolicyTransaction[]> => {
    return new Promise((res, rej) => {
      papaparse.parse(file, {
        header: true,
        worker: true,
        skipEmptyLines: 'greedy',
        error(error, file) {
          debugger;
        },
        complete: (results) => {
          const policyTransactions = results.data;
          this._transactionsMap.set(file.name, policyTransactions);
          res(policyTransactions);
        },
      });
    });
  };

  // TODO need to refactor
  private evaluateImport(messages: string[], selectedYear: number): Promise<void> {
    let carrierMissingIDCount: number = 0;
    let mgaMissingIDCount: number = 0;
    let agentMissingIDCount: number = 0;
    let incorrectDateFormatCount: number = 0;
    let incorrectNumberFormatCount: number = 0;
    let incorrectTransactionYearCount: number = 0;

    return this.agencyService.getMGAAgencies('name').then(
      agencies => {
        this.agencyToAgencyIdMappingTable = new Map(
          agencies.map(agency => {
            return [agency.agency_id, agency];
          }),
        );

        let missingMGAList: Map<string, string> = new Map<string, string>();
        this._transactionsMap.forEach((value, key) => {
          const fileName = key;
          const transactions = value;
          messages.unshift(`<${fileName}> file validation ...`);
          transactions.forEach((t, index) => {
            const fileRow = index + 2;
            const fileNameEndString = `in "${fileName}"`;
            const isNumbersWellFormatted = [
              PolicyTransactionKeys.faceAmount,
              PolicyTransactionKeys.lifePrem,
              PolicyTransactionKeys.targetPrem,
              PolicyTransactionKeys.excessPrem,
              PolicyTransactionKeys.annuity,
              PolicyTransactionKeys.policies,
              PolicyTransactionKeys.weightedPrem,
            ].every(key => {
              const value = t[key];
              if (!value) {
                return true;
              }
              const isNaN = Number.isNaN(Number(value));

              if (isNaN) {
                messages.unshift('========================================================');
                messages.unshift(`FATAL ERROR: Can't Import: ${key} is incorrectly formatted in ${fileRow} file row ${fileNameEndString}`);
                messages.unshift('========================================================');
              }
              return !isNaN;
            });

            if (!isNumbersWellFormatted) {
              incorrectNumberFormatCount++;
            }
            if (t.mga_id == '' || t.mga_id == null || t.mga_name == '' || t.mga_name == null) {
              mgaMissingIDCount++;
            } else {
              if (!this.agencyToAgencyIdMappingTable.has(t.mga_id)) {
                missingMGAList.set(t.mga_id, t.mga_name);
              }
            }

            if (t.carrier_name == '' || t.carrier_name == null || t.recruiter == '' || t.recruiter == null) {
              carrierMissingIDCount++;
            }

            if (t.agent_id == '' || t.agent_id == null) {
              agentMissingIDCount++;
            }

            if (!isMatch(t.transdate, 'M/d/yyyy')) {
              incorrectDateFormatCount++;
            }

            const transactionDate = parse(t.transdate, 'M/d/yyyy', new Date());
            if (transactionDate.getFullYear() !== selectedYear) {
              incorrectTransactionYearCount++;
              messages.unshift('========================================================');
              messages.unshift(`FATAL ERROR: Wrong year in ${fileRow} file row ${fileNameEndString}`);
              messages.unshift('========================================================');
            }
          });
        });

        if (incorrectDateFormatCount > 0) {
          messages.unshift('========================================================');
          messages.unshift(
            `FATAL ERROR: Can't Import: Transdate is incorrectly formatted in ${incorrectDateFormatCount} records`,
          );
          messages.unshift('========================================================');
          throw new Error();
        }

        if (incorrectNumberFormatCount > 0) {
          messages.unshift('========================================================');
          messages.unshift(
            `FATAL ERROR: Can't Import: Values is incorrectly formatted in ${incorrectNumberFormatCount} records`,
          );
          messages.unshift('========================================================');
          throw new Error();
        }

        if (carrierMissingIDCount > 0) {
          messages.unshift('========================================================');
          messages.unshift(
            `FATAL ERROR: Can't Import: Import File is missing either a Carrier Name or a Carrier ID in ${carrierMissingIDCount} records`,
          );
          messages.unshift('========================================================');
          throw new Error();
        }

        if (mgaMissingIDCount > 0) {
          messages.unshift('========================================================');
          messages.unshift(
            `FATAL ERROR: Can't Import: Import File is missing either a MGA Name or a MGA ID in ${mgaMissingIDCount} records`
          );
          messages.unshift('========================================================');
          throw new Error();
        }

        if (missingMGAList.size > 0) {
          missingMGAList.forEach((value, key) => {
            messages.unshift('Import File contains unknown MGA Id: ' + value + '(' + key + ').');
          });
          messages.unshift('========================================================');
          messages.unshift(`FATAL ERROR: Import File contains ${missingMGAList.size} unknown MGA.`);
          messages.unshift('========================================================');
          throw new Error();
        }

        if (agentMissingIDCount > 0) {
          messages.unshift(
            `Import File is missing an Agent ID for ${agentMissingIDCount} records. Establishing Temp Id's for them.`
          );
        }

        if (incorrectTransactionYearCount > 0) {
          throw new Error();
        }

        messages.unshift('========================================================');
        messages.unshift('Validation Success.');
        messages.unshift('========================================================');
      },
      err => {
        console.error('Error in Report Admin', err);
        throw new Error();
      },
    );
  }

  reset() {
    this._transactionsMap.clear();
  }
}
