import { Inject, Injectable } from '@angular/core';
import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import { dateFromTimestamp } from '../utils/date-from-timestamp';
import { QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';
import { DataService } from '../services/data.service';
import {
  NoteLog,
  NoteLogKeys,
} from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/note-log.model';
import { combineLatest, map, Observable } from 'rxjs';
import {
  ActivityBase,
  ActivityBaseKeys,
} from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/activity-base.model';
import { ChangeSourceType } from '@ag-common-lib/public-api';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable({
  providedIn: 'root',
})
export class NoteLogsService extends DataService<NoteLog> {
  private readonly noteLogsCollectionPath = 'crm-record-note-logs';

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, NoteLogsService.fromFirestore);
    super.setCollection(this.noteLogsCollectionPath);
  }

  static readonly fromFirestore = (data): NoteLog => {
    return Object.assign({}, data, {
      [NoteLogKeys.date]: dateFromTimestamp(data[NoteLogKeys.date]),
    }) as NoteLog;
  };

  updateNoteLogsFields(
    documentId: string,
    data: Partial<NoteLog>,
    action: ChangeSourceType | null = null,
  ): Promise<NoteLog> {
    Object.assign(data, {
      [BaseModelKeys.actionName]: action,
    });
    return super.updateFields(documentId, data, true);
  }

  getNoteLogsList = (activityConfig: ActivityBase): Observable<NoteLog[]> => {
    const targetNoteLogsQp: QueryParam[] = [];
    const hostOwnerNoteLogsQp: QueryParam[] = [];

    // Creating QueryParams based on the provided activityConfig
    const targetTypeQuery = new QueryParam(
      ActivityBaseKeys.targetType,
      WhereFilterOperandKeys.equal,
      activityConfig[ActivityBaseKeys.targetType],
    );

    targetNoteLogsQp.push(targetTypeQuery);

    targetNoteLogsQp.push(
      new QueryParam(
        ActivityBaseKeys.targetId,
        WhereFilterOperandKeys.equal,
        activityConfig[ActivityBaseKeys.targetId],
      ),
    );
    if (!!activityConfig[NoteLogKeys.hostOwnerId]) {
      hostOwnerNoteLogsQp.push(
        new QueryParam(NoteLogKeys.hostOwnerId, WhereFilterOperandKeys.equal, activityConfig[NoteLogKeys.hostOwnerId]),
      );
    }

    // Logging the query parameters
    console.log('targetNoteLogsQp', targetNoteLogsQp);

    // Returning the list of NoteLogs directly as an Observable
    return combineLatest([
      this.getList(targetNoteLogsQp).pipe(map(items => (Array.isArray(items) ? items : []))),
      this.getList(hostOwnerNoteLogsQp).pipe(map(items => (Array.isArray(items) ? items : []))),
    ]).pipe(
      map(([targetNoteLogs, hostOwnerNoteLogs]) => [targetNoteLogs, hostOwnerNoteLogs].flat()), // Ensuring the result is always an array
    );
  };
}
