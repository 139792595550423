import { DynamicListsGroup } from '@ag-common-lib/lib';
import { BaseModel } from '@ag-common-lib/lib/models/base.model';

export enum AddAgentToDynamicListKeys {
  dynamicLists = 'dynamicLists',
}

export class AddAgentToDynamicListFormData extends BaseModel {
  [AddAgentToDynamicListKeys.dynamicLists]: DynamicListsGroup[] = [];
}
