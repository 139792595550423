import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxCheckBoxModule,
  DxDropDownButtonModule,
  DxLoadPanelModule,
  DxPopoverModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { ConferenceRegistrationsGridComponent } from './conference-registration-grid.component';
import { ConferenceRegistrationTakStatusPipe } from './pipe/conference-registration-task-status.pipe';
import { SharedModule } from '../../../shared/shared.module';
import { AgCrmCommonModule } from '../../common/ag-crm-common.module';
import { ConferencesModule } from '../conferences/conferences.module';
import { AgAssignOwnerViewerModule, ModalWindowModule } from 'ag-common-svc/lib/components';
import { InvitedParticipantsGridComponent } from './invited-participants-grid/invited-participants-grid.component';
import { RejectedParticipantsGridComponent } from './rejected-participants-grid/rejected-participants-grid.component';
import { ConferenceRegistrationStatusPipe } from './pipe/conference-registration-status.pipe';
import { RegistrantIssueStatusPipe } from './pipe/registrant-issue-status.pipe';
import { CampaignsManagementTaskBoardModule } from '../../../ag-campaigns/campaigns-management-task-board/campaigns-management-task-board.module';
import { ConferenceRegistrationTicketStatusPipe } from './pipe/conference-registration-ticket-status.pipe';
import { AttendeeDetailsModalModule } from '../conferences/modals/attendee-details-modal/attendee-details-modal.module';
import { ConferenceRegistrationOwnerPipe } from './pipe/conference-registration-owner.pipe';
import { ParticipationRequestsGridComponent } from './participation-requests-grid/participation-requests-grid.component';
import { EventInquiryRequestsService } from 'ag-common-svc/lib/services/event-inquire-requests.service';
import { RelatedAgentsDatasourcePipe } from './pipe/related-agents-datasource.pipe';
import { RegistrationParticipationEmailService } from 'ag-common-svc/lib/services/registration-participation-email.service';
import { IsAllowToApproveRequestPipe } from './pipe/is-allow-to-approve-request.pipe';
import { IsShowAgentNotWelcomedWarningPipe } from './pipe/is-show-agent-not-welcomed-warning.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ConferencesModule,
    AgAssignOwnerViewerModule,
    DxLoadPanelModule,
    ModalWindowModule,
    CampaignsManagementTaskBoardModule,
    AttendeeDetailsModalModule,
    AgCrmCommonModule,
    DxCheckBoxModule,
    DxTooltipModule,
    DxPopoverModule,
    DxDropDownButtonModule,
  ],
  declarations: [
    ConferenceRegistrationsGridComponent,
    InvitedParticipantsGridComponent,
    RejectedParticipantsGridComponent,
    ParticipationRequestsGridComponent,
    ConferenceRegistrationTakStatusPipe,
    ConferenceRegistrationStatusPipe,
    RegistrantIssueStatusPipe,
    ConferenceRegistrationTicketStatusPipe,
    ConferenceRegistrationOwnerPipe,
    RelatedAgentsDatasourcePipe,
    IsAllowToApproveRequestPipe,
    IsShowAgentNotWelcomedWarningPipe,
  ],
  exports: [ConferenceRegistrationsGridComponent, InvitedParticipantsGridComponent, RejectedParticipantsGridComponent],
  providers: [EventInquiryRequestsService, RegistrationParticipationEmailService],
})
export class ConferenceRegistrationGridModule {}
