import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeLogDetailComponent } from './change-log-detail.component';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogAddressModule } from './address/change-log-address.module';
import { ChangeLogAssociationModule } from './association/change-log-association.module';
import { ChangeLogDietaryConsiderationModule } from './dietary-consideration/change-log-dietary-consideration.module';
import { ChangeLogEmailModule } from './email-address/change-log-email.module';
import { ChangeLogHeadshotModule } from './headshot/change-log-headshot.module';
import { ChangeLogPhoneModule } from './phone-number/change-log-phone.module';
import { ChangeLogRequestOutcomeModule } from './request-outcome/change-log-request-outcome.module';
import { ChangeLogTshirtSizeModule } from './t-shirt-sizes/change-log-tshirt-size.module';
import { ChangeLogPipesModule } from '../../pipes/change-log-pipes.module';
import { ChangeLogGoalsModule } from './goals/change-log-goals.module';
import { ChangeLogFavouriteModule } from './favourite/change-log-favourite.module';
import { ChangeLogCarrierModule } from './carrier/change-log-carrier.module';
import { ChangeLogStateLicenseModule } from './state-license/change-log-state-license.module';
import { ChangeLogAgentCampaignsModule } from './agent-campaigns/change-log-agent-campaigns.module';

@NgModule({
  declarations: [ChangeLogDetailComponent],
  imports: [
    CommonModule,
    SharedModule,
    ChangeLogAddressModule,
    ChangeLogAssociationModule,
    ChangeLogDietaryConsiderationModule,
    ChangeLogEmailModule,
    ChangeLogHeadshotModule,
    ChangeLogPhoneModule,
    ChangeLogRequestOutcomeModule,
    ChangeLogTshirtSizeModule,
    ChangeLogPipesModule,
    ChangeLogGoalsModule,
    ChangeLogFavouriteModule,
    ChangeLogCarrierModule,
    ChangeLogStateLicenseModule,
    ChangeLogAgentCampaignsModule,
  ],
  exports: [ChangeLogDetailComponent],
})
export class ChangeLogDetailModule {}
