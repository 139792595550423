import { ChangeSourceType } from '../../lists/change-logs/change-source-type.enum';

export enum DeleteAgentKeys {
  agentDbId = 'agentDbId',
  actionName = 'actionName',
}

export interface DeleteAgent {
  [DeleteAgentKeys.agentDbId]: string;
  [DeleteAgentKeys.actionName]?: ChangeSourceType | null;
}
