import { Component, HostBinding } from '@angular/core';
import { Agency, Agent, AgentKeys } from 'ag-common-lib/public-api';
import { QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/lib/dao/CommonFireStoreDao.dao';
import { AgencyService, AgentService } from 'ag-common-svc/public-api';
import { PhoneNumberMaskPipe } from 'ag-common-svc/shared/pipes/phone-number-mask.pipe';
import { PrimaryPhoneNumberPipe } from 'ag-common-svc/shared/pipes/primary-phone-number.pipe';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'ag-crm-christmas-card-list',
  templateUrl: './christmas-card-list.component.html',
  styleUrls: ['./christmas-card-list.component.scss'],
  providers: [PrimaryPhoneNumberPipe, PhoneNumberMaskPipe],
})
export class ChristmasCardListComponent {
  @HostBinding('class') className = 'christmas-card-list';

  public dataSource$: Observable<DataSource>;
  public AgentKeys = AgentKeys;
  public agencies$: Observable<Agency[]>;
  public isAgentDetailsVisible = false;
  public selectedAgent: Agent;

  constructor(
    agentService: AgentService,
    private agencyService: AgencyService,
    public primaryPhoneNumberPipe: PrimaryPhoneNumberPipe,
    public phoneNumberMaskPipe: PhoneNumberMaskPipe,
  ) {
    const qp = [new QueryParam(AgentKeys.christmas_card, WhereFilterOperandKeys.equal, true)];

    this.dataSource$ = agentService.getList(qp).pipe(
      map(agents => {
        return new DataSource({
          store: new ArrayStore({
            key: 'dbId',
            data: Array.isArray(agents) ? agents : [],
          }),
        });
      }),
    );

    this.agencies$ = this.agencyService.getList().pipe(shareReplay(1));
  }

  public showAgentDetails = ({ data }) => {
    this.selectedAgent = data;

    this.isAgentDetailsVisible = true;
  };

  public calculatePrimaryPhoneNumberCellValue = agent => {
    const primaryPhoneNumber = this.primaryPhoneNumberPipe.transform(agent[AgentKeys.phone_numbers]) ?? null;
    return this.phoneNumberMaskPipe.transform(primaryPhoneNumber);
  };

  private getPrimaryShippingAddress = agent => {
    const shippingAddress = agent?.addresses?.find(address => address?.is_primary_shipping);

    return shippingAddress;
  };

  public calculateAddressCellValue = agent => {
    const shippingAddress = this.getPrimaryShippingAddress(agent);
    return [
      shippingAddress?.address1,
      shippingAddress?.address2,
      shippingAddress?.city,
      shippingAddress?.state,
      shippingAddress?.zip,
    ]
      .filter(Boolean)
      .join(' ');
  };

  public calculateAddress1CellValue = agent => {
    const shippingAddress = this.getPrimaryShippingAddress(agent);

    return shippingAddress?.address1;
  };

  public calculateAddress2CellValue = agent => {
    const shippingAddress = this.getPrimaryShippingAddress(agent);

    return shippingAddress?.address2;
  };

  public calculateCityCellValue = agent => {
    const shippingAddress = this.getPrimaryShippingAddress(agent);

    return shippingAddress?.city;
  };

  public calculateStateCellValue = agent => {
    const shippingAddress = this.getPrimaryShippingAddress(agent);

    return shippingAddress?.state;
  };

  public calculateZipCellValue = agent => {
    const shippingAddress = this.getPrimaryShippingAddress(agent);

    return shippingAddress?.zip;
  };

  public onExporting = e => {
    e.component.beginUpdate();
    [
      'shippingAddress.address1',
      'shippingAddress.address2',
      'shippingAddress.city',
      'shippingAddress.state',
      'shippingAddress.zip',
    ].forEach(item => {
      e.component.columnOption(item, 'visible', true);
    });

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Agents');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    })
      .then(function () {
        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Christmas Cards 2022.csv');
        });
      })
      .finally(() => {
        [
          'shippingAddress.address1',
          'shippingAddress.address2',
          'shippingAddress.city',
          'shippingAddress.state',
          'shippingAddress.zip',
        ].forEach(item => {
          e.component.columnOption(item, 'visible', false);
        });
        e.component.endUpdate();
      });

    e.cancel = true;
  };
}
