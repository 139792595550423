import { Component, HostBinding, Input } from '@angular/core';
import { ConferenceModalService } from '../conference-form.service';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Conference, ConferenceKeys, Messages } from '@ag-common-lib/public-api';
import { validateUrlWithOrWithoutProtocol } from 'ag-common-svc/lib/utils/validation';

@Component({
  selector: 'ag-crm-conference-embedded-form',
  templateUrl: './conference-embedded-form.component.html',
  styleUrls: ['./conference-embedded-form.component.scss'],
})
export class ConferenceEmbeddedFormComponent {
  @HostBinding('class') className = 'conference-embedded-form';
  @Input() conference: Partial<Conference>;
  @Input() validationGroup: string;
  @Input() isReadonlyMode: boolean = false;

  protected iframeData$ = this.conferenceModalService.conferenceDbId$.pipe(
    map(conferenceDbId => {
      return `
<div class="embedded-iframe__container">
  <iframe
    id="agCampaignServicesListIframe"
    class="embedded-iframe__iframe"
    src="${environment.embeddedAppUrl}/events-inquiry/${conferenceDbId}"
    style="width: 100%; border: none"
  >
  </iframe>

  <div class="embedded-iframe__loader">
    <div class="embedded-iframe__loader-icon"></div>
  </div>
</div>

<style>
  .embedded-iframe__container {
    position: relative;
    width: 100%
  }

  .embedded-iframe__iframe {
    position: relative;
    height: 471px;
    background: transparent;
    z-index: 2;
  }

  .embedded-iframe__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .embedded-iframe__loader-icon {
    width: 32px;
    height: 32px;
    margin: auto;
    background: transparent
      url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100 100"><circle cx="50" cy="50" r="45" stroke="%23009BE6" stroke-width="6" fill="none" stroke-dasharray="283" stroke-dashoffset="75"></circle></svg>')
      no-repeat center;
    background-size: 32px 32px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
</style>

<script>
  let agCampaignServicesListIframe = window.document.getElementById("agCampaignServicesListIframe");

  window.addEventListener("load", () => {
    agCampaignServicesListIframe = window.document.getElementById("agCampaignServicesListIframe");
  });

  window.addEventListener("message", message => {
    if (message.data.type !== "ag-campaign-services-list-resize") {
      return;
    }

    if (!agCampaignServicesListIframe) {
      agCampaignServicesListIframe = window.document.getElementById("agCampaignServicesListIframe");
    }

    const height = message.data.height;

    agCampaignServicesListIframe.style.height = height + "px";
  });
</script>
`;
    }),
  );
  protected urlToAppForm = '';
  readonly Messages = Messages;
  validateUrlWithOrWithoutProtocol = e => validateUrlWithOrWithoutProtocol(e?.value);

  constructor(private conferenceModalService: ConferenceModalService) {}

  protected readonly ConferenceKeys = ConferenceKeys;
}
