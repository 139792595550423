import { Component, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { Agent, AgentKeys } from 'ag-common-lib/lib/models/domain/agent.model';
import { MgaFormService } from './mga-form.service';
import { AgentService } from 'ag-common-svc/lib/services/agent.service';
import { AgencyService } from 'ag-common-svc/lib/services/agency.service';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import { LookupsService } from 'ag-common-svc/lib/services';
import { ChangeSourceType } from 'ag-common-lib/lib/lists/change-logs/change-source-type.enum';

@Component({
  selector: 'ag-crm-mga-editor-modal',
  templateUrl: './mga-editor-modal.component.html',
  styleUrls: ['./mga-editor-modal.component.scss'],
  providers: [MgaFormService],
})
export class MgaEditorModalComponent implements OnInit {
  @ViewChild('mgaFormRef', { static: false }) mgaFormComponent: DxFormComponent;
  @ViewChild('mgaModalRef', { static: true }) mgaModalComponent: ModalWindowComponent;
  @ViewChild('agencyList', { static: false }) agencySelectBoxComponent: DxSelectBoxComponent;
  @ViewChild('managerList', { static: false }) managerSelectBoxComponent: DxSelectBoxComponent;

  filteredManagers$ = this.mgaFormService.filteredManagers$;
  filteredAgencies$ = this.mgaFormService.filteredAgencies$;
  filteredMGAs$ = this.mgaFormService.filteredMGAs$;

  mgaFormData = this.mgaFormService.formData;
  inProgress$ = this.mgaFormService.inProgress$;
  agentDbId: string;
  sourceType: ChangeSourceType | null = null

  protected readonly AgentKeys = AgentKeys;

  constructor(
    public agentService: AgentService,
    public agencyService: AgencyService,
    private mgaFormService: MgaFormService,
    private lookupService: LookupsService,
  ) {}

  ngOnInit(): void {}

  onAfterRevertChanges = (): void => {
    this.mgaFormComponent.instance.repaint();
  };

  handleClosePopup = () => this.mgaFormService.onCancelEdit.call(this, this.onAfterRevertChanges);

  handleSaveMga = e => {
    const validationResults = this.mgaFormComponent.instance.validate();
    if (validationResults.isValid) {
      this.mgaFormService.updateMGA(this.agentDbId, this.mgaFormData, this.sourceType).then(() => {
        this.hideModal();
      });
    }
  };

  showModal = async (data: Agent, sourceType: ChangeSourceType | null): Promise<void> => {
    this.agentDbId = data?.[BaseModelKeys.dbId];
    this.sourceType = sourceType;

    this.mgaFormData = await this.mgaFormService.getFormData(data);
    await this.mgaFormService.buildManagersList(
      data?.[AgentKeys.p_agent_id],
      data?.[AgentKeys.p_agency_id],
      data?.[AgentKeys.p_mga_id],
    );
    if (data?.[AgentKeys.p_mga_id]) {
      this.mgaFormService.getFilteredAgenciesByMga(data[AgentKeys.p_mga_id]);
    }
    this.mgaModalComponent?.showModal();
  };

  hideModal = (): void => {
    this.mgaModalComponent.hideModal();
  };
}
