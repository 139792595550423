import { Inject, Injectable, Optional } from '@angular/core';
import {
  BindUserToAgent,
  BulkEmailSendingResponse,
  ChangeSourceType,
  ConfirmEmail,
  DeleteAgent,
  DeleteConference,
  DeleteConferenceRegistration,
  DeleteFirebaseUser,
  DeleteMediaPayload,
  DeleteWeekTransactions,
  EmailToSendConfig,
  FunctionsNames,
  GetImageContentByUrlPayload,
  GetImageContentByUrlResponse,
  ImportTransactionsToElastic,
  PaymentIntentPayload,
  ProcessPaymentPayload,
  ProcessRefundPayload,
  RegistrationSummaryEmail,
  SearchRequest,
  SearchResponse,
  SendOTP,
  SupportTaskEmailToAgent,
  UpdateAgentGoalsPayload,
  UpdateUserLoginEmail,
  UpdateUserPassword,
  UploadFilePayload,
  UploadMediaPayload,
  ValidateOtp,
} from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { FIREBASE_APP } from '../injections/firebase-app';

import saveAs from 'file-saver';
import { USE_CLOUD_FUNCTIONS_EMULATOR } from '../injections/use-cloud-functions-emulator';
import {
  GetStatsPayload,
  GetStatsResponse,
  PushNotificationCampaign,
  SummaryConfigurationsEmailExcursion,
} from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib';
import { CopyConferenceRegistration } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/functions/copy-conference-registration.model';
import { ParsedEmail } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/email-parser.model';
import { BaseCloudFunctionsService } from '../utils/base-cloud-functions-service';
import { RegistrationParticipationEmail } from '@ag-common-lib/lib/models/functions/registration-participation-email.model';

@Injectable({
  providedIn: 'root',
})
export class CloudFunctionsService extends BaseCloudFunctionsService {
  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    @Optional() @Inject(USE_CLOUD_FUNCTIONS_EMULATOR) useEmulator = false,
  ) {
    super(fireBaseApp, useEmulator);
  }

  sendOTP = (payload: SendOTP): Promise<any> => httpsCallable(this.functions, FunctionsNames.sendOTP)(payload);

  bindUserToAgent = (uid: string, agentDbId: string): Promise<any> => {
    const payload: BindUserToAgent = { uid, agentDbId };

    return httpsCallable(this.functions, FunctionsNames.bindUserToAgent)(payload);
  };

  confirmEmail = (payload: ConfirmEmail): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.confirmEmail)(payload);
  };

  deleteAgent = (agentDbId: string, actionName: ChangeSourceType | null = null): Promise<any> => {
    const payload: DeleteAgent = { agentDbId, actionName };

    return httpsCallable(this.functions, FunctionsNames.deleteAgent)(payload);
  };

  deleteFirebaseUser = (uid: string): Promise<any> => {
    const payload: DeleteFirebaseUser = { uid };

    return httpsCallable(this.functions, FunctionsNames.deleteFirebaseUser)(payload);
  };

  findAgentDbIdByLoginEmail = (payload: { email }): Promise<string> =>
    httpsCallable<{ email: string }, string>(
      this.functions,
      FunctionsNames.findAgentDbIdByLoginEmail,
    )(payload).then(response => response?.data);

  updateUserLoginEmail = (payload: UpdateUserLoginEmail): Promise<any> =>
    httpsCallable(this.functions, FunctionsNames.updateUserLoginEmail)(payload);

  updateSalesGoals = (payload: UpdateAgentGoalsPayload[]): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.updateSalesGoals)(payload);
  };

  validateOTP = (payload: ValidateOtp): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.validateOTP)(payload);
  };

  collectPaymentIntent = (payload: PaymentIntentPayload): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.collectPaymentIntent)(payload);
  };

  processPayment = (payload: ProcessPaymentPayload): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.processPayment)(payload);
  };

  refundPayment = (payload: ProcessRefundPayload): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.refundPayment)(payload);
  };

  handleWebhookPayment = (): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.handleWebhookPayment)();
  };

  getPaymentEventListByTypes = (types: string[]): Promise<any> => {
    console.log(types);
    return httpsCallable(this.functions, FunctionsNames.getPaymentEventListByTypes)({ types });
  };

  updateUserPassword = (payload: UpdateUserPassword): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.updateUserPassword)(payload);
  };

  searchWithElastic = (payload: SearchRequest): Promise<HttpsCallableResult<SearchResponse>> => {
    return httpsCallable<SearchRequest, SearchResponse>(this.functions, FunctionsNames.elasticSearch)(payload);
  };

  uploadMedia = (payload: UploadMediaPayload): Promise<string> => {
    return httpsCallable<UploadMediaPayload, string>(
      this.functions,
      FunctionsNames.uploadMedia,
    )(payload).then(res => {
      return res?.data;
    });
  };

  deleteMedia = (payload: DeleteMediaPayload): Promise<string> => {
    return httpsCallable<DeleteMediaPayload, string>(
      this.functions,
      FunctionsNames.deleteMedia,
    )(payload).then(res => {
      return res?.data;
    });
  };

  getImageContentByUrl = (payload: GetImageContentByUrlPayload): Promise<GetImageContentByUrlResponse> => {
    return httpsCallable<GetImageContentByUrlPayload, GetImageContentByUrlResponse>(
      this.functions,
      FunctionsNames.getImageContentByUrl,
    )(payload).then(response => response?.data);
  };

  deleteConferenceRegistration = (payload: DeleteConferenceRegistration): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.deleteConferenceRegistration)(payload);
  };

  copyConferenceRegistration = (payload: CopyConferenceRegistration): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.copyConferenceRegistration)(payload);
  };

  sendConferenceRegistrationSummary = (
    payload: RegistrationSummaryEmail | RegistrationParticipationEmail,
  ): Promise<any> => httpsCallable(this.functions, FunctionsNames.registrationSummaryEmail)(payload);

  sendExcursionsConfigurations = (payload: SummaryConfigurationsEmailExcursion): Promise<any> =>
    httpsCallable(this.functions, FunctionsNames.excursionsConfigurationsEmail)(payload);

  bulkEmailSending = (payload: EmailToSendConfig[]): Promise<HttpsCallableResult<BulkEmailSendingResponse>> =>
    httpsCallable<EmailToSendConfig[], BulkEmailSendingResponse>(
      this.functions,
      FunctionsNames.bulkEmailSending,
    )(payload);

  sendSupportTaskEmailToAgent = (urlToSupportTask: string, inviteeEmail: string, fullName: string): Promise<any> => {
    const payload: SupportTaskEmailToAgent = { urlToSupportTask, inviteeEmail, fullName };
    return httpsCallable(this.functions, FunctionsNames.supportTaskEmailToAgent)(payload);
  };

  getArchivedImages = async (media: any): Promise<void> => {
    await fetch(this.getFunctionUrl(FunctionsNames.getArchivedImages), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          media,
          optimized: true,
        },
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob(); // Get the response as a Blob object
      })
      .then(blob => {
        // Handle the response
        saveAs(blob, 'images.zip');
      })
      .catch(error => {
        // Handle errors
        console.error('Error calling function:', error);
      });
  };

  getAuthentication = async (): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.getAuthentication)();
  };

  deleteAuthentication = async (uid: string, action: ChangeSourceType | null = null): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.deleteAuthentication)({ uid, action });
  };

  forceVerifyEmailAuthentication = async (uid: string): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.forceVerifyEmailAuthentication)({ uid });
  };

  sendPushNotification = (payload: PushNotificationCampaign): Promise<any> =>
    httpsCallable(this.functions, FunctionsNames.sendPushNotification)(payload);

  createCustomToken = (): Promise<HttpsCallableResult<{ token: string }>> =>
    httpsCallable<void, { token: string }>(this.functions, FunctionsNames.createCustomToken)();

  getStats = (payload: GetStatsPayload): Promise<HttpsCallableResult<GetStatsResponse>> =>
    httpsCallable<GetStatsPayload, GetStatsResponse>(this.functions, FunctionsNames.getStats)(payload);

  uploadFile = (payload: UploadFilePayload): Promise<string> => {
    return httpsCallable<UploadFilePayload, string>(
      this.functions,
      FunctionsNames.uploadFile,
    )(payload).then(res => {
      return res?.data;
    });
  };

  deleteWeekTransactions = async (payload: DeleteWeekTransactions): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.deleteWeekTransactions)(payload);
  };

  /*Payload type is base64 string*/
  parseMSGFile = async (payload: string): Promise<ParsedEmail> => {
    return httpsCallable<string, ParsedEmail>(
      this.functions,
      FunctionsNames.parseMSGFile,
    )(payload).then(response => response?.data);
  };

  importTransactionsToElastic = async (payload: ImportTransactionsToElastic): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.importTransactionsToElastic)(payload);
  };

  deleteConference = (payload: DeleteConference): Promise<any> => {
    return httpsCallable(this.functions, FunctionsNames.deleteConference)(payload);
  };
}
