import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { AgMediaUploaderModalComponent } from '../../../ag-media-uploader/ag-media-uploader-modal.component';
import { BehaviorSubject, map } from 'rxjs';
import { AgencyMediaEditorService } from './agency-media-editor.service';
import { MediaAction } from '../../../ag-media-gallery/ag-media-gallery.models';
import { AgencyFileDirectoriesService } from '../../../../services/agency-file-directories.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { confirm } from 'devextreme/ui/dialog';
import { ToastrService } from 'ngx-toastr';
import { AgencyFilesMediaService } from '../../../../services/agency-files-media.service';
import { ItemClickEvent } from 'devextreme/ui/menu';
import { MediaUploaderTabs } from 'ag-common-svc/lib/components/ag-media-uploader/ag-media-uploader-modal.models';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import {
  AgencyFileDirectory,
  EntityPermissionActivityKeys,
  AgencyFileDirectoryKeys,
  AGMedia,
  Entity,
  EntityPermissionModelKeys,
} from '@ag-common-lib/public-api';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agency-media',
  templateUrl: './agency-media.component.html',
  styleUrls: ['./agency-media.component.scss'],
})
export class AgencyMediaComponent {
  @ViewChild(AgMediaUploaderModalComponent, { static: false }) mediaUploaderComponent: AgMediaUploaderModalComponent;

  @Input() set agencyDbId(agencyId: string) {
    this.agencyMediaEditorService.setAgencyId(agencyId);
    this.mediaPathPrefix$.next(`agencies/${agencyId}`);
    this._agencyDbId = agencyId;
    this.refreshDirectories();
  }

  mediaPathPrefix$ = new BehaviorSubject<string>('');
  directoriesDataSource: AgencyFileDirectory[];
  selectedItemIndex: number | null = 0;
  extraMediaActions: MediaAction[] = [];
  folderName = '';
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  private _agencyDbId: string;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly AgencyFileDirectoryKeys = AgencyFileDirectoryKeys;
  protected readonly MediaUploaderTabs = MediaUploaderTabs;

  constructor(
    protected agencyMediaEditorService: AgencyMediaEditorService,
    private agencyFileDirectoriesService: AgencyFileDirectoriesService,
    private toastr: ToastrService,
    private agencyFilesMediaService: AgencyFilesMediaService,
  ) {}

  addFolder(): void {
    if (this.folderName) {
      const isCurrentFolderNameExist =
        this.directoriesDataSource?.filter(directory => directory.name == this.folderName).length > 0;
      if (isCurrentFolderNameExist) {
        this.toastr.error(`Folder Name "${this.folderName}" already exists`);
        return;
      }

      const directory: AgencyFileDirectory = {
        [AgencyFileDirectoryKeys.agencyDbId]: this._agencyDbId,
        [AgencyFileDirectoryKeys.name]: this.folderName,
        [AgencyFileDirectoryKeys.isSystem]: false,
      };

      this.agencyMediaEditorService.createFolder(directory).then(() => {
        this.folderName = '';
        this.refreshDirectories();
      });
    }
  }

  async deleteFolder(folder: AgencyFileDirectory): Promise<void> {
    const folderName = folder[AgencyFileDirectoryKeys.name] ?? '';
    const agencyId = folder[AgencyFileDirectoryKeys.agencyDbId];

    await this.agencyFilesMediaService.getList(agencyId, folder[BaseModelKeys.dbId]).pipe(
      map(items => {
        if (items?.length) {
          this.toastr.error(`Folder "${folderName}" contains media. Please remove media before.`);
          return;
        }

        const result = confirm(`<i>Are you sure you want to Delete Folder "${folderName}"?</i>`, 'Confirm');
        result.then(dialogResult => {
          if (dialogResult) {
            this.agencyMediaEditorService.deleteFolder(agencyId, folder[BaseModelKeys.dbId]).then(() => {
              this.refreshDirectories();
            });
          }
        });
      }),
    );
  }

  uploadFile(): void {
    this.mediaUploaderComponent.showModal();
  }

  onMediaChanged(media: AGMedia): void {
    this.agencyMediaEditorService.createMedia(this._agencyDbId, media);
  }

  menuItemClick({ itemData }: ItemClickEvent<any>): void {
    this.agencyMediaEditorService.setCurrentDirectory(itemData as any);
  }

  onDeleteClicked(media: AGMedia): void {
    this.agencyMediaEditorService.deleteMedia(this._agencyDbId, media);
  }

  private refreshDirectories(): void {
    this.agencyFileDirectoriesService
      .getList(this._agencyDbId)
      .pipe(
        map(fileDirectories => {
          this.directoriesDataSource = fileDirectories ?? [];
          if (!!fileDirectories) {
            this.setDefaultDirectory(fileDirectories);
          }
        }),
      )
      .subscribe();
  }

  private setDefaultDirectory(fileDirectories: AgencyFileDirectory[]): void {
    const defaultIndex = 0;
    this.agencyMediaEditorService.setCurrentDirectory(fileDirectories[defaultIndex]);
    this.selectedItemIndex = defaultIndex;
  }

  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
}
