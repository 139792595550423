import { Injectable } from '@angular/core';
import { BaseFormService } from 'ag-common-svc/lib/utils/base-form-service';
import { PushNotificationCampaign, PushNotificationCampaignKeys } from '@ag-common-lib/lib';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { PushNotificationsCampaignService } from 'ag-common-svc/public-api';

@Injectable()
export class NotificationCampaignModalService extends BaseFormService<PushNotificationCampaign> {
  constructor(private pushNotificationsCampaignService: PushNotificationsCampaignService) {
    super();
  }

  getFormData = (pushNotificationCampaign?: Partial<PushNotificationCampaign>) => {
    const notification = pushNotificationCampaign?.[PushNotificationCampaignKeys.notification];
    const notificationProxy = new Proxy(Object.assign({}, notification), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange(
            [PushNotificationCampaignKeys.notification, prop].join('.'),
            value,
            prevValue,
          );
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });

    const initialData = Object.assign({}, new PushNotificationCampaign(), pushNotificationCampaign, {
      [PushNotificationCampaignKeys.notification]: notificationProxy,
    });

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);

        Reflect.set(target, prop, value, receiver);

        return true;
      },
    });

    return this.formData;
  };

  handleSave = async () => {
    const dbId = this.formData?.[BaseModelKeys.dbId];
    this.startProgress();
    return dbId
      ? this.pushNotificationsCampaignService.updateFields(dbId, this.formData).finally(() => {
          this.stopProgress();
        })
      : this.pushNotificationsCampaignService.create(this.formData).finally(() => {
          this.stopProgress();
        });
  };
}
