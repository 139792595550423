import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  Agency,
  AgencyCarrier,
  AgencyKeys,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
} from 'ag-common-lib/public-api';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { AgencyCarriersModalService } from './agency-carriers-modal.service';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-agency-carriers',
  templateUrl: './agency-carriers.component.html',
  styleUrls: ['./agency-carriers.component.scss'],
})
export class AgencyCarriersComponent implements OnInit {
  @ViewChild('carriersEditorModalRef') carriersEditorModalComponent: ModalWindowComponent;
  @Input() agency: Agency;
  @Output() carriersChange = new EventEmitter<AgencyCarrier[]>();

  agencyCarriers: AgencyCarrier[] = [];

  readonly inProgress$ = this.agencyCarriersModalService.inProgress$;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly AgencyKeys = AgencyKeys;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(private agencyCarriersModalService: AgencyCarriersModalService) {}

  ngOnInit(): void {
    this.agency[AgencyKeys.carriers] = this.agency[AgencyKeys.carriers] ? this.agency[AgencyKeys.carriers] : [];
    this.agencyCarriers = this.agency[AgencyKeys.carriers];
  }

  onCarriersChange(carriers: AgencyCarrier[]): void {
    this.agency[AgencyKeys.carriers] = carriers;
  }

  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
