import { Component, Input, ViewChild } from '@angular/core';
import {
  Agency,
  AgencyKeys,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  StateLicense,
} from '@ag-common-lib/public-api';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { StatesLicensedInService } from './states-licensed-in.service';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-agency-state-licensed-in',
  templateUrl: './states-licensed-in.component.html',
  styleUrls: ['./states-licensed-in.component.scss'],
})
export class StatesLicensedInComponent {
  @ViewChild('stateLicenseEditorModalRef', { static: true }) stateLicenseEditorModalComponent: ModalWindowComponent;
  @Input() agency: Agency;

  inProgress$ = this.statesLicensedInService.inProgress$;

  protected readonly AgencyKeys = AgencyKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(private statesLicensedInService: StatesLicensedInService) {}

  onStateLicensesChange(stateLicenses: StateLicense[]): void {
    this.agency[AgencyKeys.stateLicenses] = stateLicenses;
  }

  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
