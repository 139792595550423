import { LocalDateTimeString } from '../utils/local-date-time-string.model';
import { NotifyEmail } from './notify-email.model';
import { EmailTemplates } from '../../../lib';

export const emailTemplateSubjects = new Map<EmailTemplates, string>([
  [
    EmailTemplates.notifyEmailWinterSummitApprovedToRegister,
    'Alliance Group Winter Summit - Complete Your Registration Now',
  ],
  [EmailTemplates.notifyEmailWinterSummitDenied, 'Alliance Group Winter Summit - Your Registration Application'],
  [EmailTemplates.notifyEmailWinterSummitRegistrationSummary, 'Alliance Group Winter Summit - Your Spot is Saved'],
  [EmailTemplates.notifyEmailWinterSummitKBYG, 'Alliance Group Winter Summit - Know Before You Go'],
  [EmailTemplates.notifyEmailWinterSummitPending, 'Alliance Group Winter Summit - Application Pending'],
]);

export enum RegistrationParticipationEmailKeys {
  eventName = 'eventName',
  agentAuthEmail = 'agentAuthEmail',
  registrationEndDate = 'registrationEndDate',
  coordinatorEmail = 'coordinatorEmail',
  coordinatorPrimaryPhoneNumber = 'coordinatorPrimaryPhoneNumber',
  hotelName = 'hotelName',
  hotelAddress = 'hotelAddress',
  hotelPhoneNumber = 'hotelPhoneNumber',
  isExistingAgent = 'isExistingAgent',
  isMergedAgent = 'isMergedAgent',
  isNewAgent = 'isNewAgent',
  isAgentNotWelcome = 'isAgentNotWelcome',
}

export interface RegistrationParticipationEmail extends NotifyEmail {
  [RegistrationParticipationEmailKeys.eventName]?: string;
  [RegistrationParticipationEmailKeys.agentAuthEmail]?: string;
  [RegistrationParticipationEmailKeys.registrationEndDate]?: LocalDateTimeString;
  [RegistrationParticipationEmailKeys.coordinatorEmail]?: string;
  [RegistrationParticipationEmailKeys.coordinatorPrimaryPhoneNumber]?: string;
  [RegistrationParticipationEmailKeys.hotelName]?: string;
  [RegistrationParticipationEmailKeys.hotelAddress]?: string;
  [RegistrationParticipationEmailKeys.hotelPhoneNumber]?: string;
  [RegistrationParticipationEmailKeys.isExistingAgent]?: boolean;
  [RegistrationParticipationEmailKeys.isMergedAgent]?: boolean;
  [RegistrationParticipationEmailKeys.isNewAgent]?: boolean;
  [RegistrationParticipationEmailKeys.isAgentNotWelcome]?: boolean;
}
