import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ActiveLookup, Association, AssociationKeys, Lookups, Messages } from '@ag-common-lib/public-api';
import { DxListComponent } from 'devextreme-angular';
import { InitializedEvent, SelectionChangedEvent } from 'devextreme/ui/list';
import { SelectAssociationService } from './select-association.service';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-select-association',
  templateUrl: './select-association.component.html',
  styleUrls: ['./select-association.component.scss'],
  providers: [SelectAssociationService],
})
export class SelectAssociationComponent {
  @HostBinding('class') protected className = 'select-association';
  @ViewChild('listRef') listComponent: DxListComponent;

  @Input() set association(data: Partial<Association>) {
    this.selectAssociationService.setFormData(data);
  }
  @Input() set associations(data: Association[]) {
    this.selectAssociationService.setAssociations(data);
  }
  @Input() set associationKeyExpr(data: string | ((association: Partial<Association>) => string)) {
    this.selectAssociationService.setKeyExpr(data);
  }

  @Input() isReadonly: boolean = false;
  @Input() isListVisible: boolean = true;
  @Input() selectorHidden: boolean = false;
  @Input() newAssociationTitle: string = 'Add new Emergency Contact';
  @Input() validationGroup: string;
  @Input() propertiesToAssign: Set<string>;
  @Input() onAssociationChanged: (association: Partial<Association>) => void;
  @Output() handleRelationshipTypeSelectionChanged = new EventEmitter<ActiveLookup>();

  protected associationsDataSource$ = this.selectAssociationService.agentAssociationDataSource$;
  protected formData$ = this.selectAssociationService.formData$;
  protected keyExpr = this.selectAssociationService.pickAssociationKey;
  protected selectedAssociationKeys: string[];

  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly Lookups = Lookups;
  readonly Messages = Messages;

  readonly AssociationKeys = AssociationKeys;

  constructor(private selectAssociationService: SelectAssociationService) {
    this.selectAssociationService.selectedAssociationKeys$.subscribe(selectedAssociationKeys => {
      this.listComponent?.instance?.off('selectionChanged');
      this.selectedAssociationKeys = selectedAssociationKeys;

      setTimeout(() => {
        this.listComponent?.instance?.on('selectionChanged', this.onSelectionChanged);
      }, 0);
    });
  }

  protected onInitialized = (e: InitializedEvent) => {
    if (this.onSelectionChanged) {
      e.component.instance().on('selectionChanged', this.onSelectionChanged);
    }
  };

  protected onSelectionChanged = (e: SelectionChangedEvent) => {
    const association = e.addedItems[0];

    this.selectAssociationService.updateAssociation(association, this.propertiesToAssign, this.onAssociationChanged);
  };
}
