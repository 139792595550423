import { Injectable } from '@angular/core';
import {
  Agent,
  AgentCampaignData,
  AgentCampaignStepName,
  AgentKeys,
  ChangeSourceType,
} from '@ag-common-lib/public-api';
import { AgentEmailAddressesService, AgentImagesService, AgentService, LookupsService } from '../../../public-api';
import { AgentProfileDataService } from 'ag-common-svc/lib/utils/agent-profile-data-service';
import { AgentAssociationsService } from 'ag-common-svc/lib/services/agent-associations.service';
import { normalizeAddresses } from 'ag-common-svc/lib/utils/address.util';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable()
export class AgentCampaignAgentProfileDataService extends AgentProfileDataService {
  constructor(
    lookupsService: LookupsService,
    agentEmailAddressesService: AgentEmailAddressesService,
    agentAssociationsService: AgentAssociationsService,
    agentImagesUtil: AgentImagesService,
    private readonly agentService: AgentService,
  ) {
    super(lookupsService, agentEmailAddressesService, agentAssociationsService, agentImagesUtil);
  }

  handleAgentData = async (agent: Agent, data: AgentCampaignData, actionName: ChangeSourceType | null) => {
    const updates: Partial<Agent> = {};
    const asyncUpdates: Promise<any>[] = [];

    if (data?.[AgentCampaignStepName.contactInfoAddress]) {
      const addresses = normalizeAddresses(
        data?.[AgentCampaignStepName.contactInfoAddress],
        agent?.[AgentKeys.addresses],
      );
      Object.assign(updates, { [AgentKeys.addresses]: addresses });
    }

    if (data?.[AgentCampaignStepName.contactInfoPhoneNumber]) {
      const phoneNumbers = this.handleAgentPhoneNumber(
        data?.[AgentCampaignStepName.contactInfoPhoneNumber],
        agent?.[AgentKeys.phone_numbers],
      );
      Object.assign(updates, { [AgentKeys.phone_numbers]: phoneNumbers });
    }

    if (data?.[AgentCampaignStepName.socialMedia]) {
      const socialMedia = await this.handleAgentSocials(
        data?.[AgentCampaignStepName.socialMedia],
        agent?.[AgentKeys.socials],
      );

      Object.assign(updates, { [AgentKeys.socials]: socialMedia });
    }

    if (data?.[AgentCampaignStepName.contactInfoEmailAddress]) {
      const emailAddress$ = this.handleAgentEmailAddress(
        data?.[AgentCampaignStepName.contactInfoEmailAddress],
        agent?.[BaseModelKeys.dbId],
      );

      asyncUpdates.push(emailAddress$);
    }

    if (data?.[AgentCampaignStepName.logoHeadshot]) {
      const media = this.handleAgentLogoHeadshot(
        data?.[AgentCampaignStepName.logoHeadshot],
        agent?.[BaseModelKeys.dbId],
      );

      asyncUpdates.push(...media);
    }

    if (data?.[AgentCampaignStepName.bio]?.title) {
      Object.assign(updates, { [AgentKeys.title]: data?.[AgentCampaignStepName.bio]?.title });
    }

    return Promise.all([
      this.agentService.updateAgentFields(agent?.[BaseModelKeys.dbId], updates, actionName),
      ...asyncUpdates,
    ]);
  };
}
