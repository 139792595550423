import { BaseModel } from '../base.model';
import { MappingProperty } from '../functions';

export enum PolicyTransactionKeys {
  carrierName = 'carrier_name',
  agencyNumber = 'agency_number',
  mgaId = 'mga_id',
  agentNumber = 'agent_number',
  agentId = 'agent_id',
  agentName = 'agent_name',
  transDate = 'transdate',
  policyNumber = 'policy_number',
  insuredName = 'insured_name',
  productName = 'product_name',
  faceAmount = 'face_amount',
  lifePrem = 'life_prem',
  targetPrem = 'target_prem',
  excessPrem = 'excess_prem',
  annuity = 'annuity',
  policies = 'policies',
  weightedPrem = 'weighted_prem',
  recruiter = 'recruiter',
  year = 'year',
  week = 'week',
  hash = 'hash',
  // fields for runtime mapping
  mgaName = 'mga_name',
  agentDisplayName = 'agent_display_name',
}
export class PolicyTransaction extends BaseModel {
  [PolicyTransactionKeys.carrierName]?: string;
  [PolicyTransactionKeys.agencyNumber]?: string;
  [PolicyTransactionKeys.mgaId]?: string;
  [PolicyTransactionKeys.agentNumber]?: string;
  [PolicyTransactionKeys.agentId]?: string;
  [PolicyTransactionKeys.agentName]?: string;
  [PolicyTransactionKeys.transDate]?: string;
  [PolicyTransactionKeys.policyNumber]?: string;
  [PolicyTransactionKeys.insuredName]?: string;
  [PolicyTransactionKeys.productName]?: string;
  [PolicyTransactionKeys.faceAmount]?: number = 0;
  [PolicyTransactionKeys.lifePrem]?: number = 0;
  [PolicyTransactionKeys.targetPrem]?: number = 0;
  [PolicyTransactionKeys.excessPrem]?: number = 0;
  [PolicyTransactionKeys.annuity]?: number = 0;
  [PolicyTransactionKeys.policies]?: number = 0;
  [PolicyTransactionKeys.weightedPrem]?: number = 0;
  [PolicyTransactionKeys.recruiter]?: string;
  [PolicyTransactionKeys.year]?: number;
  [PolicyTransactionKeys.week]?: number;
  [PolicyTransactionKeys.hash]?: string;
  // fields for runtime mapping
  [PolicyTransactionKeys.mgaName]?: string;
  [PolicyTransactionKeys.agentDisplayName]?: string;
}

export const PolicyTransactionMapping: {
  [key: string]: MappingProperty;
} = {
  [PolicyTransactionKeys.carrierName]: { type: 'keyword' },
  [PolicyTransactionKeys.agencyNumber]: { type: 'keyword' },
  [PolicyTransactionKeys.mgaId]: { type: 'keyword' },
  [PolicyTransactionKeys.agentNumber]: { type: 'keyword' },
  [PolicyTransactionKeys.agentId]: { type: 'keyword' },
  [PolicyTransactionKeys.agentName]: { type: 'keyword' },
  [PolicyTransactionKeys.transDate]: { type: 'date', format: 'yyyy/MM/dd' },
  [PolicyTransactionKeys.policyNumber]: { type: 'keyword' },
  [PolicyTransactionKeys.insuredName]: { type: 'keyword' },
  [PolicyTransactionKeys.productName]: { type: 'keyword' },
  [PolicyTransactionKeys.recruiter]: { type: 'keyword' },
  [PolicyTransactionKeys.hash]: { type: 'keyword' },
  [PolicyTransactionKeys.faceAmount]: { type: 'double' },
  [PolicyTransactionKeys.lifePrem]: { type: 'double' },
  [PolicyTransactionKeys.targetPrem]: { type: 'double' },
  [PolicyTransactionKeys.excessPrem]: { type: 'double' },
  [PolicyTransactionKeys.annuity]: { type: 'double' },
  [PolicyTransactionKeys.policies]: { type: 'double' },
  [PolicyTransactionKeys.weightedPrem]: { type: 'double' },
  [PolicyTransactionKeys.year]: { type: 'double' },
  [PolicyTransactionKeys.week]: { type: 'double' },
};
