import { Injectable } from '@angular/core';
import { BaseFormService } from 'ag-common-svc/lib/utils/base-form-service';
import {
  Agent,
  AgentKeys,
  EmailAddressKeys,
  Entity,
  EntityApplications,
  EntityPermissionActivityKeys,
} from '@ag-common-lib/public-api';
import { UserRolesService } from 'ag-common-svc/lib/services/user-roles.service';
import { shareReplay } from 'rxjs/operators';
import { filter, mergeMap, Observable, startWith } from 'rxjs';
import { AgentService } from 'ag-common-svc/lib/services/agent.service';
import { QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/lib/dao/CommonFireStoreDao.dao';

@Injectable()
export class ConferencePermissionsModalService extends BaseFormService<Partial<Agent>> {
  eventListRoleIds$ = this.rolesService
    .getRoleIdsByEntity(Entity.conferenceList, EntityApplications.crm, EntityPermissionActivityKeys.read)
    .pipe(shareReplay(1));

  agentListWithConferencePermission$: Observable<Agent[]> = this.eventListRoleIds$.pipe(
    filter(Boolean),
    mergeMap(conferenceListRoleIds => {
      const qp = new QueryParam(AgentKeys.roles, WhereFilterOperandKeys.arrayContainsAny, conferenceListRoleIds);
      return this.agentService.getList([qp]);
    }),
    startWith([]),
    shareReplay(1),
  );

  constructor(
    private agentService: AgentService,
    private rolesService: UserRolesService,
  ) {
    super();
  }

  getFormData = async (data?: Partial<Agent>): Promise<Agent> => {
    const primaryEmailAddress = data?.[AgentKeys.email_addresses] || [
      {
        [EmailAddressKeys.address]: data?.[AgentKeys.p_email],
        [EmailAddressKeys.isLogin]: true,
      },
    ];
    const initialData = Object.assign({}, new Agent(), data, {
      [AgentKeys.email_addresses]: primaryEmailAddress ?? [],
    });

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });

    return this.formData;
  };
}
