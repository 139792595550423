import { Injectable } from '@angular/core';
import {
  Address,
  Agent,
  AgentKeys,
  Association,
  AttendeeKeys,
  Conference,
  ConferenceKeys,
  ConferencePossibleStayPeriodKey,
  ConferenceRegistrationStepName,
  ConferenceStepsConfigurationKeys,
  ConferenceGuestsStepsConfigurationSectionName,
  DietaryConsideration,
  EmailAddress,
  Headshot,
  HotelReservation,
  HotelReservationKeys,
  PhoneNumber,
  PhoneNumberKeys,
  Registrant,
  RegistrantData,
  RegistrantKeys,
  RegistrantModelKeys,
  RegistrantWizardStateKeys,
  RegistrationType,
  TShirtSizes,
  WizardStepState,
  Entity,
  EmailAddressKeys,
  EntityPermissionActivityKeys,
} from 'ag-common-lib/public-api';
import {
  AgentEmailAddressesService,
  AgentService,
  BaseFormService,
  ConferenceGuestsService,
  ConferenceRegistrantsService,
  ConferenceService,
  getCurrentStepInProgress,
  prepareStepsToComplete,
} from 'ag-common-svc/public-api';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  finalize,
  firstValueFrom,
  from,
  map,
  mergeMap,
  Observable,
  of,
  shareReplay,
  Subject,
  take,
  tap,
} from 'rxjs';
import {
  ATTENDEE_DETAIL_MODAL_SECTIONS_CONFIGURATION,
  AttendeeDetailsModalSection,
  AttendeeSectionGroups,
  AttendeeSectionGroupsData,
  attendeeSectionGroupsToFieldsMap,
} from './attendee-details-modal.model';
import { AgentAssociationsService } from 'ag-common-svc/lib/services/agent-associations.service';
import { set } from 'lodash';
import { getAddressKeyExpression } from 'ag-common-svc/lib/utils/address.util';
import { SuffixPipe } from 'ag-common-svc/shared/pipes/suffix.pipe';
import { HasPermissionPipe } from 'ag-common-svc/shared/pipes/has-permission.pipe';
import { switchMap } from 'rxjs/operators';
import { RegistrationSummaryEmailService } from 'ag-common-svc/lib/services/registration-summary-email.service';
import { IsRequestedDifferentHotelCheckInCheckOutDatesPipe } from 'ag-common-svc/shared/pipes/is-requested-different-hotel-check-in-check-out-dates.pipe';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Injectable()
export class AttendeeDetailsModalService extends BaseFormService<RegistrantData> {
  userAction$ = new Subject<any>();

  private _isFirstTimeAttendee$ = new BehaviorSubject(false);
  isFirstTimeAttendee$ = this._isFirstTimeAttendee$.asObservable();

  private _formData$ = new BehaviorSubject<RegistrantData>(null);
  formData$ = this._formData$.asObservable();

  hasFormChanges$ = this.formChangesDetector.actions$.pipe(map(() => this.formChangesDetector.hasChanges));

  private _isRequestedDifferentHotelCheckInCheckOutDates$ = new BehaviorSubject(false);
  isRequestedDifferentHotelCheckInCheckOutDates$ = this._isRequestedDifferentHotelCheckInCheckOutDates$.asObservable();

  assignOwnerList$: Observable<DataSource<any, any>>;
  attendee$ = new BehaviorSubject<Attendee>(null);
  private _sectionsInEditState$ = new BehaviorSubject(new Set<AttendeeSectionGroups>());
  sectionsInEditState$ = this._sectionsInEditState$.asObservable();

  private _sectionsEditMapState = new Map<AttendeeSectionGroups, AttendeeSectionGroupsData<AttendeeSectionGroups>>();

  isBillingDifferentFromShippingAddress$ = new BehaviorSubject<boolean>(false);

  private _conferenceDbId$ = new BehaviorSubject<string>(null);
  conferenceDbId$ = this._conferenceDbId$.asObservable();
  conference$: Observable<Conference> = this._conferenceDbId$.pipe(
    filter(Boolean),
    mergeMap(conferenceDbId => {
      return this.conferenceService.getDocumentData(conferenceDbId);
    }),
    shareReplay(1),
  );

  private _registrantDbId$ = new BehaviorSubject<string>(null);
  registrantDbId$ = this._registrantDbId$.asObservable();

  registrant$: Observable<Registrant> = combineLatest({
    registrantDbId: this._registrantDbId$.pipe(filter(Boolean)),
    conferenceDbId: this._conferenceDbId$.pipe(filter(Boolean)),
  }).pipe(
    mergeMap(({ conferenceDbId, registrantDbId }) => {
      return this.conferenceRegistrantsService.getDocumentData(conferenceDbId, registrantDbId);
    }),
    shareReplay(1),
  );
  wizardState$ = this.registrant$.pipe(map(registrant => registrant?.[RegistrantModelKeys.wizardState]));

  conferenceStepsConfiguration$ = this.conference$.pipe(
    map(conference => conference?.[ConferenceKeys.stepsConfiguration]),
  );
  conferenceGuestExcludedSections$ = this.conferenceStepsConfiguration$.pipe(
    map(config => {
      return config.find(
        c =>
          c[ConferenceStepsConfigurationKeys.stepName] ===
          ConferenceRegistrationStepName.registrantGuestConfigurationStep,
      )?.[ConferenceStepsConfigurationKeys.excludedSections] as ConferenceGuestsStepsConfigurationSectionName[];
    }),
  );
  conferencePersonalInfoExcludedSections$ = this.conferenceStepsConfiguration$.pipe(
    map(config => {
      return config.find(
        c =>
          c[ConferenceStepsConfigurationKeys.stepName] ===
          ConferenceRegistrationStepName.registrantPersonalInformationStep,
      )?.[ConferenceStepsConfigurationKeys.excludedSections] as ConferenceRegistrationStepName[];
    }),
  );

  eventName$ = this.conference$.pipe(map(conference => conference?.[ConferenceKeys.eventName]));

  agent$: Observable<Agent> = this._registrantDbId$.pipe(
    filter(Boolean),
    mergeMap(agentDbId => {
      return this.agentService.getDocument(agentDbId);
    }),
    map(agentSnapshot => {
      if (!agentSnapshot.exists()) {
        return null;
      }
      return agentSnapshot.data() ?? null;
    }),
    shareReplay(1),
  );

  agentEmailAddresses$ = this.agent$.pipe(
    mergeMap((agent: Agent) => this.agentEmailAddressesService.getList(agent?.[BaseModelKeys.dbId])),
    shareReplay(1),
  );

  agentEmergencyContacts$ = this.agent$.pipe(
    mergeMap((agent: Agent) => this.agentAssociationsService.getEmergencyContactsList(agent?.[BaseModelKeys.dbId])),
    shareReplay(1),
  );

  agentPhoneNumbers$ = this.agent$.pipe(
    map((agent: Agent) => agent?.[AgentKeys.phone_numbers]),
    shareReplay(1),
  );

  agentAddresses$ = this.agent$.pipe(
    map((agent: Agent) => agent?.[AgentKeys.addresses]),
    shareReplay(1),
  );

  attendeeSections$ = combineLatest({
    conference: this.conference$.pipe(filter(Boolean)),
    hasLogsPermission: this.hasPermissionPipe
      .transform(Entity.conferenceChangeLogs, EntityPermissionActivityKeys.read)
      .pipe(shareReplay(1)),
    hasTransactionHistoryPermission: this.hasPermissionPipe
      .transform(Entity.conferenceTransactions)
      .pipe(shareReplay(1)),
  }).pipe(
    map(({ conference, hasLogsPermission, hasTransactionHistoryPermission }) => {
      const sectionsMap = new Map<AttendeeDetailsModalSection, boolean>([
        [AttendeeDetailsModalSection.flyInformation, conference?.[ConferenceKeys.flightsEnabled] ?? false],
        [AttendeeDetailsModalSection.hotelReservation, conference?.[ConferenceKeys.hotelEnabled] ?? false],
        [AttendeeDetailsModalSection.guests, conference?.[ConferenceKeys.guestsEnabled] ?? false],
        [AttendeeDetailsModalSection.excursions, conference?.[ConferenceKeys.excursionsEnabled] ?? false],
        [AttendeeDetailsModalSection.changeLog, hasLogsPermission ?? false],
        [AttendeeDetailsModalSection.transactions, hasTransactionHistoryPermission ?? false],
      ]);

      return ATTENDEE_DETAIL_MODAL_SECTIONS_CONFIGURATION.filter(section => {
        if (sectionsMap.has(section?.id)) {
          return sectionsMap.get(section?.id);
        }
        return true;
      });
    }),
    shareReplay(1),
  );

  registrantGuests$ = this.registrant$.pipe(
    filter(Boolean),
    mergeMap((conferenceRegistration: Registrant) => {
      return this.conferenceGuestsService.getList(
        conferenceRegistration?.[RegistrantModelKeys.conferenceDbId],
        conferenceRegistration?.[BaseModelKeys.dbId],
      );
    }),

    shareReplay(1),
  );

  private _guestsIds$ = this.registrantGuests$.pipe(map(guests => guests.map(guest => guest[BaseModelKeys.dbId])));

  currentStepInProgress$ = combineLatest({
    conference: this.conference$,
    conferenceRegistration: this.registrant$,
    guestsIds: this._guestsIds$,
  }).pipe(map(getCurrentStepInProgress), shareReplay(1));

  mayBeRefreshFromAgent$ = combineLatest({
    agent: this.agent$,
    conferenceRegistration: this.registrant$,
  }).pipe(
    switchMap(({ agent, conferenceRegistration }) =>
      from(Promise.all([firstValueFrom(this.suffixPipe.transform(agent?.[AgentKeys.p_suffix]))])).pipe(
        map(([suffix]) => ({ suffix, agent, conferenceRegistration })),
      ),
    ),
    map(({ suffix, agent, conferenceRegistration }) => {
      const wizardState = conferenceRegistration?.[RegistrantModelKeys.wizardState] ?? {};

      if (
        wizardState?.[ConferenceRegistrationStepName.registrantPersonalInformationAboutStep] &&
        wizardState?.[ConferenceRegistrationStepName.registrantPersonalInformationAboutStep] !== WizardStepState.initial
      ) {
        return false;
      }

      const conferenceRegistrationInfo = conferenceRegistration?.[RegistrantModelKeys.data];

      const agentPrefixId = agent?.[AgentKeys.p_prefix];
      const agentSuffixId = agent?.[AgentKeys.p_suffix];
      const agentFirstName = agent?.[AgentKeys.p_agent_first_name];
      const agentLastName = agent?.[AgentKeys.p_agent_last_name];
      const agentBadgeName = [agentFirstName, agentLastName, suffix].filter(Boolean).join(' ');
      const phoneNumbers = agent?.[AgentKeys.phone_numbers];

      const agentPrimaryPhoneNumber = phoneNumbers?.find(phoneNumber => phoneNumber?.[PhoneNumberKeys.is_primary]);

      const agentInfo = {
        [RegistrantKeys.prefix]: agentPrefixId,
        [RegistrantKeys.firstName]: agentFirstName,
        [RegistrantKeys.middleName]: agent?.[AgentKeys.p_agent_middle_name],
        [RegistrantKeys.lastName]: agentLastName,
        [RegistrantKeys.suffix]: agentSuffixId,
        [RegistrantKeys.gender]: agent?.[AgentKeys.gender],
        [RegistrantKeys.dob]: agent?.[AgentKeys.dob],
        [RegistrantKeys.badgeName]: agentBadgeName,
        [RegistrantKeys.mobilePhone]: agentPrimaryPhoneNumber,
      };

      const keysToCheck = [
        RegistrantKeys.prefix,
        RegistrantKeys.firstName,
        RegistrantKeys.middleName,
        RegistrantKeys.lastName,
        RegistrantKeys.suffix,
        RegistrantKeys.gender,
        RegistrantKeys.dob,
        RegistrantKeys.badgeName,
      ];
      const agentPrimaryInfoNotEqual = keysToCheck.some(key => {
        console.log(conferenceRegistrationInfo[key], agentInfo[key]);

        return conferenceRegistrationInfo[key] !== agentInfo[key];
      });

      const phoneNumbersNotEqual =
        conferenceRegistrationInfo[RegistrantKeys.mobilePhone][PhoneNumberKeys.number] !==
        agentInfo[RegistrantKeys.mobilePhone][PhoneNumberKeys.number];

      return agentPrimaryInfoNotEqual || phoneNumbersNotEqual;
    }),
    shareReplay(1),
  );

  private _showModal$ = new Subject<string>();
  showModal$: Observable<number> = this._showModal$.pipe(
    filter(Boolean),
    tap(() => {
      this.startLoadData();
    }),
    mergeMap(attendeeDbDbId => {
      return this.registrant$.pipe(
        filter(Boolean),
        map(registrant => registrant?.[BaseModelKeys.dbId]),
        filter(registrantDbId => registrantDbId === attendeeDbDbId),
        take(1),
      );
    }),
    mergeMap(() =>
      this.attendeeSections$.pipe(
        map(sections => {
          const index = sections?.findIndex(section => section?.id === this._defaultSectionId);

          return index < 0 ? 0 : index;
        }),
        take(1),
      ),
    ),
    tap(() => {
      this.stopLoadData();
    }),
    finalize(() => {
      this.stopLoadData();
    }),
    shareReplay(1),
  );

  private _defaultSectionId: AttendeeDetailsModalSection;
  private _inviteeEmail: string;

  constructor(
    private suffixPipe: SuffixPipe,
    private toastrService: ToastrService,
    private agentService: AgentService,
    private agentAssociationsService: AgentAssociationsService,
    private conferenceService: ConferenceService,
    private conferenceGuestsService: ConferenceGuestsService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
    private agentEmailAddressesService: AgentEmailAddressesService,
    private registrationSummaryEmailService: RegistrationSummaryEmailService,
    private isRequestedDifferentHotelCheckInCheckOutDatesPipe: IsRequestedDifferentHotelCheckInCheckOutDatesPipe,
    private hasPermissionPipe: HasPermissionPipe,
  ) {
    super();

    combineLatest({ conference: this.conference$, registrant: this.registrant$ }).subscribe(this.getFormData);

    this.assignOwnerList$ = this.conference$.pipe(
      map(conference => conference?.[ConferenceKeys.permissionConfigurationIds]),
      mergeMap(permissionConfigurationIds => {
        return from(this.agentService.getAgentsByAgentIds(permissionConfigurationIds));
      }),
      map(assignOwnerList => {
        assignOwnerList = assignOwnerList.map(item => ({
          ...item,
          [AgentKeys.email_addresses]: item[AgentKeys.email_addresses] || [
            {
              [EmailAddressKeys.address]: item[AgentKeys.p_email],
              [EmailAddressKeys.isLogin]: true,
            },
          ],
        }));
        return new DataSource({
          store: new ArrayStore({
            key: 'dbId',
            data: Array.isArray(assignOwnerList) ? assignOwnerList : [],
          }),
        });
      }),
      shareReplay(1),
    );
  }

  showModal = (
    attendee: Attendee,
    defaultSectionId: AttendeeDetailsModalSection = AttendeeDetailsModalSection.generalInfo,
  ): Promise<any> => {
    this.attendee$.next(attendee); // to send email
    this._inviteeEmail = attendee?.[AttendeeKeys.inviteeEmail];
    const conferenceDbId = attendee?.[AttendeeKeys.conferenceDbId];
    this._defaultSectionId = defaultSectionId;
    this._conferenceDbId$.next(conferenceDbId);
    const registrantDbId = attendee.registrant?.[BaseModelKeys.dbId];
    this._registrantDbId$.next(attendee.registrant?.[BaseModelKeys.dbId]);
    this._showModal$.next(registrantDbId);

    return firstValueFrom(this.userAction$);
  };

  setRegistrationData = (attendee?: Attendee) => {
    const conferenceDbId = attendee?.[AttendeeKeys.conferenceDbId];
    this._conferenceDbId$.next(conferenceDbId);
    this._registrantDbId$.next(attendee?.registrant?.[BaseModelKeys.dbId]);
  };

  getFormData = async ({
    registrant,
    conference,
  }: {
    registrant?: Registrant;
    conference: Conference;
  }): Promise<RegistrantData> => {
    const registrantData = registrant?.[RegistrantModelKeys.data];
    const wizardState = registrant?.[RegistrantModelKeys.wizardState];

    const isFirstTimeAttendee = registrantData?.[RegistrantKeys.firstTimeAttendee];
    const isRequestedAdditionalGuests = registrantData?.[RegistrantKeys.additionalGuestRequested];
    this.calculateIsRequestedDifferentHotelDates(registrantData);

    this._isFirstTimeAttendee$.next(isFirstTimeAttendee);

    const primaryAddressProxy = this.prepareEmailAddressProxy(registrantData?.[RegistrantKeys.primaryEmailAddress]);
    const mobilePhoneProxy = this.preparePhoneProxy(registrantData?.[RegistrantKeys.mobilePhone]);
    const tShirtsSizesProxy = this.prepareTShirtsSizesProxy(registrantData?.[RegistrantKeys.tShirtSizes]);
    const headshotProxy = this.prepareHeadshotProxy(registrantData?.[RegistrantKeys.headshot]);
    const emergencyContactProxy = this.prepareEmergencyContactProxy(registrantData?.[RegistrantKeys.emergencyContact]);
    const dietaryConsiderationProxy = this.prepareDietaryConsiderationProxy(
      registrantData?.[RegistrantKeys.dietaryConsideration],
    );
    const shippingAddress = registrantData?.[RegistrantKeys.shippingAddress];
    const billingAddress = registrantData?.[RegistrantKeys.billingAddress];
    const shippingAddressKey = getAddressKeyExpression(shippingAddress);
    const billingAddressKey = getAddressKeyExpression(billingAddress);
    const isBillingDifferentFromShippingAddress = shippingAddressKey !== billingAddressKey;
    const shippingAddressProxy = this.prepareShippingAddressesProxy(shippingAddress);
    const billingAddressProxy = this.prepareBillingAddressesProxy(billingAddress);
    const hotelReservationProxy = this.prepareHotelReservationProxy(registrantData?.[RegistrantKeys.hotelReservation]);

    this.formData = new Proxy(
      Object.assign({}, registrantData, {
        [RegistrantKeys.isBillingDifferentFromShippingAddress]: isBillingDifferentFromShippingAddress,
        [RegistrantKeys.primaryEmailAddress]: primaryAddressProxy,
        [RegistrantKeys.mobilePhone]: mobilePhoneProxy,
        [RegistrantKeys.tShirtSizes]: tShirtsSizesProxy,
        [RegistrantKeys.headshot]: headshotProxy,
        [RegistrantKeys.dietaryConsideration]: dietaryConsiderationProxy,
        [RegistrantKeys.emergencyContact]: emergencyContactProxy,
        [RegistrantKeys.shippingAddress]: shippingAddressProxy,
        [RegistrantKeys.billingAddress]: billingAddressProxy,
        [RegistrantKeys.hotelReservation]: hotelReservationProxy,
      }),
      {
        set: (target, prop, value, receiver) => {
          const prevValue = target[prop];
          if (value !== prevValue) {
            this.formChangesDetector.handleChange(prop, value, prevValue);
            Reflect.set(target, prop, value, receiver);

            switch (prop) {
              case RegistrantKeys.registrationType:
                this.getTravelDates(conference, wizardState, value);
                break;
              case RegistrantKeys.firstTimeAttendee:
                this._isFirstTimeAttendee$.next(value);
                break;

              case RegistrantKeys.inviteeStatus:
                Object.assign(this.formData, { [RegistrantKeys.inviteeOutcomeStatus]: null });
                break;

              case RegistrantKeys.isBillingDifferentFromShippingAddress:
                this.isBillingDifferentFromShippingAddress$.next(value ?? false);
                if (!value) {
                  Object.keys(this.formData?.[RegistrantKeys.billingAddress]).forEach(key => {
                    set(this.formData?.[RegistrantKeys.billingAddress], key, null);
                  });
                  Object.entries(this.formData?.[RegistrantKeys.shippingAddress]).forEach(([key, value]) => {
                    set(this.formData?.[RegistrantKeys.billingAddress], key, value);
                  });
                }
                break;
              default:
                break;
            }
          }

          return true;
        },
      },
    );

    this._formData$.next(this.formData);
    this.formChangesDetector.clear();
    this.stopLoadData();

    return this.formData;
  };

  updateRegistrant = async (): Promise<void> => {
    if (this._inviteeEmail !== this.formData?.[RegistrantKeys.inviteeEmail]) {
      this.toastrService.error("Data can't saved. Reload required.");
      return;
    }

    const conferenceDbId = this._conferenceDbId$.value;
    const registrantDbId = this._registrantDbId$.value;

    this.startProgress();
    return this.conferenceRegistrantsService
      .update(conferenceDbId, registrantDbId, { [RegistrantModelKeys.data]: this.formData }, true)
      .then(() => {
        this.toastrService.success('Attendee Information Successfully Updated');
        this.formChangesDetector.clear();
      })
      .catch(err => {
        this.toastrService.error('Attendee Information was Not Updated');
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  lockWizard = async (): Promise<void> => {
    const conferenceRegistration = await firstValueFrom(this.registrant$);
    const conference = await firstValueFrom(this.conference$);
    this.startProgress();

    const wizardState = prepareStepsToComplete({
      conference,

      conferenceRegistration,
    });

    Object.assign(wizardState, {
      [RegistrantWizardStateKeys.isSubmitted]: true,
    });

    return this.conferenceRegistrantsService
      .update(
        conferenceRegistration?.[RegistrantModelKeys.conferenceDbId],
        conferenceRegistration?.[BaseModelKeys.dbId],
        {
          [RegistrantModelKeys.wizardState]: wizardState,
        },
        true,
      )
      .then(() => {
        this.revertAllChanges();
      })
      .catch(err => {
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  unlockWizard = async (): Promise<void> => {
    const conferenceDbId = this._conferenceDbId$.value;
    const registrantDbId = this._registrantDbId$.value;
    this.startProgress();

    // TODO handle agent data changes

    return this.conferenceRegistrantsService
      .update(
        conferenceDbId,
        registrantDbId,
        {
          [RegistrantModelKeys.wizardState]: {
            [RegistrantWizardStateKeys.isSubmitted]: false,
            [ConferenceRegistrationStepName.registrationSummaryStep]: null,
            [ConferenceRegistrationStepName.registrantPaymentStep]: null,
            [ConferenceRegistrationStepName.registrationConfirmationStep]: null,
          },
        },
        true,
      )
      .then(() => {
        this.revertAllChanges();
      })
      .catch(err => {
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  updateAttendee = async (): Promise<void> => {
    const agent = await firstValueFrom(this.agent$);
    const conferenceDbId = this._conferenceDbId$.value;
    const registrantDbId = this._registrantDbId$.value;
    this.startProgress();

    const agentPrefixId = agent?.[AgentKeys.p_prefix];
    const agentSuffixId = agent?.[AgentKeys.p_suffix];
    const agentFirstName = agent?.[AgentKeys.p_agent_first_name];
    const agentLastName = agent?.[AgentKeys.p_agent_last_name];
    const [suffix] = await Promise.all([firstValueFrom(this.suffixPipe.transform(agentSuffixId))]);
    const agentBadgeName = [agentFirstName, agentLastName, suffix].filter(Boolean).join(' ');
    const phoneNumbers = agent?.[AgentKeys.phone_numbers];

    const agentPrimaryPhoneNumber = phoneNumbers?.find(phoneNumber => phoneNumber?.[PhoneNumberKeys.is_primary]);

    const agentInfo = {
      [RegistrantKeys.prefix]: agentPrefixId,
      [RegistrantKeys.firstName]: agentFirstName,
      [RegistrantKeys.middleName]: agent?.[AgentKeys.p_agent_middle_name],
      [RegistrantKeys.lastName]: agentLastName,
      [RegistrantKeys.suffix]: agentSuffixId,
      [RegistrantKeys.gender]: agent?.[AgentKeys.gender],
      [RegistrantKeys.dob]: agent?.[AgentKeys.dob],
      [RegistrantKeys.badgeName]: agentBadgeName,
      [RegistrantKeys.mobilePhone]: agentPrimaryPhoneNumber,
    };

    return this.conferenceRegistrantsService
      .update(
        conferenceDbId,
        registrantDbId,
        {
          [RegistrantModelKeys.data]: agentInfo,
        },
        true,
      )
      .then(() => {
        this.revertAllChanges();
      })
      .catch(err => {
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  startEditSectionGroup = (sectionGroupId: AttendeeSectionGroups) => {
    const sectionsInEditState = this._sectionsInEditState$.value;
    sectionsInEditState.add(sectionGroupId);
    this._sectionsEditMapState.set(
      sectionGroupId,
      Object.assign({}, this.formData[attendeeSectionGroupsToFieldsMap.get(sectionGroupId)]),
    );
  };

  cancelEditSectionGroup = (sectionGroupId: AttendeeSectionGroups) => {
    const sectionsInEditState = this._sectionsInEditState$.value;
    sectionsInEditState.delete(sectionGroupId);
    this.formData[attendeeSectionGroupsToFieldsMap.get(sectionGroupId)] = {
      ...this._sectionsEditMapState.get(sectionGroupId),
    };
    this._sectionsEditMapState.delete(sectionGroupId);
  };

  saveEditSectionGroup = (sectionGroupId: AttendeeSectionGroups) => {
    const sectionsInEditState = this._sectionsInEditState$.value;
    sectionsInEditState.delete(sectionGroupId);
    this._sectionsEditMapState.delete(sectionGroupId);
  };

  private prepareTShirtsSizesProxy = (tShirtsSizes: Partial<TShirtSizes>) => {
    return new Proxy(Object.assign({}, new TShirtSizes(), tShirtsSizes), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange([RegistrantKeys.tShirtSizes, prop].join('.'), value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });
  };

  private prepareDietaryConsiderationProxy = (dietaryConsideration: Partial<DietaryConsideration>) => {
    return new Proxy(Object.assign({}, new DietaryConsideration(), dietaryConsideration), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange(
            [RegistrantKeys.dietaryConsideration, prop].join('.'),
            value,
            prevValue,
          );
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });
  };

  private prepareEmailAddressProxy = (emailAddress: Partial<EmailAddress>) => {
    return new Proxy(Object.assign({}, emailAddress), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange([RegistrantKeys.primaryEmailAddress, prop].join('.'), value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });
  };

  private preparePhoneProxy = (phoneNumber: Partial<PhoneNumber>) => {
    return new Proxy(Object.assign({}, phoneNumber), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange([RegistrantKeys.mobilePhone, prop].join('.'), value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });
  };

  private prepareHeadshotProxy(headshot: Headshot) {
    return new Proxy(Object.assign({}, headshot), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange([RegistrantKeys.headshot, prop].join('.'), value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });
  }

  private prepareEmergencyContactProxy(emergencyContact: Association) {
    return new Proxy(Object.assign({}, emergencyContact), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange([RegistrantKeys.emergencyContact, prop].join('.'), value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });
  }

  private prepareShippingAddressesProxy(shippingAddress: Address) {
    const shippingAddressProxy = new Proxy(Object.assign({}, new Address(), shippingAddress), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value == prevValue) {
          return true;
        }

        this.formChangesDetector.handleChange([RegistrantKeys.shippingAddress, prop].join('.'), value, prevValue);
        Reflect.set(target, prop, value, receiver);

        if (!this.formData?.[RegistrantKeys.isBillingDifferentFromShippingAddress]) {
          set(this.formData[RegistrantKeys.billingAddress], prop, value);
        }

        return true;
      },
    });
    return shippingAddressProxy;
  }

  private prepareBillingAddressesProxy(billingAddress: Address) {
    const billingAddressAddressProxy = new Proxy(Object.assign({}, new Address(), billingAddress), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          this.formChangesDetector.handleChange([RegistrantKeys.billingAddress, prop].join('.'), value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });

    return billingAddressAddressProxy;
  }

  private prepareHotelReservationProxy(hotelReservation: HotelReservation) {
    const hotelReservationAddressProxy = new Proxy(Object.assign({}, new HotelReservation(), hotelReservation), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange([RegistrantKeys.hotelReservation, prop].join('.'), value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });

    return hotelReservationAddressProxy;
  }

  private calculateIsRequestedDifferentHotelDates = (registrant: RegistrantData) => {
    const hotelReservation = registrant?.[RegistrantKeys.hotelReservation];
    const checkInDate = hotelReservation?.[HotelReservationKeys.checkInDate];
    const checkOutDate = hotelReservation?.[HotelReservationKeys.checkOutDate];
    const requestedCheckInDate = hotelReservation?.[HotelReservationKeys.requestedCheckInDate];
    const requestedCheckOutDate = hotelReservation?.[HotelReservationKeys.requestedCheckOutDate];

    const isRequestedDifferentHotelCheckInCheckOutDates =
      this.isRequestedDifferentHotelCheckInCheckOutDatesPipe.transform(
        checkInDate,
        checkOutDate,
        requestedCheckInDate,
        requestedCheckOutDate,
      );

    this._isRequestedDifferentHotelCheckInCheckOutDates$.next(isRequestedDifferentHotelCheckInCheckOutDates);
  };

  private getTravelDates = (conference: Conference, wizardState, registrationType: RegistrationType) => {
    const participantScheduleRanges = conference?.[ConferenceKeys.participantScheduleRanges];
    const scheduleRange = participantScheduleRanges.find(
      range => range?.[ConferencePossibleStayPeriodKey.registrationType] === registrationType,
    );

    const arrivalDate =
      scheduleRange?.[ConferencePossibleStayPeriodKey.primaryArrivalDate] ?? conference?.[ConferenceKeys.startDate];
    const departureDate =
      scheduleRange?.[ConferencePossibleStayPeriodKey.primaryDepartureDate] ?? conference?.[ConferenceKeys.endDate];

    if (!wizardState?.[ConferenceRegistrationStepName.registrantHotelReservationStep]) {
      set(this.formData, [RegistrantKeys.hotelReservation, HotelReservationKeys.checkInDate], arrivalDate);
      set(this.formData, [RegistrantKeys.hotelReservation, HotelReservationKeys.checkOutDate], departureDate);
      set(this.formData, [RegistrantKeys.hotelReservation, HotelReservationKeys.requestedCheckInDate], arrivalDate);
      set(this.formData, [RegistrantKeys.hotelReservation, HotelReservationKeys.requestedCheckOutDate], departureDate);
    }
  };

  getExcludedFields = (
    stepNames: ConferenceRegistrationStepName | ConferenceRegistrationStepName[],
  ): Observable<RegistrantKeys[]> => {
    if (!stepNames?.length) {
      return of([]);
    }

    const stepNamesToCheck = Array.isArray(stepNames) ? stepNames : [stepNames];

    return this.conferenceStepsConfiguration$.pipe(
      take(1),
      map(configuration => {
        return configuration
          .filter(config => stepNamesToCheck.includes(config.stepName))
          .flatMap(config => config?.[ConferenceStepsConfigurationKeys.excludedFields] ?? []);
      }),
    );
  };
}
