import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import {
  APPROVE_DENY_REASON_VISIBILITY_LEVEL_LOOKUP,
  ApproveDenyReason,
  ApproveDenyReasonKeys,
  Constants,
} from '@ag-common-lib/public-api';
import { StatusActivityModalService } from './status-activity-modal.service';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { DxFormComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-status-activity-modal',
  templateUrl: './status-activity-modal.component.html',
  styleUrls: ['./status-activity-modal.component.scss'],
  providers: [StatusActivityModalService],
})
export class StatusActivityModalComponent {
  @HostBinding('class') className = 'ag-status-activity-modal';
  @ViewChild('statusActivityModalRef', { static: true }) statusActivityModalComponent: ModalWindowComponent;
  @ViewChild('statusActivityFormRef', { static: false }) statusActivityFormComponent: DxFormComponent;
  @Input() title: string;
  @Input() isVisibilityTypeLocked = false;

  agencyId: string;
  approveDenyReasonFormData: ApproveDenyReason;
  readonly BaseModelKeys = BaseModelKeys;
  readonly ApproveDenyReasonKeys = ApproveDenyReasonKeys;
  readonly approveDenyReasonVisibilityLevelLookup = APPROVE_DENY_REASON_VISIBILITY_LEVEL_LOOKUP;
  readonly Constants = Constants;

  inProgress$ = this.statusActivityModalService.inProgress$;
  showSaveButton$: Observable<boolean>;

  constructor(private statusActivityModalService: StatusActivityModalService) {}

  showModal = async (agencyId: string, data?: ApproveDenyReason): Promise<void> => {
    this.agencyId = agencyId;
    this.approveDenyReasonFormData = await this.statusActivityModalService.getFormData(data);
    this.showSaveButton$ = this.statusActivityModalService.showSaveButton(this.approveDenyReasonFormData);
    this.statusActivityModalComponent?.showModal();
  };

  handleSaveApproveDenyReason = e => {
    const validationResults = this.statusActivityFormComponent.instance.validate();
    if (validationResults.isValid) {
      this.statusActivityModalService.saveActivity(this.agencyId).then(() => {
        e.component.instance.hide();
      });
    }
  };

  onAfterRevertChanges = (): void => {
    this.statusActivityFormComponent.instance.repaint();
  };

  handleClosePopup = () => this.statusActivityModalService.onCancelEdit.call(this, this.onAfterRevertChanges);
}
