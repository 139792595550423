import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserRolesService } from 'ag-common-svc/lib/services/user-roles.service';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { UserRole } from '@ag-common-lib/public-api';

@Pipe({
  name: 'userRolesNameByIds',
})
export class UserRolesNameByIdsPipe implements PipeTransform {
  constructor(private rolesService: UserRolesService) {}

  transform(roles: string[]): Observable<UserRole[]> {
    const rolesSet = new Set<string>(roles ?? []);
    return this.rolesService.getList().pipe(
      map(roles => roles?.filter(role => rolesSet.has(role?.[BaseModelKeys.dbId]))),
      shareReplay(1),
    );
  }
}
