import { Component, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { ToastrService } from 'ngx-toastr';
import { EmailTemplate } from 'ag-common-lib/public-api';
import { AuthService } from 'ag-common-svc/public-api';
import { EmailTemplateService } from 'src/app/shared/services/email-template.service';
import { firstValueFrom } from 'rxjs';
import { QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/lib/dao/CommonFireStoreDao.dao';

@Component({
  selector: 'app-email-templates-grid',
  templateUrl: './email-templates-grid.component.html',
  styleUrls: ['./email-templates-grid.component.scss'],
})
export class EmailTemplatesGridComponent {
  @ViewChild('templategrid') templategrid: DxDataGridComponent;

  templatesDataSource: any = {};

  emailTemplate: EmailTemplate;

  viewTemplatelDetailsVisible: boolean = false;

  constructor(
    public authService: AuthService,
    public emailTemplateService: EmailTemplateService,
    public toastrService: ToastrService,
  ) {
    this.templatesDataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: async function (loadOptions: any) {
        let qp: QueryParam[] = [];
        const agent = await firstValueFrom(authService.loggedInAgent$);
        qp.push(new QueryParam('created_by', WhereFilterOperandKeys.equal, agent.p_email));
        return emailTemplateService.getAllByValue(qp, null, 'name');
      },
      remove: function (id: any) {
        return emailTemplateService.delete(id);
      },
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        onClick: () => {
          this.createNewEmailTemplate();
        },
      },
    });
  }

  private createNewEmailTemplate() {
    this.emailTemplateService.createNewEmailTemplate().then(template => {
      this.emailTemplate = template;
      this.viewTemplatelDetailsVisible = true;
    });
  }

  editEmailTemplate(e) {
    this.emailTemplate = e.data;
    this.viewTemplatelDetailsVisible = true;
  }

  closeEmailDetails(e) {
    this.viewTemplatelDetailsVisible = false;
  }

  saveEmailTemplate(emailTemplate: EmailTemplate) {
    if (emailTemplate) {
      if (emailTemplate.dbId) {
        this.emailTemplateService.update(emailTemplate).then(() => {
          this.toastrService.success('Email Template Saved Successfully!');
          this.templategrid.instance.refresh();
          this.viewTemplatelDetailsVisible = false;
        });
      } else {
        this.emailTemplateService.create(emailTemplate).then(() => {
          this.toastrService.success('Email Template Created Successfully!');
          this.templategrid.instance.refresh();
          this.viewTemplatelDetailsVisible = false;
        });
      }
    } else {
      this.viewTemplatelDetailsVisible = false;
    }
  }

  getCreatedDate(e) {
    if (e && e.date_created) {
      return new Date(e.date_created.seconds * 1000).toLocaleString();
    } else {
      return '';
    }
  }
}
