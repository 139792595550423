import { Injectable } from '@angular/core';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import {
  Conference,
  EntityApplications,
  GuestData,
  GuestKeys,
  Registrant,
  UserRole,
  UserRoleModelKeys,
} from 'ag-common-lib/public-api';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';
import {
  AuthService,
  ConferenceGuestsService,
  ConferenceRegistrantsService,
  ConferenceService,
} from 'ag-common-svc/public-api';
import { Dictionary, groupBy } from 'lodash';
import { BehaviorSubject, combineLatest, filter, map, mergeMap, Observable, shareReplay, startWith } from 'rxjs';

@Injectable()
export class AttendeesListService {
  private _conferenceDbId$ = new BehaviorSubject<string>(null);
  conferenceDbId$ = this._conferenceDbId$.asObservable();

  conference$: Observable<Conference> = this._conferenceDbId$.pipe(
    filter(Boolean),
    mergeMap(conferenceDbId => this.conferenceService.getDocumentData(conferenceDbId)),
    startWith({}),
    shareReplay(1),
  );

  private guestsGroupedByRegistrantId$: Observable<Dictionary<GuestData[]>> = this._conferenceDbId$.pipe(
    filter(Boolean),
    mergeMap(conferenceDbId => this.conferenceGuestsService.getConferenceGuestsByConferenceId(conferenceDbId)),
    map(guests => groupBy(guests, GuestKeys.registrantDbId)),
    startWith({}),
    shareReplay(1),
  );

  conferenceRegistrants$: Observable<Registrant[]> = this._conferenceDbId$.pipe(
    filter(Boolean),
    mergeMap(conferenceDbId => this.conferenceRegistrantsService.getRegistrantsByConferenceId(conferenceDbId)),
    shareReplay(1),
  );

  attendees$: Observable<Attendee[]> = combineLatest({
    conference: this.conference$,
    registrants: this.conferenceRegistrants$,
    guestsGroupedByRegistrantId: this.guestsGroupedByRegistrantId$,
  }).pipe(
    map(({ conference, registrants, guestsGroupedByRegistrantId }) => {
      const attendees = [];

      registrants.forEach(registrant => {
        const registrantDbId = registrant?.[BaseModelKeys.dbId];
        const guests = guestsGroupedByRegistrantId[registrantDbId];
        const attendee = new Attendee(conference, registrant);

        attendees.push(attendee);

        guests?.forEach(guest => {
          const attendee = new Attendee(conference, registrant, guest);

          attendees.push(attendee);
        });
      });

      return attendees;
    }),
    shareReplay(1),
  );

  private readonly armUserRoles = this.authService.userRoles$.pipe(
    map((userRoles: UserRole[]) => {
      const armRoles = userRoles?.filter(userRole => userRole?.[UserRoleModelKeys.appId] === EntityApplications.crm);
    }),
  );

  constructor(
    private conferenceService: ConferenceService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
    private conferenceGuestsService: ConferenceGuestsService,
    private authService: AuthService,
  ) {}

  setConferenceDbId(conferenceDbId: string): void {
    this._conferenceDbId$.next(conferenceDbId);
  }
}
