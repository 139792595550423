import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AgentService } from '../../services/agent.service';
import { BehaviorSubject, combineLatest, Observable, startWith } from 'rxjs';
import { AgentFileDirectoriesService, AgentFilesMediaService, AgentImagesService } from '../../../public-api';
import { BaseModelKeys } from '../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/base.model';
import {
  AgentFileDirectory,
  AgentFileDirectoryKeys,
  AgentMedia,
  AgentMediaKeys,
  AGMedia,
  ChangeSourceType,
} from '@ag-common-lib/public-api';
import { shareReplay, switchMap } from 'rxjs/operators';

@Injectable()
export class AgentMediaComponentService {
  private _agentId$ = new BehaviorSubject<string>(null);
  agentId$ = this._agentId$.asObservable();

  private _currentDirectory$ = new BehaviorSubject<AgentFileDirectory>(null);
  currentDirectory$ = this._currentDirectory$.asObservable();

  currentMediaList$: Observable<AgentMedia[]>;

  constructor(
    private agentFilesMediaService: AgentFilesMediaService,
    private agentImagesService: AgentImagesService,
    private agentFileDirectoriesService: AgentFileDirectoriesService,
    private agentService: AgentService,
    private toastrService: ToastrService,
  ) {
    this.currentMediaList$ = combineLatest([this.agentId$, this.currentDirectory$]).pipe(
      startWith([]),
      switchMap(([agentId, currentDirectory]) => {
        return !!currentDirectory
          ? this.agentFilesMediaService.getList(agentId, currentDirectory[BaseModelKeys.dbId])
          : [];
      }),
      shareReplay(1),
    );
  }

  get currentDirectory(): AgentFileDirectory {
    return this._currentDirectory$.value as AgentFileDirectory;
  }

  setAgentId(agentId: string): void {
    this._agentId$.next(agentId);
  }

  setCurrentDirectory(directory: AgentFileDirectory): void {
    this._currentDirectory$.next(directory);
  }

  setAsPrimaryImage(agentId: string, media: AGMedia) {
    // // todo update agent profile picture to use wasabi
    // this.agentService
    //   .updateAgentFields(this.agentId$.value, {
    //     [AgentKeys.p_headshot_link]: media[AGMediaKeys.wasabiPath],
    //   })
    //   .then(() => {
    //     this.toastrService.error('Profile picture is updated!');
    //   });
  }

  deleteMedia(agentId: string, media: AGMedia, action: ChangeSourceType | null = null): Promise<boolean> {
    return this.agentFilesMediaService
      .delete(agentId, this.currentDirectory[BaseModelKeys.dbId], media[BaseModelKeys.dbId], action)
      .then(() => false)
      .catch(err => {
        this.toastrService.error('Media is not deleted!');
        throw err;
      })
      .finally(() => {});
  }

  createMedia(agentId: string, media: AGMedia, action: ChangeSourceType | null = null): Promise<AgentMedia | void> {
    if (this.currentDirectory?.[BaseModelKeys.dbId]) {
      return this.agentFilesMediaService
        .create(
          agentId,
          this.currentDirectory[BaseModelKeys.dbId],
          Object.assign(media, { [AgentMediaKeys.agentDbId]: agentId }) as AgentMedia,
          true,
          action
        )
        .catch(err => {
          this.toastrService.error('Media is not created!');
          throw err;
        })
        .finally(() => {});
    }

    return this.agentImagesService.addAgentMediaToImagesDirectory(agentId, {
      ...media,
      [AgentMediaKeys.agentDbId]: agentId,
    } as AgentMedia);
  }

  createFolder(directory: AgentFileDirectory): Promise<boolean> {
    const agentId = directory[AgentFileDirectoryKeys.agentDbId];
    return this.agentFileDirectoriesService
      .create(agentId, Object.assign(directory, { [AgentMediaKeys.agentDbId]: agentId }) as AgentFileDirectory)
      .then(() => false)
      .catch(err => {
        this.toastrService.error('Folder is not created!');
        throw err;
      })
      .finally(() => {});
  }

  deleteFolder(agentId: string, documentId: string): Promise<boolean> {
    return this.agentFileDirectoriesService
      .delete(agentId, documentId)
      .then(() => false)
      .catch(err => {
        this.toastrService.error('Folder is not removed!');
        throw err;
      })
      .finally(() => {});
  }
}
