import { Component, HostBinding, ViewChild } from '@angular/core';
import { Agent, AgentKeys } from '@ag-common-lib/public-api';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { AddAgentToDynamicListModalService } from './add-agent-to-dynamic-list-modal.service';
import { DynamicListsGroupKeys } from '@ag-common-lib/lib';
import { AddAgentToDynamicListKeys } from './add-agent-to-dynamic-list.model';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-add-agent-to-dynamic-list-modal',
  templateUrl: './add-agent-to-dynamic-list-modal.component.html',
  styleUrls: ['./add-agent-to-dynamic-list-modal.component.scss'],
})
export class AddAgentToDynamicListModalComponent {
  @HostBinding('class') className = 'add-agent-to-list-modal';
  @ViewChild('modalRef', { static: true }) modalComponent: ModalWindowComponent;
  @ViewChild('infoModalRef', { static: true }) infoModalComponent: ModalWindowComponent;

  caption = 'Add Agent To Mailing Lists';
  inProgress$ = this.addAgentToDynamicListModalService.inProgress$;
  dynamicLists$ = this.addAgentToDynamicListModalService.dynamicLists$;
  agentIncludedLists$ = this.addAgentToDynamicListModalService.agentIncludedLists$;
  agentNotAddedLists$ = this.addAgentToDynamicListModalService.agentNotAddedLists$;

  protected formData = {};
  protected validationGroup = 'addAgentToDynamicListsValidationGroup';
  protected readonly AddAgentToDynamicListKeys = AddAgentToDynamicListKeys;
  protected readonly DynamicListsGroupKeys = DynamicListsGroupKeys;
  protected readonly BaseModelKeys = BaseModelKeys;

  constructor(private addAgentToDynamicListModalService: AddAgentToDynamicListModalService) {}

  showPopup = async (agent: Agent) => {
    const agentDbId = agent?.[BaseModelKeys.dbId];
    const agentFullName = [agent?.[AgentKeys.p_agent_first_name], agent?.[AgentKeys.p_agent_last_name]]
      .filter(Boolean)
      .join(' ');
    this.caption = `Add "${agentFullName}" To Mailing Lists`;
    this.addAgentToDynamicListModalService.setAgentDbId(agentDbId);
    this.formData = this.addAgentToDynamicListModalService.getFormData();
    this.modalComponent.showModal();
  };

  handleSave = async () => {
    const isValid = await validateDxGroups(this.validationGroup);

    if (isValid) {
      await this.addAgentToDynamicListModalService.handleSave();
      await this.modalComponent.forceCloseModal();
      this.infoModalComponent.showModal();
    }
  };

  protected handleClosePopup = this.addAgentToDynamicListModalService.onCancelEdit;
}
