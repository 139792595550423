import { Pipe, PipeTransform } from '@angular/core';
import { ActiveLookup, TaskCategory } from 'ag-common-lib/public-api';
import { LookupsService } from 'ag-common-svc/public-api';
import { DocumentReference } from 'firebase/firestore';
import { Observable } from 'rxjs';

@Pipe({ name: 'taskSubcategories' })
export class TaskSubcategoriesPipe implements PipeTransform {
  constructor(private lookupsService: LookupsService) {}

  transform(categoryRef: DocumentReference<TaskCategory>): Observable<ActiveLookup[]> {
    return this.lookupsService.getTaskSubcategoryLookup(categoryRef?.id);
  }
}
