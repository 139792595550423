import { Component, ViewChild } from '@angular/core';
import { ConferenceRegistrationGridService } from '../conference-registration-grid.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { REGISTRATS_GRID__TITLE_LIST } from '../config/conference-registration.config';
import {
  INVITEE_OUTCOME_STATUS_LOOKUP,
  INVITEE_STATUS_LOOKUP,
  LookupKeys,
  RegistrantKeys,
  RegistrantModelKeys,
  TRUE_FALSE_LOOKUP,
} from 'ag-common-lib/public-api';

@Component({
  selector: 'app-rejected-participants-grid',
  templateUrl: './rejected-participants-grid.component.html',
  styleUrls: ['./rejected-participants-grid.component.scss'],
})
export class RejectedParticipantsGridComponent {
  @ViewChild('rejectedRegistratsGrid', { static: false }) rejectedRegistratsComponent: DxDataGridComponent;

  protected declinedRegistrants$ = this.conferenceRegistrationGridService.declinedRegistrants$;
  protected agencies$ = this.conferenceRegistrationGridService.agencies$;
  protected mgaList$ = this.conferenceRegistrationGridService.mgaList$;
  protected readonly emptyMessage: string =
    'No Conference Registrants Currently Exist. Please select another conference.';
  protected readonly titleList = REGISTRATS_GRID__TITLE_LIST;
  protected readonly yesNoLookup = TRUE_FALSE_LOOKUP;
  protected RegistrantModelKeys = RegistrantModelKeys;
  protected RegistrantKeys = RegistrantKeys;
  protected readonly inviteeStatusLookup = INVITEE_STATUS_LOOKUP;
  protected readonly inviteeOutcomeStatusLookup = INVITEE_OUTCOME_STATUS_LOOKUP;
  protected readonly LookupKeys = LookupKeys;

  constructor(private conferenceRegistrationGridService: ConferenceRegistrationGridService) {}

  showPager = (): boolean => this.rejectedRegistratsComponent?.instance?.pageCount() > 1;

  viewAgentDetails(agentId: string): void {
    // this.showAgentDetails.emit(agentId);
  }

  changeOwner = owner => {
    // const registrantId = this.selectedRegistrant[BaseModelKeys.dbId];
    // this.selectedRegistrant.data.isSendPlaqueOwner = owner?.assignedTo ?? '';
    // this.selectedRegistrant.data.isSendPlaqueOwnerNote = owner?.assignedToNote ?? '';
    // return this.conferenceRegistrationGridService.updateRegistrant(
    //   this.conferenceDbId,
    //   registrantId,
    //   this.selectedRegistrant,
    // );
  };

  calculateOwnerGroupValue(this: any, rowData: any) {
    return rowData.data.isSendPlaqueOwner;
  }

  protected showAttendeeModal = this.conferenceRegistrationGridService.showAttendeeModal;
}
