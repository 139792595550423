<ag-shr-modal-window
  #reportsModalRef
  [title]="'Upload History'"
  [width]="'80%'"
  [height]="'80%'"
  [useScrollView]="false"
  [showSaveButton]="false"
  ><div *ngIf="reportsModalRef?.popupComponent?.visible ?? false" class="report-history-modal__container">
    <dx-data-grid
      #reportsTable
      [dataSource]="dataSource"
      [showBorders]="true"
      [renderAsync]="true"
      [columnAutoWidth]="true"
      [allowColumnReordering]="true"
      [allowColumnResizing]="true"
      height="100%"
    >
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-scrolling mode="virtual" [showScrollbar]="'always'"></dxo-scrolling>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxi-column dataField="fileName" alignment="left" [width]="260"></dxi-column>
      <dxi-column
        dataField="asOfDate"
        [dataType]="'datetime'"
        alignment="left"
        [width]="150"
        [format]="dateFormat"
      ></dxi-column>
      <dxi-column dataField="year" alignment="left" [width]="55"></dxi-column>
      <dxi-column
        caption="Created By Email"
        [dataField]="BaseModelKeys.createdByEmail"
        [calculateCellValue]="calculateCreatedByCellValue"
        [width]="210"
        alignment="left"
      ></dxi-column>
      <dxi-column
        caption="Created Date"
        [dataField]="BaseModelKeys.createdDate"
        [calculateCellValue]="calculateCreatedDateCellValue"
        [sortIndex]="0"
        [sortOrder]="'desc'"
        [dataType]="'datetime'"
        [width]="150"
        alignment="left"
      ></dxi-column>

      <dxi-column dataField="recordsImported" caption="Records" alignment="left"></dxi-column>
      <dxi-column dataField="summariesGenerated" caption="Summaries" alignment="left"></dxi-column>

      <dxi-column
        [dataField]="'sourceFileKey'"
        caption="Source File"
        cellTemplate="sourceFileKeyCellTemplate"
        alignment="left"
      ></dxi-column>

      <div *dxTemplate="let cell of 'sourceFileKeyCellTemplate'">
        <a *ngIf="cell.value" [href]="cell.value | wasabiFile">Download</a>
      </div>
    </dx-data-grid>
  </div></ag-shr-modal-window
>
