import { Inject, Injectable } from '@angular/core';
import { Agent, AgentKeys, Lookup, LookupKeys, ChangeSourceType } from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { collectionGroup, getDocs, query, QueryConstraint, where } from 'firebase/firestore';
import { FIREBASE_APP } from '../../injections/firebase-app';
import { DataService } from '../data.service';
import { agentFromFirestore, getAgentFullName } from './agent-service-utils';
import CustomStore from 'devextreme/data/custom_store';
import { firstValueFrom, lastValueFrom, Observable, shareReplay } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/lib/dao/CommonFireStoreDao.dao';

@Injectable({
  providedIn: 'root',
})
export class AgentService extends DataService<Agent> {
  private readonly emailAddressCollectionPath = 'email-addresses';
  agentsDataSource$: Observable<Partial<Lookup>[]>;
  readonly agents$: Observable<Agent[]>;
  readonly agentsMap$: Observable<Record<string, Agent>>;
  readonly agentsDataSourceConfiguration;

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, AgentService.fromFirestore, AgentService.toFirestore);
    super.setCollection('agents');

    this.agents$ = this.getList([], { sortField: AgentKeys.p_agent_first_name }).pipe(shareReplay(1));
    this.agentsDataSource$ = this.agents$.pipe(
      map((response): Partial<Lookup>[] => {
        return Array.isArray(response)
          ? response.map((agent: Agent) => {
              const description = [agent?.p_agent_first_name, agent?.p_agent_last_name].filter(Boolean).join(' ');
              const value = agent?.[BaseModelKeys.dbId];
              return {
                key: agent?.dbId,
                [LookupKeys.value]: value,
                [LookupKeys.description]: description,
              };
            })
          : [];
      }),
      shareReplay(1),
    );

    this.agentsMap$ = this.agents$.pipe(
      map(agents => {
        const map: Record<string, Agent> = {};

        agents.forEach(agent => {
          const agentDbId = agent?.[BaseModelKeys.dbId];
          map[agentDbId] = agent;
        });

        return map;
      }),
      shareReplay(1),
    );

    this.agentsDataSourceConfiguration = {
      paginate: true,
      pageSize: 25,
      map: data => {
        return Object.assign({}, data, {
          [AgentKeys.p_agent_name]: getAgentFullName(data),
        });
      },
      store: new CustomStore({
        key: 'value',
        loadMode: 'raw',
        load: async loadOptions => {
          const response = await lastValueFrom(this.agents$.pipe(take(1)));

          return response;
        },
      }),
    };
  }
  static readonly fromFirestore = agentFromFirestore;

  static readonly toFirestore = (data): Agent => {
    return data[AgentKeys.p_email]
      ? Object.assign(data, {
          [AgentKeys.p_email]: data[AgentKeys.p_email].toLowerCase().trim(),
        })
      : data;
  };

  public updateAgentFields(
    documentId: string,
    data: Partial<Agent>,
    action: ChangeSourceType | null = null,
  ): Promise<Agent> {
    if (AgentKeys.agent_review_level in data) {
      Object.assign(data, {
        [AgentKeys.registrant_review_level_update_date]: new Date(),
      });
    }

    if (AgentKeys.prospect_status in data) {
      Object.assign(data, {
        [AgentKeys.prospect_status_update_date]: new Date(),
      });
    }

    Object.assign(data, {
      [BaseModelKeys.actionName]: action,
    });

    return super.updateFields(documentId, data);
  }

  public findAgentByLoginEmail = async email => {
    const queries: QueryParam[] = [];

    const emailAddressQuery = new QueryParam('address', WhereFilterOperandKeys.equal, email);
    const isLoginQuery = new QueryParam('is_login', WhereFilterOperandKeys.equal, true);

    queries.push(emailAddressQuery);
    queries.push(isLoginQuery);

    const queryConstraints: QueryConstraint[] = queries.map(query => where(query.field, query.operation, query.value));

    const collectionGroupRef = collectionGroup(this.fsDao.db, this.emailAddressCollectionPath).withConverter({
      toFirestore: null,
      fromFirestore: this.fsDao.convertResponse,
    });

    const collectionGroupQuery = query(collectionGroupRef, ...queryConstraints);
    const querySnapshot = await getDocs(collectionGroupQuery);

    if (!querySnapshot.size) {
      return null;
    }
    if (querySnapshot.size > 1) {
      throw new Error('More That One Agents With same login email was found');
    }

    const document = querySnapshot.docs[0];
    const parentDbId = document?.ref?.parent?.parent?.id;

    return this.fsDao.getById(this.collection, parentDbId);
  };

  getAgentByEmail(email: string): Promise<Agent> {
    return this.getAllByValue([new QueryParam('p_email', WhereFilterOperandKeys.equal, email)]).then(agents => {
      if (agents.length == 0) {
        return null;
      } else if (agents.length == 1) {
        return agents[0];
      } else {
        console.error('More than 1 agent found with this email address');
        return null;
      }
    });
  }

  getAgentByAuthUID(uid: string): Promise<Agent> {
    return this.getAllByValue([new QueryParam(AgentKeys.uid, WhereFilterOperandKeys.equal, uid)]).then(agents => {
      return agents[0];
    });
  }

  getAgentByAgentId(id: string): Promise<Agent> {
    return this.getAllByValue([new QueryParam('p_agent_id', WhereFilterOperandKeys.equal, id)]).then(agents => {
      if (agents.length == 0) {
        return null;
      } else if (agents.length == 1) {
        return agents[0];
      } else {
        debugger;
        console.error('More than 1 agent found with this agent id');
        return null;
      }
    });
  }

  getAgentsByAgentIds(ids: string[], sortField: string = 'p_agent_last_name'): Promise<Agent[]> {
    if (!ids?.length) {
      return Promise.resolve([]);
    }
    return this.getAllByValue([new QueryParam(BaseModelKeys.dbId, WhereFilterOperandKeys.in, ids)], null, sortField);
  }

  getAgentForChristmasCardList(): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam(AgentKeys.christmas_card, WhereFilterOperandKeys.equal, true)]);
  }

  getAgentForConferencePosterList(): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam(AgentKeys.conference_poster, WhereFilterOperandKeys.equal, true)]);
  }

  getAgentsByAgencyId(id: string, sortField: string): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam('p_agency_id', WhereFilterOperandKeys.equal, id)], null, sortField);
  }

  getAgentsByProspectStatuses(id: string[], sortField: string): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam('prospect_status', WhereFilterOperandKeys.in, id)], null, sortField);
  }

  getAgentsByAgentStatuses(id: string[], sortField: string): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam('agent_status', WhereFilterOperandKeys.in, id)], null, sortField);
  }

  getMGAsByMGAId(id: string, sortField: string): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam('p_mga_id', WhereFilterOperandKeys.equal, id)], null, sortField);
  }

  getManagersByMGAId(id: string, sortField: string): Promise<Agent[]> {
    let qp: QueryParam[] = [];
    qp.push(new QueryParam('is_manager', WhereFilterOperandKeys.equal, true));
    qp.push(new QueryParam('p_mga_id', WhereFilterOperandKeys.equal, id));

    return this.getAllByValue(qp, null, sortField);
  }

  getManagersByAgencyId(id: string, sortField: string): Promise<Agent[]> {
    let qp: QueryParam[] = [];
    qp.push(new QueryParam('is_manager', WhereFilterOperandKeys.equal, true));
    qp.push(new QueryParam('p_agency_id', WhereFilterOperandKeys.equal, id));

    return this.getAllByValue(qp, null, sortField);
  }

  getAgentCustomStore = () => {
    return new CustomStore({
      key: LookupKeys.value,
      byKey: key => this.getById(key),
      load: () => {
        return firstValueFrom(this.agents$);
        // return firstValueFrom(this.agents$.pipe(map(response => {
        //   return Array.isArray(response) ? response.map((agent: Agent) => {
        //     const description = [ agent?.p_agent_first_name, agent?.p_agent_last_name ].filter(Boolean).join(' ');
        //     const value = agent?.[BaseModelKeys.dbId];
        //     return {
        //       key: agent?.dbId,
        //       [LookupKeys.value]: value,
        //       [LookupKeys.description]: description,
        //     };
        //   }) : [];
        // })))
      },
    });
  };

  getAgentDisplayValue = agentDbId => {
    return this.getById(agentDbId).then(agent => {
      return [agent?.[AgentKeys.p_agent_first_name], agent?.[AgentKeys.p_agent_last_name]].filter(Boolean).join(' ');
    });
  };
}
