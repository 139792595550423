import { Component, HostBinding, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  Airlines,
  AIRLINES_LOOKUP,
  FlightInfoKeys,
  SEAT_PREFERENCE_LOOKUP,
} from 'ag-common-lib/lib/models/registration/flight-information.model';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { AttendeeFlightGroup, AttendeeFlightService } from '../attendee-flight.service';
import { InitializedEvent } from 'devextreme/ui/form';
import { BehaviorSubject } from 'rxjs';
import { LookupsService } from 'ag-common-svc/public-api';
import {
  AssociationKeys,
  AttendeeType,
  ConferenceRegistrationLog,
  ConferenceRegistrationLogKeys,
  ConferenceRegistrationLogType,
  ConferenceRegistrationRequestLogKey,
  ConferenceRegistrationSupportLogKey,
  Constants,
  EditorOptions,
  Entity,
  EntityPermissionActivityKeys,
  GuestKeys,
  LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
  LookupKeys,
  RegistrantKeys,
  RequestLogStatus,
  RequestOutcomeState,
  TRUE_FALSE_LOOKUP,
} from 'ag-common-lib/public-api';
import { AttendeeFlightDataModalService } from './attendee-flight-data-modal.service';
import { AttendeeNoteModalComponent } from '../../attendee-note-modal/attendee-note-modal.component';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@UntilDestroy()
@Component({
  selector: 'ag-crm-attendee-flight-data-modal',
  templateUrl: './attendee-flight-data-modal.component.html',
  styleUrls: ['./attendee-flight-data-modal.component.scss'],
})
export class AttendeeFlightDataModalComponent {
  @HostBinding('class') className = 'attendee-flight-data-modal';
  @ViewChild('attendeeFlightDataModalRef', { static: true }) attendeeFlightDataModalComponent: ModalWindowComponent;
  @ViewChild(AttendeeNoteModalComponent, { static: false }) attendeeNoteModalComponent!: AttendeeNoteModalComponent;
  @ViewChild('approveDifferTravelDatesRequestModalRef', { static: false })
  approveDifferTravelDatesRequestModalComponent: ModalWindowComponent;
  @ViewChild('rejectDifferTravelDatesRequestModalRef', { static: false })
  rejectDifferTravelDatesRequestModalComponent: ModalWindowComponent;

  protected formData: any;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly AssociationKeys = AssociationKeys;
  protected readonly prefixesLookup$ = this.lookupsService.prefixesLookup$;
  protected readonly suffixesLookup$ = this.lookupsService.suffixesLookup$;
  protected readonly gendersLookup$ = this.lookupsService.gendersLookup$;
  protected readonly FlightInfoKeys = FlightInfoKeys;
  protected readonly Airlines = Airlines;
  protected readonly TrueFalseLookup = TRUE_FALSE_LOOKUP;
  protected readonly SEAT_PREFERENCE_LOOKUP = SEAT_PREFERENCE_LOOKUP;
  protected readonly AIRLINES_LOOKUP = AIRLINES_LOOKUP;
  protected readonly LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT = LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly colCountByScreen = {
    lg: 12,
    md: 1,
  };
  protected formWidth$ = new BehaviorSubject<number>(0);
  protected validationGroup = 'attendeeFlightDataModalValidationGroup';
  protected showSameForAll = false;
  protected inProgress$ = this.attendeeFlightDataModalService.inProgress$;
  protected gender;
  protected requestInProgress$ = new BehaviorSubject<boolean>(false);
  protected note = '';
  protected excludedFields$;
  flightInfoPasportNameFieldsList: string[];
  flightInfoDriverLicenseNameFieldsList: string[];
  flightInfoOtherFieldsList: string[];
  attendeeType: AttendeeType;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly RegistrantKeys = RegistrantKeys;

  protected readonly RequestOutcomeState = RequestOutcomeState;
  protected dateEditorOptions;

  constructor(
    private lookupsService: LookupsService,
    private attendeeFlightDataModalService: AttendeeFlightDataModalService,
    private attendeeFlightService: AttendeeFlightService,
  ) {}

  showModal(data: AttendeeFlightGroup): void {
    this.attendeeType = data?.attendeeType;
    this.excludedFields$ = this.attendeeFlightService.getExcludedFields(this.attendeeType);
    this.gender = data?.gender;
    this.dateEditorOptions = {
      ...EditorOptions.DATE,
      dateSerializationFormat: LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
      value: data.dob,
      readOnly: true,
    };
    this.showSameForAll = this.attendeeType === AttendeeType.Invitee;
    this.getFlightInfoFieldsListByType(this.attendeeType);
    this.formData = this.attendeeFlightDataModalService.getFormData(data);
    this.attendeeFlightDataModalComponent?.showModal();
  }

  protected onInit = (e: InitializedEvent) => {
    const nativeElement = e.component.$element()?.[0];

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === nativeElement) {
          const width = entry.contentRect.width;

          this.formWidth$.next(width);
        }
      }
    });

    resizeObserver.observe(nativeElement);
  };

  protected handleSave = async () => {
    try {
      await this.attendeeFlightDataModalService.saveFlightUpdates();

      this.attendeeFlightDataModalComponent.hideModal();
    } catch (error) {
      console.error(error);
    }
  };

  protected handleClosePopup = this.attendeeFlightDataModalService.onCancelEdit;

  protected handleDifferTravelDatesRequest = (requestOutcomeState: RequestOutcomeState) => {
    this.note = '';
    switch (requestOutcomeState) {
      case RequestOutcomeState.approved:
        this.approveDifferTravelDatesRequestModalComponent.showModal();
        return;
      case RequestOutcomeState.rejected:
        this.rejectDifferTravelDatesRequestModalComponent.showModal();
        return;
    }
  };

  protected handleDifferTravelDatesRequestOutcome = async (requestOutcomeState: RequestOutcomeState) => {
    this.requestInProgress$.next(true);
    this.attendeeFlightDataModalService
      .handleDifferBookingDatesRequestOutcome(requestOutcomeState)
      .then(() => {
        requestOutcomeState === RequestOutcomeState.approved
          ? this.approveDifferTravelDatesRequestModalComponent.hideModal()
          : this.rejectDifferTravelDatesRequestModalComponent.hideModal();
        const logTitle =
          'Differ Travel Dates Request ' +
          (requestOutcomeState === RequestOutcomeState.approved ? 'Approved' : 'Declined');
        this.logAdditionalRequestOutcome(requestOutcomeState, logTitle);
      })
      .catch(() => {})
      .finally(() => this.requestInProgress$.next(false));
  };

  private logAdditionalRequestOutcome = (requestOutcomeState: RequestOutcomeState, logTitle: string) => {
    this.attendeeFlightService.saveLog({
      [ConferenceRegistrationLogKeys.type]: ConferenceRegistrationLogType.specialRequest,
      [ConferenceRegistrationLogKeys.data]: {
        [ConferenceRegistrationRequestLogKey.status]:
          requestOutcomeState === RequestOutcomeState.approved ? RequestLogStatus.approved : RequestLogStatus.rejected,
        [ConferenceRegistrationSupportLogKey.title]: logTitle,
        [ConferenceRegistrationSupportLogKey.date]: new Date(),
        [ConferenceRegistrationSupportLogKey.details]: this.note,
      },
    } as ConferenceRegistrationLog);
  };

  private getFlightInfoFieldsListByType(attendeeType: AttendeeType): void {
    const flightInformationParentKey =
      attendeeType === AttendeeType.Invitee ? RegistrantKeys.flightInformation : GuestKeys.flightInformation;

    this.flightInfoPasportNameFieldsList = [
      [flightInformationParentKey, FlightInfoKeys.firstName].join('.'),
      [flightInformationParentKey, FlightInfoKeys.middleName].join('.'),
      [flightInformationParentKey, FlightInfoKeys.lastName].join('.'),
      [flightInformationParentKey, FlightInfoKeys.suffix].join('.'),
    ];
    this.flightInfoDriverLicenseNameFieldsList = [
      [flightInformationParentKey, FlightInfoKeys.driverLicenseFirstName].join('.'),
      [flightInformationParentKey, FlightInfoKeys.driverLicenseMiddleName].join('.'),
      [flightInformationParentKey, FlightInfoKeys.driverLicenseLastName].join('.'),
      [flightInformationParentKey, FlightInfoKeys.driverLicenseSuffix].join('.'),
    ];
    this.flightInfoOtherFieldsList = [
      [flightInformationParentKey, FlightInfoKeys.knownTravelerNumber].join('.'),
      [flightInformationParentKey, FlightInfoKeys.seatPreference].join('.'),
      [flightInformationParentKey, FlightInfoKeys.preferredDepartureAirport].join('.'),
    ];
  }
}
