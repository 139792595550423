import { Inject, Injectable } from '@angular/core';
import { FIREBASE_APP } from 'ag-common-svc/lib/injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import { DataService } from 'ag-common-svc/lib/services/data.service';
import { TaskWorkflow } from 'ag-common-lib/public-api';

@Injectable()
export class TaskWorkflowService extends DataService<TaskWorkflow> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp);
    super.setCollection('crm-task-workflows');
  }
}
