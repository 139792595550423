import { Injectable } from '@angular/core';
import { EmailAddress, EmailAddressKeys } from '@ag-common-lib/public-api';
import { BehaviorSubject, Observable, combineLatest, map, of, shareReplay, tap } from 'rxjs';
import { pick } from 'lodash';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable()
export class SelectEmailAddressService {
  private _agentEmailAddresses$ = new BehaviorSubject<EmailAddress[]>([]);
  agentEmailAddressesDataSource$ = this._agentEmailAddresses$.pipe(
    map(emailAddress => {
      const items = emailAddress?.map(emailAddress => this.pickEmailAddressData(emailAddress));

      items.push(this.pickEmailAddressData(null));

      return items;
    }),
    shareReplay(1),
  );

  readonly formData$ = new BehaviorSubject<Partial<EmailAddress>>(new EmailAddress());

  selectedEmailAddressesKeys$: Observable<string[]> = combineLatest({
    agentEmailAddressesDataSource: this.agentEmailAddressesDataSource$,
    formData: this.formData$,
  }).pipe(
    map(({ agentEmailAddressesDataSource, formData }) => {
      const selectedAddressKeys = agentEmailAddressesDataSource
        ?.map(emailAddress => emailAddress?.[BaseModelKeys.dbId] ?? null)
        ?.filter(address => {
          return address === formData?.[BaseModelKeys.dbId];
        });

      if (!selectedAddressKeys?.length) {
        return [null];
      }

      return selectedAddressKeys;
    }),
  );

  constructor() {}

  setFormData = (emailAddress: Partial<EmailAddress>) => {
    this.formData$.next(emailAddress);
  };

  setEmailAddresses = (emailAddress: EmailAddress[]) => {
    if (!Array.isArray(emailAddress)) {
      return;
    }
    this._agentEmailAddresses$.next(emailAddress);
  };

  pickEmailAddressData = (emailAddress: Partial<EmailAddress>) => {
    return pick(emailAddress, [BaseModelKeys.dbId, EmailAddressKeys.address, EmailAddressKeys.isLogin]);
  };

  setAddress = (emailAddress?: Partial<EmailAddress>) => {
    const formData = this.formData$.value;
    if (emailAddress[BaseModelKeys.dbId] === formData?.[BaseModelKeys.dbId]) {
      return;
    }

    Object.keys(formData).forEach(key => {
      formData[key] = null;
    });

    Object.entries(emailAddress).forEach(([key, value]) => {
      formData[key] = value;
    });
  };
}
