import { Component, HostBinding, ViewChild } from '@angular/core';
import {
  AssociationKeys,
  Constants,
  DATE_NOW,
  EditorOptions,
  Entity,
  EntityPermissionActivityKeys,
  GUEST_TYPES_LOOKUP,
  GuestData,
  GuestKeys,
  HotelReservationKeys,
  LookupKeys,
  Messages,
  MIN_DATE,
  Patterns,
  RequestOutcomeState,
  WizardStepState,
  WizardStepStateMap,
} from 'ag-common-lib/public-api';
import { LookupsService } from 'ag-common-svc/public-api';
import { AttendeeGuestsService } from './attendee-guests.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { format } from 'date-fns';
import { AttendeeGuestModalComponent } from './attendee-guest-modal/attendee-guest-modal.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { AttendeeNoteModalComponent } from '../attendee-note-modal/attendee-note-modal.component';
import {
  ConferenceRegistrationLog,
  ConferenceRegistrationLogKeys,
  ConferenceRegistrationLogType,
  ConferenceRegistrationRequestLogKey,
  ConferenceRegistrationSupportLogKey,
  RequestLogStatus,
} from 'ag-common-lib/lib/models/registration/registrant-notes';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { confirm } from 'devextreme/ui/dialog';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-crm-attendee-guests',
  templateUrl: './attendee-guests.component.html',
  styleUrls: ['./attendee-guests.component.scss'],
  providers: [AttendeeGuestsService],
})
export class AttendeeGuestsComponent {
  @HostBinding('class') className = 'attendee-guests';

  @ViewChild('attendeeGuestModalComponent')
  attendeeGuestModalComponent: AttendeeGuestModalComponent;
  @ViewChild('approveAdditionalGuestRequestModalRef', { static: false })
  approveAdditionalGuestRequestModalComponent: ModalWindowComponent;
  @ViewChild('rejectAdditionalGuestRequestModalRef', { static: false })
  rejectAdditionalGuestRequestModalComponent: ModalWindowComponent;

  @ViewChild('guestGridRef', { static: false }) guestDataGrid!: DxDataGridComponent;
  @ViewChild(AttendeeNoteModalComponent, { static: false }) attendeeNoteModalComponent!: AttendeeNoteModalComponent;

  readonly emptyMessage = 'No Guests Currently Exist';
  protected note = '';
  protected requestInProgress$ = new BehaviorSubject<boolean>(false);

  protected registrantGuests$: Observable<GuestData[]> = this.attendeeGuestsService.registrantGuests$;
  protected wizardGuestStepStateLookup$: Observable<any[]> = this.attendeeGuestsService.wizardGuestStepStateLookup$;

  protected readonly LookupKeys = LookupKeys;
  protected readonly AssociationKeys = AssociationKeys;
  protected readonly GuestKeys = GuestKeys;
  protected readonly complimentaryOrAdditionalLookup = GUEST_TYPES_LOOKUP;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly WizardStepState = WizardStepState;
  protected readonly WizardStepStateMap = WizardStepStateMap;
  protected readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  protected readonly phoneMask: string = Constants.PHONE_MASK;
  protected readonly Messages = Messages;
  protected readonly gendersLookup$ = this.lookupsService.gendersLookup$;
  protected readonly maxDate: Date = DATE_NOW;
  protected readonly minDate: Date = MIN_DATE;
  protected readonly dateValidation: string | RegExp = Patterns.DATE_PATTERN_MMDDYYYY;
  protected readonly dateEditorOptions = {
    ...EditorOptions.DATE,
    min: this.minDate,
    max: this.maxDate,
    dateOutOfRangeMessage: `Please enter a date between ${format(this.minDate, Constants.DISPLAY_DATE_FORMAT)} and
      ${format(this.maxDate, Constants.DISPLAY_DATE_FORMAT)}`,
  };

  protected readonly HotelReservationKeys = HotelReservationKeys;
  protected readonly RequestOutcomeState = RequestOutcomeState;
  protected readonly prefixesLookup$ = this.lookupsService.prefixesLookup$;
  protected readonly suffixesLookup$ = this.lookupsService.suffixesLookup$;
  protected readonly isRequestedAdditionalGuest$ = this.attendeeGuestsService.isRequestedAdditionalGuest$;
  protected readonly additionalGuestRequestOutcome$ = this.attendeeGuestsService.additionalGuestRequestOutcome$;

  constructor(
    private lookupsService: LookupsService,
    private attendeeGuestsService: AttendeeGuestsService,
  ) {}

  protected handleApproveAdditionalGuestRequest = () => {
    this.note = '';
    this.approveAdditionalGuestRequestModalComponent.showModal();
  };

  protected handleRejectAdditionalGuestRequest = () => {
    this.note = '';
    this.rejectAdditionalGuestRequestModalComponent.showModal();
  };

  protected handleAdditionalGuestRequestOutcome = async (requestOutcomeState: RequestOutcomeState) => {
    this.requestInProgress$.next(true);
    this.attendeeGuestsService
      .handleAdditionalGuestRequestOutcome(requestOutcomeState)
      .then(() => {
        requestOutcomeState === RequestOutcomeState.approved
          ? this.approveAdditionalGuestRequestModalComponent.hideModal()
          : this.rejectAdditionalGuestRequestModalComponent.hideModal();
        this.logAdditionalGuestRequestOutcome(requestOutcomeState);
      })
      .catch(() => {})
      .finally(() => this.requestInProgress$.next(false));
  };

  protected completeStep = async ({ row: { data, ...rest }, ...rest2 }) => {
    const result = await confirm('<i>Are you sure you want to set The Wizard Step State to "Done"?</i>', 'Confirm');

    if (!result) {
      return;
    }

    this.attendeeGuestsService.setWizardStepStateToDone(data);
  };

  protected getIsCompleteStepVisible = ({ row: { cells } }) => {
    const idCell = cells?.find(cell => cell?.column?.dataField === BaseModelKeys.dbId);

    return idCell?.displayValue !== WizardStepState.done;
  };

  logAdditionalGuestRequestOutcome = (requestOutcomeState: RequestOutcomeState) => {
    const logStatusApproved = requestOutcomeState === RequestOutcomeState.approved;

    this.attendeeGuestsService.saveLog({
      [ConferenceRegistrationLogKeys.type]: ConferenceRegistrationLogType.specialRequest,
      [ConferenceRegistrationLogKeys.data]: {
        [ConferenceRegistrationRequestLogKey.status]: logStatusApproved
          ? RequestLogStatus.approved
          : RequestLogStatus.rejected,
        [ConferenceRegistrationSupportLogKey.title]:
          'Additional Guest Request ' + (logStatusApproved ? 'Approved' : 'Declined'),
        [ConferenceRegistrationSupportLogKey.date]: new Date(),
        [ConferenceRegistrationSupportLogKey.details]: this.note,
      },
    } as ConferenceRegistrationLog);
  };

  showAddGuestPopup = (): void => {
    this.attendeeGuestModalComponent.showModal(
      this.attendeeGuestsService.conferenceDbId$.value,
      this.attendeeGuestsService.registrantDbId$.value,
    );
  };

  showEditPopup = ({ row: { data } }): void => {
    this.attendeeGuestModalComponent.showModal(
      this.attendeeGuestsService.conferenceDbId$.value,
      this.attendeeGuestsService.registrantDbId$.value,
      data,
    );
  };

  onRowRemoving = this.attendeeGuestsService.onGuestRemoving;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
}
