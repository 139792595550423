import { Component, HostBinding, Input } from '@angular/core';
import { FlightPreference } from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/flight-preference.model';
import { FlightPreferenceFormService } from './flight-preference-form.service';
import {
  Airlines,
  AIRLINES_LOOKUP,
  FlightInfoKeys,
  SEAT_PREFERENCE_LOOKUP,
} from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/registration/flight-information.model';
import { LookupKeys } from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/lookup.model';

@Component({
  selector: 'ag-shr-flight-preference-form',
  templateUrl: './flight-preference-form.component.html',
  styleUrl: './flight-preference-form.component.scss',
})
export class FlightPreferenceFormComponent {
  @HostBinding('class') className = 'dietary-considerations-form';
  @Input() set flightPreference(data: Partial<FlightPreference>) {
    this._formData = this.flightPreferenceService.getFormData(data);
  }

  get flightPreference() {
    return this._formData;
  }
  @Input() isReadOnly: boolean = false;
  @Input() validationGroup: string;

  private _formData: FlightPreference = {};

  constructor(private flightPreferenceService: FlightPreferenceFormService) {}

  protected readonly AIRLINES_LOOKUP = AIRLINES_LOOKUP;
  protected readonly LookupKeys = LookupKeys;
  protected readonly FlightInfoKeys = FlightInfoKeys;
  protected readonly SEAT_PREFERENCE_LOOKUP = SEAT_PREFERENCE_LOOKUP;
  protected readonly Airlines = Airlines;
}
