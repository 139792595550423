import { Inject, Injectable } from '@angular/core';
import { DataService } from 'ag-common-svc/lib/services/data.service';
import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP } from 'ag-common-svc/lib/injections/firebase-app';
import { BaseModel } from 'ag-common-lib/lib/models/base.model';

@Injectable({
  providedIn: 'root',
})
export class DateTestService extends DataService<DateTest> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp);
    super.setCollection('datetest');
  }
}

export class DateTest extends BaseModel {
  public date: Date;
}
