import { Component, HostBinding, Input } from '@angular/core';
import {
  AssociationKeys,
  ATTENDEE_TYPE_LOOKUP,
  ConferenceRegistrationStepName,
  Constants,
  DATE_NOW,
  EditorOptions,
  Entity,
  EntityPermissionActivityKeys,
  INVITEE_OUTCOME_STATUS_LOOKUP,
  INVITEE_STATUS_LOOKUP,
  InviteeStatus,
  LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
  LookupKeys,
  Messages,
  MIN_DATE,
  Patterns,
  QUALIFIED_AS_LOOKUP,
  RegistrantData,
  RegistrantKeys,
  RegistrantModelKeys,
  REGISTRATION_TYPE_LOOKUP,
  TRUE_FALSE_LOOKUP,
} from 'ag-common-lib/public-api';
import { CONFERENCES_TITLE_LIST } from '../../../config/conferences.config';
import { LookupsService } from 'ag-common-svc/public-api';
import { format, isAfter, isEqual } from 'date-fns';
import { AttendeeSectionGroups } from '../attendee-details-modal.model';

import { Observable } from 'rxjs';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';
import { ValidationCallbackData } from 'devextreme/common';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-crm-attendee-general-info-form',
  templateUrl: './attendee-general-info-form.component.html',
  styleUrls: ['./attendee-general-info-form.component.scss'],
})
export class AttendeeGeneralInfoFormComponent {
  @HostBinding('class') className = 'attendee-general-info-form';
  @Input() registrantData: Partial<RegistrantData>;
  @Input() validationGroup: string;
  @Input() primaryEmailValidationGroup: string;
  @Input() tShirtSizesValidationGroup: string;
  @Input() dietaryConsiderationValidationGroup: string;
  @Input() emergencyContactValidationGroup: string;
  @Input() emergencyContactRelationshipTypeValidationGroup: string;
  @Input() mobilePhoneValidationGroup: string;
  @Input() billingAddressValidationGroup: string;
  @Input() shippingAddressValidationGroup: string;

  protected readonly AssociationKeys = AssociationKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly RegistrantModelKeys = RegistrantModelKeys;
  protected readonly titleList = CONFERENCES_TITLE_LIST;
  protected readonly Messages = Messages;
  protected readonly attendeeTypeLookup = ATTENDEE_TYPE_LOOKUP;
  protected readonly inviteeStatusLookup = INVITEE_STATUS_LOOKUP;
  protected readonly inviteeOutcomeStatusLookup = INVITEE_OUTCOME_STATUS_LOOKUP;
  protected readonly qualifiedAsLookup = QUALIFIED_AS_LOOKUP;
  protected readonly registrationTypeLookup = REGISTRATION_TYPE_LOOKUP;
  protected readonly trueFalseLookup = TRUE_FALSE_LOOKUP;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly InviteeStatus = InviteeStatus;
  protected readonly AttendeeSectionGroups = AttendeeSectionGroups;
  protected readonly maxDate: Date = DATE_NOW;
  protected readonly minDate: Date = MIN_DATE;
  protected readonly dateEditorOptions = {
    ...EditorOptions.DATE,

    min: this.minDate,
    max: this.maxDate,
    dateSerializationFormat: LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
    dateOutOfRangeMessage: `Please enter a date between ${format(this.minDate, Constants.DISPLAY_DATE_FORMAT)} and
      ${format(this.maxDate, Constants.DISPLAY_DATE_FORMAT)}`,
  };
  protected readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  protected readonly phoneMask: string = Constants.PHONE_MASK;
  protected readonly prefixesLookup$ = this.lookupsService.prefixesLookup$;
  protected readonly suffixesLookup$ = this.lookupsService.suffixesLookup$;
  protected readonly gendersLookup$ = this.lookupsService.gendersLookup$;

  protected readonly colCountByScreen = {
    lg: 12,
    md: 12,
    sm: 1,
    xs: 1,
  };
  protected readonly emailsColCountByScreen = {
    xs: 1,
    sm: 1,
    md: 6,
    lg: 12,
  };
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly dateValidation: string | RegExp = Patterns.DATE_PATTERN_MMDDYYYY;
  protected readonly ConferenceRegistrationStepName = ConferenceRegistrationStepName;

  protected agentEmailAddresses$ = this.attendeeDetailsModalService.agentEmailAddresses$;
  protected agentEmergencyContacts$ = this.attendeeDetailsModalService.agentEmergencyContacts$;
  protected agentPhoneNumbers$ = this.attendeeDetailsModalService.agentPhoneNumbers$;
  protected agentAddresses$ = this.attendeeDetailsModalService.agentAddresses$;

  protected sectionsInEditState$: Observable<Set<AttendeeSectionGroups>>;

  selectedAssociationKey: string;

  constructor(
    private lookupsService: LookupsService,
    private attendeeDetailsModalService: AttendeeDetailsModalService,
  ) {
    this.sectionsInEditState$ = attendeeDetailsModalService.sectionsInEditState$;
  }

  startEditing = this.attendeeDetailsModalService.startEditSectionGroup;

  cancelEditing = this.attendeeDetailsModalService.cancelEditSectionGroup;

  saveEditing = this.attendeeDetailsModalService.saveEditSectionGroup;

  protected emergencyContactValidationCallback = (e: ValidationCallbackData) => {
    if (e.value) {
      return true;
    }

    return !Object.values(this.registrantData?.[RegistrantKeys.emergencyContact] ?? {}).some(Boolean);
  };
  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
}
