import { NgModule } from '@angular/core';
import { FlightPreferenceFormService } from './flight-preference-form.service';
import { FlightPreferenceFormComponent } from './flight-preference-form.component';
import { SharedModule } from '../../../../../shared/shared.module';

@NgModule({
  declarations: [FlightPreferenceFormComponent],
  imports: [SharedModule],
  exports: [FlightPreferenceFormComponent],
  providers: [FlightPreferenceFormService],
})
export class FlightPreferenceFormModule {}
