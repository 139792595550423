import { Injectable } from '@angular/core';
import { ApproveDenyReason, ApproveDenyReasonKeys, ApproveDenyReasonVisibilityLevel } from '@ag-common-lib/public-api';
import { AgencyService, AgencyStatusActivityService, AuthService } from 'ag-common-svc/public-api';
import { confirm } from 'devextreme/ui/dialog';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'firebase/auth';
import { BaseFormService } from '../../../../../utils/base-form-service';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable()
export class StatusActivityModalService extends BaseFormService<ApproveDenyReason> {
  constructor(
    private agencyStatusActivityService: AgencyStatusActivityService,
    private authService: AuthService,
    private agencyService: AgencyService,
  ) {
    super();
  }

  saveActivity = agencyId =>
    this.formData[BaseModelKeys.dbId] ? this.updateActivity(agencyId) : this.createActivity(agencyId);

  cancelEdit = ({ event, component }): void => {
    if (!this.formChangesDetector?.hasChanges) {
      return;
    }

    event.cancel = true;

    const result = confirm('<i>Are you sure you want to Cancel without Saving?</i>', 'Confirm');
    result.then(dialogResult => {
      if (dialogResult) {
        this.formChangesDetector?.clear();
        component.instance.hide();
      }
    });
  };

  getFormData = async (association?: Partial<ApproveDenyReason>): Promise<ApproveDenyReason> => {
    const loggedInUser = await firstValueFrom(this.authService.loggedInAgent$.pipe(map(agent => agent?.p_agent_name)));

    const initialData = Object.assign(
      {
        [BaseModelKeys.createdDate]: new Date(),
        [BaseModelKeys.createdBy]: loggedInUser,
        [ApproveDenyReasonKeys.visibilityLevel]: ApproveDenyReasonVisibilityLevel.AllianceGroupLevel,
      },
      new ApproveDenyReason(),
      association,
    );
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);

        return true;
      },
    });

    return this.formData;
  };

  showSaveButton(reason: ApproveDenyReason): Observable<boolean> {
    return this.authService.currentUser$.pipe(
      map((user: User) => user?.email),
      map((loggedInUserEmail: string): boolean => {
        if (!reason || !reason[BaseModelKeys.dbId]) {
          return true;
        }
        return reason.created_by === loggedInUserEmail;
      }),
    );
  }

  private updateActivity = agencyId => {
    this.startProgress();
    return this.agencyStatusActivityService
      .update(agencyId, this.formData[BaseModelKeys.dbId], this.formData)
      .then(() => {
        this.formChangesDetector.clear();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  private createActivity = agencyId => {
    this.startProgress();
    return this.agencyStatusActivityService
      .create(agencyId, this.formData)
      .then(() => {
        this.formChangesDetector.clear();
      })
      .finally(() => {
        this.stopProgress();
      });
  };
}
