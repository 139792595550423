import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP } from '../injections/firebase-app';
import { CONFERENCES_COLLECTION_PATH } from './conference.service';
import { ToastrService } from 'ngx-toastr';
import { CONFERENCE_REGISTRANTS_COLLECTION_PATH } from './conference-registrants/conference-registrants.service';
import { map, Observable } from 'rxjs';
import {
  AgStripeSubtotalKeys,
  ConferenceRegistrationBuyInRateSubtotalMeta,
  RegistrationTransaction,
  RegistrationTransactionKeys,
  StripeTransactionKeys,
  SubtotalType,
} from '@ag-common-lib/public-api';
import { set } from 'lodash';
import { dateFromTimestamp } from 'ag-common-svc/public-api';
import { CommonFireStoreDao, QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';

@Injectable({
  providedIn: 'root',
})
export class ConferenceTransactionsService {
  readonly fsDao: CommonFireStoreDao<RegistrationTransaction>;
  private readonly conferenceCollectionPath = CONFERENCES_COLLECTION_PATH;
  private readonly registrantsCollectionPath = CONFERENCE_REGISTRANTS_COLLECTION_PATH;
  private readonly registrantTransactionsCollectionPath = 'stripe-transactions';

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    private toastrService: ToastrService,
  ) {
    this.fsDao = new CommonFireStoreDao<RegistrationTransaction>(
      fireBaseApp,
      ConferenceTransactionsService.fromFirestore,
      null,
    );
  }

  static readonly fromFirestore = (data: RegistrationTransaction): RegistrationTransaction => {
    const results = Object.assign({}, data, {
      [StripeTransactionKeys.paymentDate]: dateFromTimestamp(data?.[StripeTransactionKeys.paymentDate]),
    });

    results?.[StripeTransactionKeys.details]?.forEach(details => {
      if (details?.[AgStripeSubtotalKeys.subtotalType] === SubtotalType.BuyInRate) {
        const meta = details?.[AgStripeSubtotalKeys.meta] as ConferenceRegistrationBuyInRateSubtotalMeta;
        const guestDob = dateFromTimestamp(meta?.guestDob);

        set(details, [AgStripeSubtotalKeys.meta, 'guestDob'], guestDob);
      }
      if (details?.[AgStripeSubtotalKeys.refundProcessedDate]) {
        details[AgStripeSubtotalKeys.refundProcessedDate] = dateFromTimestamp(
          details[AgStripeSubtotalKeys.refundProcessedDate],
        );
      }
    });

    return results;
  };

  getAllByConferenceDbId(conferenceDbId: string) {
    const queries: QueryParam[] = [
      new QueryParam(RegistrationTransactionKeys.conferenceDbId, WhereFilterOperandKeys.equal, conferenceDbId),
    ];

    return this.fsDao
      .getCollectionGroupSnapshot(this.registrantTransactionsCollectionPath, queries)
      .pipe(map(snapshot => snapshot.docs.map(doc => (!doc.exists() ? null : doc.data()))));
  }

  getList(conferenceDbId: string, registrantDbId: string, qp: QueryParam[] = []) {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    return this.fsDao.getList(table, qp).pipe(map(transactions => transactions));
  }

  getDocumentData(
    conferenceDbId: string,
    registrantDbId: string,
    documentId: string,
  ): Observable<RegistrationTransaction> {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    return this.fsDao.getDocument(table, documentId).pipe(
      map(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          return data;
        }
        return null;
      }),
    );
  }

  async create(conferenceDbId: string, registrantDbId: string, data: RegistrationTransaction, silent = false) {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    Object.assign(data, {
      [RegistrationTransactionKeys.conferenceDbId]: conferenceDbId,
      [RegistrationTransactionKeys.registrantDbId]: registrantDbId,
    });

    const registrant = await this.fsDao.create(data, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Conference Registration Transaction Successfully Created!');

    return registrant;
  }

  async update(
    conferenceDbId: string,
    registrantDbId: string,
    documentId: string,
    updates: Partial<RegistrationTransaction>,
    silent = false,
  ) {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    const registrant = await this.fsDao.updateFields(updates, documentId, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Conference Registration Transaction Successfully Updated!');

    return registrant;
  }

  delete(conferenceDbId: string, registrantDbId: string, documentId: string, silent = false) {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    return this.fsDao.delete(documentId, table).then(response => {
      !silent && this.toastrService.success('Conference Registration Transaction Removed!');
      return response;
    });
  }

  private getCollectionPath(conferenceId: string, registrantDbId: string) {
    return [
      this.conferenceCollectionPath,
      conferenceId,
      this.registrantsCollectionPath,
      registrantDbId,
      this.registrantTransactionsCollectionPath,
    ].join('/');
  }
}
