import { Association, AssociationKeys } from '../utils/association.model';
import { InviteeAgeGroup } from './registrants.model';
import { FlightInformationModel } from './flight-information.model';
import { SelectedExcursions } from './excursion.model';
import { LookupKeys } from '../crm/lookup.model';
import { TShirtSizesKeys } from '../utils/t-shirt-seizes.model';
import { DietaryConsiderationKeys } from '../utils/dietary-consideration.model';

export enum GuestKeys {
  conferenceDbId = 'conferenceDbId',
  registrantDbId = 'registrantDbId',
  associationDbId = 'associationDbId',
  isComplimentary = 'isComplimentary',
  approved = 'approved',
  approvedBy = 'approved_by',
  approvedDate = 'approved_date',
  revokedBy = 'revoked_by',
  revokedDate = 'revoked_date',
  childOrAdult = 'child_or_adult',
  flightInformation = 'flightInformation',
  selectedExcursions = 'selectedExcursions',
  badgeName = 'badgeName',
}

export const ConferenceRegistrationGuestAssociationKeysToPick = [
  AssociationKeys.prefix,
  AssociationKeys.firstName,
  AssociationKeys.lastName,
  AssociationKeys.suffix,
  AssociationKeys.gender,
  AssociationKeys.dob,
  AssociationKeys.associationType,
  AssociationKeys.tShirtSizes,
  AssociationKeys.dietaryConsideration,
  AssociationKeys.flightPreference,
] as const;

export const GUEST_TYPES_LOOKUP = [
  { [LookupKeys.value]: true, [LookupKeys.description]: 'Complimentary' },
  { [LookupKeys.value]: false, [LookupKeys.description]: 'Additional' },
];

export class GuestData extends Association {
  [GuestKeys.conferenceDbId]?: string = '';
  [GuestKeys.registrantDbId]?: string = '';
  [GuestKeys.associationDbId]?: string = '';
  [GuestKeys.isComplimentary]?: boolean = false;
  [GuestKeys.approved]?: boolean = false;
  [GuestKeys.approvedBy]?: string = '';
  [GuestKeys.approvedDate]?: Date = null;
  [GuestKeys.revokedBy]?: string = '';
  [GuestKeys.revokedDate]?: Date;
  [GuestKeys.childOrAdult]?: InviteeAgeGroup = null;
  [GuestKeys.badgeName]?: string;

  // Flight configuration
  [GuestKeys.flightInformation]?: FlightInformationModel;
  [GuestKeys.selectedExcursions]?: SelectedExcursions;
}

export const GUEST_TITLE_LIST = {
  // Event Information
  [GuestKeys.approvedDate]: 'Approved Date',
  [GuestKeys.approvedBy]: 'Approved By',

  // Registrant Information
  [AssociationKeys.firstName]: 'First Name',
  [AssociationKeys.lastName]: 'Last Name',
  [AssociationKeys.prefix]: 'Prefix',
  [AssociationKeys.suffix]: 'Suffix',
  [AssociationKeys.associationType]: 'Relationship',
  [AssociationKeys.gender]: 'Gender',
  [AssociationKeys.dob]: 'Date of Birth',

  // Additional Information
  [TShirtSizesKeys.unisexTShirtSize]: 'Unisex T-Shirt Size',
  [GuestKeys.childOrAdult]: 'Child or Adult',
  // Dietary Information
  [DietaryConsiderationKeys.hasDietaryOrPersonalConsideration]: 'Dietary or Personal Considerations?',
  [DietaryConsiderationKeys.dietaryConsiderationType]: 'Dietary Consideration Type',
  [DietaryConsiderationKeys.dietaryConsideration]: 'Dietary Consideration',
};
