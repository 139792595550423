import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicListsGroupService } from 'ag-common-svc/public-api';
import { BaseFormService } from 'ag-common-svc/lib/utils/base-form-service';
import { DynamicListsGroup, DynamicListsGroupKeys } from '@ag-common-lib/lib';
import { BehaviorSubject, filter, map, mergeMap, Observable, shareReplay, startWith } from 'rxjs';
import { AddAgentToDynamicListFormData, AddAgentToDynamicListKeys } from './add-agent-to-dynamic-list.model';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable()
export class AddAgentToDynamicListModalService extends BaseFormService<AddAgentToDynamicListFormData> {
  agentDbId$ = new BehaviorSubject<string>(null);
  dynamicLists$: Observable<DynamicListsGroup[]> = this.agentDbId$.pipe(
    filter(Boolean),
    mergeMap(agentDbId => {
      return this.dynamicListsGroupService
        .getList()
        .pipe(map(lists => lists?.filter(item => !item?.[DynamicListsGroupKeys.recipientsDbIds]?.includes(agentDbId))));
    }),
    startWith([]),
    shareReplay(1),
  );
  agentIncludedLists$ = new BehaviorSubject<string[]>([]);
  agentNotAddedLists$ = new BehaviorSubject<string[]>([]);

  constructor(
    private dynamicListsGroupService: DynamicListsGroupService,
    private toastrService: ToastrService,
  ) {
    super();
  }

  handleSave = async () => {
    const promises = [];
    const agentDbId = this.agentDbId$.value;
    const sourceType = this.formData?.[BaseModelKeys.actionName] ?? null;
    const lists = this.formData?.[AddAgentToDynamicListKeys.dynamicLists];
    this.formChangesDetector.clear();
    this.startProgress();
    lists.forEach(item => {
      const listName = item[DynamicListsGroupKeys.title] ?? '';
      const recipientsDbIds = [...item[DynamicListsGroupKeys.recipientsDbIds], agentDbId];
      const promise = this.dynamicListsGroupService
        .updateFields(item?.[BaseModelKeys.dbId], {
          [BaseModelKeys.actionName]: sourceType,
          [DynamicListsGroupKeys.recipientsDbIds]: recipientsDbIds,
        })
        .then(response => {
          const includedLists = [...this.agentIncludedLists$.value, listName];
          this.agentIncludedLists$.next(includedLists);
        })
        .catch(error => {
          console.error('error', error);
          const excludedLists = [...this.agentNotAddedLists$.value, listName];
          this.agentNotAddedLists$.next(excludedLists);
        });
      promises.push(promise);
    });
    await Promise.all(promises)
      .catch(error => {
        console.error('error', error);
        this.toastrService.error(`Agent are not added to the lists!`);
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  setAgentDbId = (agentDbId: string) => {
    this.agentDbId$.next(agentDbId);
  };

  getFormData = (data?: Partial<AddAgentToDynamicListFormData>) => {
    const initialData = Object.assign({}, new AddAgentToDynamicListFormData(), data);

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);

          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });

    return this.formData;
  };
}
