import { LookupKeys } from '../../models/crm/lookup.model';

export enum ChangeSourceType {
  regularPortal = 'regularPortal',
  portalEventRegistration = 'portalEventRegistration',
  import = 'import',
  mergeTool = 'mergeTool',
  regularArm = 'regularArm',
  impersonate = 'impersonate',
}

export const CHANGE_SOURCE_TYPE_LOOKUP = [
  {
    [LookupKeys.value]: ChangeSourceType.import,
    [LookupKeys.description]: 'Import',
  },
  {
    [LookupKeys.value]: ChangeSourceType.mergeTool,
    [LookupKeys.description]: 'Merge Tool',
  },
  {
    [LookupKeys.value]: ChangeSourceType.regularArm,
    [LookupKeys.description]: 'ARM via UI',
  },
  {
    [LookupKeys.value]: ChangeSourceType.regularPortal,
    [LookupKeys.description]: 'Agent Portal via Profile',
  },
  {
    [LookupKeys.value]: ChangeSourceType.impersonate,
    [LookupKeys.description]: 'Agent Portal via Impersonate',
  },
  {
    [LookupKeys.value]: ChangeSourceType.portalEventRegistration,
    [LookupKeys.description]: 'Agent Portal via Event Registration',
  },
];
