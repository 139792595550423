<ag-crm-add-attendees-modal #addAttendeesModalRef></ag-crm-add-attendees-modal>

<ag-crm-attendee-details-modal #attendeeDetailsModalRef></ag-crm-attendee-details-modal>

<ag-shr-notification-scheduler-edit-modal #notificationSchedulerEditModalRef></ag-shr-notification-scheduler-edit-modal>

<ag-shr-modal-window
  #attendeesListModalRef
  [title]="eventNameTitle"
  [width]="'90vw'"
  [height]="'80vh'"
  [inProgress]="inProgress"
  [showSaveButton]="false"
  [useScrollView]="false"
>
  <dx-data-grid
    *ngIf="attendeesListModalRef?.popupComponent?.visible ?? false"
    class="editor__data-grid"
    #registrantGrid
    [dataSource]="attendees$ | async"
    [showBorders]="true"
    [showRowLines]="true"
    [columnAutoWidth]="true"
    [scrollLeft]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [noDataText]="emptyMessage"
    (onExporting)="onExporting($event)"
    [syncLookupFilterValues]="false"
    width="100%"
    height="100%"
  >
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>

    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-builder [allowHierarchicalFields]="true"></dxo-filter-builder>
    <dxo-filter-builder-popup></dxo-filter-builder-popup>

    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping></dxo-grouping>
    <dxo-export [enabled]="true" [formats]="['xlsx']"> </dxo-export>
    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      [storageKey]="(conferenceDbId$ | async) + 'attendeesList'"
    ></dxo-state-storing>

    <dxo-editing
      mode="popup"
      [allowUpdating]="false"
      [allowDeleting]="Entity.conferenceRegistrants | hasPermission: EntityPermissionActivityKeys.delete | async"
      [allowAdding]="false"
      [useIcons]="true"
    ></dxo-editing>

    <dxo-toolbar>
      <dxi-item location="before">
        <div *dxTemplate>
          <dx-menu
            [items]="availableReports$ | async"
            [displayExpr]="'title'"
            (onItemClick)="getCustomReport($event)"
            [visible]="Entity.eventAttendeesCustomReports | hasPermission: EntityPermissionActivityKeys.write | async"
          ></dx-menu>
        </div>
      </dxi-item>

      <dxi-item name="groupPanel"></dxi-item>

      <dxi-item
        name="exportButton"
        location="after"
        [visible]="Entity.eventAttendeesOverallExcelReport | hasPermission: EntityPermissionActivityKeys.write | async"
      ></dxi-item>

      <dxi-item name="columnChooserButton" location="after"></dxi-item>

      <dxi-item name="searchPanel" location="after"></dxi-item>

      <dxi-item
        [visible]="Entity.conferenceRegistrants | hasPermission: EntityPermissionActivityKeys.create | async"
        location="after"
        widget="dxButton"
        [options]="{
          icon: 'plus',
          onClick: showAddAttendeesModal,
        }"
      ></dxi-item>

      <dxi-item
        location="after"
        widget="dxDropDownButton"
        [options]="{
          icon: 'email',
          items: (conferenceEmailTemplates$ | async),
          displayExpr: emailTemplateDisplayExpr,
          onItemClick: sendBulkEmails,
          hint: 'Mass Mailing',
          showArrowIcon: false,
          dropDownOptions: {
            width: 230,
          },
        }"
        [visible]="Entity.eventAttendeesMassMailing | hasPermission: EntityPermissionActivityKeys.write | async"
      ></dxi-item>
    </dxo-toolbar>

    <dxi-column
      [dataField]="AttendeeKeys.registrationState"
      [caption]="AttendeeKeys.registrationState | caption: 'attendeesList'"
      [groupIndex]="0"
      cssClass="conference-registration-grid__cell-vertical-aligned"
      [allowGrouping]="true"
      [allowSorting]="true"
      [allowFiltering]="true"
      [showWhenGrouped]="true"
      [sortOrder]="'desc'"
    >
      <dxo-lookup
        [dataSource]="attendeeWizardStateLookup"
        [valueExpr]="LookupKeys.value"
        [displayExpr]="LookupKeys.description"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.inviteeEmail"
      [caption]="AttendeeKeys.inviteeEmail | caption: 'attendeesList'"
      [groupIndex]="1"
      [showWhenGrouped]="false"
      [sortOrder]="1"
      groupCellTemplate="emptyCountGroupCellTemplate"
    ></dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.inviteeEmailType"
      [width]="200"
      [caption]="'Invitee Email Type'"
      [caption]="AttendeeKeys.inviteeEmailType | caption: 'attendeesList'"
      [visible]="false"
    >
      <dxo-lookup
        [dataSource]="emailTypeLookup$ | async"
        [valueExpr]="BaseModelKeys.dbId"
        [displayExpr]="LookupKeys.description"
        [allowClearing]="true"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.inviteeStatus"
      [width]="150"
      [caption]="AttendeeKeys.inviteeStatus | caption: 'attendeesList'"
      [showWhenGrouped]="true"
    >
      <dxo-lookup [dataSource]="INVITEE_STATUS_LOOKUP" displayExpr="description" valueExpr="value"></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.inviteeOutcomeStatus"
      [width]="150"
      [caption]="AttendeeKeys.inviteeOutcomeStatus | caption: 'attendeesList'"
      [showWhenGrouped]="true"
    >
      <dxo-lookup [dataSource]="INVITEE_OUTCOME_STATUS_LOOKUP" displayExpr="description" valueExpr="value"></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.attendeeType"
      [width]="150"
      [caption]="AttendeeKeys.attendeeType | caption: 'attendeesList'"
    >
      <dxo-lookup [dataSource]="attendeeTypeLookup" displayExpr="description" valueExpr="value"></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.associationType"
      [caption]="AttendeeKeys.associationType | caption: 'attendeesList'"
      [visible]="false"
    >
      <dxo-lookup
        [dataSource]="associationTypeLookup$ | async"
        [valueExpr]="BaseModelKeys.dbId"
        [displayExpr]="LookupKeys.description"
        [allowClearing]="true"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.firstName"
      [caption]="AttendeeKeys.firstName | caption: 'attendeesList'"
    ></dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.lastName"
      [caption]="AttendeeKeys.lastName | caption: 'attendeesList'"
    ></dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.registrationType"
      [caption]="AttendeeKeys.registrationType | caption: 'attendeesList'"
    >
      <dxo-lookup
        [dataSource]="registrationTypeLookup"
        [valueExpr]="LookupKeys.value"
        [displayExpr]="LookupKeys.description"
        [allowClearing]="true"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="AttendeeKeys.qualifiedAs" [caption]="AttendeeKeys.qualifiedAs | caption: 'attendeesList'">
      <dxo-lookup
        [dataSource]="qualifiedAsLookup"
        [valueExpr]="LookupKeys.value"
        [displayExpr]="LookupKeys.description"
        [allowClearing]="true"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.awardName"
      [caption]="AttendeeKeys.awardName | caption: 'attendeesList'"
    ></dxi-column>

    <dxi-column [dataField]="AttendeeKeys.mgaId" [caption]="AttendeeKeys.mgaId | caption: 'attendeesList'">
      <dxo-lookup
        [dataSource]="agencies$ | async"
        [allowClearing]="false"
        [displayExpr]="'name'"
        [valueExpr]="'agency_id'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.agencyId"
      [caption]="AttendeeKeys.agencyId | caption: 'attendeesList'"
      [visible]="false"
    >
      <dxo-lookup
        [dataSource]="agencies$ | async"
        [allowClearing]="false"
        [displayExpr]="'name'"
        [valueExpr]="'agency_id'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.companyReference"
      [caption]="AttendeeKeys.companyReference | caption: 'attendeesList'"
    ></dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.firstTimeAttendee"
      [caption]="AttendeeKeys.firstTimeAttendee | caption: 'attendeesList'"
      [dataType]="'boolean'"
      [trueText]="'Yes'"
      [falseText]="'No'"
    ></dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.prefix"
      [caption]="AttendeeKeys.prefix | caption: 'attendeesList'"
      [visible]="false"
    >
      <dxo-lookup
        [dataSource]="prefixesLookup$ | async"
        [valueExpr]="BaseModelKeys.dbId"
        [displayExpr]="LookupKeys.description"
        [allowClearing]="true"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.suffix"
      [caption]="AttendeeKeys.suffix | caption: 'attendeesList'"
      [visible]="false"
    >
      <dxo-lookup
        [dataSource]="suffixesLookup$ | async"
        [valueExpr]="BaseModelKeys.dbId"
        [displayExpr]="LookupKeys.description"
        [allowClearing]="true"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.gender"
      [caption]="AttendeeKeys.gender | caption: 'attendeesList'"
      [visible]="false"
    >
      <dxo-lookup
        [dataSource]="genderTypeLookup$ | async"
        [valueExpr]="BaseModelKeys.dbId"
        [displayExpr]="LookupKeys.description"
        [allowClearing]="true"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.dob"
      [caption]="AttendeeKeys.dob | caption: 'attendeesList'"
      dataType="date"
      [format]="dateFormat"
      [visible]="false"
    ></dxi-column>

    <dxi-column [caption]="'Emergency Contact'" [visible]="false">
      <dxi-column
        *ngIf="[AttendeeKeys.emergencyContact, AssociationKeys.firstName] | path as dataField"
        [dataField]="dataField"
        [caption]="dataField | caption: 'attendeesList'"
      ></dxi-column>

      <dxi-column
        *ngIf="[AttendeeKeys.emergencyContact, AssociationKeys.lastName] | path as dataField"
        [dataField]="dataField"
        [caption]="dataField | caption: 'attendeesList'"
      ></dxi-column>

      <dxi-column
        *ngIf="[AttendeeKeys.emergencyContact, AssociationKeys.emailAddress] | path as dataField"
        [dataField]="dataField"
        [caption]="dataField | caption: 'attendeesList'"
      ></dxi-column>

      <dxi-column
        *ngIf="[AttendeeKeys.emergencyContact, AssociationKeys.contactNumber] | path as dataField"
        [dataField]="dataField"
        [caption]="dataField | caption: 'attendeesList'"
      ></dxi-column>

      <dxi-column
        *ngIf="[AttendeeKeys.emergencyContact, AssociationKeys.associationType] | path as dataField"
        [dataField]="dataField"
        [caption]="dataField | caption: 'attendeesList'"
      >
        <dxo-lookup
          [dataSource]="associationTypeLookup$ | async"
          [valueExpr]="BaseModelKeys.dbId"
          [displayExpr]="LookupKeys.description"
          [allowClearing]="true"
        >
        </dxo-lookup>
      </dxi-column>

      <dxi-column [caption]="'Address'">
        <dxi-column
          *ngIf="
            [AttendeeKeys.emergencyContact, AssociationKeys.address, AddressModelKeys.address1] | path as dataField
          "
          [dataField]="dataField"
          [caption]="dataField | caption: 'attendeesList'"
        ></dxi-column>

        <dxi-column
          *ngIf="
            [AttendeeKeys.emergencyContact, AssociationKeys.address, AddressModelKeys.address2] | path as dataField
          "
          [dataField]="dataField"
          [caption]="dataField | caption: 'attendeesList'"
        ></dxi-column>

        <dxi-column
          *ngIf="[AttendeeKeys.emergencyContact, AssociationKeys.address, AddressModelKeys.city] | path as dataField"
          [dataField]="dataField"
          [caption]="dataField | caption: 'attendeesList'"
        ></dxi-column>

        <dxi-column
          *ngIf="[AttendeeKeys.emergencyContact, AssociationKeys.address, AddressModelKeys.state] | path as dataField"
          [dataField]="dataField"
          editCellTemplate="stateEditorTmp"
          [caption]="dataField | caption: 'attendeesList'"
        ></dxi-column>

        <dxi-column
          *ngIf="[AttendeeKeys.emergencyContact, AssociationKeys.address, AddressModelKeys.zip] | path as dataField"
          [dataField]="dataField"
          [caption]="dataField | caption: 'attendeesList'"
        ></dxi-column>
      </dxi-column>
    </dxi-column>

    <dxi-column [caption]="'T-Shirt Sizes'" [visible]="false">
      <dxi-column
        *ngIf="[AttendeeKeys.tShirtSizes, TShirtSizesKeys.tShirtSize] | path as dataField"
        [dataField]="dataField"
        [caption]="dataField | caption: 'attendeesList'"
      >
        <dxo-lookup
          [dataSource]="tShortSizesLookup$ | async"
          [valueExpr]="BaseModelKeys.dbId"
          [displayExpr]="LookupKeys.description"
          [allowClearing]="true"
        >
        </dxo-lookup>
      </dxi-column>

      <dxi-column
        *ngIf="[AttendeeKeys.tShirtSizes, TShirtSizesKeys.tShirtSizeOther] | path as dataField"
        [dataField]="dataField"
        [caption]="dataField | caption: 'attendeesList'"
      ></dxi-column>

      <dxi-column
        *ngIf="[AttendeeKeys.tShirtSizes, TShirtSizesKeys.unisexTShirtSize] | path as dataField"
        [dataField]="dataField"
        [caption]="dataField | caption: 'attendeesList'"
      >
        <dxo-lookup
          [dataSource]="tShortSizesLookup$ | async"
          [valueExpr]="BaseModelKeys.dbId"
          [displayExpr]="LookupKeys.description"
          [allowClearing]="true"
        >
        </dxo-lookup>
      </dxi-column>

      <dxi-column
        *ngIf="[AttendeeKeys.tShirtSizes, TShirtSizesKeys.unisexTShirtSizeOther] | path as dataField"
        [dataField]="dataField"
        [caption]="dataField | caption: 'attendeesList'"
      ></dxi-column>
    </dxi-column>

    <dxi-column
      [dataField]="AttendeeKeys.childOrAdult"
      [caption]="AttendeeKeys.childOrAdult | caption: 'attendeesList'"
      [visible]="false"
    >
      <dxo-lookup
        [dataSource]="INVITEE_AGE_GROUP_LOOKUP"
        [valueExpr]="LookupKeys.value"
        [displayExpr]="LookupKeys.description"
        [allowClearing]="true"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column [caption]="'Dietary Consideration(s)'" [visible]="false">
      <dxi-column
        *ngIf="
          [AttendeeKeys.dietaryConsideration, DietaryConsiderationKeys.hasDietaryOrPersonalConsideration]
            | path as dataField
        "
        [dataField]="dataField"
        [caption]="dataField | caption: 'attendeesList'"
      >
        <dxo-lookup [dataSource]="YES_NO_TYPE_LOOKUP" [allowClearing]="true"></dxo-lookup>
      </dxi-column>

      <dxi-column
        *ngIf="
          [AttendeeKeys.dietaryConsideration, DietaryConsiderationKeys.dietaryConsiderationType] | path as dataField
        "
        [dataField]="dataField"
        [caption]="dataField | caption: 'attendeesList'"
      >
        <dxo-lookup
          [dataSource]="dietaryConsiderationTypesLookup$ | async"
          [valueExpr]="BaseModelKeys.dbId"
          [displayExpr]="LookupKeys.description"
          [allowClearing]="true"
        >
        </dxo-lookup>
      </dxi-column>

      <dxi-column
        *ngIf="[AttendeeKeys.dietaryConsideration, DietaryConsiderationKeys.dietaryConsideration] | path as dataField"
        [dataField]="dataField"
        [caption]="dataField | caption: 'attendeesList'"
      >
      </dxi-column>
    </dxi-column>

    <dxi-column
      cellTemplate="buttonsCellTemplate"
      [fixed]="true"
      fixedPosition="right"
      alignment="center"
      [allowReordering]="false"
      caption="Actions"
      [minWidth]="105"
    >
      <div *dxTemplate="let cellTemplateData of 'buttonsCellTemplate'">
        <div
          *ngIf="cellTemplateData?.data?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee"
          class="attendees-list-modal__buttons-container"
        >
          <dx-button
            *var="
              checkIsAttendeeDetailsButtonVisible &&
              (Entity.conferenceRegistrants | hasPermission: EntityPermissionActivityKeys.write | async) as canWrite
            "
            [icon]="canWrite ? 'edit' : 'agi-eye'"
            [hint]="canWrite ? 'Edit Registration' : 'View'"
            type="default"
            stylingMode="text"
            (onClick)="editAttendee(cellTemplateData?.data)"
          ></dx-button>

          <dx-button
            [visible]="
              checkIsAttendeeDetailsButtonVisible && Entity.conferenceRegistrants
                | hasPermission: EntityPermissionActivityKeys.delete
                | async
            "
            icon="trash"
            hint="Delete Registration"
            type="default"
            stylingMode="text"
            (onClick)="onRowRemoving(cellTemplateData?.data)"
          ></dx-button>

          <dx-drop-down-button
            [visible]="
              checkIsAttendeeDetailsButtonVisible && Entity.eventAttendeesSendEmailToQualifier
                | hasPermission: EntityPermissionActivityKeys.write
                | async
            "
            class="attendees-list-modal__row-drop-down"
            [showArrowIcon]="false"
            icon="email"
            stylingMode="text"
            type="normal"
            hint="Send via Email"
            [items]="conferenceEmailTemplates$ | async"
            [displayExpr]="emailTemplateDisplayExpr"
            [dropDownOptions]="{ width: 230 }"
            (onItemClick)="sendToEmail($event, cellTemplateData?.data)"
          >
          </dx-drop-down-button>
        </div>
      </div>
    </dxi-column>

    <dxi-column type="buttons" [visible]="false"></dxi-column>

    <dxo-summary [calculateCustomSummary]="calculateCustomSummary" [recalculateWhileEditing]="true">
      <dxi-group-item [name]="AttendeeKeys.registrationState" summaryType="custom" displayFormat="{0}"></dxi-group-item>
    </dxo-summary>

    <div *dxTemplate="let cell of 'emptyCountGroupCellTemplate'">
      <div>{{ cell.column.caption }}: {{ cell.displayValue }}</div>
    </div>
  </dx-data-grid>
</ag-shr-modal-window>

<ag-shr-modal-window
  #attendeesReportYears
  [title]="'Report Years Selection'"
  [width]="'500px'"
  [height]="'220px'"
  [inProgress]="inProgress"
  [actionTitle]="'RUN REPORT'"
  (onSaveClick)="runByQuarterReport()"
  [useScrollView]="false"
>
  <dx-tag-box
    [items]="CONFERENCE_YEARS_LIST"
    [(value)]="attendeesReportYearsValue"
    [showSelectionControls]="true"
    applyValueMode="useButtons"
  >
  </dx-tag-box>
</ag-shr-modal-window>

<dx-load-panel
  shadingColor="rgba(255,255,255,0.4)"
  [visible]="exportInProgress$ | async"
  [message]="'Please do not leave or refresh this page until complete export!'"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
