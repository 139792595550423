import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Headshot, HeadshotKeys, MediaSize, RegistrantKeys } from '@ag-common-lib/public-api';
import { Delta } from 'jsondiffpatch/lib/types';
import { normalizeLog } from '../../../utils/normalize-log';

@Component({
  selector: 'ag-shr-change-log-headshot-detail',
  templateUrl: './change-log-headshot.component.html',
  styleUrls: ['./change-log-headshot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogHeadshotComponent {
  @Input()
  set log(item: Delta) {
    const cropperPayload = item?.[HeadshotKeys.imageCropperPayload];
    const relativeX1 = cropperPayload?.relativeCropperPosition?.relativeX1;
    const relativeX2 = cropperPayload?.relativeCropperPosition?.relativeX2;
    const relativeY1 = cropperPayload?.relativeCropperPosition?.relativeY1;
    const relativeY2 = cropperPayload?.relativeCropperPosition?.relativeY2;
    this.isCropped = !!cropperPayload?.length;

    if (!!item?.[HeadshotKeys.wasabiPath]?.length) {
      this.fromHeadshot = {
        [HeadshotKeys.wasabiPath]: item?.[HeadshotKeys.wasabiPath][0],
        [HeadshotKeys.imageCropperPayload]: {
          relativeCropperPosition: {
            relativeX1: relativeX1?.[0] ?? null,
            relativeX2: relativeX2?.[0] ?? null,
            relativeY1: relativeY1?.[0] ?? '0',
            relativeY2: relativeY2?.[0] ?? '1',
          },
        },
      };
      this.toHeadshot = {
        [HeadshotKeys.wasabiPath]: item?.[HeadshotKeys.wasabiPath][1],
        [HeadshotKeys.imageCropperPayload]: {
          relativeCropperPosition: {
            relativeX1: relativeX1?.[1] ?? null,
            relativeX2: relativeX2?.[1] ?? null,
            relativeY1: relativeY1?.[1] ?? '0',
            relativeY2: relativeY2?.[1] ?? '1',
          },
        },
      };
    }
  }

  fromHeadshot: Headshot;
  toHeadshot: Headshot;
  isCropped = false;

  protected readonly normalizeLog = normalizeLog;
  protected readonly HeadshotKeys = HeadshotKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly MediaSize = MediaSize;
}
