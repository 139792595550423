import { Injectable } from '@angular/core';
import { AgentCampaignWizardService } from '../agent-campaign-wizard.service';
import {
  Agent,
  AgentCampaignContactInfoEmailAddressMetaData,
  AgentCampaignKeys,
  AgentCampaignStepName,
  EmailAddress,
  EmailAddressKeys,
} from '@ag-common-lib/public-api';
import { Observable, combineLatest, filter, map, mergeMap, shareReplay, startWith } from 'rxjs';
import { BaseFormService } from '../../../utils/base-form-service';
import { pick } from 'lodash';
import { AgentEmailAddressesService } from 'ag-common-svc/lib/services/agent-email-addresses.service';
import { FormChangesDetectorActionType } from 'ag-common-svc/shared/utils';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable()
export class AgentCampaignEmailAddressInfoService extends BaseFormService<Partial<EmailAddress>> {
  campaignContactInfoEmailAddress$: Observable<Partial<EmailAddress>>;
  agentEmailAddresses$: Observable<EmailAddress[]>;
  metaData$: Observable<AgentCampaignContactInfoEmailAddressMetaData>;

  constructor(
    private agentCampaignWizardService: AgentCampaignWizardService,
    agentEmailAddressesService: AgentEmailAddressesService,
  ) {
    super();

    this.metaData$ = this.agentCampaignWizardService.campaign$.pipe(
      map(campaign => campaign?.[AgentCampaignKeys.metaData]?.[AgentCampaignStepName.contactInfoEmailAddress]),
    );

    this.agentEmailAddresses$ = agentCampaignWizardService.agent$.pipe(
      mergeMap((agent: Agent) => agentEmailAddressesService.getList(agent?.[BaseModelKeys.dbId])),
      shareReplay(1),
    );

    this.campaignContactInfoEmailAddress$ = combineLatest({
      campaign: this.agentCampaignWizardService.campaign$,
      _resetTrigger: this.formChangesDetector.actions$.pipe(
        filter(action => action?.type === FormChangesDetectorActionType.reset),
        startWith(null),
      ),
    }).pipe(
      map(({ campaign }) => {
        const emailAddress = campaign?.[AgentCampaignKeys.data]?.[AgentCampaignStepName.contactInfoEmailAddress];
        const draftEmailAddress =
          campaign?.[AgentCampaignKeys.draftData]?.[AgentCampaignStepName.contactInfoEmailAddress];

        return Object.assign({}, emailAddress, draftEmailAddress);
      }),
      map(this.getFormData),
      shareReplay(1),
    );

    this.campaignContactInfoEmailAddress$.subscribe();
  }

  handleSetSelectedEmailAddress = async emailAddress => {
    return this.setEmailAddress(emailAddress);
  };

  handleNextStepClick = async (selectedKey: string) => {
    const changes = this.formChangesDetector.getAllChanges();

    if (!changes?.length) {
      this.agentCampaignWizardService.nextStep();
      return;
    }

    const updates: Partial<EmailAddress> = Object.assign({}, this.formData);

    this.startProgress();
    await this.agentCampaignWizardService
      .handleStepChanges(
        {
          [AgentCampaignStepName.contactInfoEmailAddress]: updates,
        },
        {
          [AgentCampaignStepName.contactInfoEmailAddress]: {
            selectedKey: selectedKey[0] ?? null,
          },
        },
      )
      .then(() => {
        this.formChangesDetector.clear();
        this.agentCampaignWizardService.nextStep();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  private setEmailAddress = (emailAddress?: Partial<EmailAddress>) => {
    Object.keys(this.formData).forEach(key => {
      this.formData[key] = null;
    });

    Object.assign(this.formData, emailAddress);
  };

  private getFormData = (emailAddress?: Partial<EmailAddress>) => {
    const initialData = this.pickEmailAddressData(emailAddress);
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);

          switch (prop) {
            case '':
          }
        }

        return true;
      },
    });

    return this.formData;
  };

  private pickEmailAddressData = (emailAddress: Partial<EmailAddress>) => {
    return pick(emailAddress, [BaseModelKeys.dbId, EmailAddressKeys.address, EmailAddressKeys.isLogin]);
  };
}
