import { Agent, AGENT_STATUS, AgentKeys } from '@ag-common-lib/public-api';
import { Pipe, PipeTransform } from '@angular/core';
import {
  EventInquiryRequest,
  EventInquiryRequestKeys,
  EventInquiryRequestStatus,
} from 'ag-common-lib/lib/models/registration/event-inquiry-request.model';
import { ColumnButtonClickEvent } from 'devextreme/ui/data_grid';

@Pipe({ name: 'isAllowToApproveRequest' })
export class IsAllowToApproveRequestPipe implements PipeTransform {
  constructor() {}

  transform(agentsMap: Record<string, Agent>) {
    return (e: ColumnButtonClickEvent<EventInquiryRequest>) => {
      const eventInquiryRequest = e?.row?.data;
      const agentDbId = eventInquiryRequest?.[EventInquiryRequestKeys.agentDbId];

      if (!agentDbId) {
        return false;
      }
      const eventInquiryStatus = eventInquiryRequest?.[EventInquiryRequestKeys.status];

      if (eventInquiryStatus === EventInquiryRequestStatus.approved) {
        return false;
      }

      const agent = agentsMap[agentDbId];

      if (!agent) {
        return false;
      }

      const agentStatus = agent?.[AgentKeys.agent_status];
      const isAgentNotWelcomed = new Set([AGENT_STATUS.INACTIVE, AGENT_STATUS.DENIED]).has(agentStatus);

      return !isAgentNotWelcomed;
    };
  }
}
