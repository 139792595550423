import { Pipe, PipeTransform } from '@angular/core';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { map } from 'rxjs/operators';
import { CarrierService } from '../../public-api';
import { CarrierModelKeys } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/domain/carrier.model';

@Pipe({ name: 'agentCarrierDescription' })
export class AgentCarrierPipe implements PipeTransform {
  constructor(private carrierService: CarrierService) {}

  transform(carrierId: string): any {
    return this.carrierService.getList().pipe(
      map(carriers => {
        if (!Array.isArray(carriers)) {
          return '';
        }
        return (
          carriers.find(({ [BaseModelKeys.dbId]: dbId }) => dbId === carrierId)?.[CarrierModelKeys.carrierName] ?? ''
        );
      }),
    );
  }
}
