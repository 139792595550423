import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Agent, AgentKeys, AgentReviewLevel, AGENT_STATUS, ChangeSourceType } from '@ag-common-lib/public-api';
import { pick } from 'lodash';
import { AgentService } from '../../../../services/agent.service/agent.service';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { BaseFormService } from '../../../../utils/base-form-service';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable()
export class PortalService extends BaseFormService<Partial<Agent>> {
  public isReviewLevelVisible$ = new BehaviorSubject(false);

  private agentId: string;

  constructor(private agentService: AgentService) {
    super();
  }

  public save = async (modalWindowComponent: ModalWindowComponent, action: ChangeSourceType | null = null) => {
    const hasChanges = this.formChangesDetector.hasChanges;

    if (hasChanges) {
      this.startProgress();

      const agentId = this.agentId;
      const updates = {};
      const changes = this.formChangesDetector.getAllChanges();

      changes.forEach(([key]) => {
        Object.assign(updates, { [key]: this.formData[key] });
      });

      await this.agentService
        .updateAgentFields(agentId, updates, action)
        .then(() => {
          this.formChangesDetector.clear();
          modalWindowComponent?.hideModal();
        })
        .finally(() => {
          this.stopProgress();
        });

      return { agentId, updates };
    }

    if (!hasChanges) {
      modalWindowComponent?.hideModal();
    }

    return null;
  };

  public getFormData = (agent?: Partial<Agent>) => {
    this.agentId = agent[BaseModelKeys.dbId];
    const initialData = pick(agent, [
      AgentKeys.p_agent_id,
      AgentKeys.p_registered_user,
      AgentKeys.agent_status,
      AgentKeys.agent_review_level,
      AgentKeys.p_strategic_agent,
      AgentKeys.prospect_status,
      AgentKeys.alliance_group_employee,
      AgentKeys.role,
      AgentKeys.agent_type,
      AgentKeys.is_rmd,
      AgentKeys.christmas_card,
      AgentKeys.conference_poster,
    ]);

    const isReviewLevelVisible = agent[AgentKeys.agent_status] === AGENT_STATUS.IN_REVIEW;
    this.isReviewLevelVisible$.next(isReviewLevelVisible);

    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);
        switch (prop) {
          case AgentKeys.agent_status:
            const prevReviewLevel = target[AgentKeys.agent_review_level];

            if (value !== AGENT_STATUS.IN_REVIEW && !prevReviewLevel) {
              Reflect.set(target, AgentKeys.agent_review_level, null, receiver);
              this.formChangesDetector.handleChange(AgentKeys.agent_review_level, null, prevReviewLevel);
            }
            if (value === AGENT_STATUS.IN_REVIEW) {
              Reflect.set(target, AgentKeys.agent_review_level, AgentReviewLevel.AllianceGroupLevel, receiver);
              this.formChangesDetector.handleChange(
                AgentKeys.agent_review_level,
                AgentReviewLevel.AllianceGroupLevel,
                prevReviewLevel,
              );
            }

            this.isReviewLevelVisible$.next(value === AGENT_STATUS.IN_REVIEW);
            break;

          default:
            break;
        }
        return true;
      },
    });

    return this.formData;
  };
}
