import { Injectable } from '@angular/core';
import { AgentCampaignWizardService } from '../agent-campaign-wizard.service';
import { AgentCampaignKeys, AgentCampaignStepName, CampaignWebSite } from '@ag-common-lib/public-api';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { BaseFormService } from '../../../utils/base-form-service';
import { AgentCampaignService } from '../../../services/agent-campaign.service';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
@Injectable()
export class AgentCampaignWebSiteService extends BaseFormService<CampaignWebSite> {
  formData$ = new BehaviorSubject<CampaignWebSite>(this.formData);

  draftWebSite$: Observable<CampaignWebSite>;

  private readonly _webSiteLocked$ = new BehaviorSubject<boolean>(false);
  webSiteLocked$ = this._webSiteLocked$.asObservable();

  constructor(
    private agentCampaignWizardService: AgentCampaignWizardService,
    private agentCampaignService: AgentCampaignService,
  ) {
    super();

    this.draftWebSite$ = this.agentCampaignWizardService.draftCampaignData$.pipe(
      map(draftCampaignData => draftCampaignData?.[AgentCampaignStepName.webSite]),
    );

    const campaignWebSite$ = this.agentCampaignWizardService.campaign$.pipe(
      map(campaign => {
        const webSite = campaign?.[AgentCampaignKeys.data]?.[AgentCampaignStepName.webSite];
        const draftWebSite = campaign?.[AgentCampaignKeys.draftData]?.[AgentCampaignStepName.webSite];

        this._webSiteLocked$.next(!!webSite?.domainOption && !!webSite?.subdomain);

        return Object.assign({}, webSite, draftWebSite);
      }),
    );

    campaignWebSite$.subscribe(this.getFormData);
  }

  handleNextStepClick = async () => {
    const changes = this.formChangesDetector.getAllChanges();

    if (!changes?.length) {
      this.agentCampaignWizardService.nextStep();
      return;
    }

    const updates: Partial<CampaignWebSite> = {};

    Object.assign(updates, this.formData);
    this.startProgress();

    await this.agentCampaignWizardService
      .handleStepChanges({
        [AgentCampaignStepName.webSite]: updates,
      })
      .then(() => {
        this.formChangesDetector.clear();
        this.agentCampaignWizardService.nextStep();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  asyncUniqWebSiteValidation = async (): Promise<boolean> => {
    const campaign = await firstValueFrom(this.agentCampaignWizardService.campaign$);
    const sites = await this.agentCampaignService.getAllWithSameWebSite(campaign?.[BaseModelKeys.dbId], this.formData);

    return !sites?.length;
  };

  resetChanges = () => {
    this.revertAllChanges();
  };

  private getFormData = (webSite?: CampaignWebSite) => {
    const initialData = Object.assign({}, webSite);
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);

          switch (prop) {
            case 'domainOption':
              if (prevValue === '__custom__' || value === '__custom__') {
                Object.assign(this.formData, { subdomain: null });
              }
          }
        }

        return true;
      },
    });

    this.formData$.next(this.formData);
  };
}
