import { Component, HostBinding, Input } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AgentCampaignSummaryService } from './agent-campaign-summary.service';
import { AgentCampaign, AgentCampaignStepName, ChangeSourceType } from '@ag-common-lib/public-api';
import { AgentCampaignWizardService } from '../agent-campaign-wizard.service';

@Component({
  selector: 'ag-shr-agent-campaign-summary',
  templateUrl: './agent-campaign-summary.component.html',
  styleUrls: ['./agent-campaign-summary.component.scss'],
})
export class AgentCampaignSummaryComponent {
  @HostBinding('class') className = 'agent-campaign-summary agent-campaign-wizard__content-container';

  @Input() canEdit = true;
  @Input() sourceType: ChangeSourceType | null = null;

  inProgress$: Observable<boolean>;
  hasDraftData$: Observable<boolean>;
  agentCampaign$: Observable<AgentCampaign>;

  readonly AgentCampaignStepName = AgentCampaignStepName;

  constructor(
    private agentCampaignSummaryService: AgentCampaignSummaryService,
    private agentCampaignWizardService: AgentCampaignWizardService,
  ) {
    this.inProgress$ = agentCampaignSummaryService.inProgress$;
    this.hasDraftData$ = agentCampaignWizardService.draftCampaignData$.pipe(
      map(draftCampaignData => !!draftCampaignData),
    );
    this.agentCampaign$ = agentCampaignWizardService.campaign$;
  }

  submitChanges = this.agentCampaignSummaryService.submitChanges;
}
