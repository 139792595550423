import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { ActiveLookup, LookupKeys, Messages, Patterns, Website, WebsiteKeys } from '@ag-common-lib/public-api';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { ToastrService } from 'ngx-toastr';
import { WebsitesService } from './websites.service';
import { BehaviorSubject } from 'rxjs';
import { validateUrlWithOrWithoutProtocol } from 'ag-common-svc/lib/utils/validation';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-websites',
  templateUrl: './websites.component.html',
  styleUrls: ['./websites.component.scss'],
  providers: [WebsitesService],
})
export class WebsitesComponent {
  @HostBinding('class') className = 'websites';
  @ViewChild('websitesEditorModalRef', { static: true }) websitesEditorModalComponent: ModalWindowComponent;
  @Input() parentDbId: string;
  @Input() websites: Website[] = [];
  @Input() canEdit = true;
  @Input() canCreate = true;
  @Input() canDelete = true;
  @Input()
  set websiteTypesLookup(items: ActiveLookup[]) {
    this._websiteTypesLookup = items;
    this._defaultWebsiteTypeLookup = items?.find(item => item?.isDefault);
  }
  get websiteTypesLookup(): ActiveLookup[] {
    return this._websiteTypesLookup;
  }
  @Input() saveWebsites: (websites: Website[]) => Promise<Website[]>;

  inProgress$ = new BehaviorSubject<boolean>(false);

  protected readonly WebsiteKeys = WebsiteKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly urlValidation = Patterns.URL_PATTERN_WITH_OR_WITHOUT_PROTOCOL;
  protected readonly BaseModelKeys = BaseModelKeys;
  readonly Messages = Messages;
  readonly emptyMessage = 'No Websites Currently Exist';
  private _defaultWebsiteTypeLookup: ActiveLookup;
  private _websiteTypesLookup: ActiveLookup[] = [];

  constructor(
    private toastrService: ToastrService,
    private websitesService: WebsitesService,
  ) {}

  showWebsitesEditorModal = (): void => {
    this.websitesEditorModalComponent.showModal();
  };

  validateUrlWithOrWithoutProtocol = e => validateUrlWithOrWithoutProtocol(e?.value);

  onInitNewRow = (e): void => {
    e.data.website_type = this._defaultWebsiteTypeLookup?.dbId;
  };

  onRowInserting = e => {
    const { __KEY__: key, ...data } = e?.data;
    const isUniq = this.checkIsWebsiteUniq(data);

    if (!isUniq) {
      this.toastrService.error('Same Social already exists in this profile');

      e.cancel = true;
      return;
    }

    const websites = this.normalizeWebsites(data);
    websites.push(Object.assign({ id: key }, data));
    e.cancel = this.updateWebsites(websites);
  };

  onRowUpdating = e => {
    const data = Object.assign({}, e?.oldData, e?.newData);
    const isUniq = this.checkIsWebsiteUniq(data, e?.key);

    if (!isUniq) {
      this.toastrService.error('Same Social already exists in this profile');

      e.cancel = true;
      return;
    }

    const websites = this.normalizeWebsites(data, e?.key);
    e.cancel = this.updateWebsites(websites);
  };

  onRowRemoving = e => {
    const websites = this.websites.filter(address => address !== e.key);
    e.cancel = this.updateWebsites(websites);
  };

  onWebsiteTypeChanged = ({ selectedItem }): void => {
    this.websitesService.setSelectedWebsiteTypeChange(selectedItem);
  };

  private checkIsWebsiteUniq = (data, key?: Website) => {
    return this.websites.every(website => {
      if (key && website === key) {
        return true;
      }
      return data?.url !== website?.url;
    });
  };

  private normalizeWebsites = (data, key?: Website) => {
    return this.websites.map(website => {
      if (key && website === key) {
        return data;
      }
      return Object.assign({}, website);
    });
  };

  private updateWebsites(websites: Website[]) {
    this.inProgress$.next(true);
    return Promise.all([this.saveWebsites(websites), this.websitesService.onUpdateWebsites()])
      .then(() => {
        this.websites = websites;
        this.toastrService.success('Websites updated!');
      })
      .catch(() => {
        this.toastrService.error('Websites are not updated!');
      })
      .finally(() => {
        this.inProgress$.next(false);
      });
  }
}
