import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { AgentKeys } from '@ag-common-lib/public-api';
import { AgentService } from 'ag-common-svc/public-api';
import { NotificationRecipientsGridComponent } from '../notification-recipients-grid/notification-recipients-grid.component';
import CustomStore from 'devextreme/data/custom_store';
import { firstValueFrom } from 'rxjs';
import { QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/lib/dao/CommonFireStoreDao.dao';

@Component({
  selector: 'ag-shr-notification-add-recipients-modal',
  templateUrl: './notification-add-recipients-modal.component.html',
  styleUrls: ['./notification-add-recipients-modal.component.scss'],
})
export class NotificationAddRecipientsModalComponent {
  @ViewChild('notificationAddRecipientsModalRef', { static: false })
  protected notificationAddRecipientsModalComponent: ModalWindowComponent;
  @ViewChild('recipientsGridRef', { static: false })
  protected agentsGridComponent: NotificationRecipientsGridComponent;

  @Input() title = 'Agents';
  @Input() actionTitle = 'Add';
  @Output() handleAddRecipients = new EventEmitter<string[]>();

  protected dataSource = new CustomStore({
    key: 'dbId',
    load: () => {
      return firstValueFrom(
        this.agentService.getList([new QueryParam(AgentKeys.fcmTokens, WhereFilterOperandKeys.notEqual, null)]),
      );
    },
  });

  constructor(private agentService: AgentService) {}

  showModal = async (): Promise<void> => {
    this.notificationAddRecipientsModalComponent?.showModal();
  };

  protected addNotificationRecipients = async () => {
    const selectedRowKeys = this.agentsGridComponent.getSelectedRowKeys();

    this.handleAddRecipients.emit(selectedRowKeys);
    this.notificationAddRecipientsModalComponent.forceCloseModal();
  };
}
