import { BaseCampaignsEmail, EMAIL_CAMPAIGN_PATH } from '@ag-common-lib/public-api';
import { CommonFireStoreDao, QueryParam } from 'ag-common-svc/lib/dao/CommonFireStoreDao.dao';
import { FirebaseApp } from 'firebase/app';

export class EmailCampaignService<T extends BaseCampaignsEmail> {
  private collectionPath = EMAIL_CAMPAIGN_PATH;

  protected readonly fsDao: CommonFireStoreDao<T>;

  constructor(fireBaseApp: FirebaseApp) {
    this.fsDao = new CommonFireStoreDao<T>(fireBaseApp, null, null);
  }

  getList(parentPath: string, qp: QueryParam[] = []) {
    const table = this.getCollectionPath(parentPath);

    return this.fsDao.getList(table, qp);
  }

  protected async sendEmail(parentPath: string, data: T) {
    const table = this.getCollectionPath(parentPath);
    const campaignEmails = await this.fsDao.create(data, table).catch(e => {
      console.log('e', e);
    });

    return campaignEmails;
  }

  protected getCollectionPath(parentPath: string) {
    return [parentPath, this.collectionPath].join('/');
  }
}
