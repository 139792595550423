import { Inject, Injectable } from '@angular/core';
import { Agency, AgencyKeys, AGENCY_TYPE, Address } from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { dateFromTimestamp } from '../../public-api';
import { FIREBASE_APP } from '../injections/firebase-app';
import { DataService } from './data.service';
import { firstValueFrom, lastValueFrom, map, Observable, of, shareReplay, skip, take } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { normalizeAddressesFromFirestore } from '../utils/address.util';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';

@Injectable({
  providedIn: 'root',
})
export class AgencyService extends DataService<Agency> {
  private readonly agenciesCollectionPath = 'agencies';
  readonly agencies$: Observable<Agency[]>;
  readonly mgas$: Observable<Agency[]>;
  readonly agenciesNamesByAgencyId$: Observable<{ [key: string]: string }>;
  readonly agencyNamesDataSourceConfiguration;

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, AgencyService.fromFirestore);
    super.setCollection(this.agenciesCollectionPath);

    this.agencies$ = this.getList([], { sortField: AgencyKeys.name }).pipe(shareReplay(1));
    this.mgas$ = this.agencies$.pipe(
      map(agencies =>
        agencies.filter(agency => {
          return agency?.[AgencyKeys.agencyType] === AGENCY_TYPE.MGA;
        }),
      ),
      shareReplay(1),
    );
    this.agenciesNamesByAgencyId$ = this.agencies$.pipe(
      map(agencies => {
        const map: { [key: string]: string } = {};

        agencies.forEach(agency => {
          const agencyId = agency?.[AgencyKeys.agencyId];
          const name = agency?.[AgencyKeys.name];

          map[agencyId] = name;
        });

        return map;
      }),
      shareReplay(1),
    );

    this.agencyNamesDataSourceConfiguration = {
      paginate: true,
      pageSize: 25,
      store: new CustomStore({
        key: 'value',
        loadMode: 'raw',
        load: async () => {
          return lastValueFrom(
            this.agenciesNamesByAgencyId$.pipe(
              map(agentIdToNameMap => {
                const lookup: { value: string; description: string }[] = [];

                Object.entries(agentIdToNameMap).forEach(([value, description]) => {
                  lookup.push({ value, description });
                });

                lookup.sort((left, right) => {
                  const leftDescription = left?.description ?? '';
                  const rightDescription = right?.description ?? '';

                  return leftDescription?.localeCompare(rightDescription, 'en', {
                    numeric: true,
                    sensitivity: 'base',
                    ignorePunctuation: true,
                  });
                });

                return lookup;
              }),

              take(1),
            ),
          );
        },
      }),
    };
  }

  static readonly fromFirestore = (data): Agency => {
    if (!!data?.relationshipWithAG) {
      data.relationshipWithAG.initiallyMet = dateFromTimestamp(data?.relationshipWithAG?.initiallyMet);
      data.relationshipWithAG.contractedDate = dateFromTimestamp(data?.relationshipWithAG?.contractedDate);
    }

    data[AgencyKeys.stateLicenses]?.forEach(stateLicense => {
      stateLicense.expiration_date = dateFromTimestamp(stateLicense.expiration_date);
      stateLicense.expiration = dateFromTimestamp(stateLicense.expiration);
    });

    data[AgencyKeys.carriers]?.forEach(carrier => {
      carrier.first_contracted = dateFromTimestamp(carrier.first_contracted);
    });

    data[AgencyKeys.socials] = data[AgencyKeys.socials] ?? [];
    data[AgencyKeys.websites] = data[AgencyKeys.websites] ?? [];
    data[AgencyKeys.addresses] = normalizeAddressesFromFirestore(data?.[AgencyKeys.addresses]);

    return Object.assign({}, data);
  };

  getAgencyCustomStore = () => {
    const store = new CustomStore({
      key: BaseModelKeys.dbId,
      byKey: key => this.getById(key),
      load: () => {
        return firstValueFrom(this.agencies$);
      },
    });

    // TODO investigate how to update store dynamic
    // this.agencies$.pipe(skip(1)).subscribe(agencies => {
    //   debugger;
    //   store.load();
    // });

    return store;
  };

  getMGACustomStore = () => {
    const store = new CustomStore({
      key: BaseModelKeys.dbId,
      byKey: key => this.getById(key),
      load: () => {
        return firstValueFrom(this.mgas$);
      },
    });

    // TODO investigate how to update store dynamic

    return store;
  };

  getAgencyByAgencyId(id: string): Promise<Agency> {
    if (!id) {
      return Promise.resolve(null);
    }
    return this.getAllByValue([new QueryParam('agency_id', WhereFilterOperandKeys.equal, id)]).then(agencies => {
      if (!agencies?.length) {
        return null;
      }

      if (agencies?.length > 1) {
        console.error('More than 1 agency found with this agency id');
        return null;
      }

      return agencies[0];
    });
  }

  getAgenciesByRMDId(agentId: string) {
    if (!agentId) {
      return of([]);
    }

    const qp = [new QueryParam(AgencyKeys.rmds, WhereFilterOperandKeys.arrayContains, agentId)];

    return this.getList(qp);
  }

  getAgenciesIdsByRMDId(agentId: string) {
    return this.getAgenciesByRMDId(agentId).pipe(
      map(agencies => {
        return agencies?.map(agency => agency?.[AgencyKeys.agencyId])?.filter(Boolean);
      }),
    );
  }

  getMGAAgencies(sortField: string): Promise<Agency[]> {
    return this.getAllByValue(
      [new QueryParam('agency_type', WhereFilterOperandKeys.equal, AGENCY_TYPE.MGA)],
      null,
      sortField,
    );
  }

  getAgencyDisplayValue = agencyDbId => {
    return this.getById(agencyDbId).then(agency => {
      return [agency?.[AgencyKeys.agencyId], agency?.[AgencyKeys.name]].filter(Boolean).join(' - ');
    });
  };

  updateFields(documentId: string, data: Partial<Agency>): Promise<Agency> {
    return super.updateFields(documentId, data);
  }
}
