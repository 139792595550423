import { ChangeSourceType } from '../../lists/change-logs/change-source-type.enum';

export enum DeleteConferenceRegistrationKeys {
  conferenceDbId = 'conferenceDbId',
  registrantDbId = 'registrantDbId',
  actionName = 'actionName',
}

export interface DeleteConferenceRegistration {
  [DeleteConferenceRegistrationKeys.conferenceDbId]: string;
  [DeleteConferenceRegistrationKeys.registrantDbId]: string;
  [DeleteConferenceRegistrationKeys.actionName]?: ChangeSourceType;
}
