import { Pipe, PipeTransform } from '@angular/core';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { map } from 'rxjs/operators';
import { LookupsService } from '../../public-api';
import { Observable } from 'rxjs';

@Pipe({ name: 'prefix' })
export class PrefixPipe implements PipeTransform {
  constructor(private lookupsService: LookupsService) {}

  transform(prefixId: string): Observable<string> {
    return this.lookupsService.prefixesLookup$.pipe(
      map(prefixes => {
        if (!Array.isArray(prefixes)) {
          return '';
        }
        return prefixes.find(({ [BaseModelKeys.dbId]: dbId }) => dbId === prefixId)?.description ?? '';
      }),
    );
  }
}
