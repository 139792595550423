<dx-form [formData]="flightPreference" [labelLocation]="'top'" labelMode="floating">
  <!-- Group: Preferred Airline -->
  <dxi-item itemType="group" caption="Preferred Airline" [colCount]="3">
    <dxi-item
      [dataField]="FlightInfoKeys.preferredAirline"
      editorType="dxSelectBox"
      [editorOptions]="{
        items: AIRLINES_LOOKUP,
        displayExpr: LookupKeys.description,
        valueExpr: LookupKeys.value,
      }"
    >
      <dxo-label text="Airline"></dxo-label>
    </dxi-item>

    <dxi-item
      *var="flightPreference?.[FlightInfoKeys.preferredAirline] as preferredAirline"
      [visible]="preferredAirline === Airlines.Other"
      [dataField]="FlightInfoKeys.otherPreferredAirline"
      [label]="{
        text: 'Other Airline',
      }"
    ></dxi-item>

    <dxi-item [dataField]="FlightInfoKeys.preferredAirlineLoyaltyNumber" editorType="dxTextBox">
      <dxo-label text="Loyalty #"></dxo-label>
    </dxi-item>
  </dxi-item>

  <!-- Group: Second Choice Airline -->
  <dxi-item itemType="group" caption="Second Choice Airline" [colCount]="3">
    <dxi-item
      [dataField]="FlightInfoKeys.secondChoiceAirline"
      editorType="dxSelectBox"
      [editorOptions]="{
        items: AIRLINES_LOOKUP,
        displayExpr: LookupKeys.description,
        valueExpr: LookupKeys.value,
      }"
    >
      <dxo-label text="Airline"></dxo-label>
    </dxi-item>
    <dxi-item
      *var="flightPreference?.[FlightInfoKeys.secondChoiceAirline] as preferredAirline"
      [visible]="preferredAirline === Airlines.Other"
      [dataField]="FlightInfoKeys.otherSecondChoiceAirline"
      [label]="{
        text: 'Other Second Choice Airline',
      }"
    ></dxi-item>

    <dxi-item [dataField]="FlightInfoKeys.secondChoiceAirlineLoyaltyNumber" editorType="dxTextBox">
      <dxo-label text="Loyalty #"></dxo-label>
    </dxi-item>
  </dxi-item>

  <!-- Group: Other -->
  <dxi-item itemType="group" caption="Other" [colCount]="3">
    <dxi-item [dataField]="FlightInfoKeys.knownTravelerNumber" editorType="dxTextBox">
      <dxo-label text="Known Traveler #"></dxo-label>
    </dxi-item>

    <dxi-item
      [dataField]="FlightInfoKeys.seatPreference"
      editorType="dxSelectBox"
      [editorOptions]="{
        items: SEAT_PREFERENCE_LOOKUP,
        displayExpr: LookupKeys.description,
        valueExpr: LookupKeys.value,
      }"
    >
      <dxo-label text="Seat Preference"></dxo-label>
    </dxi-item>

    <dxi-item [dataField]="FlightInfoKeys.preferredDepartureAirport" editorType="dxTextBox">
      <dxo-label text="Preferred Departure Airport"></dxo-label>
    </dxi-item>
  </dxi-item>
</dx-form>
