import { Inject, Injectable } from '@angular/core';
import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import { dateFromTimestamp } from '../utils/date-from-timestamp';
import { QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';
import { DataService } from '../services/data.service';
import {
  MeetingLog,
  MeetingLogKeys,
} from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/meeting-log.model';
import { combineLatest, defer, map, Observable } from 'rxjs';
import {
  ActivityBase,
  ActivityBaseKeys,
} from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/activity-base.model';
import { SHOULD_FETCH_TARGET_ACTIVITIES_SET } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/activity-info.model';
import { ChangeSourceType } from '@ag-common-lib/public-api';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable({
  providedIn: 'root',
})
export class MeetingLogsService extends DataService<MeetingLog> {
  private readonly meetingLogsCollectionPath = 'crm-record-meetings';

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, MeetingLogsService.fromFirestore);
    super.setCollection(this.meetingLogsCollectionPath);
  }

  static readonly fromFirestore = (data): MeetingLog => {
    return Object.assign({}, data, {
      [MeetingLogKeys.date]: dateFromTimestamp(data[MeetingLogKeys.date]),
    }) as MeetingLog;
  };

  updateMeetingLogsFields(
    documentId: string,
    data: Partial<MeetingLog>,
    action: ChangeSourceType | null = null,
  ): Promise<MeetingLog> {
    Object.assign(data, {
      [BaseModelKeys.actionName]: action,
    });
    return super.updateFields(documentId, data, true);
  }

  getMeetingLogsList = (activityConfig: ActivityBase): Observable<MeetingLog[]> =>
    defer(() => {
      const targetType = activityConfig[ActivityBaseKeys.targetType];
      const shouldFetchTargetActivities = SHOULD_FETCH_TARGET_ACTIVITIES_SET.has(targetType);
      const targetQp: QueryParam[] = [];
      const hostOwnerIdQp: QueryParam[] = [];
      const involvedQp: QueryParam[] = [];

      targetQp.push(
        new QueryParam(
          ActivityBaseKeys.targetId,
          WhereFilterOperandKeys.equal,
          activityConfig[ActivityBaseKeys.targetId],
        ),
      );
      hostOwnerIdQp.push(
        new QueryParam(
          MeetingLogKeys.hostOwnerId,
          WhereFilterOperandKeys.equal,
          activityConfig[ActivityBaseKeys.targetId],
        ),
      );
      involvedQp.push(
        new QueryParam(MeetingLogKeys.attendees, WhereFilterOperandKeys.arrayContainsAny, [
          activityConfig[ActivityBaseKeys.targetId],
        ]),
      );

      const queries = [this.getList(hostOwnerIdQp), this.getList(involvedQp)];

      if (shouldFetchTargetActivities) {
        queries.push(this.getList(targetQp));
      }

      return combineLatest(queries).pipe(
        map(items => {
          const itemsMap = new Map();

          items.forEach(tasks => {
            if (Array.isArray(tasks)) {
              tasks.forEach(task => {
                const dbId = task[BaseModelKeys.dbId];
                itemsMap.set(dbId, task);
              });
            }
          });

          return Array.from(itemsMap.values());
        }),
      );
    });
}
