import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogPipesModule } from '../../../pipes/change-log-pipes.module';
import { ChangeLogFavouriteComponent } from './change-log-favourite.component';

@NgModule({
  declarations: [ChangeLogFavouriteComponent],
  imports: [CommonModule, SharedModule, ChangeLogPipesModule],
  exports: [ChangeLogFavouriteComponent],
})
export class ChangeLogFavouriteModule {}
