export enum AGENT_STATUS {
  NEW_AGENT = 'New Agent',
  NEW_REGISTRATION = 'New Registration',
  IN_REVIEW = 'In Review',
  APPROVED = 'Approved',
  DENIED = 'Denied',
  NEW_PROSPECT = 'New Prospect',
  CLOSED_PROSPECT = 'Closed Prospect',
  INACTIVE = 'Inactive',
}

export const AgentStatusColorMap = new Map<AGENT_STATUS, string>([
  [AGENT_STATUS.APPROVED, '#2eb52c'],
  [AGENT_STATUS.DENIED, '#de8e8c'],
  [AGENT_STATUS.IN_REVIEW, '#dca848c2'],
  [AGENT_STATUS.NEW_AGENT, '#5c95c5'],
  [AGENT_STATUS.NEW_PROSPECT, '#5c95c5'],
]);
