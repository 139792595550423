import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { DataService } from './data.service';
import { DynamicListsGroup, DynamicListsGroupKeys } from '@ag-common-lib/lib';
import { Observable, shareReplay } from 'rxjs';
import { QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';
import { FIREBASE_APP } from '../injections/firebase-app';

@Injectable()
export class DynamicListsGroupService extends DataService<DynamicListsGroup> {
  readonly lists$: Observable<DynamicListsGroup[]>;
  private readonly dynamicListsGroupServicesCollectionPath = 'dynamic-lists-group';

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, DynamicListsGroupService.fromFirestore);
    super.setCollection(this.dynamicListsGroupServicesCollectionPath);
    this.lists$ = this.getList([], { sortField: DynamicListsGroupKeys.title }).pipe(shareReplay(1));
  }

  static readonly fromFirestore = (data): any => {
    return Object.assign({}, data);
  };

  getDynamicListsByAgentDbId(
    agentDbId: string,
    sortField?: string,
    qp: QueryParam[] = [],
  ): Observable<DynamicListsGroup[]> {
    qp.push(new QueryParam(DynamicListsGroupKeys.recipientsDbIds, WhereFilterOperandKeys.notIn, [agentDbId]));

    return this.fsDao.getList(this.dynamicListsGroupServicesCollectionPath, qp, { sortField });
  }
}
