import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AgentFileDirectoriesService } from '../services/agent-files-directories.service';
import { AgentFilesMediaService } from '../services/agent-files-media.service';
import {
  AGENT_SYSTEM_DIRECTORY_NAMES,
  AgentFileDirectory,
  AgentFileDirectoryKeys,
  SystemFileDirectories,
} from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/agent-file-directory.model';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { ChangeSourceType } from '@ag-common-lib/public-api';
import { AgentMedia } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/agent-media.model';

@Injectable()
export class AgentImagesService {
  constructor(
    private agentFileDirectoriesService: AgentFileDirectoriesService,
    private agentFilesMediaService: AgentFilesMediaService,
  ) {}

  // A method for fetching agent images (not documents)
  fetchAgentImages(agentDbId: string): Observable<AgentMedia[]> {
    return this.agentFileDirectoriesService.getList(agentDbId).pipe(
      mergeMap((fileDirectories: AgentFileDirectory[]) => {
        const imagesFileDirectory = fileDirectories.find(
          directory => directory[AgentFileDirectoryKeys.name] === SystemFileDirectories.Images,
        );
        if (!imagesFileDirectory) {
          return [];
        }
        return this.agentFilesMediaService.getList(
          imagesFileDirectory[AgentFileDirectoryKeys.agentDbId],
          imagesFileDirectory[BaseModelKeys.dbId],
        );
      }),
      map(agentMedia => agentMedia as AgentMedia[]),
    );
  }

  // Create agent media in the "Images" directory and return a Promise
  async addAgentMediaToImagesDirectory(
    agentId: string,
    mediaData: AgentMedia,
    action: ChangeSourceType | null = null,
  ): Promise<AgentMedia | void> {
    let fileDirectories = await firstValueFrom(this.agentFileDirectoriesService.getList(agentId));
    Object.assign(mediaData, {
      [BaseModelKeys.actionName]: action,
    });

    // Convert current directory names to a Set for easy lookup
    const existingDirectories = new Set(fileDirectories.map(dir => dir[AgentFileDirectoryKeys.name]));

    const directoryCreationPromises = AGENT_SYSTEM_DIRECTORY_NAMES.filter(name => !existingDirectories.has(name)).map(
      name => this.createSystemFileDirectories(agentId, name),
    );

    if (directoryCreationPromises.length > 0) {
      await Promise.all(directoryCreationPromises);
      // Refetch directories after they're created
      fileDirectories = await firstValueFrom(this.agentFileDirectoriesService.getList(agentId));
    }

    const imagesFileDirectory = fileDirectories.find(
      directory => directory[AgentFileDirectoryKeys.name] === SystemFileDirectories.Images,
    );

    if (!imagesFileDirectory) {
      throw new Error('Images directory not found.');
    }

    return this.agentFilesMediaService.create(agentId, imagesFileDirectory[BaseModelKeys.dbId], mediaData);
  }

  private async createSystemFileDirectories(agentId: string, directoryName: string): Promise<boolean> {
    const directory: AgentFileDirectory = {
      [AgentFileDirectoryKeys.agentDbId]: agentId,
      [AgentFileDirectoryKeys.name]: directoryName,
      [AgentFileDirectoryKeys.isSystem]: true,
    };
    return this.agentFileDirectoriesService
      .create(agentId, Object.assign(directory, { [AgentFileDirectoryKeys.agentDbId]: agentId }) as AgentFileDirectory)
      .then(() => false)
      .catch(err => {
        throw err;
      })
      .finally(() => {});
  }
}
