import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeLogCaptionByIndex',
})
export class ChangeLogCaptionByIndexPipe implements PipeTransform {
  transform(caption: string, ind: number): string {
    // todo: 'added' ???
    const isRemovedItem = ind.toString().includes('_');
    const typeByIndex = isRemovedItem ? 'removed' : 'changed';
    return `${caption} was ${typeByIndex}.`;
  }
}
