import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import {
  Agent,
  AgentKeys,
  EmailAddressKeys,
  EntityApplications,
  Messages,
  UserRoleModelKeys,
} from 'ag-common-lib/public-api';
import { ConferencePermissionsModalService } from './conference-permissions-modal.service';
import { DxFormComponent } from 'devextreme-angular';
import { SelectionChangedEvent } from 'devextreme/ui/select_box';
import { ToastrService } from 'ngx-toastr';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-crm-conference-permissions-modal',
  templateUrl: './conference-permissions-modal.component.html',
  styleUrls: ['./conference-permissions-modal.component.scss'],
  providers: [ConferencePermissionsModalService],
})
export class ConferencePermissionsModalComponent {
  @ViewChild('conferencePermissionsFormModalRef', { static: true })
  conferencePermissionsFormModal: ModalWindowComponent;
  @ViewChild('conferencePermissionsFormRef', { static: false }) conferencePermissionsFormComponent: DxFormComponent;
  @Output() onAfterSaveChanges = new EventEmitter<Partial<Agent>>();

  protected agentDbId: string;
  protected selectedAgentIds: string[] = [];
  protected readonly AgentKeys = AgentKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly Messages = Messages;
  readonly inProgress$ = this.conferencePermissionsModalService.inProgress$;
  agentPermissionsFormData: Partial<Agent>;
  isCreateMode = false;
  agentListWithConferencePermission$ = this.conferencePermissionsModalService.agentListWithConferencePermission$;

  constructor(
    private conferencePermissionsModalService: ConferencePermissionsModalService,
    private toastrService: ToastrService,
  ) {}

  showModal = async (selectedAgentIds: string[], data?: Partial<Agent>) => {
    this.isCreateMode = !data;
    this.selectedAgentIds = selectedAgentIds;
    this.agentDbId = data?.[BaseModelKeys.dbId] ?? null;
    this.agentPermissionsFormData = await this.conferencePermissionsModalService.getFormData(data);
    this.conferencePermissionsFormModal.showModal();
  };

  handleSaveClick = e => {
    const validationResults = this.conferencePermissionsFormComponent.instance.validate();
    if (validationResults.isValid) {
      const formData = this.agentPermissionsFormData;
      const changes = Object.assign({
        [BaseModelKeys.dbId]: this.agentDbId,
        [AgentKeys.email_addresses]: formData[AgentKeys.email_addresses],
        [AgentKeys.p_agent_name]: formData[AgentKeys.p_agent_name],
        [AgentKeys.p_agent_first_name]: formData[AgentKeys.p_agent_first_name],
        [AgentKeys.p_agent_last_name]: formData[AgentKeys.p_agent_last_name],
      });
      this.onAfterSaveChanges.emit(changes);
      this.conferencePermissionsModalService.formChangesDetector.clear();
      e.component.instance.hide();
    }
  };

  onAfterRevertChanges = (): void => {
    this.conferencePermissionsFormComponent.instance.repaint();
  };

  handleClosePopup = () => this.conferencePermissionsModalService.onCancelEdit.call(this, this.onAfterRevertChanges);

  valueChange = (event: SelectionChangedEvent) => {
    const agent: Agent = event?.selectedItem;
    const agentName = [agent?.[AgentKeys.p_agent_first_name], agent?.[AgentKeys.p_agent_last_name]]
      .filter(Boolean)
      .join(', ');
    const agentDbId = agent?.[BaseModelKeys.dbId];
    const isUniq = this.checkIsAttendeeUniq(agentDbId);
    if (!isUniq) {
      this.toastrService.error(`Agent "${agentName}" already exist. Adding skipped.`);
      return;
    }
    this.agentDbId = agentDbId;
    this.agentPermissionsFormData[AgentKeys.email_addresses] = agent?.[AgentKeys.email_addresses] || [
      {
        [EmailAddressKeys.address]: agent[AgentKeys.p_email],
        [EmailAddressKeys.isLogin]: true,
      },
    ];
  };

  private checkIsAttendeeUniq = (key: string): boolean => !this.selectedAgentIds?.some(agentKey => agentKey === key);
  protected readonly UserRoleModelKeys = UserRoleModelKeys;
  protected readonly EntityApplications = EntityApplications;
}
