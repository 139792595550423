import { Inject, Injectable } from '@angular/core';
import { PortalData } from 'ag-common-lib/lib/models/utils/portal-data.model';
import { DataService } from './data.service';
import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP } from '../injections/firebase-app';
import { ChangeSourceType } from '@ag-common-lib/public-api';

@Injectable({
  providedIn: 'root',
})
export class PortalDataService extends DataService<PortalData> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, null, null);
    super.setCollection('portal-data');
  }

  public getPortalData(): Promise<PortalData> {
    return this.fsDao.getById(this.collection, 'data');
  }

  public savePortalData(data: PortalData, action: ChangeSourceType | null) {
    return this.fsDao.update(data, 'data', this.collection, action);
  }

  public getNextAgentId(sourceType: ChangeSourceType | null = null): Promise<number> {
    return new Promise(resolve => {
      this.getPortalData().then(
        data => {
          let portalData: PortalData = data;
          let id: number = portalData.agentId;

          portalData.agentId = id + 1;

          this.savePortalData(portalData, sourceType);

          resolve(id);
        },
        err => console.error('Error in Portal Service', err),
      );
    });
  }

  public getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
