import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  Agent,
  AgentKeys,
  AGENT_REVIEW_LEVEL_LOOKUP,
  AGENT_STATUS,
  AGENT_TYPE,
  Lookup,
  PROSPECT_STATUS,
  LookupBase,
  ROLE_LOOKUP,
  ChangeSourceType,
} from '@ag-common-lib/public-api';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { PortalDataService } from 'ag-common-svc/lib/services/portal.service';
import { ListManager } from 'ag-common-svc/public-api';
import { DxFormComponent } from 'devextreme-angular';
import { PortalService } from './portal.service';
import {
  Entity
} from "../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/lists/entity-permission.enum";
import {
  EntityPermissionModelKeys
} from "../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/entity-permission.model";

@Component({
  selector: 'ag-shr-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
  providers: [PortalService],
})
export class PortalComponent {
  // eslint-disable-next-line
  @Input('agent') set _agent(data) {
    this.agent = this.portalService.getFormData(data);
  }
  @Input() canEdit: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() canCreate: boolean = true;
  @Input() sourceType: ChangeSourceType | null = null;
  // eslint-disable-next-line
  @Output() onFieldsUpdated = new EventEmitter<{ agentId: string; updates: Partial<Agent> }>();

  @ViewChild('portalEditorModalRef', { static: true }) portalEditorModalComponent: ModalWindowComponent;
  @ViewChild('portalEditorFormRef', { static: false }) portalEditorFormComponent: DxFormComponent;

  public agent: Partial<Agent>;
  public prospectStatuses: PROSPECT_STATUS[] = [];
  public agentStatuses: AGENT_STATUS[] = [];
  public agentTypes: AGENT_TYPE[] = [];
  inProgress = false;
  inProgress$ = this.portalService.inProgress$;
  isReviewLevelVisible$ = this.portalService.isReviewLevelVisible$;
  rolesLookup: LookupBase[] = ROLE_LOOKUP;

  protected readonly AgentKeys = AgentKeys;
  protected readonly agentReviewLevelLookup: Partial<Lookup>[] = AGENT_REVIEW_LEVEL_LOOKUP;
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;

  constructor(
    listManager: ListManager,
    private portalDataService: PortalDataService,
    private portalService: PortalService,
  ) {
    this.prospectStatuses = listManager.getProspectStatuses();
    this.agentStatuses = listManager.getAgentStatuses();
    this.agentTypes = listManager.getAgentTypes();

    this.rolesLookup = ROLE_LOOKUP;
  }

  showPortalEditorModal = (): void => {
    this.inProgress = false;
    this.portalEditorModalComponent.showModal();
  };

  generateAgentId = (): void => {
    this.inProgress = true;
    this.portalDataService
      .getNextAgentId(this.sourceType)
      .then(id => {
        this.agent.p_agent_id = String(id);
      })
      .catch(error => {
        console.error('Error in Agent Admin.', error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  };

  handelSaveClick = async (): Promise<void> => {
    const results = await this.portalService?.save(this.portalEditorModalComponent, this.sourceType);

    if (results) {
      this.onFieldsUpdated.emit(results);
    }
  };

  handleClosePopup = this.portalService.onCancelEdit;
}
