import { Component, ViewChild } from '@angular/core';
import { DxSchedulerComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { Appointment } from 'ag-common-lib/public-api';
import { AppointmentService } from 'src/app/shared/services/appointment.service';
import { AuthService } from 'ag-common-svc/public-api';
import { firstValueFrom } from 'rxjs';
import { QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/lib/dao/CommonFireStoreDao.dao';

@Component({
  selector: 'app-my-calendar',
  templateUrl: './my-calendar.component.html',
  styleUrls: ['./my-calendar.component.scss'],
})
export class MyCalendarComponent {
  appointmentsDataSource: any = {};

  @ViewChild('scheduler') scheduler: DxSchedulerComponent;

  currentDate: Date = new Date();

  constructor(
    public authService: AuthService,
    public appointmentService: AppointmentService,
  ) {
    this.appointmentsDataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: async function (loadOptions: any) {
        const agent = await firstValueFrom(authService.loggedInAgent$);
        if (agent) {
          let qp: QueryParam[] = [];
          qp.push(new QueryParam('owner', WhereFilterOperandKeys.equal, agent?.p_email));
          return appointmentService.getAllByValue(qp).then(dates => {
            dates.forEach(date => {
              date.startDate = new Date(date.startDate['seconds'] * 1000);
            });

            return dates;
          });
        } else {
          return null;
        }
      },
      insert: async function (value: Appointment) {
        // value.target = session.selectedAgent.p_email; TODO set Target
        const agent = await firstValueFrom(authService.loggedInAgent$);

        value.owner = agent?.p_email;
        return appointmentService.create(value);
      },
      update: function (key: any, value: Appointment) {
        return appointmentService.update(value);
      },
      remove: function (id: any) {
        return appointmentService.delete(id);
      },
      errorHandler: function (error) {
        console.log(error.message);
      },
    });
  }

  onDsChanges(e) {
    this.scheduler.instance.getDataSource().reload();
  }
}
