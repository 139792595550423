import { Injectable } from '@angular/core';
import { AgencyKeys, SupportedCollections } from '@ag-common-lib/public-api';
import { CloudFunctionsService } from '../cloud-functions.service';
import { BaseElasticSearchService } from './base-elastic-search-service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data';
import { PolicyTransactionKeys } from '@ag-common-lib/lib/models/domain/policy-transaction.model';
import { DxFilterOperators } from '@ag-common-lib/lib';
import { PolicyTransactionSummary } from '@ag-common-lib/lib/models/domain/policy-transaction-summaries.model';
import { set } from 'lodash';
import { BehaviorSubject, combineLatest, defer, from, map, shareReplay, tap } from 'rxjs';
import { AgentElasticSearchService } from './agent-elastic-search.service';
import { AgencyService } from '../agency.service';

@Injectable({ providedIn: 'root' })
export class PolicyTransactionSummariesElasticSearchService extends BaseElasticSearchService<PolicyTransactionSummary> {
  private _agentIdSortingProperties$ = new BehaviorSubject({});

  agentIdToNameMap$ = combineLatest({
    portalAgentIdToNameMap: this.agentElasticSearchService.agentIdToNameMap$,
  }).pipe(
    map(({ portalAgentIdToNameMap }) => {
      const map: { [key: string]: string } = {};

      portalAgentIdToNameMap.forEach((description, value) => {
        map[value] = description;
      });

      return map;
    }),
    tap(data => {
      this._agentIdSortingProperties$.next(data);
    }),
    shareReplay(1),
  );

  constructor(
    protected cloudFunctionsService: CloudFunctionsService,
    private agencyService: AgencyService,
    private agentElasticSearchService: AgentElasticSearchService,
  ) {
    super(`${SupportedCollections.policyTransactionsSummaries}-*`, SupportedCollections.policyTransactionsSummaries);

    this.runtimeMappings$ = combineLatest({
      [PolicyTransactionKeys.mgaName]: this.agencyService.agenciesNamesByAgencyId$.pipe(
        map(params => {
          const source = `
            def value = field('mga_id').get('');
            if (params.containsKey(value)) {
              emit(params[value]);
            } else {
              emit(value);
            }
          `.replace(/\n|\s{2,}/gm, '');

          return { source, params };
        }),
      ),
      [PolicyTransactionKeys.agentDisplayName]: this.agentIdToNameMap$.pipe(
        map(params => {
          const source = `
            def agentId = field('agent_id').get('');
            def fileName = field('agent_name').get('');
            if (params.containsKey(agentId)) {
              emit(params[agentId]);
            } else {
              emit(fileName)
            }
          `.replace(/\n|\s{2,}/gm, '');
          return { source, params };
        }),
      ),
    });

    this.resolveRuntimeMappings();

    this.defaultSorting = [{ [PolicyTransactionKeys.weightedPrem]: { order: 'desc', numeric_type: 'double' } }, '_doc'];
  }

  getDataSource = (options?: LoadOptions) => {
    return new DataSource({
      paginate: true,
      pageSize: 50,
      store: this.getStore(options),
    });
  };

  getStore = (options?: LoadOptions) => {
    const ids = {};

    return new CustomStore({
      key: PolicyTransactionKeys.agentId,
      byKey: id => this.getById(id),
      load: async loadOptions => {
        const filter = [options?.filter].filter(Boolean);
        const group = loadOptions?.group;
        const groupSelector = group?.[0]?.selector;

        if (loadOptions.filter) {
          filter?.length && filter.push(DxFilterOperators.and);
          filter.push(loadOptions.filter);
        }

        loadOptions.filter = filter;

        set(loadOptions, ['userData', 'treeGroups'], true);

        const isLoadingAll = (loadOptions as any).isLoadingAll;
        if (isLoadingAll) {
          set(loadOptions, ['userData', 'isLoadingAll'], true);
        }
        set(loadOptions, ['userData', 'treeGroups'], true);

        const skip = loadOptions?.skip;

        if (groupSelector && !!skip) {
          const searchAfter = ids?.[groupSelector]?.[skip - 1];

          searchAfter &&
            set(loadOptions, ['userData', 'searchAfter'], {
              [groupSelector]: ids?.[groupSelector][skip - 1],
            });
        }

        const response = await this.getFromElastic(loadOptions);

        if (groupSelector && !ids?.[groupSelector]) {
          ids[groupSelector] = [];
        }

        if (groupSelector) {
          const keys = response?.data?.map(item => item?.key);
          const itemsToRemove = ids?.[groupSelector]?.length - skip;
          ids?.[groupSelector].splice(skip, itemsToRemove, ...keys);
        }

        return response;
      },
    });
  };
}
