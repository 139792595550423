import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import {
  Agent,
  APPROVE_DENY_REASON_VISIBILITY_LEVEL_LOOKUP,
  ApproveDenyReason,
  ApproveDenyReasonKeys,
  ApproveDenyReasonVisibilityLevel,
  Constants,
  Lookup,
  LookupKeys,
  ChangeSourceType,
} from '@ag-common-lib/public-api';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgentApproveDenyReasonsService } from 'ag-common-svc/lib/services/agent-approve-deny-reason.service';
import { AgentService, AuthService } from 'ag-common-svc/public-api';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ApproveDenyReasonsModalComponent } from '../approve-deny-reasons-modal/approve-deny-reasons-modal.component';
import { ApproveDenyReasonEditorConfig } from './approve-deny-reasons-grid.model';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/lib/dao/CommonFireStoreDao.dao';

@UntilDestroy()
@Component({
  selector: 'ag-shr-approve-deny-reasons-grid',
  templateUrl: './approve-deny-reasons-grid.component.html',
  styleUrls: ['./approve-deny-reasons-grid.component.scss'],
})
export class ApproveDenyReasonsGridComponent {
  @HostBinding('class') className = 'approve-deny-reasons-grid';
  @ViewChild('approveDenyReasonModalRef')
  approveDenyReasonsModalComponent: ApproveDenyReasonsModalComponent;
  @Input() set agentId(dbId: string) {
    this.agentId$.next(dbId);
  }
  @Input() title: string;
  @Input() isEditable: boolean = true;
  @Input() canEdit: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() canCreate: boolean = true;
  @Input() sourceType: ChangeSourceType | null = null;
  @Input() extraToolbarItems = [];
  @Input() editModalOptions: ApproveDenyReasonEditorConfig;
  @Input() set allowedVisibilityLevels(data: ApproveDenyReasonVisibilityLevel[]) {
    if (Array.isArray(data)) {
      this.allowedVisibilityLevels$.next(data);
    }
  }

  public agentsDataSource$: Observable<any>;
  public BaseModelKeys = BaseModelKeys;
  public ApproveDenyReasonKeys = ApproveDenyReasonKeys;
  public approveDenyReasonVisibilityLevelLookup = APPROVE_DENY_REASON_VISIBILITY_LEVEL_LOOKUP;
  public approveDenyReasons$: Observable<DataSource>;

  private loggedInUserEmail: string;
  private loggedInAgentDbId: string;
  private readonly agentId$ = new BehaviorSubject<string>(undefined);
  private allowedVisibilityLevels$ = new BehaviorSubject<ApproveDenyReasonVisibilityLevel[]>([]);
  readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;

  constructor(
    private agentService: AgentService,
    private agentApproveDenyReasonsService: AgentApproveDenyReasonsService,
    private authService: AuthService,
  ) {
    this.authService.currentUser$
      ?.pipe(
        untilDestroyed(this),
        map(user => user?.email),
      )
      .subscribe(loggedInUserEmail => {
        this.loggedInUserEmail = loggedInUserEmail;
      });
    this.authService.loggedInAgent$
      ?.pipe(
        untilDestroyed(this),
        map(agent => agent?.[BaseModelKeys.dbId]),
      )
      .subscribe(loggedInAgentDbId => {
        this.loggedInAgentDbId = loggedInAgentDbId;
      });

    this.approveDenyReasons$ = combineLatest([this.agentId$, this.allowedVisibilityLevels$]).pipe(
      filter(([agentId]) => !!agentId),
      switchMap(([agentId, allowedVisibilityLevels]) => {
        const qp: QueryParam[] = [];
        if (Array.isArray(allowedVisibilityLevels) && allowedVisibilityLevels?.length) {
          const visibilityLevelsQueryParam = new QueryParam(
            ApproveDenyReasonKeys.visibilityLevel,
            WhereFilterOperandKeys.in,
            allowedVisibilityLevels,
          );
          qp.push(visibilityLevelsQueryParam);
        }

        return this.agentApproveDenyReasonsService.getList(agentId, qp);
      }),
      map(approveDenyReasons => {
        return new DataSource({
          store: new ArrayStore({
            key: 'dbId',
            data: Array.isArray(approveDenyReasons) ? approveDenyReasons : [],
          }),
        });
      }),
      shareReplay(1),
    );

    this.agentsDataSource$ = this.agentService.getList().pipe(
      map((response): Partial<Lookup>[] =>
        Array.isArray(response)
          ? response.map((agent: Agent) => {
              const description = [agent?.p_agent_first_name, agent?.p_agent_last_name].filter(Boolean).join(' ');
              const value = agent?.[BaseModelKeys.dbId];

              return {
                key: agent?.dbId,
                [LookupKeys.value]: value,
                [LookupKeys.description]: description,
              };
            })
          : [],
      ),
    );
  }

  public onRowRemoving = e => {
    e.cancel = this.agentApproveDenyReasonsService.delete(this.agentId$.value, e.data[BaseModelKeys.dbId], this.sourceType);
  };

  public showAddApproveDenyReasonPopup = () => {
    this.approveDenyReasonsModalComponent.showModal(
      this.agentId$.value,
      this.editModalOptions?.initialApproveDenyReason as ApproveDenyReason,
    );
  };

  public showEditPopup = ({ row: { data } }) => {
    this.approveDenyReasonsModalComponent.showModal(this.agentId$.value, data);
  };

  public getIsEditingAllowed = ({ row: { data } }) => {
    if (data.isSystem) {
      return false;
    }

    if (this.loggedInUserEmail && this.loggedInUserEmail === data[BaseModelKeys.createdByAgentDbId]) {
      return true;
    }

    if (!!this.loggedInUserEmail && this.loggedInUserEmail === data[BaseModelKeys.createdByEmail]) {
      return true;
    }

    return false;
  };
}
