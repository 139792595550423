import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  from,
  fromEventPattern,
  map,
  mergeMap,
  Observable,
  of,
  shareReplay,
  tap,
} from 'rxjs';
import { AgentService } from './agent.service/agent.service';
import {
  Auth,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
  Persistence,
  getAuth,
} from 'firebase/auth';
import { UserPermissionService } from './user-permissions.service';
import { LoggerService } from './logger.service';
import { Agent, AgentKeys, ChangeSourceType, LogMessage, UserPermission, UserRole } from '@ag-common-lib/public-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgZone } from '@angular/core';
import { UserRolesService } from '../services/user-roles.service';
import { AuthLogService } from './auth-log.service';
import { FirebaseApp } from 'firebase/app';
import { terminate, clearIndexedDbPersistence, Firestore, getFirestore } from 'firebase/firestore';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

export class AuthService {
  protected afterLogoutRedirectPath: string;
  protected homePathCommands: string[];

  readonly currentUser$: Observable<User>;
  readonly loggedInAgent$: Observable<Agent>;
  readonly userPermissions$: Observable<UserPermission[]>;
  readonly userRoles$: Observable<UserRole[]>;

  /**
   * @deprecated Use loggedInAgent$ observable instead
   */
  currentAgent$: BehaviorSubject<Agent> = new BehaviorSubject(undefined);

  protected auth: Auth;
  protected doBeforeLogOut: Array<(agent: Agent) => Promise<any>> = [];

  private db: Firestore;

  constructor(
    fireBaseApp: FirebaseApp,
    protected ngZone: NgZone,
    protected router: Router,
    protected route: ActivatedRoute,
    private toster: ToastrService,
    private agentService: AgentService,
    private loggerService: LoggerService,
    private userPermissionService: UserPermissionService,
    private userRolesService: UserRolesService,
    private authLogService: AuthLogService,
  ) {
    this.db = getFirestore(fireBaseApp);

    this.currentUser$ = fromEventPattern(
      handler => this.auth.onAuthStateChanged(handler),
      (_handler, unsubscribe) => {
        unsubscribe();
      },
    );

    this.loggedInAgent$ = this.currentUser$.pipe(
      mergeMap((user: User) => {
        return user && user?.getIdTokenResult
          ? from(user?.getIdTokenResult(true)).pipe(
              map(idTokenResult => {
                return idTokenResult?.claims?.agentDbId;
              }),
              mergeMap((agentId: string) => {
                if (!agentId) {
                  return of(null);
                }
                return this.agentService.getDocument(agentId).pipe(
                  map(doc => {
                    const data = doc.data();

                    return data;
                  }),
                );
              }),
            )
          : of(null);
      }),
      tap(agent => {
        this.currentAgent$.next(agent);
      }),
      shareReplay(1),
    );

    this.userPermissions$ = this.loggedInAgent$.pipe(
      filter(Boolean),
      mergeMap(agent => {
        return this.userPermissionService.getList(agent[BaseModelKeys.dbId]);
      }),
      shareReplay(1),
    );

    //new object for permissions check
    this.userRoles$ = this.loggedInAgent$.pipe(
      filter(Boolean),
      map(agent => agent[AgentKeys.roles]),
      mergeMap(agentRoles => {
        const agentRolesSet = new Set(agentRoles ?? []);
        return this.userRolesService.getList().pipe(
          map(allUserRoles => allUserRoles.filter(role => agentRolesSet.has(role?.[BaseModelKeys.dbId]))), // get user Roles by names
        );
      }),
      shareReplay(1),
    );
  }

  protected setAuth(fireBaseApp: FirebaseApp) {
    this.auth = getAuth(fireBaseApp);

    this.auth.beforeAuthStateChanged(async user => {
      if (user) {
        return;
      }

      const loggedInAgent = await firstValueFrom(this.loggedInAgent$);
      if (!loggedInAgent) {
        return;
      }

      for (const action of this.doBeforeLogOut) {
        await action(loggedInAgent);
      }

      return;
    });
  }

  async signInWithEmailAndPassword(
    email: string,
    password: string,
    persistance: Persistence = browserSessionPersistence,
  ): Promise<Agent | void> {
    try {
      const userData = await this.signIn(email, password, persistance);

      if (!userData?.user?.emailVerified) {
        await this.logMessage('LOGIN', userData?.user?.email, 'User exists but email not verified. ');

        this.onAgentEmailNotVerified();

        return;
      }

      const idTokenResult = await userData?.user?.getIdTokenResult(true);
      const agentId = idTokenResult?.claims?.agentDbId;

      if (!agentId) {
        const ec = await this.logMessage(
          'LOGIN',
          userData?.user?.email,
          'Google Record: AgentDbId is not assigned to User',
        );

        this.toster.error(
          'An Agent record matching that Email Address could not be found. Please contact Alliance Group for Assistance with this code:' +
            ec,
          'Login Error',
          { disableTimeOut: true },
        );
        await this.logOut();

        return;
      }

      const agent = idTokenResult?.claims?.agent;

      if (!agent) {
        const ec = await this.logMessage(
          'LOGIN',
          userData?.user?.email,
          `Could not find agent record for user ${userData?.user?.email}`,
        );

        this.toster.error(
          'An Agent record matching that Email Address could not be found. Please contact Alliance Group for Assistance with this code:' +
            ec,
          'Login Error',
          { disableTimeOut: true },
        );
        await this.logOut();

        return;
      }

      return agent as Agent;
    } catch (error) {
      debugger;
      switch (error?.code) {
        case 'auth/wrong-password':
          this.logMessage('LOGIN', email, 'You have entered an incorrect password for this email address.', [
            { ...error },
          ]).then(ec => {
            this.toster.error(
              `<br/><p>You have entered an incorrect password for this email address.</p><p>If you have forgotten your password, enter your Email Address and press the "Forgot Password" button.</p><p>If the problem continues, please contact Alliance Group for assistance with this code: <b>${ec}</b></p>`,
              'Login Error',
              { disableTimeOut: true, enableHtml: true },
            );
          });
          break;

        case 'auth/user-not-found':
          this.logMessage('LOGIN', email, 'The email address (' + email + ') is not recognized.', [{ ...error }]).then(
            ec => {
              this.toster.error(
                'The email address (' +
                  email +
                  ') is not recognized. Correct the Email Address and Try again. If the problem continues, please contact Alliance Group for assistance with this code: ' +
                  ec,
                'Login Error',
                { disableTimeOut: true },
              );
            },
          );
          break;

        case 'auth/too-many-requests':
          this.logMessage('LOGIN', email, 'Too many failed attempts. The account is temporarily locked.', [
            { ...error },
          ]).then(ec => {
            this.toster.error(
              'There have been too many failed logins to this account. Please reset your password by going to the login screen, entering your password, and pressing the "Forgot Password" button. If the problem continues, please contact Alliance Group for assistance with this code: ' +
                ec,
              'Login Error',
              { disableTimeOut: true },
            );
          });
          break;

        default:
          this.logMessage(
            'LOGIN',
            email,
            'Unknown Error loggin in with the email address (' + email + '). Check Error details for more information',
            [{ ...error }],
          )
            .then(ec => {
              this.toster.error(
                'There was an Error accessing your account. Please contact Alliance Group for Assistance with this code: ' +
                  ec,
                'Login Error',
                { disableTimeOut: true },
              );
            })
            .catch(e => {
              debugger;
            });
          break;
      }
    }
  }

  logUserIntoPortal = async (actionName: ChangeSourceType): Promise<void> => {
    const agent = await firstValueFrom(this.loggedInAgent$.pipe(filter(Boolean)));
    if (!agent) {
      return;
    }
    const updates = {
      [AgentKeys.login_count]: Number.isInteger(agent?.login_count) ? agent?.login_count + 1 : 1,
      [AgentKeys.last_login_date]: new Date(),
    };

    if (!agent.logged_in) {
      Object.assign(updates, {
        [AgentKeys.logged_in]: true,
        [AgentKeys.first_login_date]: new Date(),
      });
    }

    await Promise.all([
      this.agentService.updateAgentFields(agent?.dbId, updates, actionName),
      this.authLogService.create(),
    ]);
    return;
  };

  navigateForward = () => {
    const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');

    if (returnUrl) {
      return this.ngZone.run(() => {
        return this.router.navigate([returnUrl] /* , { onSameUrlNavigation: 'reload' } */);
      });
    }

    return this.ngZone.run(() => {
      return this.router.navigate(this.homePathCommands);
    });
  };

  async logOut(withRedirect = true) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    try {
      if (withRedirect) {
        await this.router.navigate([this.afterLogoutRedirectPath]);
      }

      await signOut(this.auth);
      await terminate(this.db);
      await clearIndexedDbPersistence(this.db);

      window.location.reload();
    } catch (error) {
      console.error('Error in Auth Service.', error);
    }
  }

  registerUser = (email, password): Promise<UserCredential> => {
    return createUserWithEmailAndPassword(this.auth, email, password);
  };

  addBeforeLogOutAction = (action: (agent: Agent) => Promise<any>): (() => void) => {
    const nexActionIndex = this.doBeforeLogOut.length;

    this.doBeforeLogOut.push(action);

    return () => {
      this.doBeforeLogOut.splice(nexActionIndex, 1);
    };
  };

  private signIn(email, password, persistance: Persistence): Promise<UserCredential> {
    return setPersistence(this.auth, persistance).then(() => {
      let retriesCount = 0;
      const action = () =>
        signInWithEmailAndPassword(this.auth, email, password).catch(e => {
          const errorsCodesToRetry = new Set(['auth/internal-error']);
          if (retriesCount <= 3 && errorsCodesToRetry.has(e?.code)) {
            retriesCount++;
            return action();
          }
          throw e;
        });

      return action();
    });
  }

  protected logMessage(type: string, created_by: string, message: string, data?: any) {
    try {
      let ec = this.generateErrorCode();

      let logMessage: LogMessage = {
        ...new LogMessage(type, created_by, message, ec, data),
      };

      return this.loggerService.create(logMessage).then(() => {
        return ec;
      });
    } catch (err) {
      console.error('error', err);
      return null;
    }
  }

  protected onAgentEmailNotVerified = async () => {};

  navigateHome = () => {
    return this.ngZone.run(() => {
      return this.router.navigate(this.homePathCommands);
    });
  };

  private generateErrorCode() {
    return 'xxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  setAfterLogoutRedirectPath(afterLogoutRedirectPath: string) {
    this.afterLogoutRedirectPath = afterLogoutRedirectPath;
  }
}
