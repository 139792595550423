import { Injectable } from '@angular/core';
import { Association, AssociationKeys } from '@ag-common-lib/public-api';
import { BehaviorSubject, Observable, combineLatest, map, shareReplay } from 'rxjs';
import { set } from 'lodash';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable()
export class SelectAssociationService {
  private _agentAssociations$ = new BehaviorSubject<Association[]>([]);
  agentAssociationDataSource$ = this._agentAssociations$.pipe(
    map(associations => {
      const items = associations?.map(association => association);

      items.push({} as Association);

      return items;
    }),
    shareReplay(1),
  );

  readonly formData$ = new BehaviorSubject<Partial<Association>>(new Association());
  readonly keyExpr$ = new BehaviorSubject<string | ((association: Partial<Association>) => string)>(null);

  selectedAssociationKeys$: Observable<string[]> = combineLatest({
    agentAssociationDataSource: this.agentAssociationDataSource$,
    formData: this.formData$,
  }).pipe(
    map(({ agentAssociationDataSource, formData }) => {
      const formDataKey = this.pickAssociationKey(formData);
      const selectedAssociationKeys = agentAssociationDataSource
        ?.map(association => this.pickAssociationKey(association))
        ?.filter(key => {
          return key === formDataKey;
        });

      if (!selectedAssociationKeys?.length) {
        return [null];
      }

      return selectedAssociationKeys;
    }),
  );

  constructor() {}

  setFormData = (associations: Partial<Association>) => {
    this.formData$.next(associations);
  };

  setKeyExpr = (keyExpr: string | ((association: Partial<Association>) => string)) => {
    this.keyExpr$.next(keyExpr);
  };

  setAssociations = (associations: Association[]) => {
    if (!Array.isArray(associations)) {
      return;
    }
    this._agentAssociations$.next(associations);
  };

  pickAssociationKey = (association: Partial<Association>) => {
    const keyExp = this.keyExpr$.value;

    if (typeof keyExp === 'string') {
      return association?.[keyExp] ?? null;
    }

    if (typeof keyExp === 'function') {
      return keyExp(association) ?? null;
    }

    return association?.[BaseModelKeys.dbId]
      ? [association?.[AssociationKeys.firstName], association?.[AssociationKeys.lastName]]
          .join('')
          .replace(/\s/g, '')
          .toLocaleLowerCase()
      : null;
  };

  updateAssociation = (association: Partial<Association>, propertiesToAssign = null, onAssociationChanged) => {
    const formData = this.formData$.value;
    if (association[BaseModelKeys.dbId] === formData?.[BaseModelKeys.dbId]) {
      return null;
    }

    Object.keys(formData).forEach(key => {
      if (!new Set(Object.values(AssociationKeys)).has(key as any)) {
        return;
      }
      switch (key) {
        case AssociationKeys.dietaryConsideration:
          Object.keys(formData?.[AssociationKeys.dietaryConsideration] ?? {}).forEach(dietaryConsiderationKey => {
            set(formData, [AssociationKeys.dietaryConsideration, dietaryConsiderationKey], null);
          });
          return;
        case AssociationKeys.tShirtSizes:
          Object.keys(formData?.[AssociationKeys.tShirtSizes] ?? {}).forEach(tShirtSizesKey => {
            set(formData, [AssociationKeys.tShirtSizes, tShirtSizesKey], null);
          });
          return;

        default:
          set(formData, key, null);
          return;
      }
    });
    Object.entries(association).forEach(([key, value]) => {
      if (propertiesToAssign && !propertiesToAssign.has(key)) {
        return;
      }
      switch (key) {
        case AssociationKeys.dietaryConsideration:
          Object.entries(association?.[AssociationKeys.dietaryConsideration] ?? {}).forEach(
            ([dietaryConsiderationKey, dietaryConsiderationValue]) => {
              set(formData, [AssociationKeys.dietaryConsideration, dietaryConsiderationKey], dietaryConsiderationValue);
            },
          );
          return;
        case AssociationKeys.tShirtSizes:
          Object.entries(association?.[AssociationKeys.tShirtSizes] ?? {}).forEach(
            ([tShirtSizesKey, tShirtSizesValue]) => {
              set(formData, [AssociationKeys.tShirtSizes, tShirtSizesKey], tShirtSizesValue);
            },
          );
          return;

        default:
          set(formData, key, value);
          return;
      }
    });

    onAssociationChanged && onAssociationChanged(formData, association?.[BaseModelKeys.dbId]);
  };
}
