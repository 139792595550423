import { Pipe, PipeTransform } from '@angular/core';
import {
  changeLogsLookup,
  agentChangeLogsLookup,
  ChangeLogsHistoryType,
  excludedChangeLogsFieldsSet,
} from '@ag-common-lib/public-api';

@Pipe({
  name: 'changeLogFieldsName',
})
export class ChangeLogFieldsNamePipe implements PipeTransform {
  transform(fields: string[], changeLogType: ChangeLogsHistoryType = ChangeLogsHistoryType.registrant) {
    const logsLookup = changeLogType === ChangeLogsHistoryType.registrant ? changeLogsLookup : agentChangeLogsLookup;
    const filteredFields = fields?.filter(field => !excludedChangeLogsFieldsSet.has(field));
    if (!(Array.isArray(filteredFields) && filteredFields?.length) || !logsLookup?.length) {
      return '';
    }
    if (fields?.length && fields[0] === '0') {
      // '0' => remove or create action without data
      return '';
    }
    return filteredFields
      .map(fieldName => {
        const lookup = logsLookup?.find(item => item.value === fieldName.trim());
        return lookup ? `${lookup?.description}` : fieldName;
      })
      .filter(Boolean)
      .join(', ');
  }
}
