import { Inject, Injectable } from '@angular/core';
import { AGENTS_COLLECTION_NAME, AgentDocumentCollections } from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { CommonFireStoreDao, QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';
import { FIREBASE_APP } from '../injections/firebase-app';
import { BaseModel } from '@ag-common-lib/lib/models/base.model';

export enum GridIds {
  agents = 'agents',
}

export enum AgentGridConfigurationKeys {
  agentDbId = 'agentDbId',
  gridId = 'gridId',
  name = 'name',
  configuration = 'configuration',
}

export class AgentGridConfiguration extends BaseModel {
  [AgentGridConfigurationKeys.agentDbId]: string;
  [AgentGridConfigurationKeys.name]: string;
  [AgentGridConfigurationKeys.gridId]: GridIds;
  [AgentGridConfigurationKeys.configuration]?: any;
}

@Injectable()
export class AgentGridsConfigurationsService {
  public readonly fsDao: CommonFireStoreDao<AgentGridConfiguration>;
  private readonly agentCollectionPath = AGENTS_COLLECTION_NAME;
  private readonly agentGridsConfigurationsPath = AgentDocumentCollections.gridsConfigurations;

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    private toastrService: ToastrService,
  ) {
    this.fsDao = new CommonFireStoreDao<AgentGridConfiguration>(fireBaseApp, null, null);
  }

  public getList(agentId: string, gridId: GridIds) {
    const table = this.getCollectionPath(agentId);
    const qp = [new QueryParam(AgentGridConfigurationKeys.gridId, WhereFilterOperandKeys.equal, gridId)];

    return this.fsDao.getList(table, qp);
  }

  public async create(agentId: string, data: AgentGridConfiguration) {
    const table = this.getCollectionPath(agentId);
    const agentGridConfiguration = await this.fsDao
      .create(Object.assign(data, { [AgentGridConfigurationKeys.agentDbId]: agentId }), table)
      .catch(e => {
        console.log('e', e);
      });

    return agentGridConfiguration;
  }

  public async update(agentId: string, documentId: any, updates: Partial<AgentGridConfiguration>) {
    const table = this.getCollectionPath(agentId);

    const agentGridConfiguration = await this.fsDao.updateFields(updates, documentId, table).catch(e => {
      console.log('e', e);
      throw new Error(e);
    });

    return agentGridConfiguration;
  }

  public delete(agentId: string, documentId: any) {
    const table = this.getCollectionPath(agentId);

    return this.fsDao.delete(documentId, table).then(response => {
      this.toastrService.success('Grid Configuration Removed!');
      return response;
    });
  }

  private getCollectionPath(agentId: string) {
    return [this.agentCollectionPath, agentId, this.agentGridsConfigurationsPath].join('/');
  }
}
