import { Injectable } from '@angular/core';
import {
  ActiveLookup,
  Association,
  AssociationKeys,
  DietaryConsideration,
  GuestData,
  GuestKeys,
  LocalDateTimeString,
  TShirtSizes,
} from 'ag-common-lib/public-api';
import { BaseFormService, ConferenceGuestsService, LookupsService } from 'ag-common-svc/public-api';
import { confirm } from 'devextreme/ui/dialog';
import { AttendeeGuestsService } from '../attendee-guests.service';
import { BehaviorSubject, combineLatest, firstValueFrom, map, mergeMap, Observable } from 'rxjs';
import { ValidationCallbackData } from 'devextreme/common';
import { differenceInYears, isValid, toDate } from 'date-fns';
import { AgentAssociationsService } from 'ag-common-svc/lib/services/agent-associations.service';
import { SuffixPipe } from 'ag-common-svc/shared/pipes/suffix.pipe';
import { filter, shareReplay } from 'rxjs/operators';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Injectable()
export class AttendeeGuestModalService extends BaseFormService<GuestData> {
  agentAssociations$: Observable<Association[]>;
  conferenceGuestExcludedSections$ = this.attendeeGuestsService.conferenceGuestExcludedSections$;

  private _conferenceId: string;
  private _registrantId: string;

  private _guestDbId$ = new BehaviorSubject<string>(null);

  private _suffixesLookup: ActiveLookup[];

  constructor(
    private suffixPipe: SuffixPipe,
    private conferenceGuestsService: ConferenceGuestsService,
    private attendeeGuestsService: AttendeeGuestsService,
    private agentAssociationsService: AgentAssociationsService,
    private lookupsService: LookupsService,
  ) {
    super();

    this.lookupsService.suffixesLookup$.subscribe(suffixes => (this._suffixesLookup = suffixes));

    const agentAssociations$ = this.attendeeGuestsService.registrantDbId$.pipe(
      filter(Boolean),
      mergeMap((registrantDbId: string) => {
        return this.agentAssociationsService.getList(registrantDbId);
      }),
    );

    this.agentAssociations$ = combineLatest({
      agentAssociations: agentAssociations$,
      registrantGuestDbI: this._guestDbId$,
      registrantGuests: this.attendeeGuestsService.registrantGuests$,
    }).pipe(
      map(({ agentAssociations, registrantGuestDbI, registrantGuests }) => {
        const assignedAssociations = new Set();

        registrantGuests?.forEach(registrantGuest => {
          if (registrantGuest?.[BaseModelKeys.dbId] === registrantGuestDbI) {
            return;
          }

          assignedAssociations.add(this.getAssociationKey(registrantGuest));
        });
        const items = [];

        agentAssociations?.forEach(association => {
          const associationKey = this.getAssociationKey(association);

          if (assignedAssociations.has(associationKey)) {
            return;
          }

          items.push(association);
        });

        return items;
      }),
      shareReplay(1),
    );
  }

  saveGuest = () => (this.formData[BaseModelKeys.dbId] ? this.updateGuest() : this.createGuest());

  cancelEdit = ({ event, component }): void => {
    if (!this.formChangesDetector?.hasChanges) {
      return;
    }

    event.cancel = true;

    const result = confirm('<i>Are you sure you want to Cancel without Saving?</i>', 'Confirm');
    result.then(dialogResult => {
      if (dialogResult) {
        this.formChangesDetector?.clear();
        component.instance.hide();
      }
    });
  };

  getFormData = async (conferenceId: string, registrantId: string, guest?: Partial<GuestData>): Promise<GuestData> => {
    this._conferenceId = conferenceId;
    this._registrantId = registrantId;
    this._guestDbId$.next(guest?.dbId);

    const initialData = Object.assign(
      {
        [GuestKeys.isComplimentary]: false,
      },
      new GuestData(),
      guest,
    );
    const tShirtsSizesProxy = this.prepareTShirtsSizesProxy(guest?.[AssociationKeys.tShirtSizes]);
    const dietaryConsiderationProxy = this.prepareDietaryConsiderationProxy(
      guest?.[AssociationKeys.dietaryConsideration],
    );
    this.formData = new Proxy(
      Object.assign({}, initialData, {
        [AssociationKeys.tShirtSizes]: tShirtsSizesProxy,
        [AssociationKeys.dietaryConsideration]: dietaryConsiderationProxy,
      }),
      {
        set: (target, prop, value, receiver) => {
          const prevValue = target[prop];
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);

          return true;
        },
      },
    );

    return this.formData;
  };

  onAssociationChanged = (association: Partial<Association>) => {
    const associationSuffixId = association?.[AssociationKeys.suffix];
    const associationFirstName = association?.[AssociationKeys.firstName];
    const associationLastName = association?.[AssociationKeys.lastName];
    const associationDbId = association?.[BaseModelKeys.dbId];
    const [suffix] =
      this._suffixesLookup.find(({ [BaseModelKeys.dbId]: dbId }) => dbId === associationSuffixId)?.description ?? '';
    const associationBadgeName = [associationFirstName, associationLastName, suffix].filter(Boolean).join(' ');

    Object.assign(this.formData, {
      [GuestKeys.associationDbId]: associationDbId,
      [GuestKeys.badgeName]: associationBadgeName,
    });
  };

  getAssociationKey = (association: Partial<GuestData>) => {
    const associationDbId = association?.[GuestKeys.associationDbId];

    if (associationDbId) {
      return associationDbId;
    }

    return association?.[BaseModelKeys.dbId] ?? null;
  };

  private updateGuest = () => {
    this.startProgress();
    return this.conferenceGuestsService
      .update(this._conferenceId, this._registrantId, this.formData[BaseModelKeys.dbId], this.formData)
      .then(() => {
        this.formChangesDetector.clear();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  private createGuest = () => {
    this.startProgress();
    return this.conferenceGuestsService
      .create(this._conferenceId, this._registrantId, this.formData)
      .then(() => {
        this.formChangesDetector.clear();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  hotelRulesValidationCallback = async (e: ValidationCallbackData) => {
    const currentGuestId = this.formData?.[BaseModelKeys.dbId];
    const guests = await firstValueFrom(this.attendeeGuestsService.registrantGuests$);

    if (guests?.length < 3) {
      return true;
    }

    const { numberOfAdults, numberOfChildren } = guests.reduce(
      (acc, guest) => {
        const dob = guest?.[BaseModelKeys.dbId] === currentGuestId ? e.value : guest?.[AssociationKeys.dob];
        const age = this.calculateAge(dob);

        if (!age) {
          return acc;
        }

        if (age >= 13) {
          acc.numberOfAdults += 1;
          return acc;
        }

        if (age >= 2) {
          acc.numberOfChildren += 1;
          return acc;
        }

        return acc;
      },
      {
        numberOfAdults: 1, // Registrant is adult always
        numberOfChildren: 0,
      },
    );

    return (numberOfAdults <= 2 && numberOfChildren <= 2) || (numberOfAdults <= 3 && numberOfChildren <= 1);
  };

  calculateAge = (birthDate: LocalDateTimeString): number => {
    if (!isValid(toDate(birthDate))) {
      return null;
    }

    return differenceInYears(new Date(), birthDate);
  };

  private prepareDietaryConsiderationProxy = (dietaryConsideration: Partial<DietaryConsideration>) => {
    return new Proxy(Object.assign({}, dietaryConsideration), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange(
            [AssociationKeys.dietaryConsideration, prop].join('.'),
            value,
            prevValue,
          );
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });
  };

  private prepareTShirtsSizesProxy = (tShirtsSizes: Partial<TShirtSizes>) => {
    return new Proxy(Object.assign({}, tShirtsSizes), {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange([AssociationKeys.tShirtSizes, prop].join('.'), value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });
  };
}
