<div #changeLogContainerRef>
  <dx-data-grid
    #changeLogGridRef
    class="ag-changes-history__grid mb-3"
    [dataSource]="dataSource"
    [showBorders]="false"
    [remoteOperations]="true"
    [syncLookupFilterValues]="false"
    [columnAutoWidth]="true"
    [scrollLeft]="true"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [focusedRowEnabled]="false"
    [focusedColumnIndex]="0"
    [autoNavigateToFocusedRow]="false"
    height="100%"
    (onRowPrepared)="onRowPrepared($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
  >
    <dxo-header-filter [visible]="false"></dxo-header-filter>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-builder [allowHierarchicalFields]="true"></dxo-filter-builder>
    <dxo-filter-builder-popup></dxo-filter-builder-popup>
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>

    <dxo-toolbar>
      <dxi-item name="searchPanel"></dxi-item>

      <dxi-item location="after">
        <div *dxTemplate>
          <button
            type="button"
            class="btn btn-default waves-effect"
            data-toggle="fullscreen"
            (click)="fullscreen(changeLogContainerRef)"
          >
            <i class="dx-icon dx-icon-agi-up-right-and-down-left-from-center"></i>
          </button>
        </div>
      </dxi-item>
    </dxo-toolbar>

    <dxi-column dataField="date" dataType="datetime" [format]="dateFormat" [sortOrder]="'desc'"></dxi-column>

    <dxi-column
      dataField="collection"
      caption="Collection"
      [allowFiltering]="true"
      [visible]="false"
      [filterValues]="collectionFilterValues"
    ></dxi-column>

    <dxi-column dataField="action" [visible]="changeLogType !== ChangeLogsHistoryType.agent"></dxi-column>

    <dxi-column dataField="updatedBy" caption="Who Change" [allowFiltering]="true" [allowSearch]="true" [minWidth]="200">
      <dxo-lookup
        [dataSource]="agentsLookup$ | async"
        [allowClearing]="true"
        [displayExpr]="LookupKeys.description"
        [valueExpr]="LookupKeys.value"
      >
      </dxo-lookup>
    </dxi-column>

    <dxi-column
      dataField="changedBySource"
      caption="Change Source"
      [width]="200"
      [allowFiltering]="true"
      [allowSearch]="true"
    >
      <dxo-lookup
        [dataSource]="changeSourceTypeLookup"
        [valueExpr]="LookupKeys.value"
        [displayExpr]="LookupKeys.description"
        [allowClearing]="true"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      dataField="updatedFields"
      caption="Updated Fields"
      dataType="string"
      cellTemplate="updatedFieldsCellTemplate"
      [filterOperations]="['contains']"
      [calculateFilterExpression]="updatedFieldsCalcFilterExpr"
    ></dxi-column>

    <dxi-column
      dataField="wasabiPath"
      caption="Wasabi Data"
      [allowFiltering]="true"
      [visible]="false"
      [filterValues]="wasabiPathFilterValues"
    ></dxi-column>

    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
    <div *dxTemplate="let masterDetails of 'detail'">
      <ng-container *ngIf="masterDetails.data as data">
        <!-- Agent type-->
        <ng-container *var="data.documentIds?.agentDbId as agentDbId">
          <ng-container *ngIf="data.wasabiPath | wasabiFile | changeLogsDiff: !!agentDbId | async as changeLogs">
            <!-- Email Address-->
            <ng-container
              *ngIf="data?.updatedFields[0] === LoggedCollections.agentEmailAddress; else defaultAgentChangeLog"
            >
              <ng-container [ngSwitch]="data.action">
                <ng-container *ngSwitchCase="DocumentWriteAction.remove">
                  <p class="fw-bold">Email Address "{{ changeLogs[0]?.[EmailAddressKeys.address] }}" was removed.</p>
                </ng-container>

                <ng-container *ngSwitchCase="DocumentWriteAction.create">
                  <p class="fw-bold">Email Address "{{ changeLogs[1]?.[EmailAddressKeys.address] }}" was created.</p>
                  <ag-crm-agent-info-change-log
                    *ngIf="getChangedLog(changeLogs) | logsByKey: EMAIL_ADDRESS_SEARCH_KEYS_CONFIG as logs"
                    [changeLogs]="logs"
                  ></ag-crm-agent-info-change-log>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <p class="fw-bold">Email Address was updated.</p>
                  <ag-crm-agent-info-change-log
                    *ngIf="changeLogs | logsByKey: EMAIL_ADDRESS_SEARCH_KEYS_CONFIG as logs"
                    [changeLogs]="logs"
                  ></ag-crm-agent-info-change-log>
                </ng-container>
              </ng-container>
            </ng-container>
            <!--Agent-->
            <ng-template #defaultAgentChangeLog>
              <ng-container [ngSwitch]="data.action">
                <ng-container *ngSwitchCase="DocumentWriteAction.remove">
                  <p class="fw-bold">
                    Agent "{{
                      [changeLogs[0]?.[AgentKeys.p_agent_first_name], changeLogs[0]?.[AgentKeys.p_agent_last_name]]
                        | fullName
                    }}" was removed.
                  </p>
                </ng-container>

                <ng-container *ngSwitchCase="DocumentWriteAction.create">
                  <p class="fw-bold">
                    Agent "{{
                      [changeLogs[1]?.[AgentKeys.p_agent_first_name], changeLogs[1]?.[AgentKeys.p_agent_last_name]]
                        | fullName
                    }}" was created.
                  </p>
                  <ag-crm-agent-info-change-log
                    *ngIf="getChangedLog(changeLogs) | logsByKey: AGENT_SEARCH_KEYS_CONFIG as logs"
                    [changeLogs]="logs"
                  ></ag-crm-agent-info-change-log>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <p class="fw-bold">Agent was updated.</p>
                  <ag-crm-agent-info-change-log
                    *ngIf="changeLogs | logsByKey: AGENT_SEARCH_KEYS_CONFIG as logs"
                    [changeLogs]="logs"
                  ></ag-crm-agent-info-change-log>
                </ng-container>
              </ng-container>
            </ng-template>
          </ng-container>
        </ng-container>
        <!-- Registrant type-->
        <ng-container *var="data.documentIds?.registrantGuestDbId as guestId">
          <ng-container *ngIf="data.wasabiPath | wasabiFile | changeLogsDiff: !!guestId | async as changeLogs">
            <ng-container *ngIf="!guestId; else guestsLogs">
              <ag-shr-rsvp-change-log
                *ngIf="changeLogs | logsByKey: RSVP_SEARCH_KEYS_CONFIG as logs"
                [changeLogs]="logs"
              ></ag-shr-rsvp-change-log>

              <ag-shr-personal-info-change-log
                *ngIf="changeLogs | logsByKey: PERSONAL_INFO_SEARCH_KEYS_CONFIG as logs"
                [changeLogs]="logs"
              ></ag-shr-personal-info-change-log>

              <ag-shr-excursions-change-log
                *ngIf="changeLogs?.[RegistrantKeys.selectedExcursions] as logs"
                [changeLogs]="getChangedLog(logs)"
                [attendeeName]="registrantDbId | lookupDescriptionText: (agentsLookup$ | async)"
              ></ag-shr-excursions-change-log>

              <ag-shr-flight-info-change-log
                *ngIf="changeLogs?.[RegistrantKeys.flightInformation] as logs"
                [changeLogs]="getChangedLog(logs)"
                [agentsLookup]="agentsLookup$ | async"
                [attendeeName]="registrantDbId | lookupDescriptionText: (agentsLookup$ | async)"
              ></ag-shr-flight-info-change-log>

              <ag-shr-hotel-reservation-change-log
                *ngIf="changeLogs?.[RegistrantKeys.hotelReservation] as logs"
                [changeLogs]="getChangedLog(logs)"
                [agentsLookup]="agentsLookup$ | async"
                [attendeeName]="registrantDbId | lookupDescriptionText: (agentsLookup$ | async)"
              ></ag-shr-hotel-reservation-change-log>
            </ng-container>

            <ng-template #guestsLogs>
              <ng-container *var="guestId | lookupDescriptionText: (registrantGuestsLookup$ | async) as guestName">
                <ag-shr-guests-change-log
                  [action]="data.action"
                  [guestName]="guestName"
                  [changeLogs]="getChangedLog(changeLogs) | logsByKey: GUESTS_SEARCH_KEYS_CONFIG"
                ></ag-shr-guests-change-log>

                <ng-container *ngIf="changeLogs?.[GuestKeys.selectedExcursions] as logs">
                  <ag-shr-excursions-change-log
                    *ngIf="hasSelectedExcursion(logs)"
                    [changeLogs]="getChangedLog(logs)"
                    [attendeeName]="guestName"
                  ></ag-shr-excursions-change-log>
                </ng-container>

                <ng-container *ngIf="changeLogs?.[GuestKeys.flightInformation] as logs">
                  <ag-shr-flight-info-change-log
                    [changeLogs]="getChangedLog(logs)"
                    [agentsLookup]="registrantGuestsLookup$ | async"
                    [attendeeName]="guestName"
                  ></ag-shr-flight-info-change-log>
                </ng-container>
              </ng-container>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <!-- Templates -->
    <div *dxTemplate="let cell of 'updatedFieldsCellTemplate'">
      <ng-container *ngFor="let item of [cell.value]">
        <p class="ag-changes-history__grid-row text-wrap" *var="item | changeLogFieldsName: changeLogType as fields">
          <span [innerHTML]="fields | highlightFilterSearchTerm: (filterPanelText$ | async)"></span>
        </p>
      </ng-container>
    </div>
  </dx-data-grid>
</div>

<!-- Not logged with logAgentWrite: -->
<!-- Associations, Status Activity, Need To Knows, Permissions, Media, Campaign -->
