import { Component, ViewChild } from '@angular/core';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { NotificationSchedulerEditModalService } from './notification-scheduler-edit-modal.service';
import { PushNotificationCampaign, PushNotificationCampaignKeys, PushNotificationKeys } from '@ag-common-lib/lib';
import { Constants, Entity, EntityPermissionActivityKeys } from '@ag-common-lib/public-api';
import { PushNotificationsCampaignService, ScheduledPushNotificationsService } from 'ag-common-svc/public-api';
import { DxListComponent } from 'devextreme-angular/ui/list';
import { CustomItemCreatingEvent } from 'devextreme/ui/select_box';
import { NotificationAddRecipientsModalComponent } from '../../notification-add-recipients-modal/notification-add-recipients-modal.component';
import { NotificationNotificationChannelModalComponent } from '../../notification-notification-channel-modal/notification-notification-channel-modal.component';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { CloudFunctionsService } from 'ag-common-svc/lib/services/cloud-functions.service';
import { NotificationAddSegmentsModalComponent } from '../../dynamic-segments-group/notification-add-segments-modal/notification-add-segments-modal.component';
import { NotificationAddListsModalComponent } from '../../dynamic-lists-group/notification-add-lists-modal/notification-add-lists-modal.component';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-notification-scheduler-edit-modal',
  templateUrl: './notification-scheduler-edit-modal.component.html',
  styleUrls: ['./notification-scheduler-edit-modal.component.scss'],
  providers: [NotificationSchedulerEditModalService, ScheduledPushNotificationsService],
})
export class NotificationSchedulerEditModalComponent {
  @ViewChild('notificationSchedulerEditModalRef', { static: false })
  protected notificationSchedulerEditModalComponent: ModalWindowComponent;

  @ViewChild('addRecipientsModalRef', { static: false })
  protected notificationAddRecipientsModalComponent: NotificationAddRecipientsModalComponent;

  @ViewChild('notificationNotificationChannelModalRef', { static: false })
  protected notificationNotificationChannelModalComponent: NotificationNotificationChannelModalComponent;

  @ViewChild('notificationAddSegmentGroupModalRef', { static: false })
  protected notificationAddSegmentGroupModalComponent: NotificationAddSegmentsModalComponent;

  @ViewChild('notificationAddListsModalRef', { static: false })
  protected notificationAddListsModalComponent: NotificationAddListsModalComponent;

  @ViewChild(DxListComponent, { static: false }) listComponent: DxListComponent;

  isCheckedRecipientsListVisible = false;
  protected inProgress$ = this.notificationSchedulerEditModalService.inProgress$;
  protected Entity = Entity;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected BaseModelKeys = BaseModelKeys;
  protected PushNotificationKeys = PushNotificationKeys;
  protected PushNotificationCampaignKeys = PushNotificationCampaignKeys;
  protected validationGroup = 'validationGroup';
  protected scheduledNotification: PushNotificationCampaign;
  protected Constants = Constants;
  protected dateNow = new Date();
  protected notificationsDataSource$ = this.pushNotificationsCampaignService.getList();
  protected isDeliveredNotification$ = this.notificationSchedulerEditModalService.isDeliveredNotification$;

  constructor(
    private cloudFunctionsService: CloudFunctionsService,
    protected notificationSchedulerEditModalService: NotificationSchedulerEditModalService,
    private pushNotificationsCampaignService: PushNotificationsCampaignService,
  ) {}

  showModal = async (pushNotificationCampaign: PushNotificationCampaign): Promise<void> => {
    this.scheduledNotification = this.notificationSchedulerEditModalService.getFormData(pushNotificationCampaign);
    this.notificationSchedulerEditModalComponent?.showModal();
  };

  protected handleScheduleNotification = async () => {
    const isValid = await validateDxGroups(this.validationGroup);
    if (isValid) {
      await this.notificationSchedulerEditModalService.handleSave();
      this.notificationSchedulerEditModalComponent.forceCloseModal();
    }
  };

  protected sendNotification = async () => {
    await this.cloudFunctionsService.sendPushNotification(this.scheduledNotification);
  };

  protected cloneNotification = this.notificationSchedulerEditModalService.cloneNotification;

  protected toggleCheckNotification = e => {
    this.isCheckedRecipientsListVisible = !this.isCheckedRecipientsListVisible;
  };

  protected onValueChanged = e => {
    const dataToCopy = [
      PushNotificationCampaignKeys.title,
      PushNotificationCampaignKeys.description,
      PushNotificationCampaignKeys.notification,
    ].reduce((acc, item) => {
      acc[item] = e[item] ?? null;
      return acc;
    }, {});
    Object.assign(this.scheduledNotification, dataToCopy);
  };

  protected onCustomItemCreating = (e: CustomItemCreatingEvent, notificationSelectBoxRef) => {
    const title = e?.text;

    if (!title) {
      Object.assign(e, { customItem: null });
      return;
    }

    const customItem = { [PushNotificationCampaignKeys.title]: title };
    [PushNotificationCampaignKeys.description, PushNotificationCampaignKeys.notification].forEach(key => {
      customItem[key] = this.scheduledNotification?.[key] ?? null;
    });

    Object.assign(e, { customItem });

    setTimeout(() => {
      notificationSelectBoxRef.selectedItem = customItem;
    });
  };

  protected showAddRecipientsModal = () => {
    this.notificationAddRecipientsModalComponent.showModal();
  };

  protected showAddChannelModal = () => {
    this.notificationNotificationChannelModalComponent.showModal();
  };

  protected showAddDynamicListsGroupModal = () => {
    this.notificationAddListsModalComponent.showModal(
      this.scheduledNotification?.[PushNotificationCampaignKeys.dynamicListsIds] ?? [],
    );
  };

  protected showAddDynamicSegmentModal = () => {
    this.notificationAddSegmentGroupModalComponent.showModal(
      this.scheduledNotification?.[PushNotificationCampaignKeys.dynamicSegmentsIds] ?? [],
    );
  };

  protected handleAddRecipients = newRecipients => {
    const recipientsSet = new Set(this.scheduledNotification?.[PushNotificationCampaignKeys.recipients] ?? []);
    newRecipients?.forEach(recipient => {
      recipientsSet.add(recipient);
    });

    this.scheduledNotification[PushNotificationCampaignKeys.recipients] = Array.from(recipientsSet);
  };

  protected handleAddChannel = (channel: string) => {
    const notificationChannels = this.scheduledNotification?.[PushNotificationCampaignKeys.channels] ?? [];

    this.scheduledNotification[PushNotificationCampaignKeys.channels] = [...notificationChannels, channel];
  };

  protected handleSegments = (segments: string[]) => {
    const notificationSegments = this.scheduledNotification?.[PushNotificationCampaignKeys.dynamicSegmentsIds] ?? [];

    this.scheduledNotification[PushNotificationCampaignKeys.dynamicSegmentsIds] = [
      ...notificationSegments,
      ...segments,
    ];
  };

  protected handleLists = (lists: string[]) => {
    const notificationLists = this.scheduledNotification?.[PushNotificationCampaignKeys.dynamicListsIds] ?? [];

    this.scheduledNotification[PushNotificationCampaignKeys.dynamicListsIds] = [...notificationLists, ...lists];
  };

  protected deleteSegment = e => {
    const id = e.itemData.dbId;
    const notificationSegments = this.scheduledNotification?.[PushNotificationCampaignKeys.dynamicSegmentsIds] ?? [];
    this.scheduledNotification[PushNotificationCampaignKeys.dynamicSegmentsIds] = notificationSegments.filter(
      segmentId => segmentId !== id,
    );
  };

  protected deleteList = e => {
    const id = e.itemData.dbId;
    const notificationLists = this.scheduledNotification?.[PushNotificationCampaignKeys.dynamicListsIds] ?? [];
    this.scheduledNotification[PushNotificationCampaignKeys.dynamicListsIds] = notificationLists.filter(
      listId => listId !== id,
    );
  };

  protected deleteRecipient = e => {
    const id = e.itemData.dbId;
    const notificationRecipients = this.scheduledNotification?.[PushNotificationCampaignKeys.recipients] ?? [];
    this.scheduledNotification[PushNotificationCampaignKeys.recipients] = notificationRecipients.filter(
      recipientId => recipientId !== id,
    );
  };
}
