import { Injectable } from '@angular/core';
import { CloudFunctionsService } from '../services/cloud-functions.service';
import { WasabiImgPipe } from '../../shared/pipes/wasabi-img.pipe';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ConferenceService } from './conference.service';
import { stringFromDate } from '../utils/string-from-date';
import { isEmpty } from 'lodash';
import {
  RegistrationParticipationEmail,
  RegistrationParticipationEmailKeys,
} from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/functions/registration-participation-email.model';
import { EventInquiryRequestsService } from './event-inquire-requests.service';
import { EventInquiryRequestKeys } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/registration/event-inquiry-request.model';
import { getFullAddress } from '../../shared/utils/full-address';
import { AgentService } from './agent.service';
import { AgentKeys } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/domain/agent.model';
import {
  AGENT_STATUS,
  ConferenceKeys,
  EmailActionTrigger,
  EmailTemplateConfigurationKeys,
  Headshot,
  MediaSize,
  NotifyEmailKeys,
} from '@ag-common-lib/public-api';

class SendParticipationEmailPayload {
  isTestEmail?: boolean;
  emailRecipientTestMode?: string[];
  eventInquiryDbId: string;
  eventInquiryRequestDbId: string;
  emailActionTrigger: EmailActionTrigger;
}

@Injectable()
export class RegistrationParticipationEmailService {
  constructor(
    private cloudFunctionsService: CloudFunctionsService,
    private wasabiImgPipe: WasabiImgPipe,
    private toastrService: ToastrService,
    private conferenceService: ConferenceService,
    private eventInquiryRequestsService: EventInquiryRequestsService,
    private agentService: AgentService,
  ) {}

  async sendRegistrationParticipationEmail(payload: SendParticipationEmailPayload): Promise<void> {
    const summaryEmail = await this.generateRegistrationParticipationEmail(payload);

    await this.cloudFunctionsService
      .sendConferenceRegistrationSummary(summaryEmail)
      .then(() => {
        this.toastrService.success('Participation Status was sent via email!');
      })
      .catch(error => {
        console.log('Email Error: ', error);
        this.toastrService.error('Participation Status was not sent via email!');
      });
  }

  generateRegistrationParticipationEmail = async (
    payload: SendParticipationEmailPayload,
  ): Promise<RegistrationParticipationEmail> => {
    const { eventInquiryDbId, eventInquiryRequestDbId, emailActionTrigger, isTestEmail = false } = payload;
    const conference = await firstValueFrom(this.conferenceService.getDocumentData(eventInquiryDbId));
    const emailTemplates = conference?.[ConferenceKeys.emailTemplates];
    const wasabiImagePath = conference?.[ConferenceKeys.welcomeImagePath] ?? null;
    const bccEmail = conference?.[ConferenceKeys.bccEmail];
    const eventCoordinatorEmail = conference?.[ConferenceKeys.eventCoordinatorEmail] ?? 'events@alliancegrouplife.com';
    const emailTemplateConfiguration = emailTemplates?.find(
      template => template?.[EmailTemplateConfigurationKeys.trigger] === emailActionTrigger,
    );
    const defaultSubject = emailTemplateConfiguration?.[EmailTemplateConfigurationKeys.subject];
    const eventRequestData = await firstValueFrom(
      this.eventInquiryRequestsService.getData(eventInquiryDbId, eventInquiryRequestDbId),
    );
    const agentData = await this.agentService.getById(eventRequestData[EventInquiryRequestKeys.agentDbId]);
    const agentPrimaryEmail = agentData?.[AgentKeys.p_email];
    const agentAuthEmail = agentData?.[AgentKeys.p_email];
    const registrationEndDate = conference?.[ConferenceKeys.registrationEndDate];
    const eventInquiryEmail = eventRequestData?.[EventInquiryRequestKeys.email];
    const agentStatus = agentData?.[AgentKeys.agent_status];
    const isNewUser = agentStatus === AGENT_STATUS.NEW_REGISTRATION;
    const isAgentNotWelcome = new Set([AGENT_STATUS.INACTIVE, AGENT_STATUS.DENIED]).has(agentStatus);
    const isMergedUser = agentPrimaryEmail?.toLowerCase() !== eventInquiryEmail?.toLowerCase();
    const to = [eventInquiryEmail];
    if (isMergedUser) {
      to.push(agentPrimaryEmail);
    }

    const contextCommonData = {
      [NotifyEmailKeys.imagePath]: null,
      [NotifyEmailKeys.firstName]: eventRequestData[EventInquiryRequestKeys.firstName],
      [NotifyEmailKeys.lastName]: eventRequestData[EventInquiryRequestKeys.lastName],
      [NotifyEmailKeys.replyTo]: eventCoordinatorEmail,
      [NotifyEmailKeys.summaryEmailTemplate]: emailTemplateConfiguration?.[EmailTemplateConfigurationKeys.templateName],
      [RegistrationParticipationEmailKeys.isMergedAgent]: isMergedUser,
      [RegistrationParticipationEmailKeys.isAgentNotWelcome]: isAgentNotWelcome,
      [RegistrationParticipationEmailKeys.isNewAgent]: !isMergedUser && isNewUser,
      [RegistrationParticipationEmailKeys.isExistingAgent]: !isMergedUser && !isNewUser,
      [RegistrationParticipationEmailKeys.registrationEndDate]: stringFromDate(registrationEndDate),
      [RegistrationParticipationEmailKeys.agentAuthEmail]: agentAuthEmail,
      [RegistrationParticipationEmailKeys.eventName]:
        conference?.[ConferenceKeys.eventName] ?? 'Alliance Group Winter Summit',
      [RegistrationParticipationEmailKeys.hotelAddress]:
        getFullAddress(conference?.[ConferenceKeys.hotelAddress]) ?? '-',
      [RegistrationParticipationEmailKeys.hotelPhoneNumber]:
        conference?.[ConferenceKeys.hotelPrimaryPhone] ?? '8153389668',
      [RegistrationParticipationEmailKeys.coordinatorEmail]: isEmpty(conference?.[ConferenceKeys.eventCoordinatorEmail])
        ? 'events@alliancegrouplife.com'
        : conference?.[ConferenceKeys.eventCoordinatorEmail],
      [RegistrationParticipationEmailKeys.coordinatorPrimaryPhoneNumber]: isEmpty(
        conference?.[ConferenceKeys.eventCoordinatorPhone],
      )
        ? '8153389668'
        : conference?.[ConferenceKeys.eventCoordinatorPhone],
    };

    if (wasabiImagePath) {
      contextCommonData[NotifyEmailKeys.imagePath] = this.getImagePath(wasabiImagePath);
    }

    if (isTestEmail) {
      const emailRecipientTestMode = payload?.emailRecipientTestMode;
      let testModeSubject = `AG_MAILING_TEST ${defaultSubject} `;

      to?.forEach(senTo => {
        testModeSubject += `\n to ${senTo}`;
      });

      return Object.assign(contextCommonData, {
        [NotifyEmailKeys.email]: emailRecipientTestMode,
        [NotifyEmailKeys.subjectTitle]: testModeSubject,
      });
    }

    return Object.assign(contextCommonData, {
      [NotifyEmailKeys.email]: to,
      [NotifyEmailKeys.subjectTitle]: defaultSubject,
      [NotifyEmailKeys.bccEmail]: bccEmail,
    });
  };

  private getImagePath(wasabiImage: Headshot): string {
    const url = this.wasabiImgPipe.transform(
      wasabiImage?.wasabiPath,
      MediaSize.medium,
      true,
      wasabiImage?.imageCropperPayload ?? null,
    );
    return url.substring(0, url.lastIndexOf('/'));
  }
}
