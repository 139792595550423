import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ActiveLookup, LookupKeys, Messages, Social, SocialKeys } from '@ag-common-lib/public-api';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { SocialsService } from '../socials.service';
import { validateUrlWithOrWithoutProtocol } from 'ag-common-svc/lib/utils/validation';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-socials-grid',
  templateUrl: './socials-grid.component.html',
  styleUrls: ['./socials-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialsGridComponent {
  @HostBinding('class') className = 'socials-grid';

  @Input() isEdit: boolean = false;
  @Input() allowUpdating: boolean = true;
  @Input() allowDeleting: boolean = true;
  @Input() allowAdding: boolean = true;
  @Input() selectionMode: 'none' | 'multiple' = 'none';
  @Input() socials: Social[] = [];
  @Input()
  set socialTypesLookup(items: ActiveLookup[]) {
    this._socialTypesLookup = items;
    this._defaultSocialTypeLookup = items?.find(item => item?.isDefault);
  }
  get socialTypesLookup(): ActiveLookup[] {
    return this._socialTypesLookup;
  }
  @Input() socialMediaLookup: ActiveLookup[] = [];
  @Input() saveSocials: (socials: Social[]) => Promise<Social[]>;
  @Output() handleShowSocialsEditorModal = new EventEmitter<void>();
  @Input() selectedRowKeys: any[];
  @Output() selectedRowKeysChange = new EventEmitter<any[]>();

  inProgress$ = new BehaviorSubject<boolean>(false);

  protected readonly Messages = Messages;
  protected readonly SocialKeys = SocialKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  readonly emptyMessage = 'No Socials Currently Exist';
  private _defaultSocialTypeLookup: ActiveLookup;
  private _socialTypesLookup: ActiveLookup[] = [];

  constructor(
    private toastrService: ToastrService,
    private socialsService: SocialsService,
  ) {}

  showSocialsEditorModal = (): void => {
    this.handleShowSocialsEditorModal.emit();
  };

  onInitNewRow = (e): void => {
    e.data.social_type = this._defaultSocialTypeLookup?.dbId;
  };

  onRowInserting = e => {
    const { __KEY__: key, ...data } = e?.data;
    const isUniq = this.checkIsSocialUniq(data);

    if (!isUniq) {
      this.toastrService.error('Same Social already exists in this profile');

      e.cancel = true;
      return;
    }

    const socials = this.normalizeSocials(data);
    socials.push(Object.assign({ id: key }, data));
    this.updateSocials(socials);
    e.cancel = false;
  };

  onRowUpdating = e => {
    const data = Object.assign({}, e?.oldData, e?.newData);
    const isUniq = this.checkIsSocialUniq(data, e?.key);

    if (!isUniq) {
      this.toastrService.error('Same Social already exists in this profile');
      e.cancel = true;
      return;
    }

    const socials = this.normalizeSocials(data, e?.key);
    this.updateSocials(socials);
    e.cancel = false;
  };

  onRowRemoving = e => {
    const socials: Social[] = this.socials.filter(address => {
      return address !== e.key;
    });
    this.updateSocials(socials);
    e.cancel = false;
  };

  validateUrlWithOrWithoutProtocol = e => validateUrlWithOrWithoutProtocol(e?.value);

  onSocialTypeChanged = ({ selectedItem }): void => {
    this.socialsService.setSelectedSocialTypeChange(selectedItem);
  };

  onSocialMediaChanged = ({ selectedItem }): void => {
    this.socialsService.setSelectedSocialMedia(selectedItem);
  };

  private checkIsSocialUniq = (data, key?: Social) => {
    return this.socials.every(social => {
      if (key && social === key) {
        return true;
      }

      return data?.url !== social?.url;
    });
  };

  private normalizeSocials = (data, key?: Social) => {
    return this.socials.map(social => {
      if (key && social === key) {
        return data;
      }

      return Object.assign({}, social);
    });
  };

  private updateSocials = (socials: Social[]) => {
    this.inProgress$.next(true);
    Promise.all([this.saveSocials(socials), this.socialsService.onUpdateSocials()])
      .then(() => {
        this.socials = socials;
        this.toastrService.success('Socials updated!');
      })
      .catch(() => {
        this.toastrService.error('Socials are not updated!');
      })
      .finally(() => {
        this.inProgress$.next(false);
      });
  };
}
