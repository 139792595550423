<dx-form [(formData)]="conference" labelMode="floating" [colCount]="1" [readOnly]="isReadonlyMode">
  <dxi-item [dataField]="ConferenceKeys.bccEmail" [label]="{ text: 'BCC' }">
    <div *dxTemplate>
      <dx-tag-box
        #tagBoxRef
        [dataSource]="agentsEmailAddressesDataSource"
        [value]="conference?.[ConferenceKeys.bccEmail]"
        (valueChange)="conference && (conference[ConferenceKeys.bccEmail] = $event)"
        [searchEnabled]="true"
        [showDropDownButton]="true"
        [showClearButton]="true"
        [multiline]="false"
        [acceptCustomValue]="false"
        [label]="'BCC'"
        labelMode="floating"
        [valueExpr]="AgentKeys.email_addresses"
        [displayExpr]="AgentKeys.email_addresses"
        class="attendees-box"
        [placeholder]="''"
        [maxDisplayedTags]="3"
        [readOnly]="isReadonlyMode"
      >
        <div *dxTemplate="let data of 'item'">
          <div style="padding: 5px 0">
            <strong>{{ data?.[AgentKeys.email_addresses] }}</strong>
          </div>

          <div *ngFor="let agent of data?.agents; let i = index" style="padding: 5px 0 0 0px">
            {{
              [agent?.[AgentKeys.p_agent_first_name], agent?.[AgentKeys.p_agent_last_name]]
                | fullName
                | empty: "Unknown Agent"
            }}
            <i *ngIf="agent?.p_email === data?.[AgentKeys.email_addresses]" class="dx-icon-agi-key"></i>
          </div>
        </div>
      </dx-tag-box>
    </div>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-data-grid
        [dataSource]="conference?.[ConferenceKeys.emailTemplates]"
        [showRowLines]="true"
        [rowAlternationEnabled]="true"
        [showColumnHeaders]="true"
        [columnAutoWidth]="true"
        (onRowInserting)="onRowInserting($event)"
        (onRowUpdating)="onRowUpdating($event)"
        (onRowRemoving)="onRowRemoving($event)"
        [wordWrapEnabled]="true"
        height="100%"
      >
        <dxo-editing
          mode="row"
          [useIcons]="true"
          [allowUpdating]="!isReadonlyMode"
          [allowDeleting]="!isReadonlyMode"
          [allowAdding]="!isReadonlyMode"
          [newRowPosition]="'last'"
        >
        </dxo-editing>

        <dxo-toolbar>
          <dxi-item location="before">
            <span *dxTemplate class="dx-form-group-caption">Event Email Templates</span>
          </dxi-item>

          <dxi-item name="addRowButton"></dxi-item>
        </dxo-toolbar>

        <dxi-column
          [dataField]="EmailTemplateConfigurationKeys.templateName"
          caption="Template Name"
          [calculateDisplayValue]="calculateTemplateNameDisplayValue"
          [setCellValue]="setTemplateCellValue"
          editCellTemplate="templateEditorCellTmp"
        >
          <dxi-validation-rule type="required"></dxi-validation-rule>

          <div *dxTemplate="let cell of 'templateEditorCellTmp'">
            <dx-select-box
              [dataSource]="emailTemplatesLookup"
              valueExpr="value"
              displayExpr="description"
              [grouped]="true"
              [value]="cell?.value"
              (valueChange)="cell.setValue($event)"
            ></dx-select-box>
          </div>
        </dxi-column>

        <dxi-column [dataField]="EmailTemplateConfigurationKeys.subject" caption="Subject" width="50%"> </dxi-column>

        <dxi-column
          [dataField]="EmailTemplateConfigurationKeys.trigger"
          caption="Action Trigger"
          width="280"
          [editorOptions]="{
            showClearButton: true,
            placeholder: 'None',
          }"
        >
          <dxo-lookup
            [dataSource]="emailActionTriggersLookup"
            [valueExpr]="LookupKeys.value"
            [displayExpr]="LookupKeys.description"
          ></dxo-lookup>
        </dxi-column>

        <dxi-column type="buttons" *ngIf="!isReadonlyMode">
          <dxi-button name="edit"></dxi-button>

          <dxi-button name="delete"></dxi-button>

          <dxi-button
            name="testEmail"
            icon="email"
            [visible]="canSendTestEmail"
            [onClick]="handlerSendTestEmail"
          ></dxi-button>
        </dxi-column>
      </dx-data-grid>
    </div>
  </dxi-item>
</dx-form>

<ag-shr-modal-window
  #sendTestEmailModalRef
  [width]="450"
  [height]="'auto'"
  title="Send Test Email"
  [actionTitle]="'SEND'"
  (onSaveClick)="sendToEmail()"
>
  <ng-container *ngIf="sendTestEmailModalRef?.popupComponent?.visible ?? false">
    <dx-form #sendTEstEmailFormRef class="ag-assign-owner__form" [(formData)]="testEmailFormData" labelMode="floating">
      <dxi-item [dataField]="'to'" [label]="{ text: 'BCC' }">
        <div *dxTemplate>
          <dx-tag-box
            #tagBoxRef
            [dataSource]="agentsEmailAddressesDataSource"
            [value]="testEmailFormData?.to"
            (valueChange)="testEmailFormData && (testEmailFormData.to = $event)"
            [searchEnabled]="true"
            [showDropDownButton]="true"
            [showClearButton]="true"
            [multiline]="false"
            [acceptCustomValue]="false"
            [label]="'To'"
            labelMode="floating"
            [valueExpr]="AgentKeys.email_addresses"
            [displayExpr]="AgentKeys.email_addresses"
            class="attendees-box"
            [placeholder]="''"
            [maxDisplayedTags]="3"
            [readOnly]="isReadonlyMode"
          >
            <div *dxTemplate="let data of 'item'">
              <div style="padding: 5px 0">
                <strong>{{ data?.[AgentKeys.email_addresses] }}</strong>
              </div>

              <div *ngFor="let agent of data?.agents; let i = index" style="padding: 5px 0 0 0px">
                {{
                  [agent?.[AgentKeys.p_agent_first_name], agent?.[AgentKeys.p_agent_last_name]]
                    | fullName
                    | empty: "Unknown Agent"
                }}
                <i *ngIf="agent?.p_email === data?.[AgentKeys.email_addresses]" class="dx-icon-agi-key"></i>
              </div>
            </div>
          </dx-tag-box>
        </div>
      </dxi-item>

      <dxi-item
        *ngIf="EmailTemplatesModels.registrant === testEmailFormData.emailTemplate.model"
        [dataField]="'payload'"
        editorType="dxSelectBox"
        [label]="{ text: 'Event Registration' }"
        [editorOptions]="{
          dataSource: conferenceRegistrants$ | async,
          displayExpr: [RegistrantModelKeys.data, RegistrantKeys.inviteeEmail] | path,
          searchEnabled: true,
          placeholder: '',
        }"
      ></dxi-item>

      <dxi-item
        *ngIf="EmailTemplatesModels.registrationInquiry === testEmailFormData.emailTemplate.model"
        [dataField]="'payload'"
        editorType="dxSelectBox"
        [label]="{ text: 'Event Application' }"
        [editorOptions]="{
          dataSource: eventInquiryRequests$ | async,
          displayExpr: EventInquiryRequestKeys.email,
          searchEnabled: true,
          placeholder: '',
        }"
      ></dxi-item>
    </dx-form>
  </ng-container>
</ag-shr-modal-window>
