<div class="report-administration__container">
  <div class="row mt-2">
    <div class="col-6">
      <div class="dx-fieldset-header">Upload Paid File</div>
    </div>
    <div class="col-6">
      <dx-button
        style="float: right"
        stylingMode="contained"
        text="Show Upload History"
        type="normal"
        [width]="240"
        (onClick)="showUploadHistory()"
      >
      </dx-button>
    </div>
  </div>

  <div class="report-administration__work-area row mt-2">
    <div class="col-6 d-flex flex-column h-100" id="upload">
      <dx-scroll-view [useNative]="false" [showScrollbar]="'always'" width="100%" height="100%">
        <div class="form h-100">
          <div class="report-administration__item-row">
            <div class="report-administration__item-label report-administration__item-label--required">
              <h3>Year</h3>
            </div>
            <div class="report-administration__item-input">
              <dx-select-box
                #yearSelectBox
                [label]="'Year'"
                [labelMode]="'hidden'"
                [value]="selectedYear$ | async"
                (valueChange)="handleYearChange($event)"
                [items]="years"
                width="150"
                [validationMessagePosition]="'left'"
              >
                <dx-validator #yearTargetValidator>
                  <dxi-validation-rule type="required" message="Required to Select"></dxi-validation-rule>
                </dx-validator>
              </dx-select-box>
            </div>
          </div>

          <div class="report-administration__item-row">
            <div class="report-administration__item-label"><h3>"As Of" Date</h3></div>
            <div class="report-administration__item-input">
              <dx-date-box [label]="asOfLabel" [labelMode]="'hidden'" [(value)]="asOfDate" width="150"></dx-date-box>
            </div>
          </div>

          <div class="report-administration__item-row">
            <div class="report-administration__item-label">
              <h3>
                Transaction to Import: <span> ({{ transactions?.length ?? 0 }}) </span>
              </h3>
            </div>
          </div>

          <div class="report-administration__item-row">
            <div class="report-administration__item-label">
              <h3>
                Summaries to Import: <span> ({{ summaries?.length ?? 0 }}) </span>
              </h3>
            </div>
          </div>
          <div class="report-administration__item-row">
            <div class="report-administration__item-label">
              <h3>
                Premium Total: <span> {{ premiumTotal }} </span>
              </h3>
            </div>
          </div>
          <div class="report-administration__item-row">
            <div class="report-administration__item-label">
              <h3>
                Policies: <span> {{ policies }} </span>
              </h3>
            </div>
          </div>
          <div class="report-administration__item-row">
            <div id="fileuploader-container" class="position-relative">
              <dx-button
                class="report-administration__clear-btn"
                [icon]="'close'"
                [hint]="'Clear files'"
                stylingMode="text"
                type="normal"
                [visible]="!!files?.length"
                (onClick)="clearUploadedFiles()"
              ></dx-button>

              <dx-file-uploader
                #fileUploader
                class="report-administration__file-uploader"
                selectButtonText="Select File(s)"
                accept=".csv"
                [multiple]="true"
                name="files[]"
                [allowCanceling]="true"
                [showFileList]="true"
                [uploadFile]="onFileUploaded.bind(this)"
                (onUploaded)="onUploaded($event)"
                (onFilesUploaded)="onFilesUploaded($event)">
              >
              </dx-file-uploader>
            </div>
          </div>
        </div>
      </dx-scroll-view>

      <div class="mt-4" *ngIf="showImportButton && allFilesUploaded">
        <shr-button-with-indicator
          [title]="'Import'"
          (onClick)="handleImport()"
          [isInProgress]="importInProgress"
        ></shr-button-with-indicator>
      </div>
    </div>

    <div class="report-administration__messages-container col-6">
      <h1>Messages</h1>

      <hr style="width: 100%" />

      <div class="report-administration__messages">
        <dx-scroll-view [useNative]="false" [showScrollbar]="'always'" width="100%" height="100%">
          <ng-container *ngFor="let msg of messages; let i = index">
            <h4 class="report-administration__message">{{ msg }}</h4>
          </ng-container>
        </dx-scroll-view>
      </div>
    </div>
  </div>
</div>

<ag-crm-report-administration-history-modal></ag-crm-report-administration-history-modal>
