import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import {
  ActiveLookup,
  Address,
  AddressModelKeys,
  BUSINESS_PERSONAL_TYPE_LOOKUP,
  COUNTRIES,
  LookupKeys,
  Messages,
} from '@ag-common-lib/public-api';
import { BehaviorSubject } from 'rxjs';
import { InitializedEvent } from 'devextreme/ui/form';
import { ValidationCallbackData } from 'devextreme/common';
import { AddressFormService } from './address-form.service';
import { GoogleMapsService } from 'ag-common-svc/lib/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@UntilDestroy()
@Component({
  selector: 'ag-shr-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  providers: [AddressFormService],
})
export class AddressFormComponent {
  @HostBinding('class') protected className = 'address-form';

  protected formData: Partial<Address>;
  protected isPrimaryShippingDisabled: boolean = false;
  protected isPrimaryBillingDisabled: boolean = false;

  @Input() pathPrefix: string;
  @Input() set address(data: Partial<Address>) {
    this.isPrimaryBillingDisabled = data?.[AddressModelKeys.isPrimaryBilling];
    this.isPrimaryShippingDisabled = data?.[AddressModelKeys.isPrimaryShipping];
    this.formData = this.addressFormService.getFormData(data);
  }

  @Input() isReadonly: boolean = false;
  @Input() isTypeVisible: boolean = true;
  @Input() isPrimaryShippingVisible: boolean = true;
  @Input() isPrimaryBillingVisible: boolean = true;
  @Input() isPhysicalLocationVisible: boolean = true;
  @Input() isAddress1Required: boolean = true;
  @Input() isAddress2Required: boolean = false;
  @Input() isCityRequired: boolean = false;
  @Input() isStateRequired: boolean = false;
  @Input() isZipRequired: boolean = false;
  @Input() isCountryRequired: boolean = false;
  @Input() isCountyRequired: boolean = false;
  @Input() isCountryVisible: boolean = true;
  @Input() isCountyVisible: boolean = true;
  @Input() validationGroup: string;
  @Input() typesLookup: Partial<ActiveLookup>[] = BUSINESS_PERSONAL_TYPE_LOOKUP;
  @Input() colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 12,
    lg: 12,
  };
  @Input() screenLimits;
  @Output() addressTypeChanged = new EventEmitter<Address>();

  protected readonly googleAddressValidatorAdapter = {
    getValue: e => {
      return this.formData;
    },
    bypass: () => {
      return !!this.formData?.[AddressModelKeys.validationResponse]?.result?.verdict;
    },
    reset: e => {
      console.log('ag-shr-address-form addressUniqAdapter reset', e);
    },
  };

  protected formSize$ = new BehaviorSubject(null);

  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly AddressModelKeys = AddressModelKeys;
  protected readonly countries = COUNTRIES;
  protected readonly Messages = Messages;

  constructor(
    private addressFormService: AddressFormService,
    private googleMapsService: GoogleMapsService,
  ) {}

  protected onFormInitialized = (e: InitializedEvent) => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === e.element) {
          this.formSize$.next(entry.contentRect.width);
          break;
        }
      }
    });

    resizeObserver.observe(e.element);
  };

  protected address1ValidationCallback = (e: ValidationCallbackData) => {
    if (e.value) {
      return true;
    }

    return !Object.values(this.address ?? {}).some(Boolean);
  };

  protected handleAddressSelectionChange = (address: Partial<Address>) => {
    Object.assign(this.formData, address);
  };

  protected onSelectedAddressTypesChanged = ({ selectedItem }): void => {
    this.addressTypeChanged.emit(selectedItem);
  };

  protected googleValidationCallback = () => {
    return this.checkAndConfirmValidation();
  };

  private checkAndConfirmValidation = async (): Promise<boolean> => {
    if (this.formData?.[AddressModelKeys.validationResponse]?.result?.verdict) {
      return true;
    }
    const validationResponse = await this.googleMapsService.validateAddress(this.formData);

    this.formData[AddressModelKeys.validationResponse] = validationResponse;

    const verdict = this.formData?.[AddressModelKeys.validationResponse]?.result?.verdict;
    const isCompleted = verdict?.addressComplete ?? false;
    const hasUnconfirmedComponents = verdict?.hasUnconfirmedComponents ?? false;

    if (isCompleted && !hasUnconfirmedComponents) {
      return true;
    }

    return false;
  };

  onCancelEdit = this.addressFormService.onCancelEdit;
}
