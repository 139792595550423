import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AGENT_TYPE_LOOKUP, Agent, AgentKeys, LookupKeys, TRUE_FALSE_LOOKUP } from '@ag-common-lib/public-api';
import { AgencyService } from 'ag-common-svc/public-api';
import { shareReplay } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { RowRemovingEvent } from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import { sortingMethod } from 'ag-common-svc/lib/utils/data-grid.utils';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-notification-recipients-grid',
  templateUrl: './notification-recipients-grid.component.html',
  styleUrls: ['./notification-recipients-grid.component.scss'],
})
export class NotificationRecipientsGridComponent {
  @ViewChild('agentsGridRef', { static: false })
  protected agentsGridComponent: DxDataGridComponent;

  @Input() title: string;
  @Input() dataSource: CustomStore;
  @Input() mode: 'add' | 'view' | 'locked' = 'view';
  @Output() handleAddRecipients = new EventEmitter<void>();
  @Output() handleRemoveRecipient = new EventEmitter<RowRemovingEvent>();

  protected AgentKeys = AgentKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly TRUE_FALSE_LOOKUP = TRUE_FALSE_LOOKUP;
  protected readonly AGENT_TYPE_LOOKUP = AGENT_TYPE_LOOKUP;
  protected readonly agencies$ = this.agencyService.getList().pipe(shareReplay(1));

  constructor(private agencyService: AgencyService) {
    this.sortingMethod = this.sortingMethod.bind(this);
  }

  getSelectedRowKeys = () => {
    return this.agentsGridComponent?.instance?.getSelectedRowKeys();
  };

  protected addRecipients = e => {
    this.handleAddRecipients.emit();
  };

  protected onRowRemoving = (e: RowRemovingEvent) => {
    this.handleRemoveRecipient.emit(e);
  };

  protected onSelectionChanged(e) {
    e.component.refresh(); // to show selected rows on top
  }

  sortingMethod = (value1: any, value2: any) => sortingMethod(this.agentsGridComponent?.instance, value1, value2);
}
