import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { PushNotificationChannel, PushNotificationChannelsKeys } from '@ag-common-lib/lib';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { PushNotificationsChannelsService } from 'ag-common-svc/public-api';
import { SelectionChangedEvent } from 'devextreme/ui/select_box';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay } from 'rxjs';

@Component({
  selector: 'ag-shr-notification-channel-select',
  templateUrl: './notification-channel-select.component.html',
  styleUrls: ['./notification-channel-select.component.scss'],
})
export class NotificationChannelSelectComponent {
  @HostBinding('class') className = 'notification-channel-select';

  @Input() validationGroup: string;
  @Input() isRequired = true;
  @Input() isReadOnly = false;
  @Input() label = 'Channel';

  private _excludedChannels$ = new BehaviorSubject<string[]>(null);
  @Input() set excludedChannels(data: string[]) {
    this._excludedChannels$.next(Array.isArray(data) ? data : []);
  }

  private _value$ = new BehaviorSubject<string>(null);
  @Input() set value(data: string) {
    console.log('NotificationChannelSelectComponent value', data);

    this._value$.next(data);
  }
  @Output() valueChange = new EventEmitter<string>();

  private _pushNotificationsChannelsList$ = this.pushNotificationsChannelsService
    .getList([], { sortField: PushNotificationChannelsKeys.name })
    .pipe(shareReplay(1));
  protected BaseModelKeys = BaseModelKeys;
  protected PushNotificationChannelsKeys = PushNotificationChannelsKeys;
  protected dataSource$: Observable<PushNotificationChannel[]> = combineLatest({
    excludedChannels: this._excludedChannels$,
    pushNotificationsChannelsList: this._pushNotificationsChannelsList$,
  }).pipe(
    map(({ excludedChannels, pushNotificationsChannelsList }) => {
      const set = new Set(excludedChannels);

      return pushNotificationsChannelsList.filter(channel => !set.has(channel?.[BaseModelKeys.dbId]));
    }),
  );
  protected parts: string[] = [];

  protected parts$ = combineLatest({
    channelsMap: this.dataSource$.pipe(
      map(channels => {
        const map = new Map<string, PushNotificationChannel>();

        channels.forEach(channel => {
          const channelDbId = channel?.[BaseModelKeys.dbId];
          map.set(channelDbId, channel);
        });

        return map;
      }),
    ),
    value: this._value$,
  }).pipe(
    map(({ channelsMap, value }) => {
      if (!value) {
        return [];
      }
      const parts: string[] = [];

      const valueChannel = channelsMap.get(value);
      const getPart = (channel: PushNotificationChannel) => {
        parts.push(channel?.[BaseModelKeys.dbId]);
        const parentChannelDbId = channel?.[PushNotificationChannelsKeys.parentChannelDbId];

        if (!parentChannelDbId) {
          return;
        }

        const parentChannel = channelsMap.get(parentChannelDbId);
        getPart(parentChannel);
      };

      getPart(valueChannel);

      return parts.reverse();
    }),
  );

  constructor(private pushNotificationsChannelsService: PushNotificationsChannelsService) {}

  protected handleSelectPathPart = (e: SelectionChangedEvent, parentChannelDbId?: string) => {
    const selectedItem = e.selectedItem;
    const channelDbId = selectedItem ? selectedItem?.[BaseModelKeys.dbId] : parentChannelDbId;

    this.valueChange.emit(channelDbId);
  };
}
