import { Component, HostBinding, Input } from '@angular/core';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import {
  Conference,
  ConferenceKeys,
  DX_USD_CURRENCY_FORMAT,
  GuestRate,
  GuestRateKeys,
  HtmlEditorTypeKeys,
  Messages,
  MonthYearSelection,
  MonthYearSelectionLookup,
} from 'ag-common-lib/public-api';
import { ConferenceService } from 'ag-common-svc/public-api';
import { DxoEditingComponent } from 'devextreme-angular/ui/nested';
import { ValidationCallbackData } from 'devextreme/common';
import { InitNewRowEvent, RowInsertingEvent, RowRemovingEvent, RowUpdatingEvent } from 'devextreme/ui/data_grid';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ag-crm-conference-guests-form',
  templateUrl: './conference-guests-form.component.html',
  styleUrls: ['./conference-guests-form.component.scss'],
})
export class ConferenceGuestsFormComponent {
  @HostBinding('class') className = 'conference-guests-form';
  @Input() conference: Conference;

  @Input() validationGroup: string;
  @Input() isReadonlyMode: boolean = false;

  popupVisible = false;

  protected readonly Messages = Messages;
  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly GuestRateKeys = GuestRateKeys;
  protected readonly MonthYearSelectionLookup = MonthYearSelectionLookup;
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;

  protected readonly HtmlEditorTypeKeys = HtmlEditorTypeKeys;
  protected readonly roomsOccupancyValidationGroup = 'roomsOccupancyValidationGroup';

  constructor(
    private toastrService: ToastrService,
    private conferenceService: ConferenceService,
  ) {}

  startDateRangeValidation = (editingComponent: DxoEditingComponent, { data }: ValidationCallbackData): boolean => {
    if (!this.conference[ConferenceKeys.guestRates]?.length) {
      return true;
    }

    const startAge = data?.[GuestRateKeys.startAgeRange];
    const monthYearSelection = data?.[GuestRateKeys.monthYearSelection];
    const startAgeMonths = monthYearSelection === MonthYearSelection.month ? startAge : 12 * startAge + 1;

    return !this.conference[ConferenceKeys.guestRates].some(rate => {
      if (editingComponent.editRowKey === rate) {
        return false;
      }
      const rateStartAge = rate?.[GuestRateKeys.startAgeRange];
      const rateEndAge = rate?.[GuestRateKeys.endAgeRange];
      const rateMonthYearSelection = rate?.[GuestRateKeys.monthYearSelection];
      const rateStartAgeMonths =
        rateMonthYearSelection === MonthYearSelection.month ? rateStartAge : 12 * rateStartAge + 1;
      const rateEndAgeMonths = rateMonthYearSelection === MonthYearSelection.month ? rateEndAge : 12 * rateEndAge + 1;

      return rateStartAgeMonths <= startAgeMonths && startAgeMonths <= rateEndAgeMonths;
    });
  };

  endDateRangeValidation = (editingComponent: DxoEditingComponent, { data }: ValidationCallbackData): boolean => {
    if (!this.conference[ConferenceKeys.guestRates]?.length) {
      return true;
    }

    const endAge = data?.[GuestRateKeys.endAgeRange];
    const monthYearSelection = data?.[GuestRateKeys.monthYearSelection];
    const endAgeMonths = monthYearSelection === MonthYearSelection.month ? endAge : 12 * endAge + 1;
    return !this.conference[ConferenceKeys.guestRates].some(rate => {
      if (editingComponent.editRowKey === rate) {
        return false;
      }

      const rateStartAge = rate?.[GuestRateKeys.startAgeRange];
      const rateEndAge = rate?.[GuestRateKeys.endAgeRange];
      const rateMonthYearSelection = rate?.[GuestRateKeys.monthYearSelection];
      const rateStartAgeMonths =
        rateMonthYearSelection === MonthYearSelection.month ? rateStartAge : 12 * rateStartAge + 1;
      const rateEndAgeMonths = rateMonthYearSelection === MonthYearSelection.month ? rateEndAge : 12 * rateEndAge + 1;
      return rateStartAgeMonths <= endAgeMonths && endAgeMonths <= rateEndAgeMonths;
    });
  };

  protected onInitNewGuestBudgetRow = (e: InitNewRowEvent) => {
    const noClosedRange = this.conference[ConferenceKeys.guestRates].find(rate => !rate?.[GuestRateKeys.endAgeRange]);

    if (!!noClosedRange) {
      this.toastrService.error(
        `Please set "End Age" for "${noClosedRange?.[GuestRateKeys.guestTypeName]}" before adding new rows.`,
      );
      setTimeout(() => {
        e.component?.cancelEditData();
      });
      return;
    }

    Object.assign(e.data, {
      [GuestRateKeys.monthYearSelection]: MonthYearSelection.years,
      [GuestRateKeys.startAgeRange]: null,
      [GuestRateKeys.endAgeRange]: null,
      [GuestRateKeys.budget]: null,
    });
  };

  protected onRowInserting = (e: RowInsertingEvent) => {
    delete e.data.__KEY__;
    const rows = [Object.assign({}, e.data)];

    this.conference[ConferenceKeys.guestRates]?.forEach(rate => {
      const row = Object.assign({}, rate);

      rows.push(row);
    });

    e.cancel = this.saveChanges(rows);
  };

  protected onRowUpdating = (e: RowUpdatingEvent) => {
    const rows = [];

    this.conference[ConferenceKeys.guestRates]?.forEach(rate => {
      const row = Object.assign({}, rate);
      if (e.key === rate) {
        Object.assign(row, e.newData);
      }
      rows.push(row);
    });

    e.cancel = this.saveChanges(rows);
  };

  protected onRowRemoving = (e: RowRemovingEvent) => {
    const rows = [];

    this.conference[ConferenceKeys.guestRates]?.forEach(rate => {
      if (e.key !== rate) {
        const row = Object.assign({}, rate);
        rows.push(row);
      }
    });

    e.cancel = this.saveChanges(rows);
  };

  private saveChanges = async (rows: GuestRate[]) => {
    const conferenceDbId = this.conference?.[BaseModelKeys.dbId];

    if (!conferenceDbId) {
      Object.assign(this.conference, { [ConferenceKeys.guestRates]: rows });
      return false;
    }

    return this.conferenceService
      .updateFields(conferenceDbId, { [ConferenceKeys.guestRates]: rows })
      .then(() => {
        return false;
      })
      .catch(() => {
        return true;
      });
  };

  onShowPreview(value: string) {
    this.popupVisible = true;
  }
}
