import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { FIREBASE_APP } from '../injections/firebase-app';
import { CONFERENCES_COLLECTION_PATH } from './conference.service';
import { ToastrService } from 'ngx-toastr';
import { CONFERENCE_REGISTRANTS_COLLECTION_PATH } from './conference-registrants/conference-registrants.service';
import { map, Observable } from 'rxjs';
import {
  ConferenceRegistrationLog,
  ConferenceRegistrationLogKeys,
  ConferenceRegistrationSupportLogKey,
} from '@ag-common-lib/public-api';
import { dateFromTimestamp } from 'ag-common-svc/public-api';
import { CommonFireStoreDao, QueryParam } from '../dao/CommonFireStoreDao.dao';

@Injectable()
export class ConferenceRegistrationLogsService {
  readonly fsDao: CommonFireStoreDao<ConferenceRegistrationLog>;
  private readonly conferenceCollectionPath = CONFERENCES_COLLECTION_PATH;
  private readonly registrantsCollectionPath = CONFERENCE_REGISTRANTS_COLLECTION_PATH;
  private readonly conferenceRegistrationLogCollectionPath = 'conference-registration-log';

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    private toastrService: ToastrService,
  ) {
    this.fsDao = new CommonFireStoreDao<ConferenceRegistrationLog>(
      fireBaseApp,
      ConferenceRegistrationLogsService.fromFirestore,
      null,
    );
  }

  static readonly fromFirestore = (data: ConferenceRegistrationLog): ConferenceRegistrationLog => {
    return Object.assign({}, data, {
      [ConferenceRegistrationLogKeys.data]: Object.assign({}, data[ConferenceRegistrationLogKeys.data], {
        [ConferenceRegistrationSupportLogKey.date]: dateFromTimestamp(
          data[ConferenceRegistrationLogKeys.data]?.[ConferenceRegistrationSupportLogKey.date],
        ),
      }),
    });
  };

  getList(conferenceDbId: string, registrantDbId: string, qp: QueryParam[] = []) {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    return this.fsDao.getList(table, qp).pipe(map(transactions => transactions));
  }

  getDocumentData(
    conferenceDbId: string,
    registrantDbId: string,
    documentId: string,
  ): Observable<ConferenceRegistrationLog> {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    return this.fsDao.getDocument(table, documentId).pipe(
      map(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          return data;
        }
        return null;
      }),
    );
  }

  async create(conferenceDbId: string, registrantDbId: string, data: ConferenceRegistrationLog, silent = true) {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    Object.assign(data, {
      [ConferenceRegistrationLogKeys.conferenceDbId]: conferenceDbId,
      [ConferenceRegistrationLogKeys.registrantDbId]: registrantDbId,
    });

    const registrant = await this.fsDao.create(data, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Conference Registration Log Successfully Created!');

    return registrant;
  }

  async update(
    conferenceDbId: string,
    registrantDbId: string,
    documentId: string,
    updates: Partial<ConferenceRegistrationLog>,
    silent = true,
  ) {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    const registrant = await this.fsDao.updateFields(updates, documentId, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Conference Registration Log Successfully Updated!');

    return registrant;
  }

  delete(conferenceDbId: string, registrantDbId: string, documentId: string, silent = true) {
    const table = this.getCollectionPath(conferenceDbId, registrantDbId);

    return this.fsDao.delete(documentId, table).then(response => {
      !silent && this.toastrService.success('Conference Registration Log Removed!');
      return response;
    });
  }

  private getCollectionPath(conferenceId: string, registrantDbId: string) {
    return [
      this.conferenceCollectionPath,
      conferenceId,
      this.registrantsCollectionPath,
      registrantDbId,
      this.conferenceRegistrationLogCollectionPath,
    ].join('/');
  }
}
