import { Component, Input, ViewChild } from '@angular/core';
import { Agent, Entity, EntityPermissionActivityKeys, EntityPermissionModelKeys, ChangeSourceType } from '@ag-common-lib/public-api';
import { MgaEditorModalComponent } from './modal/mga-editor-modal.component';
import { MgaFormService } from './modal/mga-form.service';

@Component({
  selector: 'ag-crm-mga',
  templateUrl: './mga.component.html',
  styleUrls: ['./mga.component.scss'],
  providers: [MgaFormService],
})
export class MgaComponent {
  @ViewChild('mgaEditorModal', { static: true }) mgaEditorModalComponent: MgaEditorModalComponent;
  @Input() agent: Agent;
  @Input() sourceType: ChangeSourceType | null = null;

  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;

  constructor(private mgaFormService: MgaFormService) {}

  showEditorModal(): void {
    this.mgaEditorModalComponent.showModal(this.agent, this.sourceType);
  }
}
