import { Inject, Injectable } from '@angular/core';
import { defer } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirebaseApp } from 'firebase/app';
import { DataService } from '../services/data.service';
import { FIREBASE_APP } from '../injections/firebase-app';
import { dateFromTimestamp } from '..//utils/date-from-timestamp';
import { SHOULD_FETCH_TARGET_ACTIVITIES_SET } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/activity-info.model';
import { QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';
import {
  ActivityBase,
  ActivityBaseKeys,
} from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/activity-base.model';
import {
  CallLog,
  CallLogKeys,
} from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/crm/call-log.model';
import { ChangeSourceType } from '@ag-common-lib/public-api';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable({
  providedIn: 'root',
})
export class CallLogsService extends DataService<CallLog> {
  private readonly callLogsCollectionPath = 'crm-record-call-logs';

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, CallLogsService.fromFirestore);
    super.setCollection(this.callLogsCollectionPath);
  }

  static readonly fromFirestore = (data): CallLog => {
    return Object.assign({}, data, {
      [CallLogKeys.date]: dateFromTimestamp(data[CallLogKeys.date]),
    }) as CallLog;
  };

  updateCallLogsFields(
    documentId: string,
    data: Partial<CallLog>,
    action: ChangeSourceType | null = null,
  ): Promise<CallLog> {
    Object.assign(data, {
      [BaseModelKeys.actionName]: action,
    });
    return super.updateFields(documentId, data, true);
  }

  getCallLogsList = (activityConfig: ActivityBase) =>
    defer(() => {
      const targetType = activityConfig[ActivityBaseKeys.targetType];
      const shouldFetchTargetActivities = SHOULD_FETCH_TARGET_ACTIVITIES_SET.has(targetType);
      const targetCallLogsQp: QueryParam[] = [];
      const involvedCallLogsQp: QueryParam[] = [];
      const hostOwnerIdQp: QueryParam[] = [];

      targetCallLogsQp.push(
        new QueryParam(
          ActivityBaseKeys.targetId,
          WhereFilterOperandKeys.equal,
          activityConfig[ActivityBaseKeys.targetId],
        ),
      );
      hostOwnerIdQp.push(
        new QueryParam(
          CallLogKeys.hostOwnerId,
          WhereFilterOperandKeys.equal,
          activityConfig[ActivityBaseKeys.targetId],
        ),
      );
      involvedCallLogsQp.push(
        new QueryParam(CallLogKeys.attendees, WhereFilterOperandKeys.arrayContainsAny, [
          activityConfig[ActivityBaseKeys.targetId],
        ]),
      );

      const queries = [
        this.getAllByValue(hostOwnerIdQp).then(items => (Array.isArray(items) ? items : [])),
        this.getAllByValue(involvedCallLogsQp).then(items => (Array.isArray(items) ? items : [])),
      ];

      if (shouldFetchTargetActivities) {
        queries.push(this.getAllByValue(targetCallLogsQp));
      }

      return Promise.all(queries);
    }).pipe(
      map(items => {
        const itemsMap = new Map();

        items.forEach(callLogs =>
          callLogs.forEach(callLog => {
            const dbId = callLog[BaseModelKeys.dbId];
            itemsMap.set(dbId, callLog);
          }),
        );

        return Array.from(itemsMap.values());
      }),
    );
}
