import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { LayoutsModule } from 'src/app/layouts/layouts.module';
import { ReportAdministrationComponent } from './report-administration/report-administration.component';
import {
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxFileUploaderModule,
    DxFormModule,
    DxLoadPanelModule,
    DxPopupModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxSwitchModule, DxValidatorModule,
} from 'devextreme-angular';
import { ReportsRouting } from './reports-routing.module';
import { ButtonWithIndicatorModule, ModalWindowModule } from 'ag-common-svc/public-api';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ReportAdministrationHistoryModalComponent } from './report-administration/report-administration-history-modal/report-administration-history-modal.component';

@NgModule({
  declarations: [ReportAdministrationComponent, ReportAdministrationHistoryModalComponent],
    imports: [
        ReportsRouting,
        CommonModule,
        SharedModule,
        FormsModule,
        LayoutsModule,
        DxButtonModule,
        DxSelectBoxModule,
        DxCheckBoxModule,
        DxFileUploaderModule,
        DxDateBoxModule,
        DxScrollViewModule,
        DxLoadPanelModule,
        DxPopupModule,
        DxDataGridModule,
        DxSwitchModule,
        DxFormModule,
        ButtonWithIndicatorModule,
        ModalWindowModule,
        DxValidatorModule,
    ],
  exports: [],
})
export class ReportsModule {}
