import { Inject, Injectable } from '@angular/core';
import {
  AGENTS_COLLECTION_NAME,
  AgentDocumentCollections,
  AgentKeys,
  EmailAddress,
  EmailAddressKeys,
  RelatedEmailAddress,
  ChangeSourceType,
} from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { collectionGroup, getDocs, query, QueryConstraint, QuerySnapshot, where } from 'firebase/firestore';
import { ToastrService } from 'ngx-toastr';
import { CommonFireStoreDao, QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';
import { FIREBASE_APP } from '../injections/firebase-app';
import { AgentElasticSearchService } from './elastic-search.services';
import { DxFilterOperators } from '@ag-common-lib/lib';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable({
  providedIn: 'root',
})
export class AgentEmailAddressesService {
  public readonly fsDao: CommonFireStoreDao<EmailAddress>;
  private readonly agentCollectionPath = AGENTS_COLLECTION_NAME;
  private readonly emailAddressCollectionPath = AgentDocumentCollections.emailAddresses;

  agentsEmailAddressesDataSource = new DataSource({
    paginate: true,
    pageSize: 30,
    store: new CustomStore({
      key: 'dbId',
      byKey: async emailAddress => {
        return { [AgentKeys.email_addresses]: emailAddress };
      },
      load: async loadOptions => {
        const items = this.agentsEmailAddressesDataSource?.items();
        const lastHit = items?.[items?.length - 1];
        const filter: any[] = [loadOptions?.filter].filter(Boolean);

        loadOptions.userData = { withAggregations: true, source: false, aggregationsOnly: true };

        if (loadOptions.skip) {
          loadOptions.userData.lastHit = lastHit;
        }

        if (loadOptions.searchValue) {
          [AgentKeys.email_addresses, AgentKeys.p_agent_first_name, AgentKeys.p_agent_last_name].forEach(field => {
            (loadOptions.searchValue as string)?.split(' ').forEach(part => {
              if (filter?.length) {
                filter.push('or');
              }

              filter.push([field, DxFilterOperators.contains, part?.trim()]);
            });
          });

          loadOptions.searchValue = null;
        }

        loadOptions.filter = filter;
        loadOptions.sort = [{ selector: 'email_addresses', desc: false }];

        const response = await this.agentElasticSearchService.getFromElastic(loadOptions);

        return this.agentElasticSearchService.normalizeAggregations(response);
      },
    }),
  });

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    private toastrService: ToastrService,
    private agentElasticSearchService: AgentElasticSearchService,
  ) {
    this.fsDao = new CommonFireStoreDao<EmailAddress>(fireBaseApp, null, AgentEmailAddressesService.toFirestore);
  }

  static readonly toFirestore = (data): EmailAddress => {
    return data[EmailAddressKeys.address]
      ? Object.assign(data, { [EmailAddressKeys.address]: data[EmailAddressKeys.address].toLowerCase().trim() })
      : data;
  };

  public getList(agentId: string, qp: QueryParam[] = []) {
    const table = this.getCollectionPath(agentId);

    return this.fsDao.getList(table, qp);
  }

  public async create(agentId: string, data: EmailAddress, silent = false, action: ChangeSourceType | null = null) {
    const table = this.getCollectionPath(agentId);
    Object.assign(data, {
      [BaseModelKeys.actionName]: action,
    });
    const emailAddress = await this.fsDao
      .create(Object.assign(data, { [EmailAddressKeys.agentDbId]: agentId }), table)
      .catch(e => {
        console.log('e', e);
      });

    !silent && this.toastrService.success('Agent Email Address Successfully Created!');

    return emailAddress;
  }

  public async update(
    agentId: string,
    documentId: any,
    updates: Partial<EmailAddress>,
    silent = false,
    action: ChangeSourceType | null = null,
  ) {
    const table = this.getCollectionPath(agentId);
    Object.assign(updates, {
      [BaseModelKeys.actionName]: action,
    });
    console.log('updates email addresses', updates);

    const emailAddress = await this.fsDao.updateFields(updates, documentId, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Agent Email Address Successfully Updated!');

    return emailAddress;
  }

  public delete(agentId: string, documentId: any, action: ChangeSourceType | null = null) {
    const table = this.getCollectionPath(agentId);

    return this.fsDao.delete(documentId, table, action).then(response => {
      this.toastrService.success('Agent Email Address Removed!');
      return response;
    });
  }

  public findSameUserEmails = (email): Promise<RelatedEmailAddress[]> => {
    const queries: QueryParam[] = [];

    const emailAddressQuery = new QueryParam(
      'address',
      WhereFilterOperandKeys.equal,
      email?.toLocaleLowerCase()?.trim(),
    );
    const isLoginQuery = new QueryParam('is_login', WhereFilterOperandKeys.equal, true);

    queries.push(emailAddressQuery);

    const queryConstraints: QueryConstraint[] = queries.map(query => where(query.field, query.operation, query.value));

    const collectionGroupRef = collectionGroup(this.fsDao.db, this.emailAddressCollectionPath).withConverter({
      toFirestore: null,
      fromFirestore: this.fsDao.convertResponse,
    });

    const collectionGroupQuery = query(collectionGroupRef, ...queryConstraints);

    return getDocs(collectionGroupQuery).then((collectionSnapshot: QuerySnapshot<any>): any => {
      const items = collectionSnapshot.docs.map(document => {
        if (!document.exists()) {
          return null;
        }
        const data = document.data();
        const parentAgent = document?.ref?.parent?.parent;
        const parentDbId = parentAgent?.id;

        const result = { data, parentDbId };

        return result;
      });

      return items;
    });
  };

  private getCollectionPath(agentId: string) {
    return [this.agentCollectionPath, agentId, this.emailAddressCollectionPath].join('/');
  }
}
