import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { ChangesHistoryGridService } from '../../../changes-history-grid.service';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';
import { StateLicenseKeys, LookupKeys, } from '@ag-common-lib/public-api';

@Component({
    selector: 'ag-shr-change-log-state-license-detail',
    templateUrl: './change-log-state-license.component.html',
    styleUrls: ['./change-log-state-license.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogStateLicenseComponent {
    @Input() log: Delta;

    protected readonly stateLicenseKeys = [
        StateLicenseKeys.state,
        StateLicenseKeys.licenseNumber,
        StateLicenseKeys.residency,
        StateLicenseKeys.expiration
    ];
    protected readonly normalizeLog = normalizeLog;
    protected readonly hasLog = hasLog;
    protected readonly stateLicenseLookup = [
        { [LookupKeys.value]: StateLicenseKeys.state, [LookupKeys.description]: 'Carrier' },
        { [LookupKeys.value]: StateLicenseKeys.licenseNumber, [LookupKeys.description]: 'Carrier' },
        { [LookupKeys.value]: StateLicenseKeys.residency, [LookupKeys.description]: 'Carrier' },
        { [LookupKeys.value]: StateLicenseKeys.expiration, [LookupKeys.description]: 'Carrier' },
    ];

    constructor(private changesHistoryGridService: ChangesHistoryGridService) {}
}
