import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { ChangesHistoryGridService } from '../../../changes-history-grid.service';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';
import {
  AGENT_CAMPAIGNS_STATUS_LOOKUP,
  AgentCampaignsKeys,
  LookupKeys,
  TRUE_FALSE_LOOKUP,
} from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-change-log-agent-campaigns-detail',
  templateUrl: './change-log-agent-campaigns.component.html',
  styleUrls: ['./change-log-agent-campaigns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogAgentCampaignsComponent {
  @Input() log: Delta;

  protected readonly agentCampaignsKeys = [
    AgentCampaignsKeys.originalOutstandUser,
    AgentCampaignsKeys.outstandSite,
    AgentCampaignsKeys.outstandEmailsSent,
    AgentCampaignsKeys.outstandContacts,
    AgentCampaignsKeys.outstandSubscriptionLevel,
    AgentCampaignsKeys.campaignsStatus,
    AgentCampaignsKeys.userSince,
    AgentCampaignsKeys.stoppedCampaigns,
  ];
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly trueFalseLookup = TRUE_FALSE_LOOKUP;
  protected readonly agentCampaignsLookup = [
    { [LookupKeys.value]: AgentCampaignsKeys.originalOutstandUser, [LookupKeys.description]: 'Original Outstand User' },
    { [LookupKeys.value]: AgentCampaignsKeys.outstandSite, [LookupKeys.description]: 'Outstand Site' },
    { [LookupKeys.value]: AgentCampaignsKeys.outstandEmailsSent, [LookupKeys.description]: 'Outstand Emails Sent' },
    { [LookupKeys.value]: AgentCampaignsKeys.outstandContacts, [LookupKeys.description]: 'Outstand Contacts' },
    {
      [LookupKeys.value]: AgentCampaignsKeys.outstandSubscriptionLevel,
      [LookupKeys.description]: 'Outstand Subscription Level',
    },
    { [LookupKeys.value]: AgentCampaignsKeys.campaignsStatus, [LookupKeys.description]: 'Campaigns Status' },
    { [LookupKeys.value]: AgentCampaignsKeys.userSince, [LookupKeys.description]: 'User Since' },
    { [LookupKeys.value]: AgentCampaignsKeys.stoppedCampaigns, [LookupKeys.description]: 'Stopped Campaigns' },
  ];
  protected readonly goalsyWithLookup = {
    [AgentCampaignsKeys.originalOutstandUser]: TRUE_FALSE_LOOKUP,
    [AgentCampaignsKeys.campaignsStatus]: AGENT_CAMPAIGNS_STATUS_LOOKUP,
  };

  constructor(private changesHistoryGridService: ChangesHistoryGridService) {}
}
