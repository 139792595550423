import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { AgentMedia, AGMedia, MediaSize } from '@ag-common-lib/public-api';
import { AgMediaUploaderModalComponent } from '../../../ag-media-uploader/ag-media-uploader-modal.component';
import { Agent } from 'http';
import { map, mergeMap, Observable, shareReplay } from 'rxjs';
import { AgentCampaignWizardService } from '../../agent-campaign-wizard.service';
import { ImageCropperModalComponent } from '../../../../components/image-cropper-modal/image-cropper-modal.component';
import { WasabiImgPipe } from '../../../../../shared/pipes/wasabi-img.pipe';
import {
  ImageCropper,
  ImageCropperKeys,
  ImageCropperPayload,
} from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/image-cropper.model';
import { AGMediaKeys } from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/ag-media.model';
import { AgentImagesService } from '../../../../services/agent-images.service';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-agent-campaign-picture',
  templateUrl: './agent-campaign-picture.component.html',
  styleUrls: ['./agent-campaign-picture.component.scss'],
})
export class AgentCampaignPictureComponent {
  @HostBinding('class') className = 'agent-campaign-picture';

  @Input() set media(media: Partial<AgentMedia & ImageCropper>) {
    this.mediaItem = Object.assign({}, media);
    this.editOptions.find(option => option.text === 'Edit Picture').disabled = !media?.[AGMediaKeys.wasabiPath];
  }
  @Input() canEdit = true;
  @Output() mediaChanged = new EventEmitter<AGMedia>();
  @Output() mediaCropped = new EventEmitter<ImageCropperPayload>();

  @ViewChild(AgMediaUploaderModalComponent, { static: false }) mediaUploaderComponent: AgMediaUploaderModalComponent; // ViewChild for DxTooltipComponent
  @ViewChild(ImageCropperModalComponent, { static: false }) imageCropperModalComponent: ImageCropperModalComponent; // ViewChild for DxTooltipComponent

  agentMediaDataSource$: Observable<AGMedia[]>;

  mediaItem: Partial<AgentMedia & ImageCropper>;
  mediaPathPrefix$: Observable<string>;

  protected readonly AGMediaKeys = AGMediaKeys;
  protected readonly ImageCropperKeys = ImageCropperKeys;
  protected readonly MediaSize = MediaSize;

  protected readonly editOptions = [
    { icon: 'mdi mdi-upload', text: 'Upload Picture', onClick: this.onUploadClicked.bind(this) },
    { icon: 'mdi mdi-pencil', text: 'Edit Picture', onClick: this.onEditClicked.bind(this), disabled: false },
  ];

  constructor(
    agentCampaignWizardService: AgentCampaignWizardService,
    private agentImagesFetchUtil: AgentImagesService,
    private wasabiImgPipe: WasabiImgPipe,
  ) {
    this.mediaPathPrefix$ = agentCampaignWizardService.agent$.pipe(
      map((agent: Agent) => `agents/${agent?.[BaseModelKeys.dbId]}`),
    );

    this.agentMediaDataSource$ = agentCampaignWizardService.agent$.pipe(
      mergeMap((agent: Agent) => this.agentImagesFetchUtil.fetchAgentImages(agent[BaseModelKeys.dbId])),
      shareReplay(1),
    );
  }

  onUploadClicked(): void {
    this.mediaUploaderComponent.showModal();
  }

  onEditClicked(): void {
    // const headshot = await (await firstValueFrom(this.agent$)).head_shot;
    this.imageCropperModalComponent.showModal(
      this.wasabiImgPipe.transform(this.mediaItem[AGMediaKeys.wasabiPath]),
      this.mediaItem?.[ImageCropperKeys.imageCropperPayload],
    );
  }

  onImageCropped = (imageCropperPayload: ImageCropperPayload) => {
    this.mediaItem.imageCropperPayload = imageCropperPayload;
    this.mediaCropped.emit(imageCropperPayload);
    return Promise.resolve();
  };

  onMediaChanged(mediaUrl: AGMedia) {
    this.mediaItem = mediaUrl;
    this.mediaChanged.emit(mediaUrl);
  }
}
