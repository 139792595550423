import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogPipesModule } from '../../../pipes/change-log-pipes.module';
import { ChangeLogDetailByTypeModule } from '../../change-log-detail-by-type/change-log-detail-by-type.module';
import { AgentChangeLogDetailsByTypeComponent } from './agent-change-log-details-by-type.component';
import { ChangeLogAddressModule } from '../../change-log-detail/address/change-log-address.module';
import { ChangeLogPhoneModule } from '../../change-log-detail/phone-number/change-log-phone.module';
import { ChangeLogGoalsModule } from '../../change-log-detail/goals/change-log-goals.module';
import { ChangeLogTshirtSizeModule } from '../../change-log-detail/t-shirt-sizes/change-log-tshirt-size.module';
import { ChangeLogDietaryConsiderationModule } from '../../change-log-detail/dietary-consideration/change-log-dietary-consideration.module';
import { ChangeLogAssociationModule } from '../../change-log-detail/association/change-log-association.module';
import { ChangeLogFavouriteModule } from '../../change-log-detail/favourite/change-log-favourite.module';
import { ChangeLogCarrierModule } from '../../change-log-detail/carrier/change-log-carrier.module';
import { ChangeLogStateLicenseModule } from '../../change-log-detail/state-license/change-log-state-license.module';
import { ChangeLogAgentCampaignsModule } from '../../change-log-detail/agent-campaigns/change-log-agent-campaigns.module';
import { ChangeLogHeadshotModule } from '../../change-log-detail/headshot/change-log-headshot.module';

@NgModule({
  declarations: [AgentChangeLogDetailsByTypeComponent],
  imports: [
    CommonModule,
    SharedModule,
    ChangeLogPipesModule,
    ChangeLogDetailByTypeModule,
    ChangeLogAddressModule,
    ChangeLogPhoneModule,
    ChangeLogGoalsModule,
    ChangeLogTshirtSizeModule,
    ChangeLogDietaryConsiderationModule,
    ChangeLogAssociationModule,
    ChangeLogFavouriteModule,
    ChangeLogCarrierModule,
    ChangeLogStateLicenseModule,
    ChangeLogAgentCampaignsModule,
    ChangeLogHeadshotModule,
  ],
  exports: [AgentChangeLogDetailsByTypeComponent],
})
export class AgentChangeLogDetailsByTypeModule {}
