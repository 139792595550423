<ag-shr-need-to-know-modal
  *ngIf="isEditable"
  #needToKnowModalRef
  [title]="editModalOptions?.title ?? 'Need To Know'"
  [isVisibilityTypeLocked]="editModalOptions?.isVisibilityTypeLocked"
></ag-shr-need-to-know-modal>

<dx-data-grid
  #dataGridRef
  class="need-to-know-grid__data-grid"
  [ngClass]="{ 'need-to-know-grid__data-grid--view': !isEditable }"
  [dataSource]="needToKnow$ | async"
  [keyExpr]="BaseModelKeys.dbId"
  [showRowLines]="true"
  [showBorders]="true"
  [showColumnLines]="true"
  [columnAutoWidth]="true"
  [wordWrapEnabled]="true"
  [rowAlternationEnabled]="false"
  (onRowRemoving)="onRowRemoving($event)"
  height="100%"
>
  <dxo-toolbar>
    <dxi-item location="before" [visible]="!!title">
      <div *dxTemplate>
        <div class="toolbar-label">
          <b>{{ title }}</b>
        </div>
      </div>
    </dxi-item>

    <dxi-item
      *ngFor="let toolbarItem of extraToolbarItems"
      [widget]="toolbarItem?.widget"
      [visible]="toolbarItem?.visible ?? true"
      [location]="toolbarItem?.location"
      [options]="toolbarItem?.options"
    >
    </dxi-item>

    <dxi-item
      *ngIf="canCreate && isEditable"
      location="after"
      widget="dxButton"
      [options]="{ icon: 'plus', onClick: showAddNeedToKnowPopup }"
    ></dxi-item>
  </dxo-toolbar>

  <dxo-editing mode="popup" [useIcons]="true" [allowUpdating]="false" [allowDeleting]="canDelete" [allowAdding]="false">
  </dxo-editing>
  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

  <dxi-column
    [width]="150"
    [dataField]="BaseModelKeys.createdByAgentDbId"
    [allowEditing]="false"
    caption="Created By"
    cellTemplate="createdByAgentDbIdCellTemplate"
  >
    <dxo-lookup [dataSource]="agentsDataSource$ | async" valueExpr="value" displayExpr="description"></dxo-lookup>
    <div *dxTemplate="let cell of 'createdByAgentDbIdCellTemplate'">
      {{ (cell.displayValue ?? cell?.data?.[BaseModelKeys.createdBy] ?? cell.value) }}
    </div>
  </dxi-column>

  <dxi-column
    [width]="150"
    [dataField]="BaseModelKeys.createdDate"
    [allowEditing]="false"
    [dataType]="'date'"
    [sortOrder]="'desc'"
    [format]="dateFormat"
  ></dxi-column>
  <dxi-column [width]="150" [dataField]="NeedToKnowKeys.visibilityLevel">
    <dxo-lookup [dataSource]="needToKnowVisibilityLevelLookup" valueExpr="value" displayExpr="description"></dxo-lookup>
  </dxi-column>
  <dxi-column [dataField]="NeedToKnowKeys.needToKnow"> </dxi-column>

  <dxi-column type="buttons" [visible]="isEditable">
    <dxi-button [visible]="canEdit" icon="edit" [onClick]="showEditPopup"></dxi-button>
    <dxi-button [visible]="getIsEditingAllowed.bind(this, loggedInUserEmail$ | async) && canDelete" name="delete">
    </dxi-button>
  </dxi-column>
</dx-data-grid>
