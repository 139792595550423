import { Agent } from '@ag-common-lib/public-api';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { DxFormComponent } from 'devextreme-angular';
import { FlightPreference } from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/flight-preference.model';
import { FlightPreferencesService } from './flight-preferences.service';
import { FlightInfoKeys } from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/registration/flight-information.model';
import { Observable } from 'rxjs';
import { BaseModelKeys } from '../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/base.model';
import { validateDxGroups } from '../../../../utils/validation';

@Component({
  selector: 'ag-shr-flight-preferences',
  templateUrl: './flight-preferences.component.html',
  styleUrl: './flight-preferences.component.scss',
})
export class FlightPreferencesComponent {
  // eslint-disable-next-line
  @Input('agent') set _agent(value: Agent) {
    this.agent = value;
    this.flightPreferenceFormDetails = this.flightPreferencesService.getFormData(value);
  }
  @Input() canEdit: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() canCreate: boolean = true;
  // eslint-disable-next-line
  @Output() onFieldsUpdated: EventEmitter<{ agentId: string; updates: Partial<Agent> }> = new EventEmitter();
  @ViewChild('flightPreferenceModalRef', { static: true }) flightPreferenceModalComponent: ModalWindowComponent;
  @ViewChild('flightPreferenceFormRef', { static: false }) flightPreferenceFormComponent: DxFormComponent;

  public flightPreferenceFormDetails: FlightPreference;

  public inProgress$: Observable<boolean>;
  public validationGroup = 'flightPreferenceValidationGroup';

  private agent: Agent;

  constructor(private flightPreferencesService: FlightPreferencesService) {}

  protected readonly FlightInfoKeys = FlightInfoKeys;

  public saveAgentUpdates = async () => {
    const isValid = await validateDxGroups(this.validationGroup);
    if (isValid) {
      const agentId = this.agent[BaseModelKeys.dbId];
      this.flightPreferencesService.handleSave(this.agent[BaseModelKeys.dbId]).then(updates => {
        this.flightPreferenceModalComponent.hideModal();
        this.onFieldsUpdated.emit({ agentId, updates });
      });
    }
  };

  public showEditorModal = () => {
    this.flightPreferenceModalComponent.showModal();
  };

  public handleClosePopup = e => {
    this.flightPreferencesService.onCancelEdit(e);
  };

}
