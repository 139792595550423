import { Component, HostBinding, ViewChild } from '@angular/core';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { Conference, ConferenceKeys, Constants, Tab } from 'ag-common-lib/public-api';
import { ConferenceExcursionStatisticService } from './conference-excursion-statistic.service';
import { EventNameTitlePipe } from 'ag-common-svc/shared/pipes/conference-registration/event-name-title.pipe';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

export enum StatisticTab {
  SeatsRemaining = 'Seats Remaining',
  PersonsWithoutExcursions = 'Persons Without Excursions',
}

@Component({
  selector: 'ag-crm-conference-excursion-statistic-modal',
  templateUrl: './conference-excursion-statistic-modal.component.html',
  styleUrls: ['./conference-excursion-statistic-modal.component.scss'],
  providers: [EventNameTitlePipe],
})
export class ConferenceExcursionStatisticModalComponent {
  @HostBinding('class') className = 'conference-excursion-statistic-modal';
  @ViewChild('conferenceExcursionStatisticModalRef', { static: true })
  conferenceExcursionStatisticModalComponent: ModalWindowComponent;

  statisticTabs: Tab[] = [
    { id: 0, text: StatisticTab.SeatsRemaining, template: StatisticTab.SeatsRemaining },
    { id: 1, text: StatisticTab.PersonsWithoutExcursions, template: StatisticTab.PersonsWithoutExcursions },
  ];
  selectedTab: StatisticTab = StatisticTab.SeatsRemaining;
  selectedIndex: number = 0;
  eventNameTitle: string;

  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly StatisticTab = StatisticTab;
  protected readonly ConferenceKeys = ConferenceKeys;

  constructor(
    private conferenceExcursionStatisticService: ConferenceExcursionStatisticService,
    private eventNameTitlePipe: EventNameTitlePipe,
  ) {}

  showModal = async (data?: Conference): Promise<void> => {
    const caption = 'Conference Excursion Statistic';
    this.eventNameTitle = this.eventNameTitlePipe.transform(
      data?.[ConferenceKeys.eventName],
      caption + Constants.EVENT_NAME_TITLE,
      caption,
    );
    this.conferenceExcursionStatisticService.setConference(data);
    this.conferenceExcursionStatisticModalComponent.showModal();
  };

  selectTab(e): void {
    this.selectedTab = e.itemData.template;
    this.selectedIndex = this.statisticTabs.find(tab => tab.template === this.selectedTab).id;
  }
}
