import { EmailRecipient } from '../utils/email-address.model';
import { EmailTemplates } from './mailer.model';

export enum NotifyEmailKeys {
  imagePath = 'imagePath',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  replyTo = 'replyTo',
  ccEmail = 'ccEmail',
  bccEmail = 'bccEmail',
  summaryEmailTemplate = 'summaryEmailTemplate',
  subjectTitle = 'subjectTitle',
}

export interface NotifyEmail {
  [NotifyEmailKeys.imagePath]?: string;
  [NotifyEmailKeys.firstName]?: string;
  [NotifyEmailKeys.lastName]?: string;
  [NotifyEmailKeys.email]?: string | string[];
  [NotifyEmailKeys.replyTo]?: string;
  [NotifyEmailKeys.ccEmail]?: Array<string | EmailRecipient>;
  [NotifyEmailKeys.bccEmail]?: Array<string | EmailRecipient>;
  [NotifyEmailKeys.summaryEmailTemplate]?: EmailTemplates;
  [NotifyEmailKeys.subjectTitle]?: string;
}
