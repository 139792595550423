import { Component, HostBinding, Input } from '@angular/core';
import {
  AddressModelKeys,
  AgentKeys,
  Conference,
  ConferenceKeys,
  ConferencePossibleStayPeriod,
  ConferencePossibleStayPeriodKey,
  Constants,
  DATE_NOW,
  HOTEL_REGION_LOOKUP,
  HotelRegion,
  LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT,
  LookupKeys,
  Messages,
  REGISTRATION_TYPE_LOOKUP,
} from 'ag-common-lib/public-api';
import { CONFERENCES_TITLE_LIST, PHONE_NUMBER_OPTIONS } from '../../../config/conferences.config';
import { InitNewRowEvent, RowInsertingEvent, RowRemovingEvent, RowUpdatingEvent } from 'devextreme/ui/data_grid';
import { InitializedEvent } from 'devextreme/ui/calendar';
import { ConferenceService } from 'ag-common-svc/public-api';
import { DxDataGridComponent } from 'devextreme-angular';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-crm-conference-hotel-info-form',
  templateUrl: './conference-hotel-info-form.component.html',
  styleUrls: ['./conference-hotel-info-form.component.scss'],
})
export class ConferenceHotelInfoFormComponent {
  @HostBinding('class') className = 'conference-hotel-info-form';
  @Input() conference: Conference;
  @Input() isReadonlyMode: boolean = false;
  @Input() validationGroup: string;

  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly ConferencePossibleStayPeriodKey = ConferencePossibleStayPeriodKey;
  protected readonly titleList = CONFERENCES_TITLE_LIST;
  protected readonly registrationTypeLookup = REGISTRATION_TYPE_LOOKUP;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly phoneNumberOptions = PHONE_NUMBER_OPTIONS;
  protected readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  protected readonly Messages = Messages;
  protected readonly AgentKeys = AgentKeys;
  protected readonly HOTEL_REGION_LOOKUP = HOTEL_REGION_LOOKUP;
  protected readonly LookupKeys = LookupKeys;
  protected readonly LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT = LOCAL_DATE_TIME_DATE_SERIALIZATION_FORMAT;
  protected readonly HotelRegion = HotelRegion;
  protected readonly AddressModelKeys = AddressModelKeys;

  protected participantScheduleRangesDataSource: ConferencePossibleStayPeriod[];

  private newRowDefaultData = null;

  constructor(private conferenceService: ConferenceService) {}

  protected onInitNewRow = (e: InitNewRowEvent) => {
    if (this.newRowDefaultData) {
      Object.assign(e.data, this.newRowDefaultData);
      this.newRowDefaultData = null;
    }
  };

  protected copyAndAddStayPeriod = async (grid: DxDataGridComponent, e) => {
    const { [ConferencePossibleStayPeriodKey.registrationType]: registrationType, ...rowData } = e?.row?.data ?? {};
    this.newRowDefaultData = rowData;

    await grid.instance.addRow();
  };

  protected handleParticipantScheduleRangesChange = e => {
    const participantScheduleRanges = this.conference[ConferenceKeys.participantScheduleRanges]?.map(range =>
      Object.assign({}, range),
    );

    Object.assign(this.conference, { [ConferenceKeys.participantScheduleRanges]: participantScheduleRanges });
  };

  protected calculatePrimaryDatesCellValue = (data: ConferencePossibleStayPeriod) => {
    return [
      data?.[ConferencePossibleStayPeriodKey.primaryArrivalDate],
      data?.[ConferencePossibleStayPeriodKey.primaryDepartureDate],
    ];
  };
  protected calculateShoulderDatesCellValue = (data: ConferencePossibleStayPeriod) => {
    return [
      data?.[ConferencePossibleStayPeriodKey.shoulderArrivalDate],
      data?.[ConferencePossibleStayPeriodKey.shoulderDepartureDate],
    ];
  };

  protected setPrimaryDatesCellValue = (updatedData, value) => {
    const [arrivalDate, departureDate] = value;

    Object.assign(updatedData, {
      [ConferencePossibleStayPeriodKey.primaryArrivalDate]: arrivalDate,
      [ConferencePossibleStayPeriodKey.primaryDepartureDate]: departureDate,
    });
  };

  protected setShoulderDatesCellValue = (updatedData, value) => {
    const [arrivalDate, departureDate] = value;

    Object.assign(updatedData, {
      [ConferencePossibleStayPeriodKey.shoulderArrivalDate]: arrivalDate,
      [ConferencePossibleStayPeriodKey.shoulderDepartureDate]: departureDate,
    });
  };

  protected validatePrimaryDates = e => {
    return this.calculatePrimaryDatesCellValue(e?.data).every(Boolean);
  };

  protected validateShoulderDates = e => {
    return this.calculateShoulderDatesCellValue(e?.data).every(Boolean);
  };

  protected navigateToDefaultCalendarDate = (e: InitializedEvent) => {
    const value = e.component.option('value');

    if (value) {
      return;
    }

    e.component.option('currentDate', new Date(this.conference?.[ConferenceKeys.startDate]) ?? DATE_NOW);
  };

  protected onRowInserting = (e: RowInsertingEvent) => {
    delete e.data.__KEY__;

    const ranges = [Object.assign({}, e.data)];

    this.conference[ConferenceKeys.participantScheduleRanges]?.forEach(range => {
      const activity = Object.assign({}, range);

      ranges.push(activity);
    });

    e.cancel = this.saveChanges(ranges);
  };

  protected onRowUpdating = (e: RowUpdatingEvent) => {
    const ranges = [];

    this.conference[ConferenceKeys.participantScheduleRanges]?.forEach(range => {
      const activity = Object.assign({}, range);
      if (e.key === range) {
        Object.assign(activity, e.newData);
      }
      ranges.push(activity);
    });

    e.cancel = this.saveChanges(ranges);
  };

  protected onRowRemoving = (e: RowRemovingEvent) => {
    const ranges = [];

    this.conference[ConferenceKeys.participantScheduleRanges]?.forEach(range => {
      if (e.key !== range) {
        const activity = Object.assign({}, range);
        ranges.push(activity);
      }
    });

    e.cancel = this.saveChanges(ranges);
  };

  isCopyButtonVisible(e): boolean {
    return !e.row.isEditing && !e.row.data.isCompleted;
  }

  private saveChanges = async (ranges: ConferencePossibleStayPeriod[]) => {
    const conferenceDbId = this.conference?.[BaseModelKeys.dbId];

    if (!conferenceDbId) {
      Object.assign(this.conference, { [ConferenceKeys.participantScheduleRanges]: ranges });
      return false;
    }

    return this.conferenceService
      .updateFields(conferenceDbId, { [ConferenceKeys.participantScheduleRanges]: ranges })
      .then(() => {
        Object.assign(this.conference, { [ConferenceKeys.participantScheduleRanges]: ranges });
        return false;
      })
      .catch(() => {
        return true;
      });
  };
}
