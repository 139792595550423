import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import {
  Agent,
  Constants,
  Lookup,
  LookupKeys,
  NEED_TO_KNOW_VISIBILITY_LEVEL_LOOKUP,
  NeedToKnow,
  NeedToKnowKeys,
  NeedToKnowVisibilityLevel,
  ChangeSourceType,
} from '@ag-common-lib/public-api';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AgentService } from '../../../../../services/agent.service/agent.service';
import { NeedToKnowModalComponent } from '../need-to-know-modal/need-to-know-modal.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { QueryParam, WhereFilterOperandKeys } from '../../../../../dao/CommonFireStoreDao.dao';
import { NeedToKnowConfig } from './need-to-know-grid.model';
import { AgentNeedToKnowService } from '../../../../../services/agent-need-to-know.service';
import { AuthService } from 'ag-common-svc/public-api';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@UntilDestroy()
@Component({
  selector: 'ag-shr-need-to-know-grid',
  templateUrl: './need-to-know-grid.component.html',
  styleUrls: ['./need-to-know-grid.component.scss'],
})
export class NeedToKnowGridComponent {
  @HostBinding('class') className = 'need-to-know-grid';
  @ViewChild('needToKnowModalRef') needToKnowModalComponent: NeedToKnowModalComponent;
  @Input() set agentId(dbId: string) {
    this.agentId$.next(dbId);
  }
  @Input() title: string;
  @Input() isEditable: boolean = true;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;
  @Input() sourceType: ChangeSourceType | null = null;
  @Input() extraToolbarItems = [];
  @Input() editModalOptions: NeedToKnowConfig;
  @Input() set allowedVisibilityLevels(data: NeedToKnowVisibilityLevel[]) {
    if (Array.isArray(data)) {
      this.allowedVisibilityLevels$.next(data);
    }
  }

  agentsDataSource$ = this.agentService.agentsDataSource$;
  public BaseModelKeys = BaseModelKeys;
  public NeedToKnowKeys = NeedToKnowKeys;
  public needToKnowVisibilityLevelLookup = NEED_TO_KNOW_VISIBILITY_LEVEL_LOOKUP;
  public needToKnow$: Observable<DataSource>;
  public loggedInUserEmail$: Observable<string>;

  private readonly agentId$ = new BehaviorSubject<string>(undefined);
  readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;

  private allowedVisibilityLevels$ = new BehaviorSubject<NeedToKnowVisibilityLevel[]>([]);

  constructor(
    authService: AuthService,
    private agentService: AgentService,
    private agentNeedToKnowService: AgentNeedToKnowService,
  ) {
    this.loggedInUserEmail$ = authService.currentUser$.pipe(map(user => user.email));
    this.needToKnow$ = combineLatest([this.agentId$, this.allowedVisibilityLevels$]).pipe(
      filter(([agentId]) => !!agentId),
      switchMap(([agentId, allowedVisibilityLevels]) => {
        const qp: QueryParam[] = [];
        if (Array.isArray(allowedVisibilityLevels) && allowedVisibilityLevels?.length) {
          const visibilityLevelsQueryParam = new QueryParam(
            NeedToKnowKeys.visibilityLevel,
            WhereFilterOperandKeys.in,
            allowedVisibilityLevels,
          );
          qp.push(visibilityLevelsQueryParam);
        }

        return this.agentNeedToKnowService.getList(agentId, qp);
      }),
      map(needToKnows => {
        return new DataSource({
          store: new ArrayStore({
            key: 'dbId',
            data: Array.isArray(needToKnows) ? needToKnows : [],
          }),
        });
      }),
      shareReplay(1),
    );
  }

  public onRowRemoving = e => {
    e.cancel = this.agentNeedToKnowService.delete(this.agentId$.value, e.data[BaseModelKeys.dbId], this.sourceType);
  };

  public showAddNeedToKnowPopup = () => {
    this.needToKnowModalComponent.showModal(
      this.agentId$.value,
      this.editModalOptions?.initialNeedToKnow as NeedToKnow,
      this.sourceType
    );
  };

  public showEditPopup = ({ row: { data } }) => {
    this.needToKnowModalComponent.showModal(this.agentId$.value, data, this.sourceType);
  };

  public getIsEditingAllowed = (loggedInUserEmail, { row: { data } }) => {
    return !!loggedInUserEmail && loggedInUserEmail === data[BaseModelKeys.createdBy];
  };
}
