import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ag-common-svc/shared/shared.module';
import { ChangeLogPipesModule } from '../../../pipes/change-log-pipes.module';
import { ChangeLogCarrierComponent } from './change-log-carrier.component';

@NgModule({
  declarations: [ChangeLogCarrierComponent],
  imports: [CommonModule, SharedModule, ChangeLogPipesModule],
  exports: [ChangeLogCarrierComponent],
})
export class ChangeLogCarrierModule {}
