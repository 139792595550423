import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { ChangesHistoryGridService } from '../../../changes-history-grid.service';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';
import { GoalKeys, TRUE_FALSE_LOOKUP, YEARS_LIST, LookupKeys } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-change-log-goals-detail',
  templateUrl: './change-log-goals.component.html',
  styleUrls: ['./change-log-goals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogGoalsComponent {
  @Input() log: Delta;

  protected readonly goalsKeys = [GoalKeys.year, GoalKeys.amount];
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly trueFalseLookup = TRUE_FALSE_LOOKUP;
  protected readonly goalsLookup = [
    { [LookupKeys.value]: GoalKeys.year, [LookupKeys.description]: 'Year' },
    { [LookupKeys.value]: GoalKeys.amount, [LookupKeys.description]: 'Amount' },
  ];
  protected readonly goalsyWithLookup = {
    [GoalKeys.year]: YEARS_LIST,
  };

  constructor(private changesHistoryGridService: ChangesHistoryGridService) {}
}
