import { Injectable } from '@angular/core';
import { EmailAddress, ChangeSourceType } from '@ag-common-lib/public-api';
import { BaseFormService } from '../../../../utils/base-form-service';
import { ToastrService } from 'ngx-toastr';
import { AgentEmailAddressesService } from '../../../../../../src/lib/services/agent-email-addresses.service';

@Injectable()
export class EmailAddressesService extends BaseFormService<EmailAddress> {
  constructor(private agentEmailAddressesService: AgentEmailAddressesService, private toastrService: ToastrService) {
    super();
  }

  updateEmailAddressChange(
    agentId: string,
    documentId: string,
    emailAddressUpdates: Partial<EmailAddress>,
    action: ChangeSourceType | null = null
  ): Promise<boolean> {
    this.startProgress();
    return this.agentEmailAddressesService
      .update(agentId, documentId, emailAddressUpdates, false, action)
      .then(() => false)
      .catch(err => {
        this.toastrService.error('Email address is not updated!');
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  }

  deleteEmailAddress(agentId: string, documentId: string, action: ChangeSourceType | null = null): Promise<boolean> {
    this.startProgress();
    return this.agentEmailAddressesService
      .delete(agentId, documentId, action)
      .then(() => false)
      .catch(err => {
        this.toastrService.error('Email address is not deleted!');
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  }

  createEmailAddress(agentId: string, emailAddress: EmailAddress, action: ChangeSourceType | null = null): Promise<boolean> {
    this.startProgress();
    return this.agentEmailAddressesService
      .create(agentId, emailAddress, false, action)
      .then(() => false)
      .catch(err => {
        this.toastrService.error('Email address is not created!');
        throw err;
      })
      .finally(() => {
        this.stopProgress();
      });
  }
}
