import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { EmailAddress, EmailAddressKeys, LookupKeys, Messages } from '@ag-common-lib/public-api';
import { DxFormComponent, DxListComponent } from 'devextreme-angular';
import { AgentCampaignEmailAddressInfoService } from './agent-campaign-email-address-info.service';
import { combineLatest, map, Observable, startWith, Subject } from 'rxjs';
import { SelectionChangedEvent } from 'devextreme/ui/list';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agent-campaign-email-address-info',
  templateUrl: './agent-campaign-email-address-info.component.html',
  styleUrls: ['./agent-campaign-email-address-info.component.scss'],
})
export class AgentCampaignEmailAddressInfoComponent {
  @HostBinding('class') className = 'agent-campaign-email-address-info agent-campaign-wizard__content-container';
  @ViewChild('emailAddressesListRef') emailAddressesListComponent: DxListComponent;
  @ViewChild('emailAddressFormRef') emailAddressFormComponent: DxFormComponent;

  @Input() hasStepIssues: boolean;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;

  inProgress$: Observable<boolean>;
  emailAddressFormData$: Observable<Partial<EmailAddress>>;
  agentEmailAddresses$: Observable<EmailAddress[]>;
  selectedEmailAddressesKey: string;
  hasFormChanges$: Observable<boolean>;

  validationGroup = 'agentCampaignEmailAddressInfoValidationGroup';

  constructor(private agentCampaignEmailAddressInfoService: AgentCampaignEmailAddressInfoService) {
    this.emailAddressFormData$ = agentCampaignEmailAddressInfoService.campaignContactInfoEmailAddress$;

    this.agentEmailAddresses$ = agentCampaignEmailAddressInfoService.agentEmailAddresses$;
    this.inProgress$ = agentCampaignEmailAddressInfoService.inProgress$;
    this.hasFormChanges$ = agentCampaignEmailAddressInfoService.hasFormChanges$;
  }

  nextStep = () => {
    const validationResults = this.emailAddressFormComponent.instance.validate();

    if (!validationResults.isValid) {
      return;
    }

    this.agentCampaignEmailAddressInfoService.handleNextStepClick(this.selectedEmailAddressesKey);
  };

  resetChanges = () => {
    this.agentCampaignEmailAddressInfoService.revertAllChanges();
  };
}
