import { Component, HostBinding, Input } from '@angular/core';
import { AgentCampaignWizardService } from './agent-campaign-wizard.service';
import { Observable } from 'rxjs';
import {
  AgentCampaignStep,
  AgentCampaignStepName,
  RelatedCampaignsManagementTaskIssues,
  ChangeSourceType,
} from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-agent-campaign-wizard',
  templateUrl: './agent-campaign-wizard.component.html',
  styleUrls: ['./agent-campaign-wizard.component.scss'],
})
export class AgentCampaignWizardComponent {
  @HostBinding('class') className = 'agent-campaign-wizard';

  @Input() withSupportButton = true;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;
  @Input() set sourceType(value: ChangeSourceType | null) {
    this._sourceType = value ?? null;
    this.agentCampaignWizardService.setSourceType(this._sourceType);
  }
  get sourceType(): ChangeSourceType | null {
      return this._sourceType;
  }

  currentStepName$: Observable<AgentCampaignStepName>;
  steps$: Observable<AgentCampaignStep[]>;
  stepsIssues$: Observable<{
    [key in AgentCampaignStepName]?: RelatedCampaignsManagementTaskIssues[];
  }>;

  readonly AgentCampaignStepName = AgentCampaignStepName;
  readonly inProgress$: Observable<boolean>;
  readonly hasDataToSubmit$: Observable<boolean>;
  private _sourceType: ChangeSourceType | null = null;

  constructor(private agentCampaignWizardService: AgentCampaignWizardService) {
    this.steps$ = agentCampaignWizardService.steps$;
    this.currentStepName$ = agentCampaignWizardService.currentStepName$;
    this.stepsIssues$ = agentCampaignWizardService.stepsIssues$;
    this.inProgress$ = agentCampaignWizardService.inProgress$;
    this.hasDataToSubmit$ = agentCampaignWizardService.hasDataToSubmit$;
  }

  onGetSupport = this.agentCampaignWizardService.getSupport;

  setCurrentStep = (step: AgentCampaignStep) => this.agentCampaignWizardService.setCurrentStep(step);
}
