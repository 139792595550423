import { Inject, Injectable } from '@angular/core';
import { CommonFireStoreDao, QueryParam, WhereFilterOperandKeys } from '../dao/CommonFireStoreDao.dao';
import { FIREBASE_APP } from '../injections/firebase-app';
import { FirebaseApp } from 'firebase/app';
import {
  EventInquiryRequest,
  EventInquiryRequestKeys,
  EVENT_INQUIRY_COLLECTION,
  EVENT_INQUIRY_REQUEST_COLLECTION,
  EventInquiryRequestStatus,
} from '@ag-common-lib/lib/models/registration/event-inquiry-request.model';
import { combineLatest, lastValueFrom, map, mergeMap, Observable, of, take } from 'rxjs';
import { ConferenceService } from './conference.service';
import { Conference } from '@ag-common-lib/public-api';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable()
export class EventInquiryRequestsService {
  public readonly fsDao: CommonFireStoreDao<EventInquiryRequest>;
  private readonly eventInquiriesCollectionPath = EVENT_INQUIRY_COLLECTION;
  private readonly eventInquiryRequestsCollectionPath = EVENT_INQUIRY_REQUEST_COLLECTION;

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    private conferenceService: ConferenceService,
  ) {
    this.fsDao = new CommonFireStoreDao<EventInquiryRequest>(fireBaseApp, null, null);
  }

  getAllByAgentDbId(agentDbId: string): Observable<EventInquiryRequest[]> {
    const qp: QueryParam[] = [
      new QueryParam(EventInquiryRequestKeys.agentDbId, WhereFilterOperandKeys.equal, agentDbId),
    ];

    return this.fsDao.getCollectionGroupSnapshot(this.eventInquiryRequestsCollectionPath, qp).pipe(
      map(snapshot => {
        return snapshot.docs.map(doc => {
          if (!doc.exists()) {
            return null;
          }
          const data = doc.data();

          return data;
        });
      }),
    );
  }

  getPendingRequestsByAgentDbId = (
    agentDbId: string,
  ): Observable<{ conference: Conference; eventInquiryRequest: EventInquiryRequest }[]> => {
    return this.getAllByAgentDbId(agentDbId).pipe(
      mergeMap(requests => {
        const pendingRequests = requests?.filter(request => {
          const status = request?.[EventInquiryRequestKeys.status];

          if (new Set([EventInquiryRequestStatus.approved]).has(status)) {
            return false;
          }
          return true;
        });

        if (!pendingRequests?.length) {
          return of([]);
        }

        const eventInquiryRequestsMap = new Map();
        pendingRequests?.forEach(request => {
          const eventInquiryDbId = request?.[EventInquiryRequestKeys.eventInquiryDbId];

          eventInquiryRequestsMap.set(eventInquiryDbId, request);
        });
        const queries: QueryParam[] = [];

        eventInquiryRequestsMap?.size &&
          queries.push(
            new QueryParam(BaseModelKeys.dbId, WhereFilterOperandKeys.in, Array.from(eventInquiryRequestsMap.keys())),
          );

        const observables: Observable<Conference[]>[] = [];
        const batchSize = 30;
        const batchCount = Math.ceil(queries?.length / batchSize);

        for (let i = 0; i < batchCount; i++) {
          const startIndex = i * batchSize;
          const endIndex = (i + 1) * batchSize;
          const batchValues = queries.slice(startIndex, endIndex);

          const observable = this.conferenceService.getList(batchValues);

          observables.push(observable);
        }

        return combineLatest(observables).pipe(
          map(list => {
            const conferences = list.flat(1);

            return conferences;
          }),
          map(conferences => {
            return conferences.map(conference => {
              const conferenceDbId = conference?.[BaseModelKeys.dbId];
              const eventInquiryRequest = eventInquiryRequestsMap.get(conferenceDbId);

              return { conference, eventInquiryRequest };
            });
          }),
        );
      }),
    );
  };

  getList(eventInquiryDbId: string, qp: QueryParam[] = []) {
    const table = this.getCollectionPath(eventInquiryDbId);

    return this.fsDao.getList(table, qp);
  }

  getIsEmailUniq = (eventInquiryDbId: string, email: string): Promise<boolean> => {
    const table = this.getCollectionPath(eventInquiryDbId);

    return lastValueFrom(
      this.fsDao
        .getList(table, [new QueryParam(EventInquiryRequestKeys.email, WhereFilterOperandKeys.equal, email)], {
          sortField: EventInquiryRequestKeys.email,
          limit: 1,
        })
        .pipe(
          map(list => {
            return !list?.length;
          }),
          take(1),
        ),
    );
  };

  async create(eventInquiryDbId: string, data: EventInquiryRequest) {
    const table = this.getCollectionPath(eventInquiryDbId);
    const eventInquiryRequest = await this.fsDao.create(
      Object.assign(data, { [EventInquiryRequestKeys.eventInquiryDbId]: eventInquiryDbId }),
      table,
    );

    return eventInquiryRequest;
  }

  getData(eventInquiryDbId: string, documentId: string): Observable<EventInquiryRequest> {
    const table = this.getCollectionPath(eventInquiryDbId);

    return this.fsDao.getDocument(table, documentId).pipe(
      map(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          return data;
        }
        return null;
      }),
    );
  }

  async update(eventInquiryDbId: string, documentId: any, updates: Partial<EventInquiryRequest>) {
    const table = this.getCollectionPath(eventInquiryDbId);

    const eventInquiryRequest = await this.fsDao.updateFields(updates, documentId, table);

    return eventInquiryRequest;
  }

  delete(eventInquiryDbId: string, documentId: any) {
    const table = this.getCollectionPath(eventInquiryDbId);

    return this.fsDao.delete(documentId, table).then(response => {
      return response;
    });
  }

  private getCollectionPath(eventInquiryDbId: string) {
    return [this.eventInquiriesCollectionPath, eventInquiryDbId, this.eventInquiryRequestsCollectionPath].join('/');
  }
}
