import { Pipe, PipeTransform } from '@angular/core';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { map } from 'rxjs/operators';
import { LookupsService } from '../../public-api';
import { Observable } from 'rxjs';

@Pipe({ name: 'suffix' })
export class SuffixPipe implements PipeTransform {
  constructor(private lookupsService: LookupsService) {}

  transform(suffixId: string): Observable<string> {
    return this.lookupsService.suffixesLookup$.pipe(
      map(suffixes => {
        if (!Array.isArray(suffixes)) {
          return '';
        }
        return suffixes.find(({ [BaseModelKeys.dbId]: dbId }) => dbId === suffixId)?.description ?? '';
      }),
    );
  }
}
