import { Injectable } from '@angular/core';
import {
  AgentService,
  ConferenceGuestsService,
  ConferenceRegistrantsService,
  ConferenceService,
  LookupsService,
} from 'ag-common-svc/public-api';
import {
  Agent,
  AttendeeKeys,
  Conference,
  GuestData,
  LookupBase,
  LookupKeys,
  Lookups,
  Registrant,
} from '@ag-common-lib/public-api';
import { BehaviorSubject, combineLatest, filter, map, mergeMap, Observable } from 'rxjs';
import { normalizeLookup } from './utils/normalize-lookup';
import { shareReplay, tap } from 'rxjs/operators';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable()
export class ChangesHistoryGridService {
  agentsDataSource$ = this.agentService.agentsDataSource$;
  agentsLookupBase$ = this.agentService.getList([], { includeRef: true, sortField: 'p_agent_first_name' }).pipe(
    tap(() => {}),
    map(
      (agents): Partial<LookupBase[]> =>
        Array.isArray(agents)
          ? agents.map((agent: Agent) => {
              const { dbId, p_agent_first_name, p_agent_last_name } = agent;
              return {
                [LookupKeys.value]: dbId,
                [LookupKeys.description]: [p_agent_first_name, p_agent_last_name].filter(Boolean).join(' '),
              };
            })
          : [],
    ),
    shareReplay(1),
  );

  private _agentDbId$ = new BehaviorSubject<string>(null);
  agentDbId$ = this._agentDbId$.asObservable();

  private _conferenceDbId$ = new BehaviorSubject<string>(null);
  conferenceDbId$ = this._conferenceDbId$.asObservable();
  conference$: Observable<Conference> = this._conferenceDbId$.pipe(
    filter(Boolean),
    mergeMap(conferenceDbId => {
      return this.conferenceService.getDocumentData(conferenceDbId);
    }),
    shareReplay(1),
  );

  private _registrantDbId$ = new BehaviorSubject<string>(null);
  registrantDbId$ = this._registrantDbId$.asObservable();
  registrant$: Observable<Registrant> = combineLatest({
    registrantDbId: this._registrantDbId$.pipe(filter(Boolean)),
    conferenceDbId: this._conferenceDbId$.pipe(filter(Boolean)),
  }).pipe(
    mergeMap(({ conferenceDbId, registrantDbId }) => {
      return this.conferenceRegistrantsService.getDocumentData(conferenceDbId, registrantDbId);
    }),
    shareReplay(1),
  );
  registrantGuestsLookup$: Observable<LookupBase[]>;

  constructor(
    private agentService: AgentService,
    private lookupsService: LookupsService,
    private conferenceService: ConferenceService,
    private conferenceGuestsService: ConferenceGuestsService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
  ) {}

  getLookupByName = (key: Lookups) => {
    return this.lookupsService.getLookupByName(key).pipe(
      map(items => normalizeLookup(items)),
      shareReplay(1),
    );
  };

  setAgentData(agent: Agent) {
    const agentDbId = agent?.[BaseModelKeys.dbId];
    this._agentDbId$.next(agentDbId);
  }

  setAttendeeData(attendee: Attendee) {
    const conferenceDbId = attendee?.[AttendeeKeys.conferenceDbId];
    this._conferenceDbId$.next(conferenceDbId);
    const registrantDbId = attendee?.registrant?.[BaseModelKeys.dbId];
    this._registrantDbId$.next(registrantDbId);
    this.registrantGuestsLookup$ = this.conferenceGuestsService.getList(conferenceDbId, registrantDbId).pipe(
      map(
        (guests): Partial<LookupBase[]> =>
          Array.isArray(guests)
            ? guests.map((guest: GuestData) => {
                return {
                  [LookupKeys.value]: guest?.dbId,
                  [LookupKeys.description]: [guest?.first_name, guest?.last_name].filter(Boolean).join(' '),
                };
              })
            : [],
      ),
      shareReplay(1),
    );
  }
}
