import { Injectable } from '@angular/core';
import { firstValueFrom, map, shareReplay } from 'rxjs';
import { AgentKeys } from '@ag-common-lib/public-api';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';
import {
  AgentService,
  ConferenceRegistrantsService,
  ConferenceService,
  DynamicListsGroupService,
  DynamicSegmentsGroupService,
} from 'ag-common-svc/public-api';
import {
  DynamicListsGroupKeys,
  DynamicSegmentsGroupKeys,
  FilterGroup,
  NotificationOptInGroupType,
} from '@ag-common-lib/lib';
import { checkIsMeetFilterCriteria } from 'ag-common-svc/lib/utils/filter-builder-converter.utils';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';
import { QueryParam, WhereFilterOperandKeys } from 'ag-common-svc/lib/dao/CommonFireStoreDao.dao';

@Injectable()
export class NotificationsSchedulerCheckedRecipientsGridService {
  private readonly agents$ = this.agentService.getList([], { sortField: AgentKeys.p_agent_first_name });

  constructor(
    private agentService: AgentService,
    private conferenceService: ConferenceService,
    private registrantService: ConferenceRegistrantsService,
    private dynamicListsGroupService: DynamicListsGroupService,
    private dynamicSegmentsGroupService: DynamicSegmentsGroupService,
  ) {}

  getRecipientDbIdsByLists(listDbIds: string[]) {
    return this.dynamicListsGroupService.lists$.pipe(
      map(lists =>
        lists.reduce(
          (acc, item) =>
            listDbIds.includes(item?.[BaseModelKeys.dbId])
              ? [...acc, ...item?.[DynamicListsGroupKeys.recipientsDbIds]]
              : acc,
          [],
        ),
      ),
      map(recipientIds => recipientIds ?? []),
      shareReplay(1),
    );
  }

  async getRecipientDbIdsByChannel(channels: string[]) {
    const promises: Promise<any[]>[] = [];
    if (!channels?.length) {
      return await Promise.resolve([]);
    }

    const batchSize = 30;
    const batchCount = Math.ceil(channels?.length / batchSize);

    for (let i = 0; i < batchCount; i++) {
      const startIndex = i * batchSize;
      const endIndex = (i + 1) * batchSize;
      const batchValues = channels.slice(startIndex, endIndex);

      const qp = new QueryParam(AgentKeys.notificationChannels, WhereFilterOperandKeys.arrayContainsAny, batchValues);

      const promise = firstValueFrom(
        this.agentService.getList([qp], { sortField: 'p_agent_first_name', includeRef: false }),
      );

      promises.push(promise);
    }

    return Promise.all(promises).then(items => items.flat(1).map(({ dbId }) => dbId));
  }

  async getRecipientDbIdsBySegments(segmentDbIds: string[]) {
    const promises: Promise<any[]>[] = [];

    if (!segmentDbIds?.length) {
      return await Promise.resolve([]);
    }

    const segments = await firstValueFrom(
      this.dynamicSegmentsGroupService.segments$.pipe(map(segments => segments ?? [])),
    );

    const filteredSegments = segments.filter(segment => segmentDbIds?.includes(segment?.[BaseModelKeys.dbId]));
    filteredSegments.forEach(segment => {
      const type = segment?.[DynamicSegmentsGroupKeys.recipientsCollection];
      const filterExpression = segment?.[DynamicSegmentsGroupKeys.filterExpression];

      if (type === NotificationOptInGroupType.agent) {
        const recipientDbIds = this.getRecipientDbIdsByFilter(filterExpression);
        promises.push(recipientDbIds);
      }

      if (type === NotificationOptInGroupType.registrant) {
        const conferenceDbId = segment?.[DynamicSegmentsGroupKeys.collectionEventDbId];
        const recipientsFromConferenceDbIds = this.getRecipientsFromConference(conferenceDbId, filterExpression);
        promises.push(recipientsFromConferenceDbIds);
      }
    });

    return await Promise.all(promises).then(items => items.flat(1));
  }

  async getRecipientsFromConference(conferenceDbId: string, filterExpression: FilterGroup) {
    const agentsDbIds = [];

    if (!conferenceDbId?.length) {
      return [];
    }

    const conference = await firstValueFrom(
      this.conferenceService
        .getList([new QueryParam(BaseModelKeys.dbId, WhereFilterOperandKeys.equal, conferenceDbId)])
        .pipe(
          map(conference => conference[0]),
          shareReplay(1),
        ),
    );
    const registrant = await firstValueFrom(
      this.registrantService
        .getRegistrantsByConferenceId(conferenceDbId, 'first_name')
        .pipe(map(registrants => registrants[0])),
    );
    const attendee = new Attendee(conference, registrant);
    const attendeeDbId = attendee?.[BaseModelKeys.dbId];

    if (!filterExpression) {
      agentsDbIds.push(attendeeDbId);
    }

    const isMeetFilterCriteria = checkIsMeetFilterCriteria(attendee, filterExpression);

    if (isMeetFilterCriteria) {
      agentsDbIds.push(attendeeDbId);
    }
    return agentsDbIds;
  }

  async getRecipientDbIdsByFilter(filterExpression: FilterGroup) {
    const results = [];
    const agents = await firstValueFrom(this.agents$);
    agents.forEach(agent => {
      const isMeetFilterCriteria = checkIsMeetFilterCriteria(agent, filterExpression);

      if (isMeetFilterCriteria) {
        const agentDbId = agent?.[BaseModelKeys.dbId];
        results.push(agentDbId);
      }
    });
    return results;
  }
}
