import { AfterViewInit, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Agent } from 'ag-common-lib/public-api';
import { ConferenceRegistrationGridService } from './conference-registration-grid.service';
import { ActivatedRoute, Params } from '@angular/router';
import { filter, map } from 'rxjs';
import { AgentService } from 'ag-common-svc/lib/services/agent.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AttendeesListService } from '../attendees-list.service';
import { DxAccordionComponent } from 'devextreme-angular';
import { AttendeeDetailsModalComponent } from '../conferences/modals/attendee-details-modal/attendee-details-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-conference-registration-grid',
  templateUrl: './conference-registration-grid.component.html',
  styleUrls: ['./conference-registration-grid.component.scss'],
  providers: [ConferenceRegistrationGridService, AttendeesListService],
})
export class ConferenceRegistrationsGridComponent implements OnInit, AfterViewInit {
  @HostBinding('class') className = 'conference-registration-grid';
  @ViewChild('attendeeDetailsModalRef', { static: true }) attendeeDetailsModalComponent: AttendeeDetailsModalComponent;
  @ViewChild('accordionRef', { static: false }) accordionComponent: DxAccordionComponent;

  protected readonly lastUpdateDate$ = this.conferenceRegistrationGridService.lastUpdateDate$;
  protected conferences$ = this.conferenceRegistrationGridService.conferencesList$;
  protected conferenceDbId$ = this.conferenceRegistrationGridService.conferenceDbId$;
  protected embeddedFormEnabled$ = this.conferenceRegistrationGridService.embeddedFormEnabled$;
  protected agentDetailsModalVisible$ = this.conferenceRegistrationGridService.agentDetailsModalVisible$;
  protected selectedAgent$ = this.conferenceRegistrationGridService.selectedAgent$;

  showConferenceSelectBox = true;

  constructor(
    private conferenceRegistrationGridService: ConferenceRegistrationGridService,
    private activatedRoute: ActivatedRoute,
    private agentService: AgentService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        untilDestroyed(this),
        map((params: Params) => params['id'] ?? null),
        filter(Boolean),
      )
      .subscribe(conferenceDbId => {
        this.conferenceRegistrationGridService.setConferenceDbId(conferenceDbId);
      });

    this.conferenceRegistrationGridService.showAttendeeDetailsModal$
      .pipe(untilDestroyed(this))
      .subscribe(({ attendee, section }) => {
        this.attendeeDetailsModalComponent.showModal(attendee, section);
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.accordionComponent.instance.updateDimensions();
    });
  }

  setConferenceDbId = conferenceDbId => this.conferenceRegistrationGridService.setConferenceDbId(conferenceDbId);

  onAgentModalHidden = this.conferenceRegistrationGridService.onAgentModalHidden;

  protected forceRefresh = this.conferenceRegistrationGridService.forceRefresh;
}
