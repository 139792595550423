import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import {
  AGENT_SEARCH_KEYS_CONFIG,
  AgentKeys,
  WEBSITES_KEYS_CONFIG,
  SOCIALS_KEYS_CONFIG,
  ADDRESSES_KEYS_CONFIG,
  GOALS_KEYS_CONFIG,
  PHONE_NUMBERS_KEYS_CONFIG,
  APPROVE_DENY_REASON_KEYS_CONFIG,
  AGENT_CARRIER_KEYS_CONFIG,
  AGENT_STATE_LICENSES_KEYS_CONFIG,
  ChangeLogsDataType,
  FAVORITES_KEYS_CONFIG,
  CAMPAIGNS_KEYS_CONFIG,
  AGENT_HEADSHOT_KEYS_CONFIG,
} from '@ag-common-lib/public-api';
import { getChangedLog, getDateTimeLog, logHasKey } from '../../utils/normalize-log';

@Component({
  selector: 'ag-crm-agent-info-change-log',
  templateUrl: './agent-info-log-details.component.html',
  styleUrls: ['./agent-info-log-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentInfoLogDetailsComponent {
  @Input()
  set changeLogs(delta: Delta) {
    const logs = {};
    const dateKeySet = new Set([
      AgentKeys.registrationDate,
      AgentKeys.dob,
      AgentKeys.approvalDate,
      AgentKeys.prospect_referred_to_date,
    ]);
    Object.entries(delta).forEach(data => {
      const key = data[0] as AgentKeys;
      const value = data[1];

      if (dateKeySet.has(key)) {
        Object.assign(logs, { [key]: getDateTimeLog(value) });
        return;
      }
      Object.assign(logs, { [key]: value });
    });
    console.log('logs ', logs);
    this._changeLogs = logs;
  }
  get changeLogs(): Delta {
    return this._changeLogs;
  }
  private _changeLogs: Delta;
  protected readonly agentInfoConfig = AGENT_SEARCH_KEYS_CONFIG;
  protected readonly agentInfoKeysConfig;
  protected readonly AGENT_SEARCH_KEYS_CONFIG = AGENT_SEARCH_KEYS_CONFIG;
  protected readonly AgentKeys = AgentKeys;
  protected readonly websitesKeysLookup = WEBSITES_KEYS_CONFIG;
  protected readonly socialsKeysLookup = SOCIALS_KEYS_CONFIG;
  protected readonly addressesKeysLookup = ADDRESSES_KEYS_CONFIG;
  protected readonly goalsKeysLookup = GOALS_KEYS_CONFIG;
  protected readonly phoneNumbersKeysLookup = PHONE_NUMBERS_KEYS_CONFIG;
  protected readonly agentCarrierKeysLookup = AGENT_CARRIER_KEYS_CONFIG;
  protected readonly agentStateLicensesKeysLookup = AGENT_STATE_LICENSES_KEYS_CONFIG;
  protected readonly agentHeadshotKeysLookup = AGENT_HEADSHOT_KEYS_CONFIG;
  protected readonly favoritesKeysLookup = FAVORITES_KEYS_CONFIG;
  protected readonly campaignsKeysLookup = CAMPAIGNS_KEYS_CONFIG;
  protected readonly approveDenyReasonKeysLookup = APPROVE_DENY_REASON_KEYS_CONFIG;
  protected readonly logHasKey = logHasKey;
  protected readonly ChangeLogsDataType = ChangeLogsDataType;
  protected readonly getChangedLog = getChangedLog;
}
