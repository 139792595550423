import { Pipe, PipeTransform } from '@angular/core';
import { ConferenceGuestsService, ConferenceRegistrantsService, ConferenceService } from 'ag-common-svc/public-api';
import { combineLatest, map, Observable, of, shareReplay } from 'rxjs';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Pipe({
  name: 'attendeesListByConference',
})
export class AttendeesListByConferencePipe implements PipeTransform {
  constructor(
    private conferenceService: ConferenceService,
    private registrantService: ConferenceRegistrantsService,
    private guestsService: ConferenceGuestsService,
  ) {}

  transform(conferenceDbId: string): Observable<Attendee[]> {
    if (!conferenceDbId) {
      return of([]);
    }
    return combineLatest({
      conference: this.conferenceService.getDocumentData(conferenceDbId),
      registrants: this.registrantService.getRegistrantsByConferenceId(conferenceDbId, 'first_name'),
      guestsGroupedByRegistrantId: this.guestsService.getConferenceGuestsByConferenceId(conferenceDbId),
    }).pipe(
      map(({ conference, registrants, guestsGroupedByRegistrantId }) => {
        const attendees = [];
        registrants.forEach(registrant => {
          const registrantDbId = registrant?.[BaseModelKeys.dbId];
          const guests = guestsGroupedByRegistrantId[registrantDbId];
          const attendee = new Attendee(conference, registrant);
          attendees.push(attendee);

          guests?.forEach(guest => {
            const attendee = new Attendee(conference, registrant, guest);
            attendees.push(attendee);
          });
        });
        return attendees;
      }),
      shareReplay(1),
    );
  }
}
