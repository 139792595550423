import { Component, HostBinding, ViewChild } from '@angular/core';
import { DxFileUploaderComponent, DxSelectBoxComponent, DxValidatorComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { PolicyTransaction, PolicyTransactionKeys } from 'ag-common-lib/lib/models/domain/policy-transaction.model';
import { ReportsService } from 'ag-common-svc/lib/services/reports.service';
import { Report } from 'ag-common-lib/lib/models/utils/report.model';
import { BehaviorSubject } from 'rxjs';
import { ImportFileValidatorService } from 'ag-common-svc/lib/services/import-file-validator.service';
import { TransactionGrouperService } from 'ag-common-svc/lib/services/transaction-grouper.service';
import { format, parse } from 'date-fns';
import { groupBy } from 'lodash';
import * as hash from 'object-hash';
import { CloudFunctionsService } from 'ag-common-svc/lib/services/cloud-functions.service';
import { SupportedCollections, UploadFilePayload, UploadMediaPayloadKeys, YEARS_LIST } from '@ag-common-lib/public-api';
import { fileToUIBase64 } from 'ag-common-svc/lib/components/ag-media-uploader/ag-media-uploader-modal.utils';
import { FireStorageDao } from 'ag-common-svc/public-api';
import { PolicyTransactionSummary } from 'ag-common-lib/lib/models/domain/policy-transaction-summaries.model';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import { ReportAdministrationHistoryModalComponent } from './report-administration-history-modal/report-administration-history-modal.component';

@Component({
  selector: 'ag-crm-report-administration',
  templateUrl: './report-administration.component.html',
  styleUrls: ['./report-administration.component.scss'],
})
export class ReportAdministrationComponent {
  @HostBinding('class') className = 'report-administration';
  @ViewChild('append') append: DxSelectBoxComponent;
  @ViewChild('fileUploader', { static: false }) fileUploaderComponent: DxFileUploaderComponent;
  @ViewChild('yearSelectBox', { static: false }) yearSelectBoxComponent: DxSelectBoxComponent;
  @ViewChild('yearTargetValidator', { static: false }) yearValidator: DxValidatorComponent;

  @ViewChild(ReportAdministrationHistoryModalComponent)
  reportAdministrationHistoryModalComponent: ReportAdministrationHistoryModalComponent;

  files: File[] = [];

  showImportButton: boolean = false;

  messages: string[] = [];

  premiumTotal = 0;
  policies = 0;

  fileUploadPromises = [];
  allFilesUploaded = false;

  protected BaseModelKeys = BaseModelKeys;
  protected loadingVisible = false;
  protected importInProgress = false;
  protected asOfLabel = '"As Of" Date';
  protected years: number[] = YEARS_LIST;
  protected asOfDate: Date = new Date();

  private _selectedYear$ = new BehaviorSubject<number>(null);
  protected selectedYear$ = this._selectedYear$.asObservable();

  protected transactions: PolicyTransaction[];
  protected summaries: PolicyTransactionSummary[];

  constructor(
    private importFileValidatorService: ImportFileValidatorService,

    private reportsService: ReportsService,
    private transactionGrouperService: TransactionGrouperService,
    private fireStorageDao: FireStorageDao,
    private toastrService: ToastrService,
    private cloudFunctionsService: CloudFunctionsService,
  ) {}

  protected handleYearChange = (year: number) => {
    this._selectedYear$.next(year);
    this.clearOptions();
    this.yearSelectBoxComponent.instance.close();
  };

  protected onUploaded = e => {
    // if file uploaded add to file list
    this.files.push(e.file);
  };

  protected onFilesUploaded = e => {
    // all files uploaded
    this.allFilesUploaded = true;
  };


  protected onFileUploaded = async (file: File) => {
    const fileUploaderInstance = this.fileUploaderComponent.instance;
    const validationResults = this.yearValidator.instance.validate();
    if (!validationResults.isValid || this.files.some(({name}) => name === file.name)) {
      fileUploaderInstance.abortUpload();
      fileUploaderInstance.removeFile(file);
      return;
    }

    this.loadingVisible = true;
    if (!this.files.length || this.files.some(({name}) => name !== file.name)) {
      this.addMessage('Uploading file(s)... <' + file.name + '>');
    }

    const promise = this.importFileValidatorService.importPaidFiles([file], this.messages, this._selectedYear$.value);
    this.fileUploadPromises.push(promise);

    try {
      const rawTransactions = await Promise.all(this.fileUploadPromises).then(items => items.flat(1));
      const { transactions, agentSummaries } = this.normalizeTransactions(rawTransactions);
      this.transactions = transactions;
      this.summaries = agentSummaries;
      this.showImportButton = true;
    } catch (error) {
      this.showImportButton = false;
      this.transactions = [];
      this.summaries = [];
      this.premiumTotal = 0;
      this.policies = 0;
      this.addMessage('Uploading file(s)... <' + file.name + '>  FAILED');
    }
    this.addMessage('Uploading file(s)... <' + file.name + '>  COMPLETE');
    this.addMessage('........................');
    this.loadingVisible = false;
  };

  private normalizeTransactions = (transactions: PolicyTransaction[]) => {
    const selectedYear = this._selectedYear$.value;
    const agentIdsMap = new Map<string, string>();
    const transactionsHashMap = new Map<string, number[]>();

    const normalizedTransactions = transactions.map((transaction, index) => {
      const transactionHash = this.getTransactionHash(transaction);
      const transactionDate = parse(transaction.transdate, 'M/d/yyyy', new Date());
      const year = transactionDate.getFullYear();
      const week = transactionDate.getWeek();
      const formattedData = format(transactionDate, 'yyyy/MM/dd');

      if (!transactionsHashMap.has(transactionHash)) {
        transactionsHashMap.set(transactionHash, []);
      }

      transactionsHashMap.get(transactionHash).push(index + 2);

      let agentId = transaction.agent_id;

      if (!agentId) {
        const agentName = transaction?.agent_name;

        if (!agentIdsMap.has(agentName)) {
          const id = this.transactionGrouperService.uuidv4();
          agentIdsMap.set(agentName, id);
        }
        agentId = agentIdsMap.get(agentName);
      }

      return Object.assign({}, transaction, {
        year,
        week,
        hash: transactionHash,
        transdate: formattedData,
        agent_id: agentId,
      });
    });

    const agentSummaries = [];
    const transactionsGroupedByAgentId: {
      [key: string]: PolicyTransaction[];
    } = {};
    const agentIdMap = new Map<string, Set<string>>();

    this.premiumTotal = 0;
    this.policies = 0;

    normalizedTransactions.forEach(transaction => {
      const agentId = transaction?.[PolicyTransactionKeys.agentId];
      const agentName = transaction?.[PolicyTransactionKeys.agentName];
      this.premiumTotal += Number(transaction?.[PolicyTransactionKeys.weightedPrem]) ?? 0;
      this.policies += Number(transaction?.[PolicyTransactionKeys.policies]) ?? 0;

      if (!agentIdMap.has(agentId)) {
        agentIdMap.set(agentId, new Set());
      }
      agentIdMap.get(agentId).add(agentName);

      if (!transactionsGroupedByAgentId[agentId]) {
        transactionsGroupedByAgentId[agentId] = [];
      }
      transactionsGroupedByAgentId[agentId].push(transaction);
    });

    Object.entries(transactionsGroupedByAgentId).forEach(([agentId, agentTransactions]) => {
      const transactionsGroupedByMGAId = groupBy(agentTransactions, PolicyTransactionKeys.mgaId);

      Object.entries(transactionsGroupedByMGAId).forEach(([mgaId, agencyTransactions]) => {
        const totals = {
          [PolicyTransactionKeys.faceAmount]: 0,
          [PolicyTransactionKeys.lifePrem]: 0,
          [PolicyTransactionKeys.targetPrem]: 0,
          [PolicyTransactionKeys.excessPrem]: 0,
          [PolicyTransactionKeys.annuity]: 0,
          [PolicyTransactionKeys.policies]: 0,
          [PolicyTransactionKeys.weightedPrem]: 0,
        };
        agencyTransactions?.forEach(transaction => {
          Object.keys(totals).forEach(totalKey => {
            const value = Number(transaction?.[totalKey]) * 100;
            totals[totalKey] += value;
          });
        });

        const nameSet = agentIdMap.get(agentId);

        const agentName = Array.from(nameSet)?.join(' / ');
        const agentSummary = {
          [PolicyTransactionKeys.year]: selectedYear,
          [PolicyTransactionKeys.agentId]: agentId,
          [PolicyTransactionKeys.agentName]: agentName,
          [PolicyTransactionKeys.mgaId]: mgaId,
        };

        Object.entries(totals).forEach(([totalKey, total]) => {
          agentSummary[totalKey] = total / 100;
        });

        agentSummaries.push(agentSummary);
      });
    });

    return { transactions: normalizedTransactions, agentSummaries };
  };

  private clearOptions = () => {
    this.messages = [];
    this.transactions = [];
    this.summaries = [];
    this.premiumTotal = 0;
    this.policies = 0;
    this.files = [];
    this.fileUploadPromises = [];
    this.fileUploaderComponent.instance.reset();
    this.importFileValidatorService.reset();
    this.showImportButton = false;
    this.allFilesUploaded = false;
  };

  clearUploadedFiles() {
    this.clearOptions();
  }

  handleImport = async () => {
    this.importInProgress = true;
    try {
      await this.importTransactions();

      await this.importSummaries();
      await this.wrapUpImport(this.transactions?.length, this.summaries?.length);

      this.toastrService.success('Records Successfully Imported!', null, { disableTimeOut: true });
    } catch (error) {
      this.toastrService.error('Records Import Failed!', null, { disableTimeOut: true });
    }

    this.importInProgress = false;
    this.clearOptions();
  };

  private importTransactions = async () => {
    try {
      const selectedYear = this._selectedYear$.value?.toString();
      console.time('importTransactions');

      const uploadResult = await this.fireStorageDao.uploadAsJSON(
        this.transactions,
        this.asOfDate.toISOString(),
        SupportedCollections.policyTransactions,
      );
      const bucket = uploadResult?.metadata.bucket;
      const filePath = uploadResult?.metadata.fullPath;

      await this.cloudFunctionsService.importTransactionsToElastic({
        year: selectedYear,
        collection: SupportedCollections.policyTransactions,
        bucket,
        filePath,
      });
      console.timeEnd('importTransactions');
    } catch (error) {
      throw new Error(error);
    }
  };

  private getTransactionHash = transaction => {
    const objToHash = {};

    Object.entries(transaction).forEach(([key, value]: [string, string]) => {
      const normalizedValue = value?.toLocaleLowerCase()?.replace(/\s/g, '');

      if (!normalizedValue) {
        return;
      }

      objToHash[key] = normalizedValue;
    });

    const transactionHash = hash(objToHash);

    return transactionHash;
  };

  private importSummaries = async () => {
    try {
      const selectedYear = this._selectedYear$.value?.toString();
      console.time('importSummaries');
      const uploadResult = await this.fireStorageDao.uploadAsJSON(
        this.summaries,
        this.asOfDate.toISOString(),
        SupportedCollections.policyTransactionsSummaries,
      );
      const bucket = uploadResult?.metadata.bucket;
      const filePath = uploadResult?.metadata.fullPath;

      await this.cloudFunctionsService.importTransactionsToElastic({
        year: selectedYear,
        collection: SupportedCollections.policyTransactionsSummaries,
        bucket,
        filePath,
      });
      console.timeEnd('importSummaries');
    } catch (error) {
      throw new Error(error);
    }
  };

  private async wrapUpImport(transactionsCount: number, summariesCount: number) {
    const report: Report = { ...new Report() };
    const selectedYear = this._selectedYear$.value;
    const sourceFileKey = await this.uploadSourceFile();

    report.fileName = this.files[0].name;
    report.year = selectedYear;
    report.recordsImported = transactionsCount;
    report.summariesGenerated = summariesCount;
    report.asOfDate = this.asOfDate.toISOString();
    report.sourceFileKey = sourceFileKey;

    this.reportsService.create(report);
  }

  private async uploadSourceFile() {
    const sourceFile = this.files[0];
    const fileName = sourceFile.name;
    const contentType = sourceFile.type;
    const base64 = await fileToUIBase64(sourceFile)
      .then(fileBase64 => {
        return fileBase64.split(',')[1];
      })
      .catch(() => {
        return null;
      });

    if (!base64) {
      return null;
    }

    const payload: UploadFilePayload = {
      [UploadMediaPayloadKeys.filePath]: 'production-import-files',
      [UploadMediaPayloadKeys.mediaData]: base64,
      [UploadMediaPayloadKeys.contentType]: contentType,
      [UploadMediaPayloadKeys.metadata]: {
        fileName,
        date: this.asOfDate.toISOString(),
      },
    };

    return this.cloudFunctionsService.uploadFile(payload).catch(() => {
      return null;
    });
  }

  protected showUploadHistory() {
    this.reportAdministrationHistoryModalComponent.showModal();
  }

  private addMessage(message: string) {
    this.messages.unshift(message);
  }
}
