import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { EmailCampaignService } from './email-campaign-base-service';
import { CONFERENCES_COLLECTION_PATH, CONFERENCE_REGISTRANTS_COLLECTION_PATH } from 'ag-common-svc/public-api';
import { BaseCampaignsEmail, RegistrationSummaryEmail } from '@ag-common-lib/public-api';
import { FIREBASE_APP } from 'ag-common-svc/lib/injections/firebase-app';

export class ConferenceRegistrationCampaignEmail extends BaseCampaignsEmail {
  context: RegistrationSummaryEmail;
}

@Injectable()
export class ConferenceRegistrationEmailCampaignService extends EmailCampaignService<ConferenceRegistrationCampaignEmail> {
  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp);
  }

  sendEmailToRegistrant(
    conferenceDbId: string,
    registrantDbId: string,
    data: ConferenceRegistrationCampaignEmail,
  ): Promise<void | ConferenceRegistrationCampaignEmail> {
    const path = [CONFERENCES_COLLECTION_PATH, conferenceDbId, CONFERENCE_REGISTRANTS_COLLECTION_PATH, registrantDbId]
      .filter(Boolean)
      .join('/');
    return super.sendEmail(path, data);
  }
}
