import { Component, ViewChild } from '@angular/core';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import CustomStore from 'devextreme/data/custom_store';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { ReportsService } from 'ag-common-svc/lib/services/reports.service';
import { Report } from 'ag-common-lib/lib/models/utils/report.model';
import { parseISO } from 'date-fns';
import { Constants } from 'ag-common-lib/lib/constants/validation.constants';

@Component({
  selector: 'ag-crm-report-administration-history-modal',
  templateUrl: './report-administration-history-modal.component.html',
  styleUrl: './report-administration-history-modal.component.scss',
})
export class ReportAdministrationHistoryModalComponent {
  @ViewChild('reportsModalRef', { static: true }) excursionModalComponent: ModalWindowComponent;

  dataSource: any = {};

  readonly dateFormat = Constants.MMDDYYYY_DATE_SHORT_TIME_FORMAT;

  constructor(private reportsService: ReportsService) {
    this.dataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: function (loadOptions: any) {
        return reportsService.getAll();
      },
      remove: function (id: any) {
        return reportsService.delete(id);
      },
    });
  }

  showModal(): void {
    this.excursionModalComponent?.showModal();
  }

  protected calculateCreatedByCellValue = (report: Report) => {
    return report?.[BaseModelKeys.createdByEmail] ?? report?.uploadedBy;
  };

  protected calculateCreatedDateCellValue = (report: Report) => {
    if (report?.[BaseModelKeys.createdDate]) {
      return report?.[BaseModelKeys.createdDate];
    }

    try {
      const date = parseISO(report?.uploadDate);
      return date;
    } catch (error) {}

    return report?.uploadDate;
  };

  protected readonly BaseModelKeys = BaseModelKeys;
}
