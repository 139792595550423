import { Component, Input, OnInit } from '@angular/core';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import { AgentPermission, AgentPermissionKeys } from 'ag-common-lib/lib/models/utils/agent-permission.model';
import { ActiveLookup, Agent, AgentKeys, LookupKeys, Role, ChangeSourceType } from 'ag-common-lib/public-api';
import { AgentPermissionService } from 'ag-common-svc/lib/services/agent-permission.service';
import { AgentService, AuthService } from 'ag-common-svc/public-api';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { firstValueFrom, map, mergeMap, Observable } from 'rxjs';

@Component({
  selector: 'ag-crm-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent implements OnInit {
  @Input() agent: Agent;
  @Input() sourceType: ChangeSourceType | null = null;

  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;

  public agentToAdd: Pick<ActiveLookup, LookupKeys.description | LookupKeys.value>;
  public agentsDataSource$: Observable<DataSource>;
  public assignedAgentsDataSource$: Observable<AgentPermission[]>;

  constructor(
    public agentPermissionService: AgentPermissionService,
    public authService: AuthService,
    public agentService: AgentService,
  ) {}

  ngOnInit(): void {
    this.agentsDataSource$ = this.authService.loggedInAgent$.pipe(
      mergeMap(agent => {
        const role = agent?.[AgentKeys.role];
        if (role?.length && role?.some(role => role === Role.PORTAL_ADMIN)) {
          return firstValueFrom(this.agentService.getList());
        }
        return this.agentService.getMGAsByMGAId(agent?.p_mga_id, 'p_agent_last_name');
      }),
      map(agents => {
        return agents.map(agent => {
          const description = [
            agent?.p_agent_first_name,
            agent?.p_agent_last_name,
            agent?.p_email ? `(${agent?.p_email})` : '',
          ]
            .filter(Boolean)
            .join(' ');

          if (!description) {
            console.log('agent', agent?.[BaseModelKeys.dbId]);
            console.log('agent', agent);
          }
          return {
            value: agent?.dbId,
            description,
          };
        });
      }),
      map(
        items =>
          new DataSource({
            paginate: true,
            pageSize: 15,
            sort: [{ selector: 'description', desc: false }],
            store: new ArrayStore({
              key: 'value',
              data: Array.isArray(items) ? items : [],
            }),
          }),
      ),
    );

    this.assignedAgentsDataSource$ = this.agentPermissionService.getAgentPermissionsByOwnerId(
      this.agent[BaseModelKeys.dbId],
      AgentPermissionKeys.ownerName,
    );
  }

  async onAddAgentToPermission() {
    const agentEmail = await firstValueFrom(this.authService.loggedInAgent$.pipe(map(agent => agent?.p_email)));
    const permission: AgentPermission = Object.assign({}, new AgentPermission(), {
      created_by: agentEmail,
      granted_to_id: this.agentToAdd.value,
      granted_to_name: this.agentToAdd.description,
      owner_id: this.agent[BaseModelKeys.dbId],
      owner_name: this.agent[AgentKeys.p_agent_name],
      [BaseModelKeys.actionName]: this.sourceType,
    });

    this.agentPermissionService.create(permission);
    this.agentToAdd = null;
  }

  onRemoveAgentFromPermissionsList(e) {
    this.agentPermissionService.delete(e.itemData.dbId, this.sourceType);
  }
}
