import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import CustomStore from 'devextreme/data/custom_store';
import { showPager, sortingMethod } from 'ag-common-svc/lib/utils/data-grid.utils';
import { firstValueFrom } from 'rxjs';
import { DynamicListsGroupKeys } from '@ag-common-lib/lib';
import { LookupKeys } from '@ag-common-lib/public-api';
import { NotificationAddListsModalService } from './notification-add-lists-modal.service';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

const MODAL_TITLE = 'Add Lists' as const;

@Component({
  selector: 'ag-shr-notification-add-lists-modal',
  templateUrl: './notification-add-lists-modal.component.html',
  styleUrls: ['./notification-add-lists-modal.component.scss'],
  providers: [NotificationAddListsModalService],
})
export class NotificationAddListsModalComponent {
  @HostBinding('class') className = 'notification-add-lists-modal';

  @ViewChild('notificationAddListsModalRef', { static: false })
  protected notificationAddListsModalComponent: ModalWindowComponent;
  @ViewChild('listsGridRef', { static: false }) listsGrid: DxDataGridComponent;
  @Output() handleLists = new EventEmitter<string[]>();

  @Input() title: string = MODAL_TITLE;
  @Input() canWrite = false;

  protected listsDbIds: string[] = [];
  protected dataSource: CustomStore;

  protected readonly BaseModelKeys = BaseModelKeys;
  protected LookupKeys = LookupKeys;
  protected readonly DynamicListsGroupKeys = DynamicListsGroupKeys;

  constructor(private notificationAddListsModalService: NotificationAddListsModalService) {
    this.sortingMethod = this.sortingMethod.bind(this);
  }

  protected addNotificationLists() {
    this.handleLists.emit(this.listsDbIds);
    this.notificationAddListsModalComponent.forceCloseModal();
  }

  showModal = async (dynamicListsIds?: string[]) => {
    const lists = await firstValueFrom(this.notificationAddListsModalService.lists$);
    this.dataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: loadOptions => {
        return lists.filter(list => !dynamicListsIds?.includes(list[BaseModelKeys.dbId]));
      },
    });
    this.notificationAddListsModalComponent?.showModal();
  };

  onSelectionChanged(e) {
    this.listsDbIds = e?.selectedRowKeys ?? [];
    e.component.refresh(); // to show selected rows on top
  }

  sortingMethod = (value1: any, value2: any) => sortingMethod(this.listsGrid?.instance, value1, value2);

  showPager = (): boolean => showPager(this.listsGrid?.instance);
}
