import {
  AGENTS_COLLECTION_NAME,
  AgentDocumentCollections,
  AgentKeyMapping,
  AgentKeys,
  ElasticAgentIndexedFields,
} from '../../domain/agent.model';
import { IndexProperties } from '../elastic-search.model';
import { DocumentWriteLogKeyMapping } from '../../domain/document-write-log.model';
import { PolicyTransactionMapping } from '../../domain/policy-transaction.model';
import { PolicyTransactionSummaryMapping } from '../../domain/policy-transaction-summaries.model';

export const SupportedCollections = {
  agents: AGENTS_COLLECTION_NAME,
  policyTransactions: 'policy-transactions',
  policyTransactionsSummaries: 'summaries-policy-transactions',
  documentWriteLog: 'document-writes-log',
} as const;

export type SupportedCollectionsKeys = (typeof SupportedCollections)[keyof typeof SupportedCollections];

export const IndexFields: Map<SupportedCollectionsKeys, Array<any>> = new Map([
  [SupportedCollections.agents, [...ElasticAgentIndexedFields]],
]);

export const IndexSubCollections: Map<SupportedCollectionsKeys, Set<any>> = new Map([
  [SupportedCollections.agents, new Set([AgentDocumentCollections.emailAddresses])],
]);

export const CollectionNameToKeyMap = new Map<string, string>([
  [AgentDocumentCollections.emailAddresses, AgentKeys.email_addresses],
]);

export const IndexPropertiesMap: Map<SupportedCollectionsKeys, IndexProperties> = new Map([
  [SupportedCollections.agents, AgentKeyMapping],
  [SupportedCollections.documentWriteLog, DocumentWriteLogKeyMapping],
  [SupportedCollections.policyTransactions, PolicyTransactionMapping],
  [SupportedCollections.policyTransactionsSummaries, PolicyTransactionSummaryMapping],
]);
