<dx-scroll-view height="100%" [useNative]="false" [showScrollbar]="'always'">
  <dx-form
    [(formData)]="conference"
    labelMode="floating"
    [colCount]="12"
    [readOnly]="isReadonlyMode"
    [validationGroup]="validationGroup"
  >
    <dxi-item
      [dataField]="ConferenceKeys.urlToAppForm"
      [label]="{ text: 'URL to application form' }"
      [editorOptions]="{ valueChangeEvent: 'input', showClearButton: true }"
      [colSpan]="12"
    >
      <dxi-validation-rule
        type="custom"
        [validationCallback]="validateUrlWithOrWithoutProtocol"
        [message]="Messages.INVALID_URL_FORMAT_TEXT"
      >
      </dxi-validation-rule>
    </dxi-item>
  </dx-form>

  <div class="position-relative mt-3">
    <ag-shr-clipboard-button
      class="conference-embedded-form__copy-button"
      [clipboardValue]="iframeData$ | async"
    ></ag-shr-clipboard-button>

    <pre class="conference-embedded-form__preview">
    {{ iframeData$ | async }}
  </pre
    >
  </div>
</dx-scroll-view>
