import { Injectable } from '@angular/core';
import { Agent, AgentKeys, ChangeSourceType } from '@ag-common-lib/public-api';
import { BaseFormService } from 'ag-common-svc/lib/utils/base-form-service';
import { AgentService } from 'ag-common-svc/lib/services/agent.service';
import { TShirtSeizesFormService } from 'ag-common-svc/lib/components/t-shirt-seizes-form/t-shirt-seizes-form.service';

@Injectable()
export class SizesService extends BaseFormService<any> {
  constructor(private readonly agentService: AgentService, private tShirtSeizesFormService: TShirtSeizesFormService) {
    super();
  }

  public handleSave = (agentId: string, action: ChangeSourceType | null = null) => {
    const updates = Object.assign({}, this.formData);

    this.startProgress();
    return this.agentService
      .updateAgentFields(agentId, updates, action)
      .then(() => this.tShirtSeizesFormService.lockLookups())
      .then(() => {
        this.formChangesDetector.clear();
        return updates;
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  public getFormData = (agent?: Partial<Agent>) => {
    const initialTShirtSizes = Object.assign({}, agent?.[AgentKeys.tShirtSizes]);
    const agentTShirtSizesProxy = new Proxy(initialTShirtSizes, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange([AgentKeys.tShirtSizes, prop].join('.'), value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });

    const funStaffInitialData = {
      [AgentKeys.tShirtSizes]: agentTShirtSizesProxy,
      [AgentKeys.shoe_size]: agent[AgentKeys.shoe_size],
      [AgentKeys.hobbies]: agent[AgentKeys.hobbies],
      [AgentKeys.favorite_destination]: agent[AgentKeys.favorite_destination],
    };
    this.formData = new Proxy(funStaffInitialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          this.formChangesDetector.handleChange(prop, value, prevValue);
          Reflect.set(target, prop, value, receiver);
        }

        return true;
      },
    });

    return this.formData;
  };
}
