import { LookupKeys } from 'ag-common-lib/public-api';
import { BaseModel } from '../base.model';

export enum EventInquiryRequestStatus {
  underReview = 'underReview',
  approved = 'approved',
  rejected = 'rejected',
  noContact = 'noContact',
}

export const EventInquiryRequestStatusMap = new Map([
  [EventInquiryRequestStatus.underReview, 'Under Review'],
  [EventInquiryRequestStatus.approved, 'Approved'],
  [EventInquiryRequestStatus.rejected, 'Rejected'],
  [EventInquiryRequestStatus.noContact, 'Info Requested'],
]);

export const EVENT_INQUIRY_REQUEST_STATUS_LOOKUP = [
  EventInquiryRequestStatus.approved,
  EventInquiryRequestStatus.rejected,
  EventInquiryRequestStatus.underReview,
  EventInquiryRequestStatus.noContact,
].map(value => {
  return { [LookupKeys.value]: value, description: EventInquiryRequestStatusMap.get(value) };
});

export enum EventInquiryRequestKeys {
  eventInquiryDbId = 'eventInquiryDbId',
  agentDbId = 'agentDbId',
  relatedAgentsDbIds = 'relatedAgentsDbIds',
  email = 'email',
  phoneNumber = 'phoneNumber',
  firstName = 'firstName',
  lastName = 'lastName',
  mgaOrUpline = 'mgaOrUpline',
  cameFrom = 'cameFrom',
  eventGoals = 'eventGoals',
  status = 'status',
}

export interface EventPublicInfoPayload {
  eventDbId: string;
}

export interface EventPublicInfoResponse {
  eventName: string;
  eventCoordinatorFirstName: string;
  eventCoordinatorLastName: string;
  eventCoordinatorEmail: string;
  eventCoordinatorPhone: string;
  registrationStartDate: string;
  registrationEndDate: string;
}

export class EventInquiryRequest extends BaseModel {
  [EventInquiryRequestKeys.agentDbId]?: string;
  [EventInquiryRequestKeys.relatedAgentsDbIds]?: string[];
  [EventInquiryRequestKeys.eventInquiryDbId]: string;
  [EventInquiryRequestKeys.email]: string;
  [EventInquiryRequestKeys.phoneNumber]: string;
  [EventInquiryRequestKeys.firstName]: string;
  [EventInquiryRequestKeys.lastName]: string;
  [EventInquiryRequestKeys.eventGoals]?: string;
  [EventInquiryRequestKeys.cameFrom]?: string;
  [EventInquiryRequestKeys.status]: EventInquiryRequestStatus = EventInquiryRequestStatus.underReview;
}

export const EVENT_INQUIRY_COLLECTION = 'event-inquiries';
export const EVENT_INQUIRY_REQUEST_COLLECTION = 'event-inquiry-requests';
