import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import {
  DynamicSegmentsGroupKeys,
  NotificationOptInGroupType,
  notificationOptInGroupTypeLookup,
} from '@ag-common-lib/lib';
import { NotificationAddSegmentsModalService } from './notification-add-segments-modal.service';
import { AgentKeys, ConferenceKeys, LookupKeys } from '@ag-common-lib/public-api';
import { DxDataGridComponent } from 'devextreme-angular';
import { firstValueFrom } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { showPager, sortingMethod } from 'ag-common-svc/lib/utils/data-grid.utils';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

const MODAL_TITLE = 'Add Segments' as const;

@Component({
  selector: 'ag-shr-notification-add-segment-group-modal',
  templateUrl: './notification-add-segments-modal.component.html',
  styleUrls: ['./notification-add-segments-modal.component.scss'],
  providers: [NotificationAddSegmentsModalService],
})
export class NotificationAddSegmentsModalComponent {
  @HostBinding('class') className = 'notification-add-segments-modal';

  @ViewChild('notificationAddSegmentGroupModalRef', { static: false })
  protected notificationAddSegmentGroupModalComponent: ModalWindowComponent;
  @ViewChild('segmentsGridGridRef', { static: false }) segmentsGrid: DxDataGridComponent;
  @Output() handleSegments = new EventEmitter<string[]>();

  @Input() title: string = MODAL_TITLE;
  @Input() canWrite = false;

  protected conferences$ = this.notificationAddSegmentsModalService.conferences$;
  protected agents$ = this.notificationAddSegmentsModalService.agents$;
  protected segmentsDbIds: string[] = [];
  protected dataSource: CustomStore;

  protected AgentKeys = AgentKeys;
  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected LookupKeys = LookupKeys;
  protected readonly DynamicSegmentsGroupKeys = DynamicSegmentsGroupKeys;
  protected readonly NotificationOptInGroupType = NotificationOptInGroupType;
  protected notificationOptInGroupTypeLookup = notificationOptInGroupTypeLookup;

  constructor(private notificationAddSegmentsModalService: NotificationAddSegmentsModalService) {
    this.sortingMethod = this.sortingMethod.bind(this);
  }

  protected addNotificationSegmentsGroup() {
    this.handleSegments.emit(this.segmentsDbIds);
    this.notificationAddSegmentGroupModalComponent.forceCloseModal();
  }

  showModal = async (dynamicSegmentsIds?: string[]) => {
    const segments = await firstValueFrom(this.notificationAddSegmentsModalService.segments$);
    this.dataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: loadOptions => {
        return segments.filter(segment => !dynamicSegmentsIds?.includes(segment[BaseModelKeys.dbId]));
      },
    });
    this.notificationAddSegmentGroupModalComponent?.showModal();
  };

  onSelectionChanged(e) {
    this.segmentsDbIds = e?.selectedRowKeys ?? [];
    e.component.refresh(); // to show selected rows on top
  }

  sortingMethod = (value1: any, value2: any) => sortingMethod(this.segmentsGrid?.instance, value1, value2);

  showPager = (): boolean => showPager(this.segmentsGrid?.instance);
}
