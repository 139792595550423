import { AGENT_STATUS, AgentKeys } from '@ag-common-lib/public-api';
import { Pipe, PipeTransform } from '@angular/core';
import { AgentService } from 'ag-common-svc/public-api';
import { map } from 'rxjs';

@Pipe({ name: 'isShowAgentNotWelcomedWarning' })
export class IsShowAgentNotWelcomedWarningPipe implements PipeTransform {
  private readonly agentsMap$ = this.agentService.agentsMap$;

  constructor(protected agentService: AgentService) {}

  transform(agentsId: string) {
    return this.agentsMap$.pipe(
      map(agentsMap => {
        if (!agentsId) {
          return true;
        }

        const agent = agentsMap[agentsId];

        if (!agent) {
          return true;
        }

        const agentStatus = agent?.[AgentKeys.agent_status];
        const isAgentNotWelcomed = new Set([AGENT_STATUS.INACTIVE, AGENT_STATUS.DENIED]).has(agentStatus);

        return isAgentNotWelcomed;
      }),
    );
  }
}
