import { Injectable } from '@angular/core';
import { Agent, AgentKeys, Role } from 'ag-common-lib/public-api';
import { AuthService, BaseFormService, ConferenceRegistrationLogsService } from 'ag-common-svc/public-api';
import { confirm } from 'devextreme/ui/dialog';
import { BehaviorSubject } from 'rxjs';
import {
  ConferenceRegistrationLog,
  ConferenceRegistrationLogKeys,
  ConferenceRegistrationLogType,
  ConferenceRegistrationSupportLogKey,
} from 'ag-common-lib/lib/models/registration/registrant-notes';
import DataSource from 'devextreme/data/data_source';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import { LoadOptions } from 'devextreme/data';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Injectable()
export class AttendeeNoteModalService extends BaseFormService<ConferenceRegistrationLog> {
  agentsDataSource: DataSource;
  private _conferenceId: string;
  private _registrantId: string;

  private _noteDbId$ = new BehaviorSubject<string>(null);
  private _isSpecialRequest$ = new BehaviorSubject<boolean>(false);
  public isSpecialRequest$ = this._isSpecialRequest$.asObservable();

  private loggedInUser: string;

  protected readonly agentsLoadOptions: LoadOptions<Agent> = {
    filter: [{ terms: { [AgentKeys.role]: [Role.CONFERENCE_MANAGER] } }],
  };

  constructor(
    private conferenceRegistrationLogsService: ConferenceRegistrationLogsService,
    private agentElasticSearchService: AgentElasticSearchService,
    private authService: AuthService,
  ) {
    super();
    this.authService.loggedInAgent$.subscribe((agent: Agent) => {
      this.loggedInUser = agent?.[BaseModelKeys.dbId];
    });

    this.agentsDataSource = this.agentElasticSearchService.getDataSource(this.agentsLoadOptions);
  }

  updateFieldsData = () => {};

  saveNote = () => (this.formData[BaseModelKeys.dbId] ? this.updateNote() : this.createNote());

  cancelEdit = ({ event, component }): void => {
    if (!this.formChangesDetector?.hasChanges) {
      return;
    }

    event.cancel = true;

    const result = confirm('<i>Are you sure you want to Cancel without Saving?</i>', 'Confirm');
    result.then(dialogResult => {
      if (dialogResult) {
        this.formChangesDetector?.clear();
        component.instance.hide();
      }
    });
  };

  getFormData = async (
    conferenceId: string,
    registrantId: string,
    log?: Partial<ConferenceRegistrationLog>,
  ): Promise<ConferenceRegistrationLog> => {
    this._conferenceId = conferenceId;
    this._registrantId = registrantId;
    this._noteDbId$.next(log?.dbId);

    const initialLogData = Object.assign({
      [ConferenceRegistrationSupportLogKey.owner]: this.loggedInUser,
      [ConferenceRegistrationSupportLogKey.date]: new Date(),
    });
    const initialData = Object.assign(
      {
        [ConferenceRegistrationLogKeys.isArchived]: false,
        [ConferenceRegistrationLogKeys.type]: ConferenceRegistrationLogType.other,
        [ConferenceRegistrationLogKeys.data]: initialLogData,
      },
      new ConferenceRegistrationLog(),
      log,
    );
    this._isSpecialRequest$.next(
      log?.[ConferenceRegistrationLogKeys.type] === ConferenceRegistrationLogType.specialRequest,
    );
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);

        switch (prop) {
          case ConferenceRegistrationLogKeys.type:
            this._isSpecialRequest$.next(value === ConferenceRegistrationLogType.specialRequest);
            break;
        }

        return true;
      },
    });

    return this.formData;
  };

  private updateNote = () => {
    this.startProgress();
    return this.conferenceRegistrationLogsService
      .update(this._conferenceId, this._registrantId, this.formData[BaseModelKeys.dbId], this.formData)
      .then(() => {
        this.formChangesDetector.clear();
      })
      .finally(() => {
        this.stopProgress();
      });
  };

  private createNote = () => {
    this.startProgress();
    return this.conferenceRegistrationLogsService
      .create(this._conferenceId, this._registrantId, this.formData)
      .then(() => {
        this.formChangesDetector.clear();
      })
      .finally(() => {
        this.stopProgress();
      });
  };
}
