import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import {
  Agent,
  AgentKeys,
  Conference,
  ConferenceKeys,
  ConferenceStepsConfigurationKeys,
  Entity,
  EntityApplications,
  EntityPermissionActivityKeys,
  UserRoleModelKeys,
} from 'ag-common-lib/public-api';
import { DxDataGridComponent } from 'devextreme-angular';
import { AgentPrimaryEmailAddressPipe } from 'ag-common-svc/shared/pipes/agent-primary-email-address.pipe';
import CustomStore from 'devextreme/data/custom_store';
import { AgentService } from 'ag-common-svc/lib/services/agent.service';
import { DxFilterOperators } from 'ag-common-svc/lib/services/elastic-search.services/base-elastic-search-service';
import { shareReplay } from 'rxjs';
import { ConferencePermissionsModalComponent } from './conference-permissions-modal/conference-permissions-modal.component';
import { UserRolesService } from 'ag-common-svc/lib/services/user-roles.service';
import { ConferenceService } from 'ag-common-svc/lib/services/conference.service';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-crm-conference-permissions-form',
  templateUrl: './conference-permissions-form.component.html',
  styleUrls: ['./conference-permissions-form.component.scss'],
  providers: [AgentPrimaryEmailAddressPipe],
})
export class ConferencePermissionsFormComponent {
  @HostBinding('class') className = 'conference-permissions-form';
  @ViewChild('permissionsGridTmp') permissionsGridComponent: DxDataGridComponent;
  @ViewChild('conferencePermissionsModalRef') conferencePermissionsModal: ConferencePermissionsModalComponent;
  @Input()
  set conference(conf: Conference) {
    this._conference = conf;
    this.selectedAgentIds = conf?.[ConferenceKeys.permissionConfigurationIds] ?? [];
    this.dataSource = new CustomStore({
      key: 'dbId',
      loadMode: 'raw',
      load: () => {
        return this.selectedAgentIds?.length ? this.agentService.getAgentsByAgentIds(this.selectedAgentIds) : [];
      },
      remove: item => {
        return this.removeAgentToConference(item);
      },
    });
  }
  get conference(): Conference {
    return this._conference;
  }
  @Input() validationGroup: string;
  @Input() isReadonlyMode: boolean = false;

  roles$ = this.rolesService.getList().pipe(shareReplay(1));
  protected readonly Entity = Entity;
  protected readonly EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  protected readonly UserRoleModelKeys = UserRoleModelKeys;
  protected readonly EntityApplications = EntityApplications;

  protected readonly ConferenceKeys = ConferenceKeys;
  protected readonly ConferenceStepsConfigurationKeys = ConferenceStepsConfigurationKeys;
  protected dataSource: CustomStore;
  protected readonly AgentKeys = AgentKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  private _conference: Conference;
  private selectedAgentIds: string[] = [];

  constructor(
    private agentPrimaryEmailAddressPipe: AgentPrimaryEmailAddressPipe,
    private agentService: AgentService,
    private rolesService: UserRolesService,
    private conferenceService: ConferenceService,
  ) {}

  protected calculateEmailCellValue = rowData => {
    const primaryEmailAddress =
      this.agentPrimaryEmailAddressPipe.transform(rowData?.[AgentKeys.email_addresses]) ?? rowData?.[AgentKeys.p_email];
    return primaryEmailAddress ?? '—';
  };

  protected calculateRolesCellValue = rowData => {
    const roles = rowData?.[AgentKeys.roles] ?? [];
    return roles?.join(', ');
  };

  protected calculateRolesFilterExpression = filterValue => {
    return [AgentKeys.roles, DxFilterOperators.contains, filterValue];
  };

  removeAgentToConference = async (agentDbId: string): Promise<void> => {
    this.selectedAgentIds = this.selectedAgentIds.filter(id => id !== agentDbId);
    await this.saveChanges(this.selectedAgentIds);
    return Promise.resolve(null);
  };

  private saveChanges = async (rows: string[]) => {
    const conferenceDbId = this.conference?.[BaseModelKeys.dbId];

    if (!conferenceDbId) {
      Object.assign(this.conference, { [ConferenceKeys.permissionConfigurationIds]: rows });
      return false;
    }

    return this.conferenceService
      .updateFields(conferenceDbId, { [ConferenceKeys.permissionConfigurationIds]: rows })
      .then(() => {
        return false;
      })
      .catch(() => {
        return true;
      });
  };

  showAddTaskOwnerPopup = () => {
    this.conferencePermissionsModal.showModal(this.selectedAgentIds);
  };

  refreshPermissionsGrid = (e: Partial<Agent>) => {
    this.selectedAgentIds.push(e.dbId);
    this.conference[ConferenceKeys.permissionConfigurationIds] = this.selectedAgentIds;
    this.permissionsGridComponent.instance.refresh();
  };
}
