import { FunctionsNames } from '@ag-common-lib/lib/models/functions/functions-names';
import { FirebaseApp } from 'firebase/app';
import { connectFunctionsEmulator, Functions, getFunctions } from 'firebase/functions';

export class BaseCloudFunctionsService {
  protected functions: Functions;

  constructor(private readonly fireBaseApp: FirebaseApp, useEmulator: boolean) {
    this.functions = getFunctions(fireBaseApp);

    if (useEmulator) {
      connectFunctionsEmulator(this.functions, 'localhost', 5001);
    }
  }

  getFunctionUrl = (functionName: FunctionsNames) => {
    const region = this.functions.region;
    const firebaseProjectId = this.fireBaseApp.options.projectId;
    const emulatorOrigin = (this.functions as any)?.emulatorOrigin;

    if (emulatorOrigin) {
      return `${emulatorOrigin}/${firebaseProjectId}/${region}/${functionName}`;
    }

    return `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;
  };
}
