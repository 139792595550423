import { Component, HostBinding, ViewChild } from '@angular/core';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import {
  ConferenceGuestsStepsConfigurationSectionName,
  ConferenceGuestsStepsConfigurationSectionNameMap,
  ConferenceRegistrationStepName,
  ConferenceStepsArgumentVariables,
  ConferenceStepsConfiguration,
  ConferenceStepsConfigurationKeys,
  FlightInfoKeys,
  HtmlEditorTypeKeys, LookupKeys,
  RegistrantKeys, REGISTRATION_TYPE_LOOKUP,
  StepsTemplate,
} from 'ag-common-lib/public-api';
import { ConferenceStepsInfoModalService } from './conference-steps-info-modal.service';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';
import { ConferenceStepsDescriptionPipe } from 'ag-common-svc/shared/pipes/conference-registration/conference-steps-description.pipe';
import { ConferenceModalService } from '../../conference-form.service';
import {
  flightInfoFieldsList,
  guestFieldsList,
  hotelReservationFieldsList,
  personalInfoExcludedSectionsList,
  personalInfoFieldsList,
  rsvpFieldsList,
} from '../config/conference-step-info.config';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

@Component({
  selector: 'ag-crm-conference-step-info-modal',
  templateUrl: './conference-step-info-modal.component.html',
  styleUrls: ['./conference-step-info-modal.component.scss'],
  providers: [ConferenceStepsInfoModalService, ConferenceStepsDescriptionPipe],
})
export class ConferenceStepInfoModalComponent {
  @HostBinding('class') className = 'conference-steps-info-modal';
  @ViewChild('conferenceStepInfoModalRef', { static: true }) conferenceStepInfoModal: ModalWindowComponent;

  stepsConfigurationData: ConferenceStepsConfiguration;
  excludedSectionsList = [
    {
      id: ConferenceGuestsStepsConfigurationSectionName.dietaryConsiderationsSection,
      name: ConferenceGuestsStepsConfigurationSectionNameMap.get(
        ConferenceGuestsStepsConfigurationSectionName.dietaryConsiderationsSection,
      ),
    },
    {
      id: ConferenceGuestsStepsConfigurationSectionName.shirtSizeSection,
      name: ConferenceGuestsStepsConfigurationSectionNameMap.get(
        ConferenceGuestsStepsConfigurationSectionName.shirtSizeSection,
      ),
    },
  ];

  rsvpFieldsList = rsvpFieldsList;
  personalInfoFieldsList = new DataSource({
    store: new ArrayStore({
      data: personalInfoFieldsList,
      key: 'id',
    }),
    group: 'category',
  });
  guestFieldsList = new DataSource({
    store: new ArrayStore({
      data: guestFieldsList,
      key: 'id',
    }),
    group: 'category',
  });
  hotelReservationFieldsList = hotelReservationFieldsList;
  flightInfoFieldsDataList = new DataSource({
    store: new ArrayStore({
      data: flightInfoFieldsList,
      key: 'id',
    }),
    group: 'category',
  });
  flightInfoGuestsFieldsDataList = new DataSource({
    store: new ArrayStore({
      data: flightInfoFieldsList.filter(
        item =>
          ![
            [RegistrantKeys.flightInformation, FlightInfoKeys.sameForAll].join('.'),
            [RegistrantKeys.flightInformation, FlightInfoKeys.equalTravelMode].join('.'),
          ].includes(item.id),
      ),
      key: 'id',
    }),
    group: 'category',
  });

  personalInfoExcludedSectionsList = personalInfoExcludedSectionsList;

  templateLookup: StepsTemplate[];
  popupVisible = false;
  conferenceStepsTemplateArguments$ = this.conferenceModalService.conferenceStepsTemplateArguments$;
  protected readonly inProgress = false;
  protected readonly validationGroup = 'conferenceStepInfoValidationGroup';
  protected readonly ConferenceStepsConfigurationKeys = ConferenceStepsConfigurationKeys;
  protected readonly ConferenceRegistrationStepName = ConferenceRegistrationStepName;
  protected readonly ConferenceStepsArgumentVariables = ConferenceStepsArgumentVariables;
  protected readonly HtmlEditorTypeKeys = HtmlEditorTypeKeys;
  protected readonly RegistrantKeys = RegistrantKeys;

  private onSave: (stepConfiguration: Partial<ConferenceStepsConfiguration>) => Promise<boolean>;
  private conferenceDbId: string;

  constructor(
    private conferenceStepsInfoModalService: ConferenceStepsInfoModalService,
    private conferenceStepsDescription: ConferenceStepsDescriptionPipe,
    private conferenceModalService: ConferenceModalService,
  ) {}

  showModal(
    data: ConferenceStepsConfiguration,
    conferenceDbId: string,
    onSave: (stepConfiguration: ConferenceStepsConfiguration) => Promise<boolean>,
  ) {
    this.conferenceDbId = conferenceDbId;
    this.onSave = onSave;
    this.templateLookup = this.conferenceStepsDescription.getStepTemplateLookup(
      data[ConferenceStepsConfigurationKeys.stepName],
    );
    this.stepsConfigurationData = this.conferenceStepsInfoModalService.getFormData(data);
    this.conferenceStepInfoModal.showModal();
  }

  handleTemplateValueChange = e => {
    const selectedItem = e?.component?.option('selectedItem');
    const configuration = {
      ...this.stepsConfigurationData,
      [ConferenceStepsConfigurationKeys.template]: selectedItem.value,
    };
    const htmlContent = this.conferenceStepsDescription.transform(configuration);
    Object.assign(this.stepsConfigurationData, {
      [ConferenceStepsConfigurationKeys.htmlContent]: htmlContent ?? '',
    });
  };

  protected handleClosePopup = this.conferenceStepsInfoModalService.onCancelEdit;

  protected handleSaveInfo = async () => {
    const isValid = await validateDxGroups([this.validationGroup]);

    if (!isValid) {
      return;
    }

    const isCancelled = await this.onSave(this.stepsConfigurationData);

    if (isCancelled) {
      return;
    }

    this.conferenceStepsInfoModalService.revertAllChanges();
    this.conferenceStepInfoModal.hideModal();
  };

  onShowPreview(value: string) {
    this.popupVisible = true;
  }

  protected readonly REGISTRATION_TYPE_LOOKUP = REGISTRATION_TYPE_LOOKUP;
  protected readonly LookupKeys = LookupKeys;
}
