import { Inject, Injectable } from '@angular/core';
import {
  AgentDocumentCollections,
  AgentMedia,
  AgentMediaKeys,
  AGENTS_COLLECTION_NAME,
  ChangeSourceType,
} from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { CommonFireStoreDao, QueryParam } from '../dao/CommonFireStoreDao.dao';
import { FIREBASE_APP } from '../injections/firebase-app';
import { AgentFileDirectoryCollections } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/agent-file-directory.model';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable({
  providedIn: 'root',
})
export class AgentFilesMediaService {
  public readonly fsDao: CommonFireStoreDao<AgentMedia>;
  private readonly agentCollectionPath = AGENTS_COLLECTION_NAME;
  private readonly fileDirectoryCollectionPath = AgentDocumentCollections.fileDirectories;
  private readonly mediaCollectionPath = AgentFileDirectoryCollections.files;

  constructor(
    @Inject(FIREBASE_APP) fireBaseApp: FirebaseApp,
    private toastrService: ToastrService,
  ) {
    this.fsDao = new CommonFireStoreDao<AgentMedia>(fireBaseApp, null, null);
  }

  public getList(agentId: string, fileDirectoryId: string, qp: QueryParam[] = []) {
    const table = this.getCollectionPath(agentId, fileDirectoryId);

    return this.fsDao.getList(table, qp);
  }

  public async create(
    agentId: string,
    fileDirectoryId: string,
    data: AgentMedia,
    silent = false,
    action: ChangeSourceType | null = null,
  ) {
    const table = this.getCollectionPath(agentId, fileDirectoryId);
    const media = await this.fsDao
      .create(Object.assign(data, { [AgentMediaKeys.agentDbId]: agentId, [BaseModelKeys.actionName]: action }), table)
      .catch(e => {
        console.log('e', e);
      });

    !silent && this.toastrService.success('Agent Media Successfully Created!');

    return media;
  }

  public async update(
    agentId: string,
    fileDirectoryId: string,
    documentId: any,
    updates: Partial<AgentMedia>,
    silent = false,
    action: ChangeSourceType | null = null,
  ) {
    const table = this.getCollectionPath(agentId, fileDirectoryId);
    Object.assign(updates, { [BaseModelKeys.actionName]: action });
    const media = await this.fsDao.updateFields(updates, documentId, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    !silent && this.toastrService.success('Agent Media Successfully Updated!');

    return media;
  }

  public delete(agentId: string, fileDirectoryId: string, documentId: any, action: ChangeSourceType | null = null) {
    const table = this.getCollectionPath(agentId, fileDirectoryId);

    return this.fsDao.delete(documentId, table, action).then(response => {
      this.toastrService.success('Agent Media Removed!');
      return response;
    });
  }

  private getCollectionPath(agentId: string, fileDirectoryId: string) {
    return [
      this.agentCollectionPath,
      agentId,
      this.fileDirectoryCollectionPath,
      fileDirectoryId,
      this.mediaCollectionPath,
    ].join('/');
  }
}
