import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { ChangesHistoryGridService } from '../../../changes-history-grid.service';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';
import { FavoriteKeys, LookupKeys } from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-change-log-favourite-detail',
  templateUrl: './change-log-favourite.component.html',
  styleUrls: ['./change-log-favourite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogFavouriteComponent {
  @Input() log: Delta;

  protected readonly favouriteKeys = [FavoriteKeys.pageId, FavoriteKeys.pageName, FavoriteKeys.pageRoute];
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly favouriteLookup = [
    { [LookupKeys.value]: FavoriteKeys.pageId, [LookupKeys.description]: 'Favorite Page ID' },
    { [LookupKeys.value]: FavoriteKeys.pageName, [LookupKeys.description]: 'Favorite Page Name' },
    { [LookupKeys.value]: FavoriteKeys.pageRoute, [LookupKeys.description]: 'Favorite Page Route' },
  ];

  constructor(private changesHistoryGridService: ChangesHistoryGridService) {}
}
