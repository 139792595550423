import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import {
  Agent,
  AGENT_SEARCH_KEYS_CONFIG,
  agentChangeLogsLookup,
  agentEmailAddressesKeySet,
  AgentKeys,
  CHANGE_SOURCE_TYPE_LOOKUP,
  ChangeLogsHistoryType,
  changeLogsLookup,
  Constants,
  EMAIL_ADDRESS_SEARCH_KEYS_CONFIG,
  EmailAddressKeys,
  excludedChangeLogsFieldsSet,
  GuestKeys,
  GUESTS_SEARCH_KEYS_CONFIG,
  LookupKeys,
  PERSONAL_INFO_SEARCH_KEYS_CONFIG,
  RegistrantKeys,
  RSVP_SEARCH_KEYS_CONFIG,
} from '@ag-common-lib/public-api';
import { DxDataGridComponent } from 'devextreme-angular';
import { ChangesHistoryGridService } from './changes-history-grid.service';
import { DOCUMENT } from '@angular/common';
import { DocumentWriteLogElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services/documet-write-log-elastic-search.service';
import { fullscreen, setPopupsContainer } from 'ag-common-svc/shared/utils/full-screen';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { getChangedLog } from './utils/normalize-log';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';
import ArrayStore from 'devextreme/data/array_store';
import { LoggedCollections } from 'ag-functions/src/log-document-writes/log-document-writes.model';
import { sortLookupByDescription } from 'ag-common-svc/shared/utils/sort-lookup-by-description';
import { DocumentWriteAction } from 'ag-functions/src/models';

@Component({
  selector: 'ag-shr-changes-history-grid',
  templateUrl: './changes-history-grid.component.html',
  styleUrls: ['./changes-history-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangesHistoryGridComponent implements OnInit {
  @HostBinding('class') className = 'ag-changes-history';
  @ViewChild('changeLogGridRef', { static: false }) changeLogGridComponent!: DxDataGridComponent;
  @ViewChild('changeLogContainerRef', { static: true }) changeLogContainer: ElementRef;

  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;
  @Input() changeLogType: ChangeLogsHistoryType = ChangeLogsHistoryType.agent;
  @Input()
  set agent(value: Agent) {
    this.changesHistoryService.setAgentData(value);
  }
  @Input()
  set attendee(value: Attendee) {
    this.changesHistoryService.setAttendeeData(value);
  }

  @HostListener('document:fullscreenchange', ['$event']) fullScreenMode() {
    if (!document.fullscreenElement) {
      document.body.classList.remove('fullscreen-enable');
      setPopupsContainer();
    }
  }

  containerElement: HTMLElement;
  conferenceDbId: string;
  registrantDbId: string;
  agentDbId: string;
  agentsLookup$ = this.changesHistoryService.agentsLookupBase$;
  registrantGuestsLookup$ = this.changesHistoryService.registrantGuestsLookup$;
  collectionFilterValues = [];
  wasabiPathFilterValues = [];

  readonly filterPanelText$ = new BehaviorSubject('');
  protected dataSource: CustomStore;
  protected updatedFieldsList: DataSource;
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_TIME_FORMAT;
  protected readonly ChangeLogsHistoryType = ChangeLogsHistoryType;
  protected readonly PERSONAL_INFO_SEARCH_KEYS_CONFIG = PERSONAL_INFO_SEARCH_KEYS_CONFIG;
  protected readonly GUESTS_SEARCH_KEYS_CONFIG = GUESTS_SEARCH_KEYS_CONFIG;
  protected readonly RSVP_SEARCH_KEYS_CONFIG = RSVP_SEARCH_KEYS_CONFIG;
  protected readonly AGENT_SEARCH_KEYS_CONFIG = AGENT_SEARCH_KEYS_CONFIG;
  protected readonly changeSourceTypeLookup = sortLookupByDescription(CHANGE_SOURCE_TYPE_LOOKUP);
  protected readonly GuestKeys = GuestKeys;
  protected readonly AgentKeys = AgentKeys;
  protected readonly RegistrantKeys = RegistrantKeys;
  protected readonly getChangedLog = getChangedLog;
  protected readonly excludedChangeLogsFieldsSet = excludedChangeLogsFieldsSet;
  protected readonly LoggedCollections = LoggedCollections;
  protected readonly LookupKeys = LookupKeys;
  protected readonly EmailAddressKeys = EmailAddressKeys;
  protected readonly EMAIL_ADDRESS_SEARCH_KEYS_CONFIG = EMAIL_ADDRESS_SEARCH_KEYS_CONFIG;
  protected readonly DocumentWriteAction = DocumentWriteAction;
  protected readonly agentEmailAddressesKeySet = agentEmailAddressesKeySet;

  constructor(
    @Inject(DOCUMENT) private document: any,
    protected documentWriteLogElasticSearchService: DocumentWriteLogElasticSearchService,
    private changesHistoryService: ChangesHistoryGridService,
  ) {
    this.dataSource = new CustomStore({
      key: 'wasabiPath',
      load: async loadOptions => {
        switch (this.changeLogType) {
          case ChangeLogsHistoryType.registrant:
            this.conferenceDbId = await firstValueFrom(this.changesHistoryService.conferenceDbId$);
            this.registrantDbId = await firstValueFrom(this.changesHistoryService.registrantDbId$);
            this.wasabiPathFilterValues = [
              [['wasabiPath', 'contains', this.conferenceDbId], 'and', ['wasabiPath', 'contains', this.registrantDbId]],
            ];
            this.collectionFilterValues = [
              ['collection', 'contains', LoggedCollections.registrant],
              'and',
              ['collection', 'contains', LoggedCollections.registrantGuest],
            ];
            this.createUpdatedFieldsList(changeLogsLookup);
            break;

          case ChangeLogsHistoryType.agent:
            this.agentDbId = await firstValueFrom(this.changesHistoryService.agentDbId$);
            this.wasabiPathFilterValues = ['wasabiPath', 'contains', this.agentDbId];
            this.collectionFilterValues = ['collection', 'contains', LoggedCollections.agent];
            this.createUpdatedFieldsList(agentChangeLogsLookup);
            break;
        }
        const response = await this.documentWriteLogElasticSearchService.getFromElastic(loadOptions);
        console.log('response ', response);
        return response;
      },
    });
  }

  ngOnInit(): void {
    this.containerElement = this.changeLogContainer?.nativeElement;
  }

  fullscreen(container: HTMLElement): void {
    this.containerElement = container;
    fullscreen(container, this.document);
  }

  updatedFieldsCalcFilterExpr(filterValue, selectedFilterOperation) {
    let column = this as any;

    if (selectedFilterOperation === 'contains') {
      let filterExpr = [];
      filterValue.forEach(val => {
        filterExpr.length >= 1 ? filterExpr.push('or') : filterExpr.push(['updatedFields', 'contains', val]);
      });
      return filterExpr;
    }
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }

  protected onRowPrepared(event): void {
    if (event.rowType === 'data') {
      const updatedFields = event.data.updatedFields;
      const hasExcludedFields = updatedFields?.filter(val => !this.excludedChangeLogsFieldsSet.has(val));
      if (!updatedFields?.length || !hasExcludedFields?.length) {
        event.rowElement.style.display = 'none';
      }
    }
  }

  protected onEditorPreparing(event): void {
    if (event.dataField == 'updatedFields' && event.parentType == 'filterRow') {
      event.editorName = 'dxTagBox';
      event.editorOptions = {
        placeholder: 'Select fields...',
        dataSource: this.updatedFieldsList,
        displayExpr: 'description',
        valueExpr: 'value',
        showClearButton: true,
        searchEnabled: true,
        applyValueMode: 'useButtons',
        showSelectionControls: true,
        maxDisplayedTags: 3,
        grouped: true,
        dropDownOptions: { container: this.containerElement },
        onValueChanged: this.onUpdatedFieldsSelectionChanged,
      };
    }
  }

  onSelectionChanged = event => {
    const selectedItem = event.selectedItem.value;
    this.changeLogGridComponent.instance.columnOption('changedBySource', 'filterValue', selectedItem);
  };

  onUpdatedFieldsSelectionChanged = event => {
    const selectedItems = event?.component?.option('selectedItems');
    const selectedKeys = selectedItems?.map(({ value }) => value) ?? [];
    this.changeLogGridComponent.instance.columnOption('updatedFields', 'filterValue', selectedKeys);
  };

  protected onOptionChanged = event => {
    if (event.name === 'searchPanel') {
      this.filterPanelText$.next(event.value);
    }
  };

  hasSelectedExcursion = logs => {
    // newly added = Array[null] without data
    return Array.isArray(logs) ? logs.filter(log => log !== null).length : !!logs;
  };

  private createUpdatedFieldsList = lookup => {
    const changeHistoryLookup = lookup.map(item => {
      return {
        category: item.category,
        value: item.value,
        description: item.description ? item.description.split(':').pop().trim() : item.value.trim(),
      };
    });
    this.updatedFieldsList = new DataSource({
      store: new ArrayStore({
        data: changeHistoryLookup ?? [],
        key: 'value',
      }),
      group: 'category',
    });
  };
}
