import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  ActiveLookup,
  EmailAddressKeys,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  LookupKeys,
  Lookups,
  Messages,
  OfficeEmailAddress,
  SupportType,
} from '@ag-common-lib/public-api';
import { DxDataGridComponent } from 'devextreme-angular';
import { take, tap } from 'rxjs/operators';
import { LookupsService } from '../../../../../services';
import { OfficeEmailAddressesService } from '../office-email-addresses.service';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-agency-office-email-address-grid',
  templateUrl: './office-email-addresses-grid.component.html',
  styleUrls: ['./office-email-addresses-grid.component.scss'],
})
export class OfficeEmailAddressesGridComponent {
  @ViewChild('addressesGridREf', { static: false }) addressGridComponent: DxDataGridComponent;
  @Input() emails: OfficeEmailAddress[] = [];
  @Input() inProgress: boolean = false;
  @Input() isEditable: boolean = true;
  @Input() agencyId: string;
  @Output() emailAddressesChange = new EventEmitter<OfficeEmailAddress[]>();

  inProgress$ = this.officeEmailAddressesService.inProgress$;

  emailTypeLookup$ = this.lookupsService.agencyEmailTypes$.pipe(
    tap((items: ActiveLookup[]): void => {
      this._defaultEmailTypeLookup = items?.find(item => item?.isDefault);
    }),
  );
  supportTypesLookup$ = this.lookupsService.supportTypesLookup$;

  private _defaultEmailTypeLookup: ActiveLookup;

  protected readonly Messages = Messages;
  protected readonly Lookups = Lookups;
  protected readonly LookupKeys = LookupKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly EmailAddressKeys = EmailAddressKeys;
  readonly emptyMessage = 'No Email Addresses Currently Exist';
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(
    private lookupsService: LookupsService,
    private officeEmailAddressesService: OfficeEmailAddressesService,
  ) {}

  onEditorPreparing = (e): void => {
    if (e.parentType !== 'dataRow') {
      return;
    }

    if (e.dataField === 'is_primary') {
      e.editorOptions.disabled = e.row.data.is_primary;
    }
  };

  onInitNewRow = (e): void => {
    e.data.is_primary = !this.emails?.length;
    this.emailTypeLookup$.pipe(take(1)).subscribe(st => {
      e.data.email_type = st.find(v => v.dbId === this._defaultEmailTypeLookup?.dbId)?.dbId;
    });
    this.supportTypesLookup$.pipe(take(1)).subscribe(st => {
      e.data.support_type = st.find(v => v.value === SupportType.MainOffice)?.dbId;
    });
  };

  onRowInserting = (e): void => {
    const { __KEY__: key, ...data } = e?.data;
    const emails = this.normalizeEmailAddresses(data);
    emails.push(Object.assign({ id: key }, data));
    e.cancel = this.updateEmailAddress(emails);
  };

  onRowUpdating = (e): void => {
    const data = Object.assign({}, e?.oldData, e?.newData);
    const emails = this.normalizeEmailAddresses(data, e?.key);
    e.cancel = this.updateEmailAddress(emails);
  };

  onRowRemoving = (e): void => {
    const emails = this.emails.filter(email => email !== e.key);
    e.cancel = this.updateEmailAddress(emails);
  };

  canDeleteEmailAddress = (e): boolean => !e.row.data.is_primary;

  onEmailTypeChanged = ({ selectedItem }): void => {
    this.officeEmailAddressesService.setSelectedAgencyEmailType(selectedItem);
  };

  onSupportTypeChanged = ({ selectedItem }): void => {
    this.officeEmailAddressesService.setSelectedAgencySupportType(selectedItem);
  };

  private normalizeEmailAddresses = (data, key?: OfficeEmailAddress): OfficeEmailAddress[] => {
    const isPrimary = data?.is_primary;

    return this.emails.map(email => {
      if (key && email === key) {
        return data;
      }

      const normalizedEmailAddresses = Object.assign({}, email);
      if (isPrimary) {
        Object.assign(normalizedEmailAddresses, { is_primary: false });
      }

      return normalizedEmailAddresses;
    });
  };

  private updateEmailAddress = (emails: OfficeEmailAddress[]) => {
    return this.officeEmailAddressesService.updateAddress(this.agencyId, emails).then(() => {
      this.emails = emails;
      this.emailAddressesChange.emit(emails);
    });
  };
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
