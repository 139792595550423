import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delta } from 'jsondiffpatch/lib/types';
import { ChangesHistoryGridService } from '../../../changes-history-grid.service';
import { hasLog, normalizeLog } from '../../../utils/normalize-log';
import {
  AgentCarrierModelKeys,
  CARRIER_LINE_OF_BUSINESS_LOOKUP,
  CARRIER_ORGANIZATION_LOOKUP,
  LookupKeys,
  TRUE_FALSE_LOOKUP,
} from '@ag-common-lib/public-api';

@Component({
  selector: 'ag-shr-change-log-carrier-detail',
  templateUrl: './change-log-carrier.component.html',
  styleUrls: ['./change-log-carrier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogCarrierComponent {
  @Input() log: Delta;

  protected readonly carrierKeys = [
    AgentCarrierModelKeys.carrier,
    AgentCarrierModelKeys.name,
    AgentCarrierModelKeys.emailAddress,
    AgentCarrierModelKeys.writingNumber,
    AgentCarrierModelKeys.individualOrCorp,
    AgentCarrierModelKeys.lineOfBusiness,
    AgentCarrierModelKeys.isActive,
  ];
  protected readonly normalizeLog = normalizeLog;
  protected readonly hasLog = hasLog;
  protected readonly carrierLookup = [
    { [LookupKeys.value]: AgentCarrierModelKeys.carrier, [LookupKeys.description]: 'Carrier' },
    { [LookupKeys.value]: AgentCarrierModelKeys.name, [LookupKeys.description]: 'Name' },
    { [LookupKeys.value]: AgentCarrierModelKeys.emailAddress, [LookupKeys.description]: 'Email Address' },
    { [LookupKeys.value]: AgentCarrierModelKeys.writingNumber, [LookupKeys.description]: 'Writing Number' },
    { [LookupKeys.value]: AgentCarrierModelKeys.individualOrCorp, [LookupKeys.description]: 'Individual / Corp' },
    { [LookupKeys.value]: AgentCarrierModelKeys.lineOfBusiness, [LookupKeys.description]: 'Line Of Business' },
    { [LookupKeys.value]: AgentCarrierModelKeys.isActive, [LookupKeys.description]: 'Active' },
  ];
  protected readonly carrierWithLookup = {
    [AgentCarrierModelKeys.individualOrCorp]: CARRIER_ORGANIZATION_LOOKUP,
    [AgentCarrierModelKeys.isActive]: TRUE_FALSE_LOOKUP,
    [AgentCarrierModelKeys.lineOfBusiness]: CARRIER_LINE_OF_BUSINESS_LOOKUP,
  };

  constructor(private changesHistoryGridService: ChangesHistoryGridService) {}
}
