import {
  LookupKeys,
  AttendeeKeys,
  AttendeeType,
  LookupId,
  RegistrationType,
  QualifiedAs,
  InviteeStatus,
  PhoneNumber,
  LocalDateTimeString,
  TShirtSizes,
  InviteeAgeGroup,
  DietaryConsideration,
  Conference,
  Registrant,
  GuestData,
  RegistrantKeys,
  RegistrantData,
  RegistrantModelKeys,
  AssociationKeys,
  GuestKeys,
  AttendeeWizardState,
} from '@ag-common-lib/public-api';
import { calculateWizardState, getCurrentStepInProgress } from './conference-registration.util';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

export class Attendee {
  [AttendeeKeys.conferenceDbId]: string;
  [AttendeeKeys.attendeeType]: AttendeeType;
  [AttendeeKeys.stepInProgress]: any;
  [AttendeeKeys.registrationState]: AttendeeWizardState;
  [AttendeeKeys.inviteeEmail]: string;
  [AttendeeKeys.inviteeEmailType]: LookupId<'EmailTypes'>;
  [AttendeeKeys.companyReference]: string;
  [AttendeeKeys.registrationType]: RegistrationType;
  [AttendeeKeys.qualifiedAs]: QualifiedAs;
  [AttendeeKeys.mgaId]: string;
  [AttendeeKeys.agencyId]: string;
  [AttendeeKeys.inviteeStatus]: InviteeStatus;
  [AttendeeKeys.inviteeOutcomeStatus]: InviteeStatus;
  [AttendeeKeys.phoneNumber]?: PhoneNumber;

  [AttendeeKeys.prefix]?: LookupId<'Prefixes'>;
  [AttendeeKeys.firstName]: string;
  [AttendeeKeys.middleName]?: string;
  [AttendeeKeys.lastName]: string;
  [AttendeeKeys.suffix]?: LookupId<'Suffixes'>;
  [AttendeeKeys.gender]?: LookupId<'Genders'>;
  [AttendeeKeys.dob]?: LocalDateTimeString;

  [AttendeeKeys.badgeName]?: string;
  [AttendeeKeys.awardName]?: string;
  [AttendeeKeys.tShirtSizes]?: TShirtSizes;
  [AttendeeKeys.childOrAdult]?: InviteeAgeGroup = null;
  [AttendeeKeys.firstTimeAttendee]?: boolean = false;

  [AttendeeKeys.dietaryConsideration]?: DietaryConsideration;
  [AttendeeKeys.associationType]?: LookupId<'AssociationType'>;

  constructor(
    conference: Conference,
    readonly registrant: Registrant,
    readonly guest: GuestData = null,
  ) {
    this[AttendeeKeys.stepInProgress] = getCurrentStepInProgress({ conference, conferenceRegistration: registrant });
    this[AttendeeKeys.registrationState] = calculateWizardState(registrant, conference);

    this.init();
  }

  private init() {
    if (this.guest) {
      this.getAttendeeFromGuest();
      return;
    }

    this.getAttendeeFromRegistrant();
  }

  private getAttendeeFromRegistrant() {
    this[AttendeeKeys.dbId] = this.registrant?.[BaseModelKeys.dbId];
    this[AttendeeKeys.conferenceDbId] = this.registrant?.[RegistrantKeys.conferenceDbId];
    this[AttendeeKeys.attendeeType] = AttendeeType.Invitee;
    this[AttendeeKeys.childOrAdult] = InviteeAgeGroup.adult;

    const registrantData: RegistrantData = this.registrant?.[RegistrantModelKeys.data];

    const registrantToAttendeeMap = new Map<RegistrantKeys, AttendeeKeys>([
      [RegistrantKeys.prefix, AttendeeKeys.prefix],
      [RegistrantKeys.firstName, AttendeeKeys.firstName],
      [RegistrantKeys.middleName, AttendeeKeys.middleName],
      [RegistrantKeys.lastName, AttendeeKeys.lastName],
      [RegistrantKeys.suffix, AttendeeKeys.suffix],
      [RegistrantKeys.gender, AttendeeKeys.gender],
      [RegistrantKeys.dob, AttendeeKeys.dob],
      [RegistrantKeys.dietaryConsideration, AttendeeKeys.dietaryConsideration],
      [RegistrantKeys.badgeName, AttendeeKeys.badgeName],
      [RegistrantKeys.tShirtSizes, AttendeeKeys.tShirtSizes],
    ]);

    this.attendeeCommonFields.forEach((attendeeKey, registrantKey) => {
      this[attendeeKey] = registrantData?.[registrantKey];
    });

    registrantToAttendeeMap.forEach((attendeeKey, registrantKey) => {
      this[attendeeKey] = registrantData?.[registrantKey];
    });
  }

  private getAttendeeFromGuest() {
    this[AttendeeKeys.dbId] = this.guest?.[BaseModelKeys.dbId];
    this[AttendeeKeys.attendeeType] = AttendeeType.Guest;

    const registrantData: RegistrantData = this.registrant?.[RegistrantModelKeys.data];

    const guestToAttendeeMap = new Map<AssociationKeys | GuestKeys, AttendeeKeys>([
      [AssociationKeys.firstName, AttendeeKeys.firstName],
      [AssociationKeys.middleName, AttendeeKeys.middleName],
      [AssociationKeys.lastName, AttendeeKeys.lastName],
      [AssociationKeys.prefix, AttendeeKeys.prefix],
      [AssociationKeys.suffix, AttendeeKeys.suffix],
      [AssociationKeys.gender, AttendeeKeys.gender],
      [AssociationKeys.dob, AttendeeKeys.dob],
      [AssociationKeys.dietaryConsideration, AttendeeKeys.dietaryConsideration],
      [AssociationKeys.associationType, AttendeeKeys.associationType],
      [GuestKeys.badgeName, AttendeeKeys.badgeName],
      [AssociationKeys.contactNumber, AttendeeKeys.phoneNumber],
      [AssociationKeys.tShirtSizes, AttendeeKeys.tShirtSizes],
      [GuestKeys.childOrAdult, AttendeeKeys.childOrAdult],
    ]);

    this.attendeeCommonFields.forEach((attendeeKey, registrantKey) => {
      this[attendeeKey] = registrantData?.[registrantKey];
    });

    guestToAttendeeMap.forEach((attendeeKey, guestKey) => {
      const guestData = this.guest?.[guestKey];

      this[attendeeKey] = guestData;
    });
  }

  private attendeeCommonFields = new Map<RegistrantKeys, AttendeeKeys>([
    [RegistrantKeys.awardName, AttendeeKeys.awardName],
    [RegistrantKeys.firstTimeAttendee, AttendeeKeys.firstTimeAttendee],
    [RegistrantKeys.inviteeStatus, AttendeeKeys.inviteeStatus],
    [RegistrantKeys.inviteeOutcomeStatus, AttendeeKeys.inviteeOutcomeStatus],
    [RegistrantKeys.inviteeEmail, AttendeeKeys.inviteeEmail],
    [RegistrantKeys.inviteeEmailType, AttendeeKeys.inviteeEmailType],
    [RegistrantKeys.mobilePhone, AttendeeKeys.phoneNumber],
    [RegistrantKeys.mgaId, AttendeeKeys.mgaId],
    [RegistrantKeys.agencyId, AttendeeKeys.agencyId],
    [RegistrantKeys.registrationType, AttendeeKeys.registrationType],
    [RegistrantKeys.qualifiedAs, AttendeeKeys.qualifiedAs],
    [RegistrantKeys.companyReference, AttendeeKeys.companyReference],
    [RegistrantKeys.emergencyContact, AttendeeKeys.emergencyContact],
  ]);
}
