import { Injectable } from '@angular/core';
import { Lookup, LookupKeys, TShirtSizes, TShirtSizesKeys } from '@ag-common-lib/public-api';
import { updateDoc } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Injectable()
export class TShirtSeizesFormService {
  private _selectedTShortSize$ = new BehaviorSubject<Lookup>(null);
  selectedTShortSize$ = this._selectedTShortSize$.asObservable();
  private _selectedUnisexTShortSize$ = new BehaviorSubject<Lookup>(null);
  selectedUnisexTShortSize$ = this._selectedUnisexTShortSize$.asObservable();

  constructor() {}

  lockLookups = () => {
    const assignedLookups = [this._selectedTShortSize$.value, this._selectedUnisexTShortSize$.value];
    const promises = assignedLookups
      .filter(lookup => lookup && !lookup?.isAssigned)
      .map(lookup => updateDoc(lookup?.reference, { [LookupKeys.isAssigned]: true }));

    return Promise.all(promises);
  };

  getFormData = (initialData: TShirtSizes) => {
    this.handleTShortSizeSelect(initialData?.[TShirtSizesKeys.tShirtSize]?.[BaseModelKeys.dbId]);
    this.handleUnisexTShortSizeSelect(initialData?.[TShirtSizesKeys.unisexTShirtSize]?.[BaseModelKeys.dbId]);

    const fromData = new Proxy(initialData ?? {}, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];

        if (value !== prevValue) {
          Reflect.set(target, prop, value, receiver);

          switch (prop) {
            case TShirtSizesKeys.tShirtSize:
              const prevSelectedTShortSize = this._selectedTShortSize$.value;

              if (prevSelectedTShortSize?.[LookupKeys.description]?.toLocaleLowerCase() === 'other') {
                Object.assign(fromData, { [TShirtSizesKeys.tShirtSizeOther]: null });
              }
              break;
            case TShirtSizesKeys.unisexTShirtSize:
              const prevUnisexSelectedTShortSize = this._selectedUnisexTShortSize$.value;

              if (prevUnisexSelectedTShortSize?.[LookupKeys.description]?.toLocaleLowerCase() === 'other') {
                Object.assign(fromData, { [TShirtSizesKeys.unisexTShirtSizeOther]: null });
              }
              break;
          }
        }

        return true;
      },
    });

    return fromData;
  };

  handleTShortSizeSelect = item => {
    this._selectedTShortSize$.next(item);
  };

  handleUnisexTShortSizeSelect = item => {
    this._selectedUnisexTShortSize$.next(item);
  };
}
