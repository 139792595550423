import { Component, Input } from '@angular/core';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import {
  Agent,
  AgentKeys,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  ChangeSourceType,
} from 'ag-common-lib/public-api';
import { AgentService } from 'ag-common-svc/public-api';

@Component({
  selector: 'ag-crm-doi',
  templateUrl: './doi.component.html',
  styleUrls: ['./doi.component.scss'],
})
export class DoiComponent {
  @Input() agent: Agent;
  @Input() sourceType: ChangeSourceType | null = null;

  editDOI: boolean = false;

  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(private agentService: AgentService) {}

  saveChanges = () => {
    this.agentService
      .updateAgentFields(
        this.agent?.[BaseModelKeys.dbId],
        { [AgentKeys.npn]: this.agent?.[AgentKeys.npn] },
        this.sourceType,
      )
      .then(() => {
        this.editDOI = false;
      });
  };
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
}
