import { Injectable } from '@angular/core';
import { AuthService, ConferenceRegistrantsService, ConferenceRegistrationLogsService } from 'ag-common-svc/public-api';
import {
  ConferenceKeys,
  ConferencePossibleStayPeriod,
  ConferencePossibleStayPeriodKey,
  ConferenceRegistrationCategoryName,
  ConferenceRegistrationCommonTask,
  ConferenceRegistrationLog,
  HotelReservation,
  HotelReservationKeys,
  Registrant,
  RegistrantKeys,
  RegistrantModelKeys,
  RequestOutcome,
  RequestOutcomeKeys,
  RequestOutcomeState,
} from 'ag-common-lib/public-api';
import { combineLatest, firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { AttendeeDetailsModalService } from '../attendee-details-modal.service';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@Injectable()
export class AttendeeHotelReservationService {
  isRequestedAdditionalRoom$: Observable<boolean>;
  additionalRoomRequestOutcome$: Observable<RequestOutcome>;
  requestDifferBookingDatesOutcome$: Observable<RequestOutcome>;
  hotelTaskStatus$: Observable<ConferenceRegistrationCommonTask>;

  conferencePossibleStayPeriod$: Observable<ConferencePossibleStayPeriod> = combineLatest({
    conference: this.attendeeDetailsModalService.conference$,
    registrantData: this.attendeeDetailsModalService.registrant$.pipe(
      map(registrant => registrant?.[RegistrantModelKeys.data]),
    ),
  }).pipe(
    map(({ conference, registrantData }) => {
      const registrationType = registrantData?.[RegistrantKeys.registrationType];
      const participantScheduleRanges = conference?.[ConferenceKeys.participantScheduleRanges];

      return participantScheduleRanges.find(
        range => range?.[ConferencePossibleStayPeriodKey.registrationType] === registrationType,
      );
    }),
  );

  constructor(
    private authService: AuthService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
    private attendeeDetailsModalService: AttendeeDetailsModalService,
    private conferenceRegistrationLogsService: ConferenceRegistrationLogsService,
  ) {
    this.isRequestedAdditionalRoom$ = this.attendeeDetailsModalService.registrant$.pipe(
      map((conferenceRegistration: Registrant) => {
        return conferenceRegistration?.[RegistrantModelKeys.data]?.[RegistrantKeys.hotelReservation]?.[
          HotelReservationKeys.additionalRoomRequested
        ];
      }),
      shareReplay(1),
    );

    this.additionalRoomRequestOutcome$ = this.attendeeDetailsModalService.registrant$.pipe(
      map((conferenceRegistration: Registrant) => {
        return conferenceRegistration?.[RegistrantModelKeys.data]?.[RegistrantKeys.hotelReservation]?.[
          HotelReservationKeys.additionalRoomRequestOutcome
        ];
      }),
      shareReplay(1),
    );

    this.requestDifferBookingDatesOutcome$ = this.attendeeDetailsModalService.registrant$.pipe(
      map((conferenceRegistration: Registrant) => {
        return conferenceRegistration?.[RegistrantModelKeys.data]?.[RegistrantKeys.hotelReservation]?.[
          HotelReservationKeys.requestDifferBookingDatesOutcome
        ];
      }),
      shareReplay(1),
    );

    this.hotelTaskStatus$ = this.attendeeDetailsModalService.registrant$.pipe(
      map(registrant => {
        return registrant?.[RegistrantModelKeys.task]?.[
          ConferenceRegistrationCategoryName.registrantHotelReservationCategory
        ];
      }),
      shareReplay(1),
    );
  }

  handleDifferBookingDatesRequestOutcome = async (requestOutcomeState: RequestOutcomeState) => {
    const loggedInAgent = await firstValueFrom(this.authService.loggedInAgent$);
    const loggedInAgentDbId = loggedInAgent?.[BaseModelKeys.dbId];

    return this.saveRequestOutcome({
      [HotelReservationKeys.requestDifferBookingDatesOutcome]: {
        [RequestOutcomeKeys.state]: requestOutcomeState,
        [RequestOutcomeKeys.processedBy]: loggedInAgentDbId,
      },
    });
  };

  handleAdditionalRoomRequestOutcome = async (requestOutcomeState: RequestOutcomeState) => {
    const loggedInAgent = await firstValueFrom(this.authService.loggedInAgent$);
    const loggedInAgentDbId = loggedInAgent?.[BaseModelKeys.dbId];

    return this.saveRequestOutcome({
      [HotelReservationKeys.additionalRoomRequestOutcome]: {
        [RequestOutcomeKeys.state]: requestOutcomeState,
        [RequestOutcomeKeys.processedBy]: loggedInAgentDbId,
      },
    });
  };

  updateHotelTask = async (task: Partial<ConferenceRegistrationCommonTask>) => {
    const registrant = await firstValueFrom(this.attendeeDetailsModalService.registrant$);
    const registrantId = registrant[BaseModelKeys.dbId];
    const conferenceDbId = registrant[RegistrantModelKeys.conferenceDbId];

    const update = {
      [RegistrantModelKeys.task]: {
        [ConferenceRegistrationCategoryName.registrantHotelReservationCategory]: task,
      },
    };

    await this.conferenceRegistrantsService.update(conferenceDbId, registrantId, update);
  };

  private saveRequestOutcome = async (hotelReservation: Partial<HotelReservation>) => {
    const registrant = await firstValueFrom(this.attendeeDetailsModalService.registrant$);
    const conferenceDbId = registrant?.[RegistrantModelKeys.conferenceDbId];
    const registrantDbId = registrant?.[BaseModelKeys.dbId];

    return this.conferenceRegistrantsService.update(
      conferenceDbId,
      registrantDbId,
      {
        [RegistrantModelKeys.data]: {
          [RegistrantKeys.hotelReservation]: hotelReservation,
        },
      },
      true,
    );
  };

  saveLog = async (log: ConferenceRegistrationLog) => {
    const registrant = await firstValueFrom(this.attendeeDetailsModalService.registrant$);
    const registrantId = registrant[BaseModelKeys.dbId];
    const conferenceDbId = registrant[RegistrantModelKeys.conferenceDbId];
    return this.conferenceRegistrationLogsService.create(conferenceDbId, registrantId, log);
  };
}
