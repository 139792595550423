import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  AgentCarrier,
  AgentCarrierModelKeys,
  AgentKeys,
  CARRIER_LINE_OF_BUSINESS_LOOKUP,
  CARRIER_ORGANIZATION_LOOKUP,
  CarrierModelKeys,
  Messages,
  ChangeSourceType,
} from '@ag-common-lib/public-api';
import { CarrierService, AgentService } from 'ag-common-svc/public-api';
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { Entity } from '../../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/lists/entity-permission.enum';
import { EntityPermissionModelKeys } from '../../../../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/entity-permission.model';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-carriers-grid',
  templateUrl: './carriers-grid.component.html',
  styleUrls: ['./carriers-grid.component.scss'],
  providers: [CarrierService, ToastrService],
})
export class CarriersGridComponent {
  @ViewChild('carriersGridRef', { static: false }) carriersGridComponent: DxDataGridComponent;
  @Input() carriers: AgentCarrier[] = [];
  @Input() isEditable: boolean = true;
  @Input() canEdit: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() canCreate: boolean = true;
  @Input() agentId: string;
  @Input() sourceType: ChangeSourceType | null = null;
  @Output() carriersChange = new EventEmitter();

  carriersLookup$ = this.carrierService.getList();

  readonly inProgress$ = new BehaviorSubject<boolean>(false);
  protected readonly CarrierModelKeys = CarrierModelKeys;
  protected readonly AgentCarrierModelKeys = AgentCarrierModelKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly Messages = Messages;
  protected readonly carrierLineOfBusinessLookup = CARRIER_LINE_OF_BUSINESS_LOOKUP;
  protected readonly carrierOrganizationLookup = CARRIER_ORGANIZATION_LOOKUP;
  protected readonly emptyMessage = 'No Agent Carriers Currently Exist';

  constructor(
    private carrierService: CarrierService,
    private agentService: AgentService,
  ) {}

  onInitNewRow = e => {
    e.data.is_active = !this.carriers?.length;
  };

  onRowInserting = e => {
    const { __KEY__: key, ...data } = e?.data;
    const carriers = this.normalizeCarriers(data);
    carriers.push(Object.assign({ id: key }, data));

    e.cancel = this.updateCarriers(carriers);
  };

  onRowUpdating = e => {
    const data = Object.assign({}, e?.oldData, e?.newData);
    const carriers = this.normalizeCarriers(data, e?.key);
    e.cancel = this.updateCarriers(carriers);
  };

  onRowRemoving = e => {
    const carriers = this.carriers.filter(carrier => carrier.id !== e.key.id);
    e.cancel = this.updateCarriers(carriers);
  };

  onCancel = e => {
    this.carriersGridComponent?.instance?.cancelEditData();
  };

  private normalizeCarriers = (data, key?: AgentCarrier) => {
    return this.carriers.map(carrier => {
      if (key && carrier.id === key.id) {
        return data;
      }

      const normalizedCarriers = Object.assign({}, carrier);
      return normalizedCarriers;
    });
  };

  private updateCarriers = carriers => {
    this.inProgress$.next(true);
    return this.agentService
      .updateAgentFields(this.agentId, { [AgentKeys.agent_carriers]: carriers }, this.sourceType)
      .then(() => {
        this.carriers = carriers;
        this.carriersChange.emit(carriers);
      })
      .finally((): void => {
        this.inProgress$.next(false);
      });
  };
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
}
