import { Component, Input, ViewChild } from '@angular/core';
import { MediaAction } from '../../components/ag-media-gallery/ag-media-gallery.models';
import { AgentMediaComponentService } from './agent-media.service';
import { BehaviorSubject, map } from 'rxjs';
import { AgMediaUploaderModalComponent } from '../ag-media-uploader/ag-media-uploader-modal.component';
import { AGMedia, AgentFileDirectory, AgentFileDirectoryKeys, ChangeSourceType } from '@ag-common-lib/public-api';
import { confirm } from 'devextreme/ui/dialog';
import { ToastrService } from 'ngx-toastr';
import { AgentFileDirectoriesService } from '../../services/agent-files-directories.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AgentFilesMediaService } from '../../services/agent-files-media.service';
import { MediaUploaderTabs } from '../ag-media-uploader/ag-media-uploader-modal.models';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agent-media',
  templateUrl: './agent-media.component.html',
  styleUrls: ['./agent-media.component.scss'],
})
export class AgentMediaComponent {
  @ViewChild(AgMediaUploaderModalComponent, { static: false }) mediaUploaderComponent: AgMediaUploaderModalComponent;

  @Input() set agentId(agentId: string) {
    this.agentMediaComponentService.setAgentId(agentId);
    this.mediaPathPrefix$.next(`agents/${agentId}`);
    this._agentDbId = agentId;
    this.refreshDirectories();
  }
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() canCreate = true;
  @Input() sourceType: ChangeSourceType | null = null;

  mediaPathPrefix$ = new BehaviorSubject<string>('');
  directoriesDataSource: AgentFileDirectory[];
  extraMediaActions: MediaAction[] = [
    // {
    //   icon: 'mdi mdi-flag-triangle',
    //   text: 'Set as Primary',
    //   actionFn: this.onSetPrimaryClicked.bind(this),
    // },
  ];

  selectedItemIndex: number | null = 0;
  folderName = '';

  protected readonly MediaUploaderTabs = MediaUploaderTabs;

  private _agentDbId: string;

  constructor(
    protected agentMediaComponentService: AgentMediaComponentService,
    private toastr: ToastrService,
    private agentFileDirectoriesService: AgentFileDirectoriesService,
    private agentFilesMediaService: AgentFilesMediaService,
  ) {}

  onSetPrimaryClicked(imageItem: AGMedia): void {
    this.agentMediaComponentService.setAsPrimaryImage(this.agentId, imageItem);
  }

  onDeleteClicked(imageItem: AGMedia): void {
    this.agentMediaComponentService.deleteMedia(this._agentDbId, imageItem, this.sourceType);
  }

  itemClick(data): void {
    const item = data.itemData;
    this.agentMediaComponentService.setCurrentDirectory(item);
  }

  onItemClick(index: number): void {
    this.selectedItemIndex = index;
  }

  uploadFileClicked(): void {
    this.mediaUploaderComponent.showModal();
  }

  onMediaChanged(media: AGMedia): void {
    this.agentMediaComponentService.createMedia(this._agentDbId, media, this.sourceType);
  }

  addFolder(): void {
    if (this.folderName) {
      const isCurrentFolderNameExist =
        this.directoriesDataSource?.filter(directory => directory.name == this.folderName).length > 0;
      if (isCurrentFolderNameExist) {
        this.toastr.error(`Folder Name "${this.folderName}" already exists`);
        return;
      }

      const directory: AgentFileDirectory = {
        [AgentFileDirectoryKeys.agentDbId]: this._agentDbId,
        [AgentFileDirectoryKeys.name]: this.folderName,
        [AgentFileDirectoryKeys.isSystem]: false,
      };

      this.agentMediaComponentService.createFolder(directory).then(() => {
        this.folderName = '';
        this.refreshDirectories();
      });
    }
  }

  async deleteFolder(folder: AgentFileDirectory): Promise<void> {
    const folderName = folder[AgentFileDirectoryKeys.name] ?? '';
    const agentId = folder[AgentFileDirectoryKeys.agentDbId];

    await this.agentFilesMediaService
      .getList(agentId, folder[BaseModelKeys.dbId])
      .pipe(
        map(items => {
          if (items?.length) {
            this.toastr.error(`Folder "${folderName}" contains media. Please remove media before.`);
            return;
          }
          const result = confirm(`<i>Are you sure you want to Delete Folder "${folderName}"?</i>`, 'Confirm');
          result.then(dialogResult => {
            if (dialogResult) {
              this.agentMediaComponentService.deleteFolder(agentId, folder[BaseModelKeys.dbId]).then(() => {
                this.refreshDirectories();
              });
            }
          });
        }),
      )
      .subscribe();
  }

  private refreshDirectories(): void {
    this.agentFileDirectoriesService
      .getList(this._agentDbId)
      .pipe(
        map(fileDirectories => {
          this.directoriesDataSource = fileDirectories ?? [];
          if (!!fileDirectories) {
            this.setDefaultDirectory(fileDirectories);
          }
        }),
      )
      .subscribe();
  }

  private setDefaultDirectory(fileDirectories: AgentFileDirectory[]): void {
    const defaultIndex = 0;
    this.agentMediaComponentService.setCurrentDirectory(fileDirectories[defaultIndex]);
    this.selectedItemIndex = defaultIndex;
  }
}
