import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { DxFormComponent } from 'devextreme-angular';
import { CampaignsAddIssuesModalService } from './campaigns-add-issues-modal.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  AgentCampaignStepName,
  CAMPAIGNS_MANAGEMENT_ISSUES_FIELD_LOOKUP,
  CAMPAIGNS_MANAGEMENT_ISSUES_STATUS_LOOKUP,
  CAMPAIGNS_MANAGEMENT_ISSUES_TYPE_LOOKUP,
  CampaignsManagementIssuesStatus,
  CampaignsManagementIssuesType,
  CampaignsManagementTaskIssues,
  CampaignsManagementTaskIssuesKeys,
  CampaignsManagementTaskLogsAction,
  CampaignsManagementTasks,
  CampaignsManagementTasksKeys,
  CampaignsManagementTasksStatus,
  LookupKeys,
  Messages,
} from 'ag-common-lib/public-api';
import { AgentCampaignsManagementTaskLogsService } from 'ag-common-svc/lib/services/agent-campaigns-management-task-logs.service';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';

@UntilDestroy()
@Component({
  selector: 'ag-crm-campaign-issues-modal',
  templateUrl: './campaigns-add-issues-modal.component.html',
  styleUrls: ['./campaigns-add-issues-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CampaignsAddIssuesModalService],
})
export class CampaignsAddIssuesModalComponent {
  @ViewChild('campaignsIssuesModalRef', { static: true }) campaignsIssuesModalComponent: ModalWindowComponent;
  @ViewChild('campaignsIssuesFormRef', { static: false }) campaignsIssuesFormComponent: DxFormComponent;
  @Output() updateIssues = new EventEmitter();
  inProgress$ = this.campaignsIssuesModalService.inProgress$;
  campaignsIssuesFormData: Partial<CampaignsManagementTaskIssues>;
  agentDbId?: string;
  taskDbId?: string;
  selectedTaskStatus?: CampaignsManagementTasksStatus;
  protected readonly CampaignsManagementTaskIssuesKeys = CampaignsManagementTaskIssuesKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly CampaignsManagementTaskIssuesLookup = CAMPAIGNS_MANAGEMENT_ISSUES_STATUS_LOOKUP;
  protected readonly CampaignsManagementTaskIssuesTypeLookup = CAMPAIGNS_MANAGEMENT_ISSUES_TYPE_LOOKUP;
  protected readonly CampaignsManagementTaskIssuesFieldLookup = CAMPAIGNS_MANAGEMENT_ISSUES_FIELD_LOOKUP;
  protected readonly Messages = Messages;

  constructor(
    private campaignsIssuesModalService: CampaignsAddIssuesModalService,
    private taskLogsService: AgentCampaignsManagementTaskLogsService,
  ) {}

  showModal = async (
    selectedTask: CampaignsManagementTasks,
    selectedIssue?: CampaignsManagementTaskIssues,
  ): Promise<void> => {
    this.agentDbId = selectedTask[CampaignsManagementTasksKeys.agentCampaignDbId];
    this.taskDbId = selectedIssue[CampaignsManagementTaskIssuesKeys.taskDbId] ?? selectedTask[BaseModelKeys.dbId];
    this.selectedTaskStatus = selectedTask[CampaignsManagementTasksKeys.taskStatus];
    this.campaignsIssuesFormData = await this.campaignsIssuesModalService.getFormData(selectedIssue);
    this.campaignsIssuesModalComponent?.showModal();
  };

  hideModal = (): void => {
    this.campaignsIssuesModalComponent.hideModal();
    this.updateIssues.emit(true);
  };

  onAfterRevertChanges = (): void => {
    this.campaignsIssuesFormComponent.instance.repaint();
  };

  handleClosePopup = () => this.campaignsIssuesModalService.onCancelEdit.call(this, this.onAfterRevertChanges);

  handleCampaignsIssuesSaveClick = (): void => {
    const validationResults = this.campaignsIssuesFormComponent.instance.validate();
    if (validationResults.isValid) {
      const formData = this.campaignsIssuesFormData;
      const issueDbId = this.campaignsIssuesFormData[BaseModelKeys.dbId] ?? null;
      const assignedTo = formData?.[CampaignsManagementTaskIssuesKeys.assignedTo] ?? '';
      const assignedAt = formData?.[CampaignsManagementTaskIssuesKeys.assignedTo] ? new Date() : null;
      const agentDbId = this.agentDbId;
      const taskDbId = this.taskDbId;
      const issueUpdate = {
        [CampaignsManagementTaskIssuesKeys.taskDbId]: taskDbId,
        [CampaignsManagementTaskIssuesKeys.updatedStatusAt]: new Date(),
        [CampaignsManagementTaskIssuesKeys.description]:
          formData?.[CampaignsManagementTaskIssuesKeys.description] ?? '',
        [CampaignsManagementTaskIssuesKeys.issueStatus]:
          formData?.[CampaignsManagementTaskIssuesKeys.issueStatus] ?? CampaignsManagementIssuesStatus.posted,
        [CampaignsManagementTaskIssuesKeys.assignedTo]: assignedTo,
        [CampaignsManagementTaskIssuesKeys.assignedAt]: assignedAt,
        [CampaignsManagementTaskIssuesKeys.issueType]:
          formData?.[CampaignsManagementTaskIssuesKeys.issueType] ?? CampaignsManagementIssuesType.content,
        [CampaignsManagementTaskIssuesKeys.issueField]:
          formData?.[CampaignsManagementTaskIssuesKeys.issueField] ?? AgentCampaignStepName.webSite,
      };
      this.campaignsIssuesModalService.saveIssue(agentDbId, taskDbId, issueUpdate, issueDbId).then(() => {
        this.hideModal();
      });
    }
  };
}
