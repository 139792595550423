import { FlightInfoKeys, FlightInformationModel } from 'ag-common-lib/lib/models/registration/flight-information.model';

export type FlightPreference = Pick<
  FlightInformationModel,
  | FlightInfoKeys.preferredAirline
  | FlightInfoKeys.otherPreferredAirline
  | FlightInfoKeys.preferredAirlineLoyaltyNumber
  | FlightInfoKeys.secondChoiceAirline
  | FlightInfoKeys.otherSecondChoiceAirline
  | FlightInfoKeys.secondChoiceAirlineLoyaltyNumber
  | FlightInfoKeys.knownTravelerNumber
  | FlightInfoKeys.seatPreference
  | FlightInfoKeys.preferredDepartureAirport
>;

export const FlightPreferencesKeysToPick = [
  FlightInfoKeys.preferredAirline,
  FlightInfoKeys.otherPreferredAirline,
  FlightInfoKeys.preferredAirlineLoyaltyNumber,
  FlightInfoKeys.secondChoiceAirline,
  FlightInfoKeys.otherSecondChoiceAirline,
  FlightInfoKeys.secondChoiceAirlineLoyaltyNumber,
  FlightInfoKeys.knownTravelerNumber,
  FlightInfoKeys.seatPreference,
  FlightInfoKeys.preferredDepartureAirport,
] as const;


export const FlightPreferenceMap = {
  [FlightInfoKeys.preferredAirline]: 'Pref. Airline',
  [FlightInfoKeys.otherPreferredAirline]: 'Other Airline',
  [FlightInfoKeys.preferredAirlineLoyaltyNumber]: 'Pref. Airline Loyalty #',
  [FlightInfoKeys.secondChoiceAirline]: 'Second Choice Airline',
  [FlightInfoKeys.otherSecondChoiceAirline]: 'Other Second Choice Airline',
  [FlightInfoKeys.secondChoiceAirlineLoyaltyNumber]: 'Second Choice Airline Loyalty #',
  [FlightInfoKeys.knownTravelerNumber]: 'Known Traveler #',
  [FlightInfoKeys.seatPreference]: 'Seat Preference',
  [FlightInfoKeys.preferredDepartureAirport]: 'Pref. Departure Airport',
};
