import { Inject, Injectable } from '@angular/core';
import { DeliveredPushNotification } from '@ag-common-lib/lib';
import { AGENTS_COLLECTION_NAME, AgentDocumentCollections } from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { map } from 'rxjs';
import { CommonFireStoreDao, QueryParam, FetchOptions } from 'ag-common-svc/lib/dao/CommonFireStoreDao.dao';
import { FIREBASE_APP } from 'ag-common-svc/lib/injections/firebase-app';

@Injectable()
export class AgentPushNotificationsService {
  public readonly fsDao: CommonFireStoreDao<DeliveredPushNotification>;
  private readonly agentCollectionPath = AGENTS_COLLECTION_NAME;
  private readonly pushNotificationsCollectionPath = AgentDocumentCollections.pushNotifications;

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    this.fsDao = new CommonFireStoreDao<DeliveredPushNotification>(fireBaseApp, null, null);
  }

  getList(agentId: string, qp: QueryParam[] = [], fetchOptions?: FetchOptions) {
    const table = this.getCollectionPath(agentId);

    return this.fsDao.getList(table, qp, fetchOptions);
  }

  getNotification(agentId: string, notificationDbId: string) {
    const table = this.getCollectionPath(agentId);

    return this.fsDao.getDocument(table, notificationDbId).pipe(
      map(snapshot => {
        return snapshot.data();
      }),
    );
  }

  async update(agentId: string, documentId: string, updates: Partial<DeliveredPushNotification>, silent = false) {
    debugger;
    const table = this.getCollectionPath(agentId);

    const pushNotification = await this.fsDao.updateFields(updates, documentId, table).catch(e => {
      // TODO add error toast
      console.log('e', e);
      throw new Error(e);
    });

    return pushNotification;
  }

  private getCollectionPath(agentId: string) {
    return [this.agentCollectionPath, agentId, this.pushNotificationsCollectionPath].join('/');
  }
}
