import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import {
  Agent,
  EditorOptions,
  Lookup,
  LookupKeys,
  NeedToKnow,
  NeedToKnowKeys,
  NEED_TO_KNOW_VISIBILITY_LEVEL_LOOKUP,
  ChangeSourceType
} from '@ag-common-lib/public-api';
import { DxFormComponent } from 'devextreme-angular';
import { map } from 'rxjs/operators';
import { NeedToKnowModalService } from './need-to-know-modal.service';
import { AgentService } from '../../../../../services/agent.service/agent.service';
import { ModalWindowComponent } from '../../../../modal-window/modal-window.component';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-need-to-know-modal',
  templateUrl: './need-to-know-modal.component.html',
  styleUrls: ['./need-to-know-modal.component.scss'],
  providers: [NeedToKnowModalService],
})
export class NeedToKnowModalComponent {
  @HostBinding('class') className = 'need-to-know-modal';
  @ViewChild('needToKnowModalRef', { static: true }) needToKnowModalComponent: ModalWindowComponent;
  @ViewChild('needToKnowFormRef', { static: false }) needToKnowFormComponent: DxFormComponent;
  @Input() title: string;
  @Input() isVisibilityTypeLocked = false;

  inProgress$ = this.needToKnowModalService.inProgress$;
  agentsDataSource$ = this.agentService.agentsDataSource$;

  readonly BaseModelKeys = BaseModelKeys;
  readonly NeedToKnowKeys = NeedToKnowKeys;
  readonly needToKnowVisibilityLevelLookup = NEED_TO_KNOW_VISIBILITY_LEVEL_LOOKUP;
  needToKnowFormData: NeedToKnow;
  dateTimeEditorOptions = { ...EditorOptions.DATE_TIME, readOnly: true };

  private agentId: string;
  sourceType: ChangeSourceType | null

  constructor(
    private agentService: AgentService,
    private needToKnowModalService: NeedToKnowModalService,
  ) {}

  showModal = async (agentId: string, data?: NeedToKnow, sourceType: ChangeSourceType | null = null) => {
    this.agentId = agentId;
    this.sourceType = sourceType;
    this.needToKnowFormData = await this.needToKnowModalService.getFormData(data);
    this.needToKnowModalComponent?.showModal();
  };

  handleSaveNeedToKnow = e => {
    const validationResults = this.needToKnowFormComponent.instance.validate();
    if (validationResults.isValid) {
      this.needToKnowModalService.saveNeedToKnow(this.agentId, this.sourceType).then(() => {
        e.component.instance.hide();
      });
    }
  };

  handleNeedToKnowFormPopupClose = this.needToKnowModalService.onCancelEditNeedToKnow;
}
