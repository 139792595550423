import { Component, Input, ViewChild } from '@angular/core';
import {
  StripeTransaction,
  StripeTransactionKeys,
  StripeTransactionRelatesToType,
  TRANSACTION_TYPE_LOOKUP,
  TRANSACTIONS_STATUSES_LOOKUP,
  TransactionsStatuses,
  TransactionType,
} from '@ag-common-lib/lib/models/stripe/stripe-transaction.model';
import {
  Agent,
  AgStripeSubtotalKeys,
  DX_USD_CURRENCY_FORMAT,
  LookupKeys,
  ProcessRefundPayloadKeys,
  RefundReasonKeys,
} from '@ag-common-lib/public-api';
import { RowRemovingEvent } from 'devextreme/ui/tree_list';
import { AttendeeTransactionModalComponent } from '../attendee-transaction-modal/attendee-transaction-modal.component';
import { PaymentMethodCreateParams } from '@stripe/stripe-js';
import { AttendeeRefundPaymentModalComponent } from '../attendee-refund-payment-modal/attendee-refund-payment-modal.component';
import { AttendeeTransactionDetailsModalComponent } from '../attendee-transaction-details-modal/attendee-transaction-details-modal.component';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { AgentService } from '../../services/agent.service/agent.service';
import { shareReplay } from 'rxjs';
import { AttendeeManualRefundPaymentModalComponent } from '../attendee-manual-refund-payment-modal/attendee-manual-refund-payment-modal.component';
import { BaseModelKeys } from '@ag-common-lib/lib/models/base.model';

@Component({
  selector: 'ag-shr-transactions-history',
  templateUrl: './transactions-history.component.html',
  styleUrls: ['./transactions-history.component.scss'],
})
export class TransactionsHistoryComponent {
  @ViewChild(AttendeeTransactionModalComponent, { static: true })
  transactionModalComponent: AttendeeTransactionModalComponent;
  @ViewChild(AttendeeRefundPaymentModalComponent, { static: true })
  refundPaymentModalComponent: AttendeeRefundPaymentModalComponent;
  @ViewChild(AttendeeTransactionDetailsModalComponent, { static: true })
  transactionDetailsModalComponent: AttendeeTransactionDetailsModalComponent;
  @ViewChild(AttendeeManualRefundPaymentModalComponent, { static: true })
  manualRefundModalComponent: AttendeeManualRefundPaymentModalComponent;

  @Input() transactions: StripeTransaction[];
  @Input() editMode = false;
  @Input() canDeleteTransaction = false;
  @Input() canEditTransaction = false;
  @Input() canCreateTransaction = false;
  @Input() billingDetails: PaymentMethodCreateParams.BillingDetails;
  @Input() handleSaveTransaction: (transaction: Partial<StripeTransaction>) => Promise<void>;
  @Input() handleTransactionRemoving: (registrantTransactionDbI: string) => Promise<void>;

  agentsList$ = this.agentService.getList().pipe(
    map(agents => {
      return agents?.map((agent: Agent) => {
        const description = [agent?.p_agent_first_name, agent?.p_agent_last_name].filter(Boolean).join(' ');
        return {
          value: agent?.dbId,
          description: description,
        };
      });
    }),
    shareReplay(1),
  );

  protected readonly TRANSACTION_TYPE_LOOKUP = TRANSACTION_TYPE_LOOKUP;
  protected readonly TRANSACTIONS_STATUSES_LOOKUP = TRANSACTIONS_STATUSES_LOOKUP;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;
  protected readonly StripeTransactionKeys = StripeTransactionKeys;
  protected readonly AgStripeSubtotalKeys = AgStripeSubtotalKeys;

  constructor(
    private toster: ToastrService,
    private agentService: AgentService,
  ) {}

  addTransactionClick = (): void => {
    this.transactionModalComponent.showModal({
      [StripeTransactionKeys.type]: TransactionType.log,
      [StripeTransactionKeys.status]: TransactionsStatuses.done,
      [StripeTransactionKeys.relatesToType]: StripeTransactionRelatesToType.conferenceRegistration,
    });
  };

  editTransactionClick = ({ row: { data } }): void => {
    this.transactionModalComponent.showModal(data);
  };

  detailsClick = ({ row: { data } }): void => {
    this.transactionDetailsModalComponent.showModal(data.details, data.type);
  };

  refundClick = ({ row: { data } }): void => {
    const totalAmountAvailableToRefund = data.totalAmountAvailableToRefund ?? data.amount;
    this.refundPaymentModalComponent.showModal({
      [ProcessRefundPayloadKeys.reason]: RefundReasonKeys.requestedByCustomer,
      [ProcessRefundPayloadKeys.amount]: totalAmountAvailableToRefund,
      [ProcessRefundPayloadKeys.paymentIntent]: data[StripeTransactionKeys.stripeId],
    });
  };

  allowEditingTransaction = e => {
    const type = e.row.data?.[StripeTransactionKeys.type];

    if (!this.editMode) {
      return false;
    }
    const status = e.row.data?.[StripeTransactionKeys.status];

    if (!type || !status || status === TransactionsStatuses.initial) {
      return true;
    }

    return type === TransactionType.log;
  };

  onMakeManualRefund = (): void => {
    this.manualRefundModalComponent.showModal({
      [StripeTransactionKeys.type]: TransactionType.refund,
    });
  };

  onMakeManualPayment = (): void => {
    this.transactionModalComponent.showModal({
      [StripeTransactionKeys.type]: TransactionType.manual,
      [StripeTransactionKeys.relatesToType]: StripeTransactionRelatesToType.conferenceRegistration,
    });
  };

  protected onTransactionRemoving = (e: RowRemovingEvent): void => {
    e.cancel = this.handleTransactionRemoving(e.key);
  };

  copyParentStripeId = ({ row: { data } }): void => {
    const parentStripeId = data?.[StripeTransactionKeys.stripeId];
    navigator.clipboard?.writeText(parentStripeId).then(() => {
      this.toster.success(`Stripe ID (${parentStripeId}) Successfully Copied!`);
    });
  };

  canRefundTransaction = (e): boolean => {
    const rowData = e.row.data;
    return rowData?.[StripeTransactionKeys.type] !== TransactionType.refund && !rowData?.isFullRefunded;
  };

  canShowDetailsTransaction = (e): boolean => {
    return e.row.data?.[StripeTransactionKeys.details]?.length;
  };
}
