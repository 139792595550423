import { Pipe, PipeTransform } from '@angular/core';
import { BaseModelKeys } from 'ag-common-lib/lib/models/base.model';
import { AgentElasticSearchService } from 'ag-common-svc/lib/services/elastic-search.services';
import { DxFilterOperators } from 'ag-common-svc/lib/services/elastic-search.services/base-elastic-search-service';
import { LoadOptions } from 'devextreme/data';

@Pipe({ name: 'relatedAgentsDatasource' })
export class RelatedAgentsDatasourcePipe implements PipeTransform {
  constructor(protected agentElasticSearchService: AgentElasticSearchService) {}
  transform(agentsIds: string[]) {
    const loadParams: LoadOptions = {
      filter: [],
    };

    const filter = loadParams.filter;
    agentsIds?.filter(Boolean)?.forEach(agentDbId => {
      if (filter?.length) {
        filter.push(DxFilterOperators.or);
      }
      filter.push([BaseModelKeys.dbId, DxFilterOperators.or, agentDbId]);
    });

    return this.agentElasticSearchService.getDataSource(loadParams);
  }
}
